<template>
    <div class="slider-news-content-container" :class="{'not-active-full' : !activeNewsFull, 'not-active-gradient' : !activeGradient}">
<!--      <transition name="fade-opacity-lazy">-->
<!--      <div class="swiper-button swiper-button-top" v-if="activeInfo?.length > 0 && controllerSetting.top">-->
<!--        <transition name="fade-opacity-lazy">-->
<!--          <div v-if="activeNewsSlider">-->
<!--            <img v-if="activeColor" :src="getUrlIcon(imgNameTop + '-dark.svg')" alt="icon">-->
<!--            <img v-else :src="getUrlIcon(imgNameTop + '-light.svg')" alt="icon">-->
<!--          </div>-->
<!--        </transition>-->
<!--      </div>-->
<!--      </transition>-->

      <transition name="fade-translate-x">
        <swiper @slide-change="onSwiperMain"
                v-if="!filterActive"
                @swiper="changeSettingSwiper"
                :navigation = "{
                  nextEl: '.swiper-button-bottom',
                  prevEl: '.swiper-button-top'
              }"
                :slides-per-view="settingSwiper.slidesPerView"
                :centered-slides="settingSwiper.centeredSlides"
                :modules="modules"
                :virtual="true"
                class="swiper swiper-overflow-none"
                :speed="1000"

                :breakpoints="{
                  0: {
                    allowTouchMove: true,
                    spaceBetween: 0
                  },
                  770: {
                    allowTouchMove: true,
                    spaceBetween: 16
                  },
                  1600: {
                    allowTouchMove: false,
                    spaceBetween: 32
                  },
                  4480: {
                    allowTouchMove: false,
                    spaceBetween: 64
                  }
                }"
                :mousewheel="true"
                :direction="settingSwiper.direction">
          <swiper-slide v-for="(item, index) in infoLengthSlider"
                        :key="index"
                        class="clickable"
                        :virtualIndex="index">
            <transition name="fade-opacity-lazy">
              <div class="content" v-if="activeInfo?.length > 0">
                <card-news class="content"
                           :active-full="activeNewsFull"
                           :info-news="activeInfo[index]"
                           v-if="index < activeInfo?.length"
                           :class="{'active-slide' : activeInfo[index].id === activeIndexNews}"/>
                <div class="card-news-container empty" v-else></div>
              </div>
              <div style="height: 100%" v-else></div>
            </transition>

          </swiper-slide>
        </swiper>
      </transition>
      <transition name="fade-opacity-lazy">
        <div class="swiper-button swiper-button-bottom" v-if="activeInfo?.length > 0 && controllerSetting.bottom">
          <transition name="fade-opacity-lazy">
            <div v-if="activeIndexNews < activeInfo?.length-1">
                <img v-if="activeColor" :src="getUrlIcon(imgNameBottom + '-dark.svg')" alt="icon">
                <img v-else :src="getUrlIcon(imgNameBottom + '-light.svg')" alt="icon">
            </div>
          </transition>
        </div>
      </transition>

    </div>
</template>

<script>
import CardNews from "@/components/entities/CardNews.vue";
import {Mousewheel, Navigation, Virtual} from "swiper";

export default {
  name: "SliderNews",
  components: {CardNews},
  props: {

    activeGradient: {
      type: Boolean,
      default: true
    },

    controllerSetting: {
      type: Object,
      default: {
        top: true,
        bottom: true
      }
    },
    activeInfo: {
      type: Array,
      required: true
    },
    settingSwiper: {
      type: Object,
      required: true
    },
    activeNewsFull: {
      type: Boolean,
      default: true
    },
    activeId: {
      type: Number,
      default: undefined
    }
  },

  data() {
    return {
      modules: [Virtual, Navigation, Mousewheel],
      newsSwiper: '',
      activeIndexNews: 0,
      filterActive: false,
      imgNameTop: 'single-arrow-bottom',
      imgNameBottom: 'single-arrow-top',
      activeNewsSlider: false
    }
  },

  watch: {
    newsSwiper() {
      this.checkAllowTouch();
    },
    activeInfo() {
      this.filterActive = true
      this.activeIndexNews = 0
      setTimeout(() => {
        this.filterActive = false
      }, 500)
    },
    activeIndexNews() {
      this.$emit('changeNewsSliderIndex', this.activeIndexNews)
      setTimeout(() => {
        this.activeNewsSlider = this.activeIndexNews !== 0;
        this.$emit('changeNewsSliderState', this.activeNewsSlider)
      }, 1000)
    },
    allowTouchMove() {
      this.checkAllowTouch();
    }
  },

  computed: {
    allowTouchMove() {
      return this.$store.state.allowTouchMoveState;
    },
    infoLengthSlider() {
      if(this.activeInfo?.length) {
        if(!this.activeMobile) {
          return this.activeInfo?.length + Math.round(this.settingSwiper.slidesPerView);
        } else {
          return this.activeInfo?.length;
        }
      } else {
        return 0
      }
    },
  },
  mounted() {
    this.checkAllowTouch();
  },
  methods: {
    checkAllowTouch() {
      if(this.newsSwiper !== '') {
        if(this.allowTouchMove) {
          this.newsSwiper.allowTouchMove = true
        } else {
          this.newsSwiper.allowTouchMove = false
        }
      }
    },
    changeSettingSwiper(swiper) {
      this.newsSwiper = swiper
    },
    onSwiperMain(swiper) {
      this.activeIndexNews = swiper.activeIndex
      if(this.activeIndexNews === this.activeInfo.length - 1) {
        swiper.allowSlideNext = false
      } else {
        swiper.allowSlideNext = true
      }
      swiper.update()
    },
  }
}
</script>

<style scoped lang="scss">
.swiper-slide {
  pointer-events: auto !important;
}

.slider-news-content-container {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: visible;

  .content {
    width: 100%;
    height: 100%;

    .card-news-container {
      height: 100%;
    }
  }

  .swiper-button {
    z-index: 998;
    position: absolute;
    width: 100%;
    height: 100%;

    & img {
      cursor: pointer;
      opacity: .6;
      transition: opacity .6s ease;

      &:hover {
        opacity: 1;
      }
    }

    &.swiper-button-top {
      top: 3px;
    }

    &.swiper-button-bottom {
      bottom: calc(3px + var(--main-padding-bottom));
    }


    & img {
      width: 100%;
      height: 100%;
    }
  }

  .swiper {
    pointer-events: auto;
    max-height: inherit;
    height: inherit;
    width: 100%;


  }

  &:after {
    width: 100%;
    height: 40%;
    background: linear-gradient(180deg,  rgba(34, 34, 59, 0.0) 0, var(--color-background) 85%);
    content: "";
    pointer-events: none;
    position: absolute;
    z-index: 900;
    bottom: -1%;
  }

  &.not-active-gradient {
    &:after {
      display: none;
    }
  }

  &:before {
    overflow: hidden;
    pointer-events: none;
    width: 100%;
    height: 15%;
    background: linear-gradient(0deg,  rgba(34, 34, 59, 0.0) 0, var(--color-background) 100%);
    content: "";
    position: absolute;
    z-index: 900;
    top: -1%;
    opacity: 0;
    transition: opacity .6s ease;
  }

  &.active-top {
    &:before {
      opacity: 1;
    }
  }
}


.card-news-container {
  &.empty {
    content: "";
  }
}

@media (max-width: 2560px){
  .slider-news-content-container {
    //max-height: 1090px;

    .swiper-button {
      max-width: 61px;
      max-height: 29px;
    }

    &.not-active-full {
      max-height: 597px;
      .card-news-container {
        max-height: 333px;
      }
    }
  }


  .card-news-container {
    max-height: 333px;
  }
}

@media (max-width: 2240px) {
  .slider-news-content-container {
    //max-height: 910px;

    .swiper-button {
      max-width: 61px;
      max-height: 29px;
    }

    &.not-active-full {
      max-height: 579px;
      .card-news-container {
        max-height: 350px;
      }
    }
  }

  .card-news-container {
    max-height: 400px;
  }
}


@media (max-width: 1920px) {
  .slider-news-content-container {
    //max-height: 760px;

    .swiper-button {
      max-width: 39px;
      max-height: 18px;
    }

    &.not-active-full {
      .card-news-container {
        max-height: 306px;
      }
    }
  }


  .card-news-container {
    max-height: 272px;
  }
}

@media (max-width: 1600px) {
  .slider-news-content-container {
    //max-height: 660px;

    .swiper-button {
      max-width: 30px;
      max-height: 15px;
    }


    &.not-active-full {
      .card-news-container {
        max-height: 188px;
      }
    }
  }

    .card-news-container {
      max-height: 181px;
    }
}
@media (max-height: 800px) {
  .slider-news-content-container {
    //max-height: 524px;
    .swiper-button {
      max-width: 30px;
      max-height: 15px;
    }
    &.not-active-full {
      .card-news-container {
        max-height: 203px;
      }
    }
  }

  .card-news-container {
    max-height: 225px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .slider-news-content-container {
    //max-height: 778px;
    .swiper-button {
      max-width: 26px;
      max-height: 12px;
    }
    &.not-active-full {
      .card-news-container {
        max-height: 216px;
      }
    }
  }

  .card-news-container {
    max-height: 207px;
  }
}

@media (max-width: 1133px) {
  .slider-news-content-container {
    //max-height: 566px;

    .swiper-button {
      max-width: 26px;
      max-height: 12px;
    }

    &.not-active-full {
      .card-news-container {
        max-height: 178px;
      }
    }
  }

  .card-news-container {
    max-height: 180px;
  }
}


@media (min-width: 2561px) {
  .slider-news-content-container {
    //max-height: 1819px;

    .swiper-button {
      max-width: 110px;
      max-height: 53px;
    }

    &.not-active-full {
      max-height: 1086px;
      .card-news-container {
        max-height: 712px;
      }
    }
  }


  .card-news-container {
    max-height: 800px;
  }
}


</style>