/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export var Role;
(function (Role) {
  Role["PARTNER"] = "PARTNER";
  Role["LEADER"] = "LEADER";
  Role["EMPLOYEES"] = "EMPLOYEES";
})(Role || (Role = {}));
export var AdminRole;
(function (AdminRole) {
  AdminRole["ADMIN"] = "ADMIN";
  AdminRole["MODERATOR"] = "MODERATOR";
  AdminRole["PERSON"] = "PERSON";
})(AdminRole || (AdminRole = {}));
export var PostType;
(function (PostType) {
  PostType["POST"] = "POST";
  PostType["CASE"] = "CASE";
})(PostType || (PostType = {}));
export var PartnerType;
(function (PartnerType) {
  PartnerType["DEFAULT"] = "DEFAULT";
  PartnerType["SVG"] = "SVG";
})(PartnerType || (PartnerType = {}));
import axios from "axios";
export var ContentType;
(function (ContentType) {
  ContentType["Json"] = "application/json";
  ContentType["FormData"] = "multipart/form-data";
  ContentType["UrlEncoded"] = "application/x-www-form-urlencoded";
  ContentType["Text"] = "text/plain";
})(ContentType || (ContentType = {}));
export class HttpClient {
  instance;
  securityData = null;
  securityWorker;
  secure;
  format;
  constructor({ securityWorker, secure, format, ...axiosConfig } = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }
  setSecurityData = (data) => {
    this.securityData = data;
  };
  mergeRequestParams(params1, params2) {
    const method = params1.method || (params2 && params2.method);
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase()]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }
  stringifyFormItem(formItem) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }
  createFormData(input) {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent = property instanceof Array ? property : [property];
      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }
      return formData;
    }, new FormData());
  }
  request = async ({ secure, path, type, query, format, body, ...params }) => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;
    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body);
    }
    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }
    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}
/**
 * @title IM legal swagger
 * @version 1.0
 * @contact
 *
 * IM legal API description
 */
export class ApiClient extends HttpClient {
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerLogout
     * @request GET:/auth/logout
     * @secure
     */
    authControllerLogout: (params = {}) =>
      this.request({
        path: `/auth/logout`,
        method: "GET",
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerLogin
     * @request POST:/auth/login
     */
    authControllerLogin: (data, params = {}) =>
      this.request({
        path: `/auth/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerResetPassword
     * @request POST:/auth/reset-password
     */
    authControllerResetPassword: (data, params = {}) =>
      this.request({
        path: `/auth/reset-password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerResetPasswordConfirm
     * @request POST:/auth/reset-password-confirm
     */
    authControllerResetPasswordConfirm: (query, data, params = {}) =>
      this.request({
        path: `/auth/reset-password-confirm`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerGetMe
     * @request GET:/auth/me
     * @secure
     */
    authControllerGetMe: (params = {}) =>
      this.request({
        path: `/auth/me`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  admin = {
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerGetUsers
     * @request GET:/admin/users
     * @secure
     */
    userAdminControllerGetUsers: (query, params = {}) =>
      this.request({
        path: `/admin/users`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerCreateUser
     * @request POST:/admin/users
     * @secure
     */
    userAdminControllerCreateUser: (data, params = {}) =>
      this.request({
        path: `/admin/users`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerGetLevels
     * @request GET:/admin/users/education-level
     * @secure
     */
    userAdminControllerGetLevels: (params = {}) =>
      this.request({
        path: `/admin/users/education-level`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerCreateLevel
     * @request POST:/admin/users/education-level
     * @secure
     */
    userAdminControllerCreateLevel: (query, params = {}) =>
      this.request({
        path: `/admin/users/education-level`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerGetUser
     * @request GET:/admin/users/{id}
     * @secure
     */
    userAdminControllerGetUser: (id, params = {}) =>
      this.request({
        path: `/admin/users/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerPatch
     * @request PATCH:/admin/users/{id}
     * @secure
     */
    userAdminControllerPatch: (id, data, params = {}) =>
      this.request({
        path: `/admin/users/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerRemoveUser
     * @request DELETE:/admin/users/{id}
     * @secure
     */
    userAdminControllerRemoveUser: (id, params = {}) =>
      this.request({
        path: `/admin/users/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerResetPassword
     * @request POST:/admin/users/{userId}/reset
     * @secure
     */
    userAdminControllerResetPassword: (userId, query, params = {}) =>
      this.request({
        path: `/admin/users/${userId}/reset`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerCreateAdmin
     * @request POST:/admin/users/admin
     */
    userAdminControllerCreateAdmin: (data, params = {}) =>
      this.request({
        path: `/admin/users/admin`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerPatchUserRole
     * @request PATCH:/admin/users/{id}/role/{role}
     * @secure
     */
    userAdminControllerPatchUserRole: (role, id, params = {}) =>
      this.request({
        path: `/admin/users/${id}/role/${role}`,
        method: "PATCH",
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerSetPractice
     * @request POST:/admin/users/{id}/practice
     * @secure
     */
    userAdminControllerSetPractice: (id, data, params = {}) =>
      this.request({
        path: `/admin/users/${id}/practice`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerDeleteLevel
     * @request DELETE:/admin/users/education-level/{id}
     * @secure
     */
    userAdminControllerDeleteLevel: (id, params = {}) =>
      this.request({
        path: `/admin/users/education-level/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerUpdateProfileAvatar
     * @request POST:/admin/users/avatar
     * @secure
     */
    userAdminControllerUpdateProfileAvatar: (data, params = {}) =>
      this.request({
        path: `/admin/users/avatar`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerUpdateProfileAvatarById
     * @request POST:/admin/users/{id}/avatar
     * @secure
     */
    userAdminControllerUpdateProfileAvatarById: (id, data, params = {}) =>
      this.request({
        path: `/admin/users/${id}/avatar`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerUpdateProfilePhoto
     * @request POST:/admin/users/profile-photo
     * @secure
     */
    userAdminControllerUpdateProfilePhoto: (data, params = {}) =>
      this.request({
        path: `/admin/users/profile-photo`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerUpdateProfilePhotoById
     * @request POST:/admin/users/{id}/profile-photo
     * @secure
     */
    userAdminControllerUpdateProfilePhotoById: (id, data, params = {}) =>
      this.request({
        path: `/admin/users/${id}/profile-photo`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerPostPublication
     * @request POST:/admin/users/publication
     * @secure
     */
    userAdminControllerPostPublication: (data, params = {}) =>
      this.request({
        path: `/admin/users/publication`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerPatchPublication
     * @request PATCH:/admin/users/publication/translation
     * @secure
     */
    userAdminControllerPatchPublication: (query, data, params = {}) =>
      this.request({
        path: `/admin/users/publication/translation`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerDeletePublicationTranslation
     * @request DELETE:/admin/users/publication/translation
     * @secure
     */
    userAdminControllerDeletePublicationTranslation: (query, params = {}) =>
      this.request({
        path: `/admin/users/publication/translation`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerAddTranslation
     * @request POST:/admin/users/publication/translation
     * @secure
     */
    userAdminControllerAddTranslation: (query, data, params = {}) =>
      this.request({
        path: `/admin/users/publication/translation`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerDeletePublication
     * @request DELETE:/admin/users/publication/{id}
     * @secure
     */
    userAdminControllerDeletePublication: (id, params = {}) =>
      this.request({
        path: `/admin/users/publication/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users Admin
     * @name UserAdminControllerSetTheme
     * @request POST:/admin/users/theme
     * @secure
     */
    userAdminControllerSetTheme: (query, params = {}) =>
      this.request({
        path: `/admin/users/theme`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),
  };
  industry = {
    /**
     * No description
     *
     * @tags Industry
     * @name IndustryControllerCreate
     * @request POST:/industry
     * @secure
     */
    industryControllerCreate: (data, params = {}) =>
      this.request({
        path: `/industry`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Industry
     * @name IndustryControllerGet
     * @request GET:/industry
     * @secure
     */
    industryControllerGet: (query, params = {}) =>
      this.request({
        path: `/industry`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Industry
     * @name IndustryControllerPatch
     * @request PATCH:/industry/{industryId}
     * @secure
     */
    industryControllerPatch: (industryId, data, params = {}) =>
      this.request({
        path: `/industry/${industryId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Industry
     * @name IndustryControllerDelete
     * @request DELETE:/industry/{industryId}
     * @secure
     */
    industryControllerDelete: (industryId, params = {}) =>
      this.request({
        path: `/industry/${industryId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  practice = {
    /**
     * No description
     *
     * @tags Practice
     * @name PracticeControllerCreate
     * @request POST:/practice
     * @secure
     */
    practiceControllerCreate: (data, params = {}) =>
      this.request({
        path: `/practice`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Practice
     * @name PracticeControllerGet
     * @request GET:/practice
     * @secure
     */
    practiceControllerGet: (query, params = {}) =>
      this.request({
        path: `/practice`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Practice
     * @name PracticeControllerPatch
     * @request PATCH:/practice/{practiceId}
     * @secure
     */
    practiceControllerPatch: (practiceId, data, params = {}) =>
      this.request({
        path: `/practice/${practiceId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Practice
     * @name PracticeControllerDelete
     * @request DELETE:/practice/{practiceId}
     * @secure
     */
    practiceControllerDelete: (practiceId, params = {}) =>
      this.request({
        path: `/practice/${practiceId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  service = {
    /**
     * No description
     *
     * @tags Service
     * @name ServiceControllerCreate
     * @request POST:/service
     * @secure
     */
    serviceControllerCreate: (data, params = {}) =>
      this.request({
        path: `/service`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Service
     * @name ServiceControllerGet
     * @request GET:/service
     * @secure
     */
    serviceControllerGet: (query, params = {}) =>
      this.request({
        path: `/service`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Service
     * @name ServiceControllerPatch
     * @request PATCH:/service/{serviceId}
     * @secure
     */
    serviceControllerPatch: (serviceId, data, params = {}) =>
      this.request({
        path: `/service/${serviceId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Service
     * @name ServiceControllerDelete
     * @request DELETE:/service/{serviceId}
     * @secure
     */
    serviceControllerDelete: (serviceId, params = {}) =>
      this.request({
        path: `/service/${serviceId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  post = {
    /**
     * No description
     *
     * @tags Post
     * @name PostControllerGet
     * @request GET:/post/{postType}
     * @secure
     */
    postControllerGet: (postType, query, params = {}) =>
      this.request({
        path: `/post/${postType}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Post
     * @name PostControllerGetBySlug
     * @request GET:/post/slug/{slug}
     * @secure
     */
    postControllerGetBySlug: (slug, params = {}) =>
      this.request({
        path: `/post/slug/${slug}`,
        method: "GET",
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Post
     * @name PostControllerCreate
     * @request POST:/post
     * @secure
     */
    postControllerCreate: (data, params = {}) =>
      this.request({
        path: `/post`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Post
     * @name PostControllerPatchPost
     * @request PATCH:/post/{postId}
     * @secure
     */
    postControllerPatchPost: (postId, data, params = {}) =>
      this.request({
        path: `/post/${postId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Post
     * @name PostControllerDeletePost
     * @request DELETE:/post/{postId}
     * @secure
     */
    postControllerDeletePost: (postId, params = {}) =>
      this.request({
        path: `/post/${postId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Post
     * @name PostControllerPatchTranslation
     * @request PATCH:/post/{postId}/translation
     * @secure
     */
    postControllerPatchTranslation: (postId, data, params = {}) =>
      this.request({
        path: `/post/${postId}/translation`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Post
     * @name PostControllerCreateTranslation
     * @request POST:/post/{postId}/translation
     * @secure
     */
    postControllerCreateTranslation: (postId, data, params = {}) =>
      this.request({
        path: `/post/${postId}/translation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Post
     * @name PostControllerDeleteTranslation
     * @request DELETE:/post/{postId}/translation/{lang}
     * @secure
     */
    postControllerDeleteTranslation: (postId, lang, params = {}) =>
      this.request({
        path: `/post/${postId}/translation/${lang}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Post
     * @name PostControllerAddPhoto
     * @request POST:/post/{postId}/{destination}
     * @secure
     */
    postControllerAddPhoto: (postId, destination, data, params = {}) =>
      this.request({
        path: `/post/${postId}/${destination}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Post
     * @name PostControllerDeletePhotoById
     * @request DELETE:/post/photo/{fileId}
     * @secure
     */
    postControllerDeletePhotoById: (fileId, params = {}) =>
      this.request({
        path: `/post/photo/${fileId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  main = {
    /**
     * No description
     *
     * @tags Main
     * @name MainControllerGetUsers
     * @request GET:/main/users
     */
    mainControllerGetUsers: (query, params = {}) =>
      this.request({
        path: `/main/users`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Main
     * @name MainControllerGetUserSlug
     * @request GET:/main/users/slug/{slug}
     */
    mainControllerGetUserSlug: (slug, params = {}) =>
      this.request({
        path: `/main/users/slug/${slug}`,
        method: "GET",
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Main
     * @name MainControllerGetUserCases
     * @request GET:/main/users/{id}/cases
     */
    mainControllerGetUserCases: (id, params = {}) =>
      this.request({
        path: `/main/users/${id}/cases`,
        method: "GET",
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Main
     * @name MainControllerGetUserPress
     * @request GET:/main/users/{id}/press
     */
    mainControllerGetUserPress: (id, params = {}) =>
      this.request({
        path: `/main/users/${id}/press`,
        method: "GET",
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Main
     * @name MainControllerGetIndustries
     * @request GET:/main/industries
     */
    mainControllerGetIndustries: (params = {}) =>
      this.request({
        path: `/main/industries`,
        method: "GET",
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Main
     * @name MainControllerGetIndustrySlug
     * @request GET:/main/industries/{slug}
     */
    mainControllerGetIndustrySlug: (slug, params = {}) =>
      this.request({
        path: `/main/industries/${slug}`,
        method: "GET",
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Main
     * @name MainControllerGetPractices
     * @request GET:/main/practices
     */
    mainControllerGetPractices: (query, params = {}) =>
      this.request({
        path: `/main/practices`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Main
     * @name MainControllerGetPracticeSlug
     * @request GET:/main/practices/{slug}
     */
    mainControllerGetPracticeSlug: (slug, params = {}) =>
      this.request({
        path: `/main/practices/${slug}`,
        method: "GET",
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Main
     * @name MainControllerGetServiceSlug
     * @request GET:/main/service/{slug}
     */
    mainControllerGetServiceSlug: (slug, params = {}) =>
      this.request({
        path: `/main/service/${slug}`,
        method: "GET",
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Main
     * @name MainControllerGetPostSlug
     * @request GET:/main/posts/{slug}
     */
    mainControllerGetPostSlug: (slug, params = {}) =>
      this.request({
        path: `/main/posts/${slug}`,
        method: "GET",
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Main
     * @name MainControllerGetCases
     * @request GET:/main/posts
     */
    mainControllerGetCases: (query, params = {}) =>
      this.request({
        path: `/main/posts`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Main
     * @name MainControllerSendCommercial
     * @request POST:/main/commercial-request
     */
    mainControllerSendCommercial: (data, params = {}) =>
      this.request({
        path: `/main/commercial-request`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Main
     * @name MainControllerSendPress
     * @request POST:/main/press-request
     */
    mainControllerSendPress: (data, params = {}) =>
      this.request({
        path: `/main/press-request`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Main
     * @name MainControllerSendTeam
     * @request POST:/main/team-request
     */
    mainControllerSendTeam: (data, params = {}) =>
      this.request({
        path: `/main/team-request`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Main
     * @name MainControllerGet
     * @request GET:/main/partners
     */
    mainControllerGet: (query, params = {}) =>
      this.request({
        path: `/main/partners`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Main
     * @name MainControllerGetTheme
     * @request GET:/main/theme
     */
    mainControllerGetTheme: (params = {}) =>
      this.request({
        path: `/main/theme`,
        method: "GET",
        ...params,
      }),
  };
  partner = {
    /**
     * No description
     *
     * @tags Partner
     * @name PartnerControllerGet
     * @request GET:/partner
     * @secure
     */
    partnerControllerGet: (query, params = {}) =>
      this.request({
        path: `/partner`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Partner
     * @name PartnerControllerCreate
     * @request POST:/partner
     * @secure
     */
    partnerControllerCreate: (data, params = {}) =>
      this.request({
        path: `/partner`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Partner
     * @name PartnerControllerPatchImage
     * @request PATCH:/partner/{id}/image
     * @secure
     */
    partnerControllerPatchImage: (id, data, params = {}) =>
      this.request({
        path: `/partner/${id}/image`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Partner
     * @name PartnerControllerPatch
     * @request PATCH:/partner/{id}
     * @secure
     */
    partnerControllerPatch: (id, data, params = {}) =>
      this.request({
        path: `/partner/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Partner
     * @name PartnerControllerDeletePartner
     * @request DELETE:/partner/{id}
     * @secure
     */
    partnerControllerDeletePartner: (id, params = {}) =>
      this.request({
        path: `/partner/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Partner
     * @name PartnerControllerHide
     * @request POST:/partner/{id}/{hide}
     * @secure
     */
    partnerControllerHide: (id, hide, params = {}) =>
      this.request({
        path: `/partner/${id}/${hide}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
