<template>
  <p class="text-content sub" :class="{'cormoranti-unicase-active': activeCormorant}">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "SubTitle",
  props: {
    activeCormorant: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.text-content {
  line-height: 1.2;
}

@media (max-width: 2560px){
  .text-content {
    &.sub {
      font-size: 21px;
    }
  }
}

@media (max-width: 1920px) {
  .text-content {
    &.sub {
      font-size: 18px;
    }
  }
}


@media (max-width: 1600px) {
  .text-content {
    &.sub {
      font-size: 12px;
    }
  }
}
@media (max-height: 800px) {
  .text-content {
    &.sub {
      font-size: 12px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .text-content {
    &.sub {
      font-size: 12px;
    }
  }
}

@media (max-width: 1133px) {
  .text-content {
    &.sub {
      font-size: 11px;
    }
  }
}


@media (min-width: 2561px) {
  .text-content {
    &.sub {
      font-size: 42px;
    }
  }
}

</style>