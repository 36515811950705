<template>

  <modal-alert :active-modal-alert="activeAlertModal"
               :alert-text="alertText"/>

  <transition name="fade-opacity-lazy">
    <loader-slot class="loading-lang-container" v-if="isLoadingLang || isLoadingStartData"/>
  </transition>

  <transition name="fade-opacity-lazy">
    <loader-slot v-if="!isLoadingStartData && (isLoadingImg || isLoading)"/>
  </transition>

  <transition name="fade-translate-x-card-revert" mode="out-in">
    <main-controller v-if="activeController"/>
  </transition>

  <transition name="fade-translate-y" mode="out-in">
    <header-mobile v-if="activeMobile && activeMobileMenu && activeMainSlide !== 6"/>
  </transition>

  <transition name="fade-opacity-lazy">
    <cookie-screen v-if="activeCookieModal" @closeCookie="changeFalseCookie"/>
  </transition>


      <main v-if="!isLoadingStartData" v-show="!activeAlertModal">
          <router-view v-slot="{ Component }">
          <transition name="fade-opacity-lazy">
              <component :is="Component"/>
          </transition>
        </router-view>
      </main>


    </template>

    <script>
    import MainController from "@/components/widgets/MainController.vue";
    import TitleRouter from "@/components/shared/TitleRouter.vue";
    import {api} from "@/providers/api";
    import Loader from "@/components/Loader.vue";
    import HeaderMobile from "@/components/widgets/HeaderMobile.vue";
    import {mapActions} from "vuex";
    import CookieModal from "@/components/entities/сookie/CookieModal.vue";
    import LoaderSlot from "@/components/entities/LoaderSlot.vue";
    import CookieScreen from "@/components/entities/сookie/CookieScreen.vue";
    import ModalAlert from "@/components/entities/ModalAlert.vue";

    export default {
      name: "MainLayout",
      components: {ModalAlert, CookieScreen, LoaderSlot, CookieModal, HeaderMobile, Loader, TitleRouter, MainController},
      data() {
        return {
          activeTitle: false,
          activeAnimationMobile: 'fade-translate-x',
          isLoadingStartData: false,
          activeAlertModal: false,
          alertText: ''
        }
      },
      async created() {
        if(this.$route.name === 'about') {
          this.isLoadingStartData = true
        }
        await this.checkInfo();
      },
      mounted() {
          this.checkCookie();
          this.checkLang();
          this.checkSize();
          screen.orientation.addEventListener('change', this.handleOrientationChange);
          addEventListener('resize', this.checkSize);
      },
      unmounted() {
        removeEventListener('resize', this.checkSize);
      },


      watch: {
        activeMainSlide() {
          this.checkActiveMobileMenu();
          this.checkLocalStorageCookie(1000);
        },

        activeStartSlide() {
          if(this.activeStartSlide === 2) {
            this.checkLocalStorageCookie(1500);
          }
        },

      },
      methods: {
        ...mapActions(['ACTIVE_MOBILE_MENU',
          'ACTIVE_COOKIE_MODAL',
          'ALLOW_TOUCH_MOVE_STATE', 'ACTIVE_MOBILE']),
        ...mapActions('persons',['getUsers','getTheme']),
        ...mapActions('partners',['getPartners']),
        ...mapActions('rating',['getRating']),
        async checkInfo() {
          try {
            await Promise.all([this.getTheme(), this.getPartners(), this.getUsers(), this.getRating()])
          } catch (e) {
            console.log(e)
          } finally {
            this.isLoadingStartData = false
          }
        },

        checkCookie() {
          if(this.activeMainSlide === 0 && !this.activeSubPage) {
            if(this.activeStartSlide === 2) {
              this.checkLocalStorageCookie(1000);
            }
          } else {
            this.checkLocalStorageCookie(1000);
          }
        },

        checkSize() {
          this.innerHeight = window.innerHeight;
          document.documentElement.style.setProperty('--vh', `${this.innerHeight * 0.01}px`);
          this.innerWidth = window.innerWidth;
          document.documentElement.style.setProperty('--vw', `${this.innerWidth * 0.01}px`);
          if(this.innerWidth <= 769) {
            this.ACTIVE_MOBILE(true)
          } else {
            this.ACTIVE_MOBILE(false)
          }
          if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
            this.ALLOW_TOUCH_MOVE_STATE(true)
          } else {
            this.ALLOW_TOUCH_MOVE_STATE(false)
          }
          this.handleOrientationChange();
        },

        handleOrientationChange(e) {
          let orientationType = screen.orientation.type
          this.activeAlertModal = false
          if(this.activeMobile) {
            if (!orientationType.includes('portrait')) {
              this.activeAlertModal = true
              this.alertText = this.$tm('alertOrientation.vertical')
            } else {
              this.activeAlertModal = false
            }
          } else {
            if (!orientationType.includes('landscape')) {
              this.alertText = this.$tm('alertOrientation.horizontal')
              this.activeAlertModal = true
            } else {
              this.activeAlertModal = false
            }
          }
        },


        checkLang() {
          if(localStorage.getItem('active-lang') === 'en') {
            this.$i18n.locale = 'en'
          } else {
            this.$i18n.locale = 'ru'
          }
        },


        checkLocalStorageCookie(time) {
          if(!Boolean(localStorage.getItem('cookieActive'))) {
            setTimeout(this.changeTrueCookie, time);
          }
        },

        checkActiveMobileMenu() {
          if(this.activeMainSlide !== 0) {
            this.activeAnimationMobile = 'fade-translate-y'
            this.ACTIVE_MOBILE_MENU(true)
          } else {
            this.activeAnimationMobile = 'fade-translate-x'
          }
        },

        changeTrueCookie() {
          this.ACTIVE_COOKIE_MODAL(true)
        },

        changeFalseCookie(item) {
          this.ACTIVE_COOKIE_MODAL(item)
        }
      },
      computed: {

        isLoadingLang() {
          return this.$store.state.isLoadingLang;
        },

        activeCookieModal() {
          return this.$store.state.activeCookieModal;
        },

        activeStartSlide() {
          return this.$store.state.activeStartSlide;
        },

        activeMobileMenu() {
          return this.$store.state.activeMobileMenu;
        },
        activeMobile() {
          return this.$store.state.activeMobile;
        },

        activeMainSlide() {
          return this.$store.state.activeMainSlide;
        },

        activeSubPage() {
          return this.$store.state.activeSubPage;
        },


        activeController() {
          if(!this.activeMobile) {
            if(this.activeSubPage || (this.activeMainSlide > 0 && this.activeMainSlide < 6) ) {
              return true
            } else {
              return false
            }
          } else {
            return false
          }
        }
      },
    }
    </script>

    <style scoped lang="scss">

    .loading-lang-container {
      background-color: var(--color-background);
      width: var(--calc-width);
      height: var(--calc-height);
      padding: 0;
      position: fixed;
    }

      main {
        overflow: hidden;
      }
    </style>