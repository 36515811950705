import {api} from "@/providers/api";

export const caseModules = {
    namespaced: true,
    state: () => ({
        activeCaseInfo: {},
        caseInfo: undefined,
        viewArray: []
    }),
    getters: {
        allCase: (state) => {
            return state.caseInfo
        },
        getAllSortedCase: (state) => {
            return state.caseInfo?.sort((a, b) => {
                return (new Date(a.publishDate)) - ( new Date(b.publishDate));
            }).reverse();
        },
        getActiveCaseInfo: (state) => {
            return state.activeCaseInfo
        },

        allViewCase: (state) => {
            return state.viewArray
        },
    },
    mutations: {
        SET_CASE: (state, payload) => {
            state.caseInfo = payload
        },
        SET_ACTIVE_CASE_INFO: (state, payload) => {
            state.activeCaseInfo = payload
        },
    },
    actions:  {
        async getCase({commit}, { params  } = {params: {}}) {
            let response = await api.main.mainControllerGetCases({type: 'CASE'},{
                ...params
            }).then(r => r.data);
            commit('SET_CASE', response)
        },
        async getActiveCase({commit}, { slug, params  } = {slug ,params: {}}) {
            let response = await api.main.mainControllerGetPostSlug(slug,{
                ...params
            }).then(r => r.data);
            commit('SET_ACTIVE_CASE_INFO', response)
        },
    }
}