<template>
  <page-case-news-page :active-id="slug"
                       v-if="!isLoading || !isLoadingData"
                       :href-title="'/press'"
                       :active-main-title="activeMainTitle"
                       :path-includes="'/press'"
                       :active-main-slide="5"
                       :active-slider="true"
                       :info-content="activeNews"/>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CardNewsCase from "@/components/entities/news-case-page/CardNewsCase.vue";
import MainFooter from "@/components/widgets/MainFooter.vue";
import TitleRouterSubPage from "@/components/shared/TitleRouterSubPage.vue";
import PageCaseNewsPage from "@/components/entities/PageCaseNewsPage.vue";

export default {
  name: "news-page",
  components: {PageCaseNewsPage, TitleRouterSubPage, MainFooter, CardNewsCase},
  props: ['slug'],

  async created() {
    await this.checkInfo();
  },


  data() {
    return {
      isLoadingData: true
    }
  },

  computed: {
    ...mapGetters({
      activeNews: "news/getActiveNewsInfo",
      newsAll: 'news/allPress',
    }),

    activeMainTitle() {
      return this.mainTitlePage.newsScreen[1];
    },

  },

  watch: {
    async slug() {
      await this.checkInfo();
    },
    activeEng() {
          this.checkLang();
    },
    activeNews() {
      if(this.activeNews === ''){
        this.IS_LOADING_IMG(false)
        this.changeMainSlide(5)
      }
    }
  },

  methods: {

    ...mapActions({
      getActiveNews: 'news/getActivePress'
    }),
    ...mapActions(['IS_LOADING_IMG', 'IS_LOADING', 'IS_LOADING_IMG', 'ACTIVE_MAIN_SLIDE']),


    async checkLang() {
      try {
        if(this.$i18n.locale === 'en') {
          if(await this.activeNews?.translations.find(item => item.lang === 'en') === undefined) {
            this.$router.push('/press')
          }
        }
      } catch (e) {
        console.log(e)
      }
    },

    async checkInfo() {
      this.isLoadingData = true
      await this.IS_LOADING_IMG(true)
      await this.IS_LOADING(true)
        try {
          await this.getActiveNews({slug: this.slug})
        } catch (e) {
          console.log(e)
        } finally {
          if(this.newsAll !== undefined) {
            this.IS_LOADING(false)
          } else {
            this.isLoadingData = false
          }
        }
    },
  }
}
</script>

<style scoped lang="scss">

</style>