<template>
  <p class="text-content main-title" :class="{'cormoranti-unicase-active': activeCormorant}">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "MainTitle",
  props: {
    activeCormorant: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.text-content {
  line-height: 1.2;
}

@media (max-width: 2560px){
  .text-content {
    &.main-title {
      font-size: 32px;
    }
  }
}

@media (max-width: 2240px) {
  .text-content {
    &.main-title {
      font-size: 28px;
    }
  }
}


@media (max-width: 1920px) {
  .text-content {
    &.main-title {
      font-size: 24px;
    }
  }
}


@media (max-width: 1600px) {
  .text-content {
    &.main-title {
      font-size: 16px;
    }
  }
}
@media (max-height: 800px) {
  .text-content {
    &.main-title {
      font-size: 16px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .text-content {
    &.main-title {
      font-size: 18px;
    }
  }
}

@media (max-width: 1133px) {
  .text-content {
    &.main-title {
      font-size: 14px;
    }
  }
}

@media (max-width: 769px) {
  .text-content {
    &.main-title {
      font-size: 14px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .text-content {
    &.main-title {
      font-size: 14px;
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}



@media (min-width: 2561px) {
  .text-content {
    &.main-title {
      font-size: 56px;
    }
  }
}
</style>