<template>
  <div class="card-title-news-case-container">
      <div class="main-title-container">
        <div class="main-title-content">
          <main-paragraph class="date-content" :class="{'active-en' : activeEng}">{{ datePublicationFormat }}</main-paragraph>

          <div class="views-info-container">
              <div class="eye-img-container">
                <img :src="getUrlIcon('eye.svg')" alt="eye">
              </div>
           <sub-paragraph :active-cormorant="false">
             {{ infoContent?.views }}
           </sub-paragraph>
         </div>
        </div>

        <large-title class="main-title-content" :active-cormorant="false">
          {{ infoContent?.translations?.name }}
        </large-title>
    </div>

      <div class="info-content practice" v-if="infoContent?.practices?.length !== 0">
        <sub-title :active-cormorant="true">{{ $tm('caseNewsPage.mainTitle')[1] }}</sub-title>
        <div class="info-container">
          <sub-paragraph class="practice" v-for="(item, index) in infoContent?.practices"
                         :key="index" :active-cormorant="false">
            #{{ item?.name.toLowerCase().split(' ').join('_') }}<span v-if="index < infoContent?.practices.length-1">, </span>
          </sub-paragraph>
        </div>
      </div>
  </div>
</template>

<script>
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import CardPersonMini from "@/components/entities/CardPersonMini.vue";
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import MainTitlePage from "@/components/entities/MainTitlePage.vue";

export default {
  name: "CardTitleNewsCaseMobile",
  props: {
    publishDate: {
      type: String,
      required: true
    },
    infoContent: {
      type: Object,
      required: true
    }
  },
  components: {MainTitlePage, SubTitle, CardPersonMini, SubParagraph, LargeTitle, MainParagraph},

  computed: {
    datePublicationFormat() {
      return this.datePublicationFormatChange(this.publishDate)
    },
  },
}
</script>

<style scoped lang="scss">
.card-title-news-case-container {
  display: flex;
  flex-direction: column;
    .main-title-container {
      display: flex;
      flex-direction: column;
      .main-title-content {
        display: flex;
        flex-direction: row;
        align-items: center;

        .date-content {
          color: #646271;
        }

        .views-info-container {
          align-items: center;
          display: flex;
          flex-direction: row;
          .eye-img-container {
            display: flex;
            align-items: center;
            justify-content: center;
            & img {
              object-fit: contain;
              width: 100%;
              height: 100%;
            }
          }

          .text-content {
            color: #646271;
            line-height: normal;
            text-transform: lowercase;
            height: inherit;
          }
        }
      }

      .main-title-content {
        font-weight: 600;
        line-height: normal;
      }
    }

  .info-content {

    .info-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .text-content {
        color: #A19BA2;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-variant: small-caps;
      }
    }
  }
}

@media (max-width: 769px) {
  .card-title-news-case-container {
    row-gap: 16px;
    .main-title-container {
      row-gap: 8px;
      .main-title-content {
        column-gap: 12px;

        .date-content {
          font-size: 12px;
        }

        .views-info-container {
          height: 24px;
          column-gap: 2px;
          .eye-img-container {
            max-width: 10px;
            max-height: 12px;
          }

          .text-content {
            font-size: 12px;
          }
        }
      }

      .main-title-content {
        font-size: 16px;
      }
    }

    .info-content {
      .text-content {
        font-size: 12px;
        margin-bottom: 8px;
      }

      .info-container {
        column-gap: 4px;
        row-gap: 4px;
        .text-content {
          font-size: 12px;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .card-title-news-case-container {
    row-gap: 24px;
    .main-title-container {
      row-gap: 8px;
      .main-title-content {
        column-gap: 12px;

        .date-content {
          font-size: 12px;
        }

        .views-info-container {
          height: 24px;
          column-gap: 2px;
          .eye-img-container {
            max-width: 10px;
            max-height: 12px;
          }

          .text-content {
            font-size: 12px;
          }
        }
      }

      .main-title-content {
        font-size: 20px;
      }
    }

    .info-content {
      .text-content {
        font-size: 12px;
        margin-bottom: 8px;
      }

      .info-container {
        .text-content {
          margin-bottom: 0;
          font-size: 10px;
        }
      }
    }
  }
}



@media (max-width: 375px){
  .card-title-news-case-container {
    row-gap: 16px;
    .main-title-container {
      row-gap: 8px;
      .main-title-content {
        column-gap: 12px;

        .date-content {
          font-size: 10px;
        }

        .views-info-container {
          height: 17px;
          column-gap: 2px;
          .eye-img-container {
            max-width: 10px;
            max-height: 12px;
          }

          .text-content {
            font-size: 10px;
          }
        }
      }

      .main-title-content {
        font-size: 18px;
      }
    }

    .info-content {
      .text-content {
        font-size: 12px;
        margin-bottom: 8px;
      }

      .info-container {
        column-gap: 4px;
        row-gap: 4px;
        .text-content {
          font-size: 10px;
          margin-bottom: 0;
        }
      }
    }
  }
}


@media (max-width: 320px) {
  .card-title-news-case-container {
    .main-title-container {
      row-gap: 4px;

      .main-title-content {
        font-size: 14px;
      }
    }

    .info-content {
      .text-content {
        font-size: 10px;
        margin-bottom: 4px;
      }

      .info-container {
        column-gap: 4px;
        row-gap: 4px;
        .text-content {
          font-size: 10px;
          margin-bottom: 0;
        }
      }
    }
  }
}


</style>