<template>
    <large-title :active-cormorant="true">
      <transition-group name="fade-opacity-animation">
         <span v-show="isVisible(index)" v-for="(item, index) in sliceText" :key="index">
        {{item}}
        </span>
      </transition-group>
    </large-title>
</template>

<script>
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";

export default {
  name: "AnimationText",
  components: {LargeTitle},
  props: {
    textInfo: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.showNextItem();
  },
  data() {
    return {
      activeItem: -1
    }
  },
  computed: {
    sliceText() {
      return this.textInfo.split(/(\s+)/);
    }
  },
  methods: {
    showNextItem() {
      if (this.activeItem < this.sliceText.length) {
        setTimeout(() => {
          this.activeItem++
          this.showNextItem();
        }, 100)
      } else {
        this.$emit('endAnimation')
      }
    },

    isVisible(index) {
      return index <= this.activeItem
    },
  }
}
</script>

<style scoped lang="scss">
</style>