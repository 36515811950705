<template>
  <div class="line-controller-content"
       @mouseenter="focusFalse"
       @click="changeActiveLine(lineText.id, lineText.mainTitle)"
       @mouseleave="focusTrue">
      <div class="text-content">
        <main-paragraph :class="activeLine === lineText.id || !focusParam ? 'active' : ''">
          {{lineText.mainTitle}}
        </main-paragraph>
      </div>
      <div class="line"></div>
      <div class="line-active" :class="activeLine === lineText.id || !focusParam ? 'active' : ''"></div>
    </div>
</template>

<script>

import MainParagraph from "@/components/shared/Text/MainParagraph.vue";

export default {
  name: "LineController",
  components: {MainParagraph},
  props: {
    lineText: {
      type: Object,
      required: true
    },
    activeLine: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      focusParam: true
    }
  },
  methods: {
    changeActiveLine(id, position) {
      this.$emit('changeActiveLine',id, position)
    },


  }
}
</script>

<style scoped lang="scss">
.line-controller-content {
    display: flex;
    width: 100%;
  height: 100%;
    position: relative;
    justify-content: center;
  align-items: flex-end;

    &:hover {
      cursor: pointer;
    }

    .text-content {
      display: flex;
      flex-direction: row;


      .text-content {
        color: #A19BA2;
        transition: all .8s ease;
        &:hover {
          color: var(--color-text);
        }

        &.active {
          color: var(--color-text);
        }
      }
    }

    .line {
      position: absolute;
      content: "";
      width: 100%;
      top: 0;
      background-color: #A19BA2;
    }

    .line-active {
      position: absolute;
      content: "";
      width: 100%;
      top: 0;
      background-color: var(--color-text);
      transform: scaleX(0);
      transform-origin: right;
      transition: transform 0.5s;

      &.active {
        transform: scaleX(1);
        transform-origin: left;
        transition: transform 0.5s;
      }
    }
}

@media (max-width: 2560px){
  .line-controller-content {

    .text-content {
      font-size: 20px;

    }

    .line {
      height: 4px;
    }

    .line-active {
      height: 4px;
    }
  }
}

@media (max-width: 2240px) {
  .line-controller-content {

    .text-content {
      font-size: 20px;
    }

    .line {
      height: 4px;
    }

    .line-active {
      height: 4px;
    }
  }
}

@media (max-width: 1920px) {
  .line-controller-content {

    .text-content {
      font-size: 18px;
    }

    .line {
      height: 3px;
    }

    .line-active {
      height: 3px;
    }
  }
}


@media (max-width: 1600px) {
  .line-controller-content {

    .text-content {
      font-size: 12px;
    }

    .line {
      height: 2px;
    }

    .line-active {
      height: 2px;
    }
  }
}

@media (max-height: 800px){
  .line-controller-content {
    .text-content {
      font-size: 12px;
    }

    .line {
      height: 2px;
    }

    .line-active {
      height: 2px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px){
  .line-controller-content {

    .text-content {
      font-size: 12px;
    }

    .line {
      height: 2px;
    }

    .line-active {
      height: 2px;
    }
  }
}


@media (max-width: 1133px) {
  .line-controller-content {

    .text-content {
      font-size: 10px;
    }

    .line {
      height: 2px;
    }

    .line-active {
      height: 2px;
    }
  }
}

@media (max-width: 769px) {
  //iPhone 14
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
 .line-controller-content {

  .text-content {
    font-size: 12px;
  }

  .line {
    height: 3px;
  }

  .line-active {
    height: 3px;
  }
}
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}



@media (min-width: 2561px) {
  .line-controller-content {

    .text-content {
      align-items: center;
      font-size: 42px;
    }

    .line {
      height: 7px;
    }

    .line-active {
      height: 7px;
    }
  }
}

</style>