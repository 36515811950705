<template>
  <div class="card-news-container" :class="{'not-active-full' : !activeFull}" @click="routToNewsPage(infoNews?.slug)">
    <div class="main-title-container">
      <main-paragraph class="date-content" :class="{'active-en' : activeEng}">{{ datePublicationFormat }}</main-paragraph>
      <large-title class="main-title-content"
                   :active-cormorant="false"
                   >{{ translationsList?.translations?.name }}</large-title>
    </div>

    <main-paragraph class="description-content">{{ translationsList?.translations?.shortName }}</main-paragraph>
    <div class="sub-title-container">
      <div class="person-container" v-if="activeFull && translationsList?.users.length !== 0">
        <sub-paragraph class="person-text" :active-cormorant="true">{{ $t('newsScreen.personTitle') }}</sub-paragraph>
        <div class="person-info">
          <sub-paragraph  v-for="(item, index) in translationsList?.users" :active-cormorant="false">
            {{ item?.fullName }}<span v-if="index < translationsList?.users.length - 1">,</span>
          </sub-paragraph>
        </div>
      </div>
      <div class="practice-container" v-if="translationsList?.practices.length !== 0">
          <sub-paragraph v-for="item in translationsList?.practices" :active-cormorant="false">
            #{{ item?.name.toLowerCase().split(' ').join('_') }}
          </sub-paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import {mapActions} from "vuex";

export default {
  name: "CardNews",
  components: {SubParagraph, LargeTitle, MainParagraph},
  props: {
    infoNews: {
      type: Object,
      required: true
    },
    activeFull: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    datePublicationFormat() {
      return this.datePublicationFormatChange(this.infoNews?.publishDate)
    },


    translationsList() {
      return {
          translations: this.checkLangCode(this.infoNews, this.$i18n.locale, 'translations', 'lang'),
          practices: this.infoNews?.practices.map(item => {
            return this.checkLangCode(item, this.$i18n.locale, 'modifications', 'langCode')
          }),
        users: this.infoNews?.users.map(item => {
          return this.checkLangCode(item, this.$i18n.locale, 'profiles', 'lang')
        }),
        };
    },
  },
  methods: {
    ...mapActions(['ACTIVE_SUB_PAGE']),
    routToNewsPage(slug) {
      this.ACTIVE_SUB_PAGE(true)
      this.$router.push('/press/' + slug)
    }
  }
}
</script>

<style scoped lang="scss">


.card-news-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom-style: solid;
  border-bottom-color: var(--color-element);
  width: 100%;
  background-color: var(--color-card);
  transition: box-shadow .6s ease;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 35px 0px rgba(var(--color-box-shadow-box));
  }

  .main-title-container {

    .date-content {
      font-weight: 500;
      &.active-en {
        font-variant: all-small-caps !important;
      }
    }

    .main-title-content {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
    }

    .text-content {
      &.large {

      }

    }
  }

  .sub-title-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: baseline;
    justify-content: space-between;

    .person-container {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      flex-basis: 50%;
      width: 100%;
      column-gap: 4px;
      position: relative;
      overflow: hidden;

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 5%;
        height: 100%;
        background: linear-gradient(90deg, rgba(34, 34, 59, 0) 0%, var(--color-card) 90%);
      }


      .text-content {
        white-space: nowrap;
      }

      .person-info {
        display: flex;
        flex-direction: row;
        font-weight: 500;
      }
    }
    .practice-container {
      opacity: .4;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-basis: 50%;
      width: 100%;
      position: relative;
      overflow: hidden;

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 5%;
        height: 100%;
        background: linear-gradient(90deg, rgba(34, 34, 59, 0) 0%, var(--color-card) 90%);
      }

      .text-content {
        white-space: nowrap;
      }
    }
  }
}

@media (max-width: 2560px){
  .card-news-container {
    padding: 18px;
    border-bottom-width: 1px;
    border-radius: 6px;

    &.not-active-full {
      .description-content {
        font-size: 21px;
      }

      .main-title-container {

        .date-content {
          font-size: 21px;
          margin-bottom: 9px;
        }

        .main-title-content {
          font-size: 37.3px;
        }
      }
    }

    .description-content {
      font-size: 22px;
    }


    .main-title-container {
        .date-content {
          font-size: 22px;
          margin-bottom: 9px;
        }
      .main-title-content {
        font-size: 44px;
      }
    }

    .sub-title-container {
      .person-container {
        column-gap: 18px;
        .person-info {
          column-gap: 5px;
        }
        .person-text {
          letter-spacing: 3.6px;
        }
      }
      .practice-container {
        column-gap: 5px;
      }
    }
  }
}

@media (max-width: 2240px) and (min-width: 1921px) {
  .card-news-container {
    padding: 18px;
    border-bottom-width: 1px;

    &.not-active-full {
      .description-content {
        font-size: 21px;
      }

      .main-title-container {

        .date-content {
          font-size: 21px;
          margin-bottom: 9px;
        }

        .main-title-content {
          font-size: 37.3px;
        }
      }
    }

    .description-content {
      font-size: 20px;
    }

    .main-title-container {
      .main-title-content {
          font-size: 37px;
      }
      .date-content {
          font-size: 21px;
        margin-bottom: 9px;
      }
    }

    .description-content {
      font-size: 21px;
    }

    .sub-title-container {
      .person-container {
        column-gap: 18px;
        .person-info {
          column-gap: 4px;
        }

        .person-text {
          letter-spacing: 3.2px;
        }
      }
      .practice-container {
        column-gap: 4px;
      }
    }
  }
}


@media (max-width: 1920px) {
  .card-news-container {
    padding: 16px;
    border-bottom-width: 1px;

    &.not-active-full {
      .description-content {
        font-size: 18px;
      }

      .main-title-container {

        .date-content {
          font-size: 21px;
          margin-bottom: 8px;
        }

        .main-title-content {
          font-size: 32px;
        }
      }
    }

    .description-content {
      font-size: 18px;
    }

    .main-title-container {
        .main-title-content {
          font-size: 32px;
        }

        .date-content {
          font-size: 18px;
          margin-bottom: 8px;
        }
    }

    .sub-title-container {
      .person-container {
        column-gap: 16px;
        .person-info {
          column-gap: 4px;
        }
        .person-text {
          letter-spacing: 2.8px;
        }
      }
      .practice-container {
        column-gap: 4px;
      }
    }
  }
}


@media (max-width: 1600px) {
  .card-news-container {
    border-radius: 4px;
    padding: 10px;
    border-bottom-width: 0.5px;

    &.not-active-full {
      .description-content {
        font-size: 12px;
      }

      .main-title-container {

        .date-content {
          font-size: 12px;
          margin-bottom: 5px;
        }

        .main-title-content {
          font-size: 21.3px;
        }
      }
    }

    .description-content {
      font-size: 12px;
    }

    .main-title-container {
      .main-title-content {
        font-size: 22px;
      }

      .date-content {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }


    .sub-title-container {
      .person-container {
        column-gap: 10px;
        .person-info {
          column-gap: 4px;
        }
        .person-text {
          letter-spacing: 2px;
        }
      }
      .practice-container {
        column-gap: 4px;
      }
    }
  }
}
@media (max-height: 800px) {
  .card-news-container {
    padding: 10px;
    border-bottom-width: 0.5px;

    &.not-active-full {
      .description-content {
        font-size: 12px;
      }

      .main-title-container {

        .date-content {
          font-size: 12px;
          margin-bottom: 5px;
        }

        .main-title-content {
          font-size: 21.3px;
        }
      }
    }

    .description-content {
      font-size: 12px;
    }

    .main-title-container {
      .main-title-content {
        font-size: 22px;
      }

      .date-content {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }



    .sub-title-container {
      .person-container {
        column-gap: 8px;
        .person-info {
          column-gap: 4px;
        }
        .person-text {
          letter-spacing: 2px;
        }
      }
      .practice-container {
        column-gap: 4px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .card-news-container {
    padding: 10px;
    border-radius: 2px;
    border-bottom-width: 0.5px;

    &.not-active-full {
      .description-content {
        font-size: 12px;
      }

      .main-title-container {

        .date-content {
          font-size: 12px;
          margin-bottom: 5px;
        }

        .main-title-content {
          font-size: 21.3px;
        }
      }
    }

    .description-content {
      font-size: 12px;
    }

    .main-title-container {
      .main-title-content {
        font-size: 22px;
      }

      .date-content {
        font-size: 12px;
        margin-bottom: 8px;
      }
    }


    .sub-title-container {
      .person-container {
        column-gap: 8px;
        .person-info {
          column-gap: 4px;
        }
        .person-text {
          letter-spacing: 2.6px;
        }
      }
      .practice-container {
        column-gap: 4px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .card-news-container {
    padding: 10px;
    border-bottom-width: 0.5px;

    &.not-active-full {
      .description-content {
        font-size: 10.6px;
      }

      .main-title-container {

        .date-content {
          font-size: 10.2px;
          margin-bottom: 4.7px;
        }

        .main-title-content {
          font-size: 18.8px;
        }
      }
    }

    .description-content {
      font-size: 10px;
    }

    .main-title-container {
      .main-title-content {
        font-size: 19px;
      }

      .date-content {
        font-size: 10px;
        margin-bottom: 5px;
      }
    }


    .sub-title-container {
      .person-container {
        column-gap: 8px;
        .person-info {
          column-gap: 4px;
        }
        .person-text {
          letter-spacing: 1.6px;
        }
      }
      .practice-container {
        column-gap: 4px;
      }
    }
  }
}

@media (max-width: 769px) {
  .card-news-container {
    height: 100%;
    padding: 16px;
    border-bottom-width: 0;

    &:hover {
      box-shadow: 0 0 0 0 rgba(0,0,0,0);
    }

    &.not-active-full {
      .description-content {
        font-size: 12px;
      }

      .main-title-container {

        .date-content {
          font-weight: 200;
          font-size: 12px;
          margin-bottom: 8px;
        }

        .main-title-content {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .description-content {
      font-size: 12px;
    }

    .main-title-container {
      .main-title-content {
        font-size: 16px;
        font-weight: 600;
      }

      .date-content {
        font-weight: 200;
        font-size: 12px;
        margin-bottom: 8px;
      }
    }


    .sub-title-container {
      flex-direction: column;
      row-gap: 16px;

      .person-container {
        column-gap: 8px;
        flex-direction: column;
        overflow: visible;

        .person-text {
          font-size: 12px;
          letter-spacing: 2.4px;
        }

        .person-info {
          flex-wrap: wrap;
          column-gap: 4px;

          .text-content {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }

      .practice-container {
        column-gap: 4px;
        overflow: hidden;

        .text-content {
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 375px){
  .card-news-container {
    height: 100%;
    padding: 16px;
    border-bottom-width: 0;

    &.not-active-full {
      .description-content {
        font-size: 12px;
      }

      .main-title-container {

        .date-content {
          font-weight: 200;
          font-size: 10px;
          margin-bottom: 8px;
        }

        .main-title-content {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    .description-content {
      font-size: 12px;
    }

    .main-title-container {
      .main-title-content {
        font-size: 14px;
        font-weight: 600;
      }

      .date-content {
        font-weight: 200;
        font-size: 10px;
        margin-bottom: 8px;
      }
    }


    .sub-title-container {
      flex-direction: column;
      row-gap: 16px;

      .person-container {
        column-gap: 8px;
        flex-direction: column;

        .person-text {
          font-size: 12px;
          letter-spacing: 2.4px;
        }

        .person-info {
          font-size: 12px;
          column-gap: 4px;

          .text-content {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }

      .practice-container {
        column-gap: 4px;

        .text-content {
          font-size: 12px;
        }
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .card-news-container {
    height: 100%;
    padding: 16px;
    border-bottom-width: 0;

    &.not-active-full {
      .description-content {
        font-size: 12px;
      }

      .main-title-container {

        .date-content {
          font-weight: 200;
          font-size: 12px;
          margin-bottom: 8px;
        }

        .main-title-content {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .description-content {
      font-size: 12px;
    }

    .main-title-container {
      .main-title-content {
        font-size: 16px;
        font-weight: 600;
      }

      .date-content {
        font-weight: 200;
        font-size: 12px;
        margin-bottom: 8px;
      }
    }


    .sub-title-container {
      flex-direction: column;
      row-gap: 16px;

      .person-container {
        column-gap: 8px;
        flex-direction: column;

        .person-text {
          font-size: 12px;
          letter-spacing: 2.4px;
        }

        .person-info {
          font-size: 12px;
          column-gap: 4px;

          .text-content {
            font-weight: 500;
          }
        }
      }

      .practice-container {
        column-gap: 4px;

        .text-content {
          font-size: 12px;
        }
      }
    }
  }
}


@media (max-width: 320px) {
  .card-news-container {
    height: 100%;
    padding: 12px;
    border-bottom-width: 0;

    &.not-active-full {
      .description-content {
        font-size: 10px;
      }

      .main-title-container {

        .date-content {
          font-weight: 200;
          font-size: 10px;
          margin-bottom: 8px;
        }

        .main-title-content {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }

    .description-content {
      font-size: 10px;
    }

    .main-title-container {
      .main-title-content {
        font-size: 12px;
        font-weight: 600;
      }

      .date-content {
        font-weight: 200;
        font-size: 10px;
        margin-bottom: 8px;
      }
    }


    .sub-title-container {
      flex-direction: column;
      row-gap: 8px;

      .person-container {
        column-gap: 8px;
        flex-direction: column;

        .person-text {
          font-size: 10px;
          letter-spacing: 2px;
        }

        .person-info {

          column-gap: 4px;

          .text-content {
            font-size: 10px;
            font-weight: 500;
          }
        }
      }

      .practice-container {
        column-gap: 4px;
        .text-content {
          font-size: 10px;
        }
      }
    }
  }
}


@media (min-width: 2561px) {
  .card-news-container {
    border-radius: 8px;
    padding: 36px;
    border-bottom-width: 2px;

    &.not-active-full {
      .description-content {
        font-size: 42px;
      }

      .main-title-container {

        .date-content {
          font-size: 42px;
          margin-bottom: 18px;
        }

        .main-title-content {
          font-size: 74.6px;
        }
      }
    }

    .main-title-container {
        .main-title-content {
          font-size: 74px;
        }

        .date-content {
          font-size: 42px;
          margin-bottom: 18px;
        }

    }

    .description-content {
      font-size: 42px;
    }

    .sub-title-container {
      .person-container {
        column-gap: 36px;
        .person-info {
          column-gap: 4px;
        }
        .person-text {
          letter-spacing: 6.4px;
        }
      }
      .practice-container {
        column-gap: 4px;
      }
    }
  }
}

</style>