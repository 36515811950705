<template>
  <div class="sub-button-container"
       :class="{'disabled' : disabledButton, 'active' : activeButton}">
    <sub-paragraph v-if="activeText" :active-cormorant="false">
      <slot/>
    </sub-paragraph>
    <slot v-else/>
  </div>
</template>

<script>
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";

export default {
  name: "ButtonSub",
  components: {SubParagraph},
  props: {
    disabledButton: {
      type: Boolean,
      required: true
    },
    activeText: {
      type: Boolean,
      default: true
    },
    activeButton: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.sub-button-container {
  display: flex;
  justify-content: center;
  max-height: 32px;
  align-items: center;
  transition: opacity .5s ease;
  width: 100%;
  background: var(--color-field);
  border-color: var(--color-stroke);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  opacity: .6;
  padding: 8px 16px;
  cursor: pointer;

  .text-content {
    width: 100%;
  }

  &:hover {
    opacity: 1;
  }

  &.disabled {
    opacity: .2;
    cursor: default;
  }

  &.active {
    border: none;
    opacity: 1;
  }
}

@media (max-width: 2560px){
}

@media (max-width: 2240px) {
  .text-content {
    font-size: 14px;
  }
}


@media (max-width: 1920px) {
}

@media (max-width: 1600px) {
  .text-content {
    font-size: 10px;
  }
}
@media (max-height: 800px) {
}

@media (max-width: 1400px) and (min-height: 801px) {
}

@media (max-width: 1133px) {
  .text-content {
    font-size: 8px;
  }
}

@media (max-width: 769px) {
  .sub-button-container {
  border-width: 0.6px;
}
}


</style>