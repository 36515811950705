<template>
  <div class="team-page-container main-container" v-if="!isLoadingData">
    <title-router-sub-page :active-router-href="'/about/team'"
                           :active-static-color="activeMobile"
                           v-if="!activeTitleLoading"
                           :active-main-title="activeMainTitle"
                           :active-sub-title="activeTitle"/>

    <transition name="fade-opacity-lazy">
      <card-person-page :slide-active="slide"
                   :person-info="activeUser"
                   v-if="activeTeamCard"/>
    </transition>
  </div>
</template>

<script>
import CardPerson from "@/components/entities/CardPerson.vue";
import {mapActions, mapGetters} from "vuex";
import TitleRouter from "@/components/shared/TitleRouter.vue";
import TitleRouterSubPage from "@/components/shared/TitleRouterSubPage.vue";
import CardPersonMobile from "@/components/entities/CardPersonMobile.vue";
import CardPersonPage from "@/components/entities/CardPersonPage.vue";

export default {
  name: "team-page",
  components: {CardPersonPage, CardPersonMobile, TitleRouterSubPage, TitleRouter, CardPerson},

  props: ['slug', 'slide', 'typeUser'],

  data() {
    return {
      activeTeamCard: false,
      activeTitle: '',
      activeUserState: false,
      typePerson: {
        ru: ['Партнеры', 'Руководители', 'Сотрудники'],
        en: ['Partners', 'executives', 'employees']
      },
      isLoadingData: true,
      activeTitleLoading: false
    }
  },

  async created() {
    await this.IS_LOADING(true)
    await this.checkInfo();
  },

  watch: {
    slide() {
      this.IS_LOADING(false)
    },

    activeEng() {
      if(this.activeUserState) {
        this.activeTitleLoading = true
        setTimeout(() => {
          this.startAnimation();
        },500)
      }
    },

    activeUser() {
      if(this.activeUser === ''){
        this.IS_LOADING_IMG(false)
        this.changeMainSlide(2)
      }
    }
  },

  methods: {
    ...mapActions(['ACTIVE_MAIN_SLIDE', 'IS_LOADING_IMG']),
    ...mapActions('persons',[ 'getActiveUser', 'getCaseUser', 'getNewsUser', 'ACTIVE_TEAM_SLIDE']),
    ...mapActions('case',[ 'getCase']),
    ...mapActions('news',[ 'getPress']),
    async checkInfo() {
      try {
        await this.getActiveUser({slug: this.slug})
        await Promise.all([
          this.getCaseUser({id: this.activeUser?.id}),
          this.getNewsUser({id: this.activeUser?.id}),
          this.getCase(),
          this.getPress()
        ])
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoadingData = false
        switch (this.typeUser) {
          case 'partners':
            this.ACTIVE_TEAM_SLIDE(1)
            break;
          case 'exes':
            this.ACTIVE_TEAM_SLIDE(2)
            break;
          case 'empl':
            this.ACTIVE_TEAM_SLIDE(3)
            break;
        }
        this.startAnimation();
      }
    },


    startAnimation() {
      document.body.style.setProperty('overflow-y', 'hidden');
      setTimeout(() => {
        this.activeTeamCard = true
        this.changeActiveSubTitle();
        this.activeUserState = true
      }, 100)
      this.ACTIVE_MAIN_SLIDE(2);
    },

    changeActiveSubTitle() {
      this.activeTitleLoading = false
        this.$nextTick(() => {
          this.checkLang();
          this.$nextTick(() => {
            if(this.activeUser !== '') {
              this.activeTitle = this.activeUser?.profiles.find(item => item.lang === this.$i18n.locale)?.fullName
              this.$nextTick(() => {
                this.activeTitle = '';
              })
            }
          })
        })
    },

    async checkLang() {
      if(this.activeUser !== '') {
        switch(this.typeUser) {
          case 'partners':
            this.activeTitle = this.typePerson[this.$i18n.locale][0]
            break;
          case 'exes':
            this.activeTitle = this.typePerson[this.$i18n.locale][1]
            break;
          case 'empl':
            this.activeTitle = this.typePerson[this.$i18n.locale][2]
            break;
        }
      }

    }
  },
  computed: {
    ...mapGetters({
      activeUser: 'persons/activeUser'
      }
    ),

    activeMainTitle() {
      return this.mainTitlePage.teamScreen.startTitle;
    },
    activeTeamSlide() {
      return this.$store.state.activeTeamSlide;
    }
  },
}
</script>

<style scoped lang="scss">
.team-page-container {
  //overflow: scroll;
}

@media (max-width: 769px) {
  .team-page-container {
    justify-content: flex-end;
    height: var(--calc-height);
  }
}


::-webkit-scrollbar {
  display: none;
}

.main-container {
  --padding-right: 0;
  --title-padding: 0px;
}



</style>