import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import colorTheme from "@/mixins/colorTheme";
import reusedCode from "@/mixins/reusedCode";
import i18n from "@/locales/i18n-config";
import VueAwesomeSwiper from "vue-awesome-swiper";
import CRUDMethods from "@/mixins/CRUDMethods";
import Vue3Lottie from "vue3-lottie";

createApp(App).use(store).use(router).use(i18n).use(VueAwesomeSwiper).use(Vue3Lottie, { name: 'LottieAnimation' }).mixin(colorTheme).mixin(reusedCode).mixin(CRUDMethods).mount('#app')
