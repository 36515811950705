<template>
    <div class="footer-screen-container">
      <div class="footer-screen-content">
        <div class="footer-text-content">
          <div class="main-title-container" :class="{'active-mobile' : activeMobile}">
            <animation-text @endAnimation="endAnimationMainTitle" v-if="activeMainTitle && !activeMobile" :text-info="$t('footerScreen.mainTitle')"/>
            <animation-text @endAnimation="endAnimationMainTitle" v-if="activeMainTitle && activeMobile" :text-info="$t('footerScreen.mainTitleMobile')"/>
          </div>
          <div class="sub-title-container" :class="{'active-mobile' : activeMobile}">
            <transition-group name="fade-opacity-animation">
              <div class="sub-title-content"
                   :key="index"
                   v-show="activeSubTitle[index]"
                   v-for="(item,index) in $tm('footerScreen.subTitle')">
                <large-title :active-cormorant="true" :class="{'eng' : activeEng }">
                  {{ item }}
                </large-title>
              </div>
            </transition-group>
          </div>
        </div>

        <transition name="fade-opacity-animation">
          <div class="big-logo-container" v-if="activeLogo && !activeMobile">
                        <div class="lottie-wrapper" v-show="activeColor">
                          <LottieAnimation :animationData="require('@/assets/lottie/signature-dark.json')"
                                           style="height: 100%; width: 100%"
                                           :flex-growth="'None'"
                                           :loop="false"
                                           :delay="1000"
                                           :rendererSettings="{ preserveAspectRatio: 'xMidYMid slice' }" />
                        </div>
                        <div class="lottie-wrapper" v-show="!activeColor">
                          <LottieAnimation :animationData="require('@/assets/lottie/signature-light.json')"
                                           style="height: 100%; width: 100%"
                                           :flex-growth="'None'"
                                           :loop="false"
                                           :delay="1000"
                                           :rendererSettings="{ preserveAspectRatio: 'xMidYMid slice' }" />
                      </div>
           </div>

          <div class="big-logo-container" v-else-if="activeLogo && activeMobile">
            <div class="lottie-wrapper">
              <LottieAnimation :animationData="require('@/assets/lottie/signature-light.json')"
                               style="height: 100%; width: 100%"
                               :flex-growth="'None'"
                               :loop="false"
                               :delay="1000"
                               :rendererSettings="{ preserveAspectRatio: 'xMidYMid slice' }" />
            </div>
          </div>

        </transition>
      </div>

      <transition name="fade-translate-y-revert">
        <main-footer v-if="activeFooter && !activeMobile" :active-color-scheme="false"/>
        <main-footer-mobile v-else-if="activeFooter && activeMobile"/>
      </transition>

      
      <div class="background-image">
        <img v-if="activeMobile" :src="imgBackgroundMobile" alt="background">
      </div>
    </div>
</template>

<script>
import MainFooter from "@/components/widgets/MainFooter.vue";
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import AnimationText from "@/components/entities/AnimationText.vue";
import MainFooterMobile from "@/components/widgets/MainFooterMobile.vue";

export default {
  name: "FooterScreen",
  components: {MainFooterMobile, AnimationText, LargeTitle, MainFooter},
  data() {
    return {
      activeMainTitle: false,
      activeSubTitle: [false,false],
      activeFooter: false,
      activeLogo: false,
      imgBackgroundMobile: '/background-footer-mobile.webp'
    }
  },
  mounted() {
    this.$router.push({name: 'contacts'})
    this.startAnimation();
  },
  unmounted() {
    this.activeFooter = false
  },
  methods: {
    startAnimation() {
        setTimeout(() => {
          this.activeFooter = true
          this.activeMainTitle = true
          setTimeout(() => {
            this.activeLogo = true
          }, 500)
        }, 500)

    },
    endAnimationMainTitle() {
        this.activeSubTitle[0] = true
        setTimeout(() => {
          this.activeSubTitle[1] = true
        }, 500)
    }
  }
}
</script>

<style scoped lang="scss">
  .footer-screen-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    position: relative;

    .footer-screen-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;

      .footer-text-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        .main-title-container {
          .text-content {
            color: var(--color-text);
          }
          &.active-mobile {
            .text-content {
              color: #22223B;
            }
          }

        }
        .sub-title-container {

          &.active-mobile {
            .text-content {
              color: #22223B;
            }
          }
          .text-content {
            line-height: 110%;
            font-variant: small-caps;
            letter-spacing: normal;
            font-family: 'CormorantUnicase-Medium', sans-serif;
            font-weight: 500;
            color: var(--color-text);
            text-transform: none;

            &.eng {
              font-variant: small-caps !important;
              text-transform: none !important;
            }
          }
        }
      }


      .big-logo-container {
        position: absolute;
        width: 100%;
        height: 100%;

        .lottie-wrapper {
          right: 0;
          top: 0;
          justify-items: center;
          position: absolute;
          width: 100%;
        }
        & img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }


    .footer-container {
      position: relative;
      bottom: 0;
    }

    .background-image {
      top: 0;
      position: absolute;
      z-index: -1;
      width: 110%;
      height: 110%;

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (max-width: 2560px){
    .footer-screen-container {
      .footer-screen-content {
        .footer-text-content {
          height: 595px;
          padding-bottom: 259px;
          padding-left: 142px;

          .main-title-container {
            margin-bottom: 60px;

            .text-content {
              font-size: 48px;
              letter-spacing: 9.6px;
            }
          }

          .sub-title-container {
            .text-content {
              font-size: 38px;
            }
          }
        }

        .big-logo-container {
          top: 175px;
          right: 350px;
          max-width: 560px;
          max-height: 790px;
        }
      }
    }
  }

  @media (max-width: 2240px) {
    .footer-screen-container {
      .footer-screen-content {
        .footer-text-content {
          height: 480px;
          padding-bottom: 189px;
          padding-left: 143px;

          .main-title-container {
            margin-bottom: 60px;

            .text-content {
              font-size: 47px;
              letter-spacing: 9.4px;
            }
          }

          .sub-title-container {
            .text-content {
              font-size: 37px;
            }
          }
        }
        .big-logo-container {
          top: 100px;
          right: 250px;
          max-width: 450px;
          max-height: 660px;
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .footer-screen-container {
      .footer-screen-content {
        .footer-text-content {
          height: 450px;
          padding-bottom: 192px;
          padding-left: 122px;

          .main-title-container {
            margin-bottom: 40px;

            .text-content {
              font-size: 40px;
              letter-spacing: 8px;
            }
          }

          .sub-title-container {
            .text-content {
              font-size: 32px;
            }
          }
        }

        .big-logo-container {
          top: 48px;
          right: 216px;
          max-width: 390px;
          max-height: 682px;
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .footer-screen-container {
      .footer-screen-content {
        .footer-text-content {
          height: 378px;
          padding-bottom: 203px;
          padding-left: 81px;

          .main-title-container {
            margin-bottom: 29px;

            .text-content {
              font-size: 26px;
              letter-spacing: 5.2px;
            }
          }

          .sub-title-container {
            .text-content {
              font-size: 22px;
            }
          }
        }

        .big-logo-container {
          top: 112px;
          right: 162px;
          max-width: 346px;
        }
      }
    }
  }
  @media (max-height: 800px) {
    .footer-screen-container {
      .footer-screen-content {
        .footer-text-content {
          height: 258px;
          padding-bottom: 82px;
          padding-left: 82px;

          .main-title-container {
            margin-bottom: 30px;

            .text-content {
              font-size: 26px;
              letter-spacing: 5.2px;
            }
          }

          .sub-title-container {
            margin-left: 0;

            .text-content {
              font-size: 22px;
            }
          }
        }

        .big-logo-container {
          top: 53px;
          right: 150px;
          max-width: 281px;
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .footer-screen-container {
      .footer-screen-content {

        .footer-text-content {
          height: 489px;
          padding-bottom: 235px;
          padding-left: 87px;

          .main-title-container {
            margin-bottom: 60px;

            .text-content {
              font-size: 36px;
              letter-spacing: 7.2px;
            }
          }

          .sub-title-container {
            margin-left: 0;

            .text-content {
              font-size: 30px;
            }
          }
        }
        .big-logo-container {
          top: 146px;
          right: 78px;
          max-width: 381px;
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .footer-screen-container {
      .footer-screen-content {

        .footer-text-content {
          height: 325px;
          padding-bottom: 163px;
          padding-left: 72px;
          .main-title-container {
            margin-bottom: 32px;

            .text-content {
              font-size: 26px;
              letter-spacing: 5.6px;
            }
          }

          .sub-title-container {
            margin-left: 0;

            .text-content {
              font-size: 20px;
            }
          }
        }

        .big-logo-container {
          top: 105px;
          right: 105px;
          max-width: 258px
        }
      }
    }
  }

  @media (max-width: 769px) {
    .footer-screen-container {
      .footer-screen-content {
        height: auto;
        padding: 24px 0 0 16px;
        .footer-text-content {
          height: auto;
          padding-bottom: 0;
          padding-left: 0;
        .main-title-container {
          margin-bottom: 16px;
          .text-content {
            font-size: 16px;
            letter-spacing: 3.2px;
          }
        }

        .sub-title-container {
          margin-left: 0;
          .text-content {
            font-size: 15px;
          }
        }
      }


        .big-logo-container {
          top: 108px;
          right: 31px;
          max-width: 111px;
          max-height: 135px;
      }

      }

      .background-image {
        top: -5%;
        right: -5%;
        & img {
          width: 120%;
          height: 130%;
          filter: blur(10px);
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .footer-screen-container {
      .footer-screen-content {
        padding: 24px 0 0 16px;

        .footer-text-content {
          .main-title-container {
            margin-bottom: 32px;

            .text-content {
              font-size: 18px;
              letter-spacing: 3.6px;
            }
          }

          .sub-title-container {
            margin-left: 0;

            .text-content {
              font-size: 16px;
            }
          }
        }

        .big-logo-container {
          top: 114px;
          right: 36px;
          max-width: 136px;
          max-height: 200px;
        }
      }

      .background-image {
        top: -5%;
        right: -5%;
        & img {
          width: 120%;
          height: 130%;
          filter: blur(10px);
        }
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){
    .footer-screen-container {
      .footer-screen-content {
        padding: 22px 0 0 22px;

        .footer-text-content {
          .main-title-container {
            margin-bottom: 16px;

            .text-content {
              font-size: 16px;
              letter-spacing: 3.2px;
            }
          }

          .sub-title-container {
            margin-left: 0;

            .text-content {
              font-size: 15px;
            }
          }
        }

        .big-logo-container {
          top: 88px;
          right: 32px;
          max-width: 111px;
          max-height: 116px
      }
      }
    }
  }


  @media (max-width: 375px){
    .footer-screen-container {
      .footer-screen-content {
        padding: 24px 0 0 16px;

        .footer-text-content {
          .main-title-container {
            margin-bottom: 20px;

            .text-content {
              font-size: 15px;
              letter-spacing: 3px;
            }
          }

          .sub-title-container {
            margin-left: 0;

            .text-content {
              font-size: 14px;
            }
          }
        }

        .big-logo-container {
          top: 84px;
          right: 25px;
          max-width: 96px;
          max-height: 83px;
      }
      }
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .footer-screen-container {
      .footer-screen-content {
        padding: 24px 0 0 16px;

        .footer-text-content {
          .main-title-container {
            margin-bottom: 24px;

            .text-content {
              font-size: 16px;
              letter-spacing: 3.2px;
            }
          }

          .sub-title-container {
            margin-left: 0;

            .text-content {
              font-size: 15px;
            }
          }
        }

        .big-logo-container {
          top: 106px;
          right: 33px;
          max-width: 92px;
          max-height: 112px;
        }
      }
    }
  }


  @media (max-width: 320px) {
    .footer-screen-container {
      .footer-screen-content {
        padding: 24px 0 10px 16px;

        .footer-text-content {
          .main-title-container {
            margin-bottom: 20px;

            .text-content {
              font-size: 14px;
              letter-spacing: 2.4px;
            }
          }

          .sub-title-container {
            margin-left: 0;

            .text-content {
              font-size: 14px;
            }
          }
        }

        .big-logo-container {
          display: none;
        }
      }
    }
  }


  @media (min-width: 2561px) {
    .footer-screen-container {
      .footer-screen-content {

        .footer-text-content {
          height: 1050px;
          padding-bottom: 284px;
          padding-left: 284px;
          .main-title-container {
            margin-bottom: 250px;
            .text-content {
              font-size: 100px;
              letter-spacing: 20.1px;
            }
          }
        }

        .big-logo-container {
          top: 250px;
          right: 550px;
          max-width: 900px;
          max-height: 1400px;
        }

      }
    }
  }

</style>