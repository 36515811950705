<template>
  <transition name="fade-opacity-lazy">
    <div class="controller-info-container" v-if="activeController">
<!--      <div class="arrow-button-container" @click="changeState">-->
<!--        <img v-if="activeColor" :src="getUrlIcon('single-arrow-top-dark.svg')" alt="arrow">-->
<!--        <img v-else :src="getUrlIcon('single-arrow-top-light.svg')" alt="arrow">-->
<!--      </div>-->
    </div>
  </transition>

</template>

<script>
export default {
  name: "ControllerInfoContainer",
  data() {
    return {
      activeController: true
    }
  },
  mounted() {
    addEventListener('wheel', this.scrollWindow);
    addEventListener('touchmove', this.scrollWindow);
  },

  watch: {
    activeEng() {
      if(this.activeController === false ) {
        this.activeController = true
        this.$emit('changeActiveCase')
        addEventListener('wheel', this.scrollWindow);
        addEventListener('touchmove', this.scrollWindow);
      }

    }
  },

  methods: {
    scrollWindow() {
      if(this.activeController) {
        this.activeController = false;
        removeEventListener('wheel', this.scrollWindow);
        removeEventListener('touchmove', this.scrollWindow);
        setTimeout(() => {
          this.$emit('changeActiveCase')
        }, 500)
      }
    },
    // changeState() {
    //   this.activeController = !this.activeController;
    //
    //   setTimeout(() => {
    //     this.$emit('changeActiveCase')
    //   }, 500)
    // },
  }
}
</script>

<style scoped lang="scss">


.controller-info-container {
  pointer-events: none;
    z-index: 500;
    display: flex;
    bottom: calc(-1 * var(--main-padding-bottom));
    position: absolute;
    width: 100%;
    height: 100%;

    .arrow-button-container {
      align-self: flex-end;
      bottom: calc(var(--main-padding-bottom));
      margin: auto auto 0;
      cursor: pointer;
      position: relative;
      opacity: .6;
      transition: opacity .6s ease;
      z-index: 502;

      &:hover {
        opacity: .8;
      }

      & img {
        width: 100%;
        height: 100%;
      }
    }

    &:after {
      pointer-events: none;
      width: 100%;
      height: 50%;
      background: linear-gradient(180deg,  rgba(34, 34, 59, 0.0) 10%, var(--color-background) 90%);
      content: "";
      position: absolute;
      z-index: 501;
      bottom: 0;
    }
}

@media (max-width: 2560px) {
      .controller-info-container {
        .arrow-button-container {
          max-width: 72px;
        }
      }
    }

@media (max-width: 1920px) {
      .controller-info-container {
        .arrow-button-container {
          max-width: 56px;
        }
      }
    }

@media (max-width: 1600px) {
  .controller-info-container {
    margin-bottom: 0;
    .arrow-button-container {
      max-width: 48px;
    }
  }
}
@media (max-height: 800px) {
  .controller-info-container {
    margin-bottom: 0;
    .arrow-button-container {
      max-width: 42px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .controller-info-container {
    margin-bottom: 0;
    .arrow-button-container {
      max-width: 42px;
    }
  }
}

@media (max-width: 1133px) {
  .controller-info-container {
    margin-bottom: 0;
    .arrow-button-container {
      max-width: 36px;
    }
  }
}
@media (min-width: 2561px) {
      .controller-info-container {

        .arrow-button-container {
          max-width: 150px;
        }
      }
    }

</style>