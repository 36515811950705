<template>
  <div class="swiper-button-container" @click="slideTo">
    <div class="swiper-button-content" :class="{'active-modal-filter' : activeModalFilter}">
      <img v-if="activeColor" :src="getUrlIcon('single-arrow-top-dark.svg')" alt="icon">
      <img v-else :src="getUrlIcon('single-arrow-top-light.svg')" alt="icon">
    </div>

  </div>
</template>

<script>
export default {
  name: "MobileController",
  methods: {
    slideTo() {
      this.$emit('slideTo')
    }
  },
  computed: {
    activeModalFilter() {
      return this.$store.state.activeModalFilter;
    },

  }
}
</script>

<style scoped lang="scss">
  .swiper-button-container {
    left: 50%;
    right: 50%;
    margin: 0 auto;
    position: absolute;
    bottom: 18px;
    z-index: 900;
    width: 100%;
    max-width: 15px;

    .swiper-button-content {
      width: 100%;
      transition: filter .6s ease;
    }
  }

  @media (max-width: 769px) {
    .swiper-button-container {
      bottom: 16px;
      max-width: 12px;
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .swiper-button-container {
      bottom: 10px;
      max-width: 15px;
    }
  }


  @media (max-width: 375px){
    .swiper-button-container {
      bottom: 12px;
      max-width: 13px;
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .swiper-button-container {
      bottom: 14px;
      max-width: 12px;
    }
  }


  @media (max-width: 320px) {
    .swiper-button-container {
      bottom: 9px;
      max-width: 10px;
    }
  }

</style>