<template>
  <div class="main-title-page"
       :class="{'active-person-title': activePersonTitle, 'active-white-color' : activeStaticColor}" v-if="isActiveMainTitle">
    <large-title :active-cormorant="true" :epic-large="true" :class="{'active-modal-filter' : activeModalFilter}">
      {{ activeMainTitle }}
    </large-title>
  </div>
</template>

<script>
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";

export default {
  name: "MainTitlePage",
  components: {LargeTitle},
  props: {
    activeMainTitle: {
      type: String,
      required: true
    },
    activePersonTitle: {
      type: Boolean,
      default: false
    },
    activeStaticColor: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActiveMainTitle: false
    }
  },
  mounted() {
    if(this.$route.name.includes('industry')) {
      setTimeout(() => {
        this.isActiveMainTitle = true
      }, 250)
    } else {
      this.isActiveMainTitle = true
    }

  },
  computed: {
    activeModalFilter() {
      return this.$store.state.activeModalFilter;
    },
  }
}
</script>

<style scoped lang="scss" >
.main-title-page {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
  top: var(--main-padding-top);
  margin-top: var(--breadcrumbs-height);

  &.active-white-color {
    .text-content {
      color: #F2E9E4 !important;
    }
  }

  .text-content {
    transition: all .6s ease;
  }

  &.active-person-title {
    .text-content {
      //line-height: 91.1%;
    }
  }
}

@media (max-width: 769px) {
  .main-title-page {
    margin-top: 8px
  }
}

@media (max-width: 375px) {
  .main-title-page {
    margin-top: 4px
  }
}

@media (max-width: 375px) and (min-height: 630px) {
  .main-title-page {
    margin-top: 8px
  }
}

@media (max-width: 320px) {
  .main-title-page {
    margin-top: 4px
  }
}

@media (max-width: 2560px){
  .main-title-page {
    .text-content {
    letter-spacing: 18.8px;
  }
}}

@media (max-width: 2240px) {
  .main-title-page {
    .text-content {
    letter-spacing: 18.8px;
      font-size: 94px;
  }}
}


@media (max-width: 1920px) {
  .main-title-page {
    .text-content {
    letter-spacing: 12px;
      font-size: 60px;
  }}
}

@media (max-width: 1600px) {
  .main-title-page {
    .text-content {
    letter-spacing: 10.8px;
      font-size: 54px;
  }}
}
@media (max-height: 800px) {
  .main-title-page {
    .text-content {
    letter-spacing: 10.8px;
      font-size: 54px;
  }}
}

@media (max-width: 1400px) and (min-height: 801px) {
  .main-title-page {
    .text-content {
      font-size: 48px;
      letter-spacing: 9.6px;
  }}
}

@media (max-width: 1133px) {
  .main-title-page {
    .text-content {
      letter-spacing: 9.6px;
      font-size: 48px;
  }}
}

@media (max-width: 769px) {
  .main-title-page {
    .text-content {
      font-family: 'CormorantUnicase-Medium', sans-serif;
      font-variant-numeric: lining-nums proportional-nums;
      letter-spacing: normal;
      font-size: 26px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .main-title-page {
    .text-content {
      font-size: 26px;
      letter-spacing: normal;
    }
  }
}

@media (max-width: 375px){
  .main-title-page {
    .text-content {
      font-size: 24px;
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .main-title-page {
    .text-content {
      font-size: 26px;
    }
  }
}


@media (max-width: 320px) {
  .main-title-page {
    .text-content {
      font-size: 20px;
    }
  }
}


@media (min-width: 2561px) {
  .main-title-page {
    .text-content {
      letter-spacing: 37.6px;
    }
  }
}
</style>