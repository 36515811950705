<template>
  <div class="large-button-container" @click="clickToState">
    <div class="large-button-content" :class="{'active-light-color' : !activeColor}">
      <div class="large-button-content-item">
        <slot/>
        {{mainTitle}}
        <button-icon v-if="activeButtonIcon" :img-name="nameImg" />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";

export default {
  name: "ButtonLarge",
  components: {ButtonIcon},
  props: {
    mainTitle: {
      type: String,
      required: true
    },
    hrefDownloadActive: {
      type: Object,
      default: {
        url: '',
        state: false
      }
    },
    nameImg: {
      type: String
    },
    activeButtonIcon: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    clickToState() {
      this.$emit('clickToState')
    }
  }
}
</script>

<style scoped lang="scss">
  .large-button-container {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    line-height: 100%;
    cursor: pointer;
    .large-button-content {
      width: 100%;
      background-color: var(--color-card);

      .large-button-content-item {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all .8s ease;
        width: 100%;

        & a {
          width: fit-content;
          height: fit-content;
        }
      }

      &:hover {
        .large-button-content-item {
          background-color: var(--color-field);
        }
      }

      &.active-light-color {
        //background-color: var(--color-background);
        border-style: solid;
        border-color: var(--color-element);

        &:hover {
          .large-button-content-item {
            background-color: var(--color-element);
          }
        }
      }
    }
  }

  @media (max-width: 2560px){
    .large-button-container {
      column-gap: 24px;
      height: 72px;
      font-size: 20px;

      .large-button-content {
        border-radius: 18px;

        .large-button-content-item {
          border-radius: 18px;
          padding: 0 43px;
        }

        &.active-light-color {
          border-width: 1.8px;
        }

      }
    }
  }

  @media (max-width: 2240px) {
    .large-button-container {
      height: 66px;
      font-size: 18px;

      .large-button-content {
        border-radius: 16px;

        .large-button-content-item {
          border-radius: 16px;
          padding: 0 38px;
        }

        &.active-light-color {
          border-width: 1.6px;
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .large-button-container {
      column-gap: 24px;
      height: 60px;
      font-size: 16px;

      .large-button-content {
        border-radius: 14px;

        .large-button-content-item {
          border-radius: 14px;
          padding: 0 32px;
        }

        &.active-light-color {
          border-width: 1px;
        }

      }
    }
  }


  @media (max-width: 1600px) {
    .large-button-container {
      column-gap: 24px;
      height: 40px;
      font-size: 10px;

      .large-button-content {
        border-radius: 10px;

        .large-button-content-item {
          border-radius: 10px;
          padding: 0 16px;
        }

        &.active-light-color {
          border-width: 1px;
        }

      }
    }
  }
  @media (max-height: 800px) {
    .large-button-container {
      column-gap: 24px;
      height: 40px;
      font-size: 10px;

      .large-button-content {
        border-radius: 10px;

        .large-button-content-item {
          border-radius: 10px;
          padding: 0 16px;
        }

        &.active-light-color {
          border-width: 1px;
        }

      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .large-button-container {
      font-size: 11px;
    }
  }

  @media (max-width: 1133px) {
    .large-button-container {
      height: 34px;
      font-size: 10px;
    }
  }
  @media (max-width: 769px) {
    .large-button-container {
      border-radius: 10px;
      max-width: 108px;
      width: 100%;
      height: 32px;
      font-size: 10px;

      .button-icon-container {
        max-width: 16px;
        max-height: 16px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .large-button-container {
      max-width: 120px;
      width: 100%;
      height: 40px;
      font-size: 10px;

      .button-icon-container {
        max-width: 16px;
        max-height: 16px;
      }
    }
  }



  @media (max-width: 320px) {
    .large-button-container {
      max-width: 86px;
      width: 100%;
      height: 24px;
      font-size: 8px;

      .button-icon-container {
        max-width: 12px;
        max-height: 12px;
      }
    }
  }



  @media (min-width: 2561px) {
    .large-button-container {
      column-gap: 24px;
      height: 126px;
      font-size: 35px;

      .large-button-content {
        border-radius: 32px;

        .large-button-content-item {
          border-radius: 32px;
          padding: 0 76px;
        }

        &.active-light-color {
          border-width: 2px;
        }

      }
    }
  }

</style>