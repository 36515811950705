<template>
  <div class="office-content" :class="{'eng' : activeEng }">
    <footer-title :active-color-scheme="activeColorScheme">{{$t('footerScreen.mainFooter.officeTitle')}}</footer-title>

    <p class="main-paragraph" :class="{'active-color-scheme': activeColorScheme}" v-for="office in $tm('footerScreen.mainFooter.officeContent')"
                    :key="office.id">
      {{ office.name }}
    </p>
    <a class="contact-text" :href="'mailto:' + emailData">{{ emailText }} {{ emailData }}</a>
  </div>
</template>

<script>
import FooterTitle from "@/components/shared/footer/FooterTitle.vue";

export default {
  name: "OfficeContent",
  components: {FooterTitle},
  props: {
    activeColorScheme: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      emailText: 'e-mail:',
      emailData: 'info@imlegal.ru'
    }
  }
}
</script>

<style scoped lang="scss">
.office-content {
  &.eng {
    font-variant: all-small-caps !important;
  }
  .main-paragraph {
    font-family: 'ProximaNova-Regular', sans-serif;
    line-height: 150%;
    color: #22223B;

    &.active-color-scheme {
      color: var(--color-text);
    }
  }

  & a {
    color: #22223B;
  }
}

@media (max-width: 2560px){
  .office-content {
    .main-paragraph {
      font-size: 16px;
    }
  }
}


@media (max-width: 1920px) {
  .office-content {
    .main-paragraph {
      font-size: 14px;
    }
  }
}
@media (max-width: 1600px) {
  .office-content {
    .main-paragraph {
      font-size: 9px;
    }
  }
}

@media (max-height: 800px) {
  .office-content {
    .main-paragraph {
      font-size: 9px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px){
  .office-content {
    .main-paragraph {
      font-size: 11px;
    }
  }
}

@media (max-width: 1133px) {
  .office-content {
    .main-paragraph {
      font-size: 8px;
    }
  }
}

@media (max-width: 769px) {
  .office-content {
    max-width: 124px;
    font-size: 10px !important;
    line-height: 120% !important;
    .main-paragraph {
      line-height: inherit;
      margin-bottom: 10px;
      font-size: inherit;

      &:nth-child(3) {
        margin-bottom: 4px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .office-content {
    max-width: 152px;
    font-size: 12px !important;
    line-height: 120% !important;
    .main-paragraph {
      line-height: inherit;
      margin-bottom: 12px;
      font-size: inherit;

      &:nth-child(3) {
        margin-bottom: 6px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  .office-content {
    .main-paragraph {
      margin-bottom: 12px;

      &:nth-child(3) {
        margin-bottom: 4px;
      }
    }
  }
}

@media (max-width: 375px){
  .office-content {
    max-width: 98px;
    font-size: 8px !important;
    .main-paragraph {
      margin-bottom: 8px;

      &:nth-child(3) {
        margin-bottom: 2px;
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .office-content {
    max-width: 124px;
    font-size: 10px !important;
    .main-paragraph {
      margin-bottom: 10px;

      &:nth-child(3) {
        margin-bottom: 4px;
      }
    }
  }
}


@media (max-width: 320px) {
  .office-content {
    max-width: 96px;
    font-size: 8px !important;
    .main-paragraph {
      margin-bottom: 8px;

      &:nth-child(3) {
        margin-bottom: 2px;
      }
    }
  }
}


@media (min-width: 2561px) {
  .office-content {
    .main-paragraph {
      font-size: 32px;
    }
  }
}

</style>