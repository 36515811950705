<template>
  <div class="main-card-person-container">
    <transition name="fade-opacity-1" >
    <div class="text-info-container" v-if="activePersonInfo" :class="{'active-news-case' : activeNews || activeCase}">

      <div class="main-title-page-container">
          <div class="sub-title-container">
          <main-title-page :active-person-title="true" :active-main-title="sliceName"/>
            <div class="person-type-container">
              <sub-paragraph v-if="activeLangUser?.profiles?.position !== ''" :active-cormorant="false" :class="{'eng' : activeEng}">
                {{ activeLangUser?.profiles?.position }}
              </sub-paragraph>
              <sub-paragraph v-if="activeLangUser?.profiles?.status !== ''" :active-cormorant="false" :class="{'eng' : activeEng}">
                {{ activeLangUser?.profiles?.status }}
              </sub-paragraph>
            </div>
        </div>

          <div class="button-controller-container">
            <button-main v-for="item in $tm('teamPage.buttonController')"
                         v-show="buttonIsActive[item.id]"
                         :key="item.id"
                         @click="changeBlock(item.id)"
                         :active-button="activeIndex === item.id"
                         :disabled-button="false">
                {{item.name}}
            </button-main>
          </div>
      </div>

      <div class="person-info-text-container">
          <transition-group name="fade-opacity-lazy">
            <section class="info-text-container about-content" id="section-0" v-if="activeAbout">

              <div class="info-container paragraph-container" v-if="activeLangUser?.profiles?.caption !== ''">
              <main-paragraph>
                {{ activeLangUser?.profiles?.caption }}
              </main-paragraph>
              </div>

            <div class="info-container professional-interest" v-if="activeLangUser?.profiles?.professionalInterests.length !== 0">
              <main-title :active-cormorant="true">{{mainTitleContent[0]}}</main-title>
              <div class="info-content" >
                <main-paragraph v-for="item in activeLangUser?.profiles?.professionalInterests">{{item}}</main-paragraph>
              </div>
            </div>

            <div class="info-container awards" v-if="activeLangUser?.profiles?.awards?.length !== 0">
              <main-title :active-cormorant="true">{{mainTitleContent[1]}}</main-title>
              <div class="info-content">
                <main-paragraph v-for="item in  activeLangUser?.profiles?.awards">{{item}}</main-paragraph>
              </div>
            </div>
            <div class="empty-block"></div>
          </section>

              <section class="info-text-container education" id="section-1" v-else-if="activeEducation">
                <div class="info-container">
<!--                  <main-title :active-cormorant="true">{{mainTitleContent[2]}}</main-title>-->
                  <div class="education-info-container">
                    <div class="info-content"  v-for="item in activeLangUser?.educations">
                      <div class="education-content top">
                        <main-paragraph>{{ new Date(item?.startDate).getFullYear() }} - {{ new Date(item?.endDate).getFullYear() }}</main-paragraph>
                        <main-paragraph>{{ item?.educationLevel?.[activeLang] }}</main-paragraph>
                      </div>
                      <div class="education-content central">
                        <main-paragraph>{{ item?.universityName }}</main-paragraph>
                      </div>
                      <div class="education-content bottom">
                        <main-paragraph>{{ item?.specialityName }}</main-paragraph>
                        <main-paragraph>{{ item?.description }}</main-paragraph>
                      </div>
                    </div>
                  </div>
                </div>

              </section>

              <section class="info-text-container publication" id="section-2" v-else-if="activePublication">
                <div class="info-container">
<!--                    <main-title  :active-cormorant="true">{{mainTitleContent[3]}}</main-title>-->
                    <div class="info-content">
                      <main-paragraph v-for="item in activeLangPublication">
                        {{ item?.name }}
                      </main-paragraph>
                    </div>
                </div>
              </section>
          </transition-group>
        <transition name="fade-opacity-lazy">
          <section class="case-slider-container" id="section-3" v-show="activeCase">
<!--            <main-title class="case" :active-cormorant="true">-->
<!--              {{ mainTitleContent[4] }}-->
<!--            </main-title>-->
                <slider-case-user class="slider-container"  @activeLength="checkActiveLengthCase"
                                  :active-person-page="true"
                                  :active-sub-page="true"
                                  :setting-swiper="settingSwiperCase"
                                  :active-case-full="false"/>
          </section>
        </transition>
        <transition name="fade-opacity-lazy">
          <section class="news-slider-container" id="section-4" v-show="activeNews">
<!--            <main-title :active-cormorant="true">{{mainTitleContent[5]}}</main-title>-->
            <slider-news-user class="slider-container"
                              :setting-swiper="settingSwiperNews"
                              :class="{'active-index': activeNewsSlider}"
                              @activeLength="checkActiveLengthNews"
                              @changeNewsSliderState="changeNewsSliderState"
                              :active-news-full="false"
                              :controller-setting="{
                                top: true,
                                bottom: true
                              }"/>
          </section>
        </transition>
      </div>
    </div>
    </transition>

    <transition name="fade-opacity-animation">
      <div class="person-image-container" v-if="activeImage">
        <div class="person-image-content">
          <img @load="loadImg" :src="personInfo?.profilePhoto" alt="person">
        </div>
      </div>
    </transition>
  </div>
</template>

<script>


import MainTitlePage from "@/components/entities/MainTitlePage.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import SliderNewsUser from "@/components/entities/SliderNewsUser.vue";
import SliderCaseUser from "@/components/entities/SliderCaseUser.vue";

export default {
  name: "CardPerson",
  components: {SubParagraph, MainTitlePage, ButtonMain, MainParagraph, SliderCaseUser, SliderNewsUser, MainTitle},
  props: {
    slideActive: {
      type: Number,
      required: true
    },
    personInfo: {
      type: Object,
      required: true
    },
    sliceName: {
      type: String,
      required: true
    },
    activeLangUser: {
      type: Object,
      required: true
    },
    activeLangPublication: {
      type: Object,
      required: true
    },
    activeLangRole: {
      type: Object,
      required: true
    },
    activeLang: {
      type: String,
      required: true
    },
    mainTitleContent: {
      type: Array,
      required: true
    },
    buttonIsActive: {
      type: Array,
      required: true
    },
    settingSwiperNews: {
      type: Object,
      required: true
    },
    settingSwiperCase: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeParentHeight: 0,
      activeAbout: false,
      activeEducation: false,
      activePublication: false,
      activeCase: false,
      activeNews: false,
      activeIndex: 0,
      prevTimer: null,
      activeImage: false,
      activePersonInfo: false,
      activeNewsSlider: false
    }
  },

  watch: {
    slideActive() {
      this.startSlide(this.slideActive)
    }
  },

  mounted() {
    if(this.personInfo?.profilePhoto === null) {
      this.IS_LOADING(false)
    }
    this.activePersonInfo = true;
    this.activeImage = true;
    setTimeout(() => {
      if(this.buttonIsActive[0] === false && this.slideActive !== 'news') {
        this.startSlide('cases');
      } else {
        this.startSlide(this.slideActive);
      }
    }, 1000)
  },

  methods: {
    startSlide(id) {
      this.hideBlock();
      switch (id) {
        case 'cases':
          if(this.buttonIsActive[3]) {
            this.activeCase = true
            this.activeIndex = 3;
          } else {
            let firstActiveIndex = this.buttonIsActive.findIndex(item => item === true)
            this.openActiveBlock(firstActiveIndex)
          }
          break
        case 'news':
          if(this.buttonIsActive[4]) {
            this.activeNews = true
            this.activeIndex = 4;
          } else {
            let firstActiveIndex = this.buttonIsActive.findIndex(item => item === true)
            this.openActiveBlock(firstActiveIndex)
          }
          break
        case 'about':
          if(this.buttonIsActive[0]) {
            this.activeIndex = 0;
            this.activeAbout = true
          } else {
            let firstActiveIndex = this.buttonIsActive.findIndex(item => item === true)
            this.openActiveBlock(firstActiveIndex)
          }
          break
        case 'edu':
          this.activeIndex = 1;
          this.activeEducation = true
          break
        case 'pub':
          this.activeIndex = 2;
          this.activePublication = true
          break
      }
    },

    loadImg() {
      this.IS_LOADING(false)
    },


    openActiveBlock(id) {
      switch (id) {
        case 0:
          this.activeIndex = 0;
          this.activeAbout = true
          break;
        case 3:
          this.activeCase = true
          this.activeIndex = 3;
          break;
        case 4:
          this.activeNews = true
          this.activeIndex = 4;
          break;
      }
    },


    hideBlock() {
      this.activeAbout = false
      this.activeEducation = false
      this.activePublication = false
      this.activeCase = false
      this.activeNews = false
    },

    changeBlock(id) {
      switch (id) {
        case 0:
          this.$router.push({name: 'person-page', params: {slide: 'about'}})
          break
        case 1:
          this.$router.push({name: 'person-page', params: {slide: 'edu'}})
          break
        case 2:
          this.$router.push({name: 'person-page', params: {slide: 'pub'}})
          break
        case 3:
          this.$router.push({name: 'person-page', params: {slide: 'cases'}})
          break
        case 4:
          this.$router.push({name: 'person-page', params: {slide: 'news'}})
          break
      }
    },


    changeNewsSliderState(state) {
      this.activeNewsSlider = state
    },

    checkActiveLengthNews(length) {
      this.$emit('checkActiveLengthNews', length)
    },

    checkActiveLengthCase(length) {
      this.$emit('checkActiveLengthCase', length)
    },
  }
}
</script>

<style scoped lang="scss">

.main-title-page {
  margin-top: 0;
  top: 0;
}

.slider-container {
  height: 100%;
}

span {
  cursor: pointer;
}

.text-content {
  &.main-text {
    line-height: 150%;
  }

  &.main-title {
    line-height: 100%;
  }
}

  .main-card-person-container {
    width: 100%;
    margin-top: var(--breadcrumbs-height);
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: 100%;

    .text-info-container {
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;

      .main-title-page-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex-basis: 25%;

        .sub-title-container {
          display: flex;
          flex-direction: column;
          top: 0;
          position: relative;
          background-color: var(--color-background);
          width: 100%;
          .person-type-container {
            display: flex;
            flex-direction: column;

            .text-content {
              color: #9A8C98FF;

              &.eng {
                font-variant: all-small-caps;
              }
            }
          }
        }

        .button-controller-container {
          display: flex;
          flex-direction: row;
        }

      }

      &.active-news-case {
        &:after {
          visibility: hidden;
        }
      }

      &:after {
        transition: visibility .6s ease;
        visibility: visible;
        width: 100%;
        height: 20%;
        pointer-events: none;
        transform: translateZ(0);
        background: linear-gradient(180deg,  rgba(34, 34, 59, 0.0) 0%, var(--color-background) 100%);
        content: "";
        position: absolute;
        z-index: 850;
        bottom: 0;
      }


        .person-info-text-container {
          flex-basis: 65%;
          transform: translateZ(0);
          display: flex;
          height: 100%;
          overflow-scrolling: touch;
          flex-direction: column;
          justify-content: flex-start;
          overflow-x: hidden;
          overflow-y: auto;



          &::-webkit-scrollbar {
            display: none;
          }

          .paragraph-container {
            display: flex;
            flex-direction: column;
            .text-content {
              line-height: 150%;
            }
          }

          .info-text-container {


            .info-container {
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;

              .info-content {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
              }

              &.paragraph-container {
                .text-content {
                  line-height: 150%;
                }
              }

            }

            &.education {
              .education-info-container {
                display: flex;
                flex-direction: column;
                row-gap: 16px;

                .info-content {
                  display: flex;
                  flex-direction: column;
                  .education-content {
                    display: flex;
                    flex-direction: row;
                  }
                }
              }
            }
          }
          .case-slider-container {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            width: 100%;
            height: 100%;

            .slider-content-container {
              width: 100%;
              height: 100%;
            }
          }

          .news-slider-container {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            width: 100%;
            height: 100%;
            .slider-news-content-container {
              transition: padding-top .5s ease;
              padding-top: var(--padding-top-slider);
              --padding-top-slider: 0px;
              max-height: 100%;
              width: 100%;
              height: 100%;
            }
          }
        }
    }


    .person-image-container {
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 950;
      background-color: var(--color-card);
      &.empty {
        opacity: 0;
        z-index: -1;
        position: relative;
      }

      .person-image-content {
        width: 100%;
        height: 100%;

        & img {
          width: 100%;
          height: 100%;
        }
      }

      &:after {
        width: 20%;
        height: 100%;
        pointer-events: none;
        background: linear-gradient(270deg,  rgba(34, 34, 59, 0.0) 0, var(--color-background) 76.77%);
        content: "";
        position: absolute;
        z-index: 900;
        bottom: 0;
      }
    }
  }
  
  @media (min-width: 770px) {
    .main-card-person-container {
      .text-info-container {
        .person-info-text-container {
          .news-slider-container {
            &:before {
              transition: all .6s ease;
              overflow: hidden;
              pointer-events: none;
              width: 100%;
              height: 20%;
              background: linear-gradient(0deg,  rgba(34, 34, 59, 0.0) 0, var(--color-background) 50%);
              content: "";
              position: absolute;
              z-index: 900;
              top: -10%;
            }
          }
        }
      }
    }
  }

@media (max-width: 2560px){
  .main-card-person-container {

    .text-info-container {
      .main-title-page-container {
        max-height: 410px;
        row-gap: 64px;
        margin-bottom: 32px;

        .sub-title-container {
          max-height: 276px;
          row-gap: 4px;
          .person-type-container {
            .text-content {
              font-size: 16px;
            }
          }
        }

        .button-controller-container {
          column-gap: 18px;
        }
      }

      .text-content {
        &.main-title {
          margin-bottom: 28px;
        }
      }

      .person-info-text-container {
        max-width: 1146px;


        .news-slider-container {
          .slider-news-content-container {
            --padding-top-slider: 44px;
            max-height: 800px;
          }
        }

        .info-text-container {

          .info-container {
            margin-bottom: 64px;
            .info-content {
              row-gap: 8px;
              column-gap: 38px;
            }
          }

          &.education {
            .education-info-container {
              row-gap: 24px;
              .info-content {
                row-gap: 4px;
                .education-content {
                  column-gap: 38px;
                }
              }
            }
          }

          .empty-block {
            max-height: 56px;
            min-height: 56px;
          }
        }
      }
    }


    .person-image-container {
      max-width: 940px;
      min-width: 940px;
      .person-image-content {
      }
    }
  }
}

@media (max-width: 2240px) {
  .main-card-person-container {

    .text-info-container {
      .main-title-page-container {
        max-height: 387px;
        row-gap: 24px;
        margin-bottom: 24px;

        .sub-title-container {
          max-height: 276px;
          row-gap: 4px;
          .person-type-container {
            .text-content {
              font-size: 16px;
            }
          }
        }

        .button-controller-container {
          column-gap: 18px;
        }
      }

      .text-content {
        &.main-title {
          margin-bottom: 28px;
        }
      }

      .person-info-text-container {
        max-width: 971px;


        .news-slider-container {
          .slider-news-content-container {
            max-height: 686px;
          }
        }

        .info-text-container {

          .info-container {
            margin-bottom: 72px;
            .info-content {
              row-gap: 8px;
              column-gap: 38px;
            }
          }

          &.education {
            .education-info-container {
              row-gap: 19px;
              .info-content {
                row-gap: 4px;
                .education-content {
                  column-gap: 38px;
                }
              }
            }
          }

          .empty-block {
            max-height: 56px;
            min-height: 56px;
          }
        }
      }
    }


    .person-image-container {
      max-width: 777px;
      min-width: 777px;
      .person-image-content {
      }
    }
  }
}


@media (max-width: 1920px) {
  .main-card-person-container {

    .text-info-container {

      .main-title-page-container {
        max-height: 313px;
        row-gap: 64px;
        margin-bottom: 16px;

        .sub-title-container {
          max-height: 216px;
          row-gap: 16px;
          .person-type-container {
            .text-content {
              font-size: 20px;
            }
          }
        }

        .button-controller-container {
          column-gap: 16px;
        }
      }

      .text-content {
        &.main-title {
          margin-bottom: 24px;
        }
      }

      .person-info-text-container {
        max-width: 832px;

        .news-slider-container {
          .slider-news-content-container {
            --padding-top-slider: 40px;
            max-height: 594px;
          }
        }

        .info-text-container {

          .info-container {
            margin-bottom: 64px;
            .info-content {
              row-gap: 8px;
              column-gap: 32px;
            }
          }

          &.education {
            .education-info-container {
              row-gap: 16px;

              .info-content {
                row-gap: 4px;
                .education-content {
                  column-gap: 32px;
                }
              }
            }
          }



          .empty-block {
            max-height: 128px;
            min-height: 128px;
          }
        }
      }
    }


    .person-image-container {
      max-width: 660px;
      min-width: 660px;
      .person-image-content {
      }
    }
  }
}


@media (max-width: 1600px) {
  .main-card-person-container {

    .text-info-container {

      .main-title-page-container {
        max-height: 235px;
        row-gap: 14px;
        margin-bottom: 14px;
        .sub-title-container {
          max-height: 161px;
          row-gap: 3px;
          .person-type-container {
            .text-content {
              font-size: 10px;
            }
          }
        }

        .button-controller-container {
          column-gap: 10px;
        }
      }

      .text-content {
        &.main-title {
          margin-bottom: 16px;

          &.case {
            margin-bottom: 64px;
          }
        }
      }

      .person-info-text-container {
        max-width: 635px;

        .news-slider-container {
          .slider-news-content-container {

            --padding-top-slider: 30px;
            max-height: 520px;
          }
        }

        .case-slider-container {
          .slider-content-container {
            padding-bottom: 37px;
          }
        }

        .info-text-container {

          .info-container {
            margin-bottom: 42px;
            .info-content {
              row-gap: 8px;
              column-gap: 22px;
            }
          }

          &.education {
            .education-info-container {
              row-gap: 10px;

              .info-content {
                row-gap: 4px;
                .education-content {
                  column-gap: 22px;
                }
              }
            }
          }



          .empty-block {
            max-height: 56px;
            min-height: 56px;
          }
        }
      }
    }


    .person-image-container {
      max-width: 499px;
      min-width: 499px;
      .person-image-content {
      }
    }
  }
}

@media (max-height: 800px) {
  .main-card-person-container {

    .text-info-container {

      .main-title-page-container {
        max-height: 220px;
        row-gap: 14px;
        margin-bottom: 14px;
        .sub-title-container {
          max-height: 161px;
          row-gap: 4px;
          .person-type-container {
            .text-content {
              font-size: 10px;
            }
          }
        }

        .button-controller-container {
          column-gap: 10px;
        }
      }

      .text-content {
        &.main-title {
          margin-bottom: 16px;

          &.case {
            margin-bottom: 16px;
          }
        }
      }

      .person-info-text-container {
        max-width: 554px;

        .news-slider-container {
          .slider-news-content-container {
            --padding-top-slider: 24px;
            max-height: 520px;
          }
        }

        .case-slider-container {
          .slider-content-container {
            padding-bottom: 22px;
          }
        }

        .info-text-container {

          .info-container {
            margin-bottom: 42px;
            .info-content {
              row-gap: 8px;
              column-gap: 22px;
            }
          }

          &.education {
            .education-info-container {
              row-gap: 10px;

              .info-content {
                row-gap: 4px;
                .education-content {
                  column-gap: 22px;
                }
              }
            }
          }



          .empty-block {
            max-height: 56px;
            min-height: 56px;
          }
        }
      }
    }


    .person-image-container {
      max-width: 420px;
      min-width: 420px;
      .person-image-content {
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .main-card-person-container {

    .text-info-container {

      .main-title-page-container {
        max-height: 200px;
        row-gap: 14px;
        margin-bottom: 16px;
        .sub-title-container {
          max-height: 161px;
          row-gap: 4px;
          .person-type-container {
            .text-content {
              font-size: 10px;
            }
          }
        }

        .button-controller-container {
          column-gap: 12px;
        }
      }

      .text-content {
        &.main-title {
          margin-bottom: 18px;

          &.case {
            margin-bottom: 32px;
          }
        }
      }

      .person-info-text-container {
        max-width: 546px;
        flex-basis: 75%;

        .news-slider-container {
          .slider-news-content-container {
            --padding-top-slider: 24px;
            max-height: 750px;
          }
        }

        .case-slider-container {
          .slider-content-container {
            padding-bottom: 22px;
          }
        }

        .info-text-container {

          .info-container {
            margin-bottom: 46px;
            .info-content {
              row-gap: 8px;
              column-gap: 22px;
            }
          }

          &.education {
            .education-info-container {
              row-gap: 12px;

              .info-content {
                row-gap: 3px;
                .education-content {
                  column-gap: 22px;
                }
              }
            }
          }



          .empty-block {
            max-height: 56px;
            min-height: 56px;
          }
        }
      }
    }


    .person-image-container {
      max-width: 527px;
      min-width: 527px;
      .person-image-content {
      }
    }
  }
}

@media (max-width: 1133px) {
  .main-card-person-container {

    .text-info-container {

      .main-title-page-container {
        max-height: 190px;
        row-gap: 12px;
        margin-bottom: 10px;
        .sub-title-container {
          max-height: 142px;
          row-gap: 4px;
          .person-type-container {
            .text-content {
              font-size: 8px;
            }
          }
        }

        .button-controller-container {
          column-gap: 10px;
        }
      }

      .text-content {
        &.main-title {
          margin-bottom: 14px;

          &.case {
            margin-bottom: 32px;
          }
        }
      }

      .person-info-text-container {
        max-width: 492px;

        .news-slider-container {
          .slider-news-content-container {
            --padding-top-slider: 24px;
            max-height: 420px;
          }
        }

        .case-slider-container {
          .slider-content-container {
            padding-bottom: 0;
          }
        }

        .info-text-container {

          .info-container {
            margin-bottom: 38px;
            .info-content {
              row-gap: 8px;
              column-gap: 18px;
            }
          }

          &.education {
            .education-info-container {
              row-gap: 10px;

              .info-content {
                row-gap: 3px;
                .education-content {
                  column-gap: 18px;
                }
              }
            }
          }



          .empty-block {
            max-height: 36px;
            min-height: 36px;
          }
        }
      }
    }


    .person-image-container {
      max-width: 374px;
      min-width: 374px;
      .person-image-content {
      }
    }
  }
}
@media (max-width: 769px) {
  //iPhone 14
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 2561px) {
  .main-card-person-container {

    .text-info-container {

      .main-title-page-container {
        max-height: 720px;
        row-gap: 48px;

        .sub-title-container {
          max-height: 560px;
          row-gap: 4px;
          .person-type-container {
            .text-content {
              font-size: 34px;
            }
          }
        }

        .button-controller-container {
          column-gap: 38px;
        }
      }

      .text-content {
        &.main-title {
          margin-bottom: 56px;
        }
      }

      .person-info-text-container {

        max-width: 1942px;

        .news-slider-container {
          .slider-news-content-container {
            --padding-top-slider: 90px;
            max-height: 1450px;
          }
        }

        .info-text-container {
          .info-container {
            margin-bottom: 152px;
            .info-content {
              row-gap: 8px;
              column-gap: 74px;
            }
          }

          &.education {
            .education-info-container {
              row-gap: 38px;
              .info-content {
                row-gap: 10px;
                .education-content {
                  column-gap: 75px;
                }
              }
            }
          }



          .empty-block {
            max-height: 356px;
            min-height: 356px;
          }
        }
      }
    }


    .person-image-container {
      max-width: 1540px;
      min-width: 1540px;
    }
  }
}

</style>