<template>
  <modal-frame :main-title="$t('modalContent.modalTeam.mainTitle')"
               @submitInfo="submitInfo"
               :alert-text="alertText"
               @changeStateAlert="changeStateAlert"
               :disabled-button="disabledButtonTeam"
               @changeActivePolicy="changeActivePolicy">
    <form class="team-modal-container" ref="modalFrame" :class="{'eng' : activeEng}">
      <div class="single-input-container">
        <div class="single-input-content" v-for="info in $tm('modalContent.modalTeam.singleInput')" :key="info.id">
          <sub-paragraph :active-cormorant="false">{{info.titleName}}</sub-paragraph>
          <input-main :active-glass="false"
                      @changeTextInput="changeTextInput"
                      :name-block="'single-input-container'"
                      :info="info"/>
        </div>
      </div>

      <input-file-block active-input="0" @changeActiveFile="changeActiveFile">
        <template v-slot:start>
          {{ $t('modalContent.modalTeam.inputFilePlaceholder') }}
        </template>
        <template v-slot:right>
          {{ $t('modalContent.modalTeam.inputFileUpdatePlaceholder') }}
        </template>
        <template v-slot:left>
          {{ $t('modalContent.modalTeam.inputFileFinally') }}
        </template>
      </input-file-block>

      <div class="textarea-container">
        <input-main :active-glass="false"
                    @changeTextInput="changeTextInput"
                    :name-block="'textarea-container'"
                    :info="$tm('modalContent.modalTeam.messageInput')"/>
      </div>
    </form>

  </modal-frame>
</template>

<script>
import ModalFrame from "@/components/entities/ModalFrame.vue";
import InputMain from "@/components/shared/InputMain.vue";
import InputFile from "@/components/shared/InputFile.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import {api} from "@/providers/api";
import InputFileBlock from "@/components/entities/InputFileBlock.vue";

export default  {
  name: "ModalTeam",
  components: {InputFileBlock, SubParagraph, InputFile, InputMain, ModalFrame},
  data() {
    return {
      inputValues: {
        name: '',
        contact: '',
        text: ''
      },
      // message: '',
      fileSrc: undefined,
      policyButton: false,
      alertText: ''
    }
  },
  computed: {
    isValid() {
      return Object.values(this.inputValues).every(v => v.length)
    },
    isValidName() {
      return this.inputValues.name.length !== 0;
    },
    isValidContact() {
      return this.inputValues.contact.length !== 0;
    },
    isValidMessage() {
      return this.inputValues.text.length !== 0;
    },
    disabledButtonTeam() {
      return !(this.isValid && this.fileSrc !== undefined && this.policyButton);
    }
  },

  methods: {
    changeStateAlert() {
      if(this.$i18n.locale === 'ru') {
        if(!this.isValidName && !this.isValidMessage && !this.isValidContact && this.fileSrc === '' && !this.policyButton) {
          this.alertText = 'Введите все данные'
        } else if(!this.isValid) {
          if (!this.isValidName) {
            this.alertText = 'Введите имя'
          } else if (!this.isValidContact) {
            this.alertText = 'Введите почту'
          } else if (!this.isValidMessage) {
            this.alertText = 'Введите сообщение'
          }
        } else if (this.fileSrc === undefined) {
          this.alertText = 'Добавьте файл'
        } else if (!this.policyButton) {
          this.alertText = 'Ознакомьтесь с Политикой в области обработки персональных данных'
        }
      } else if (this.$i18n.locale === 'en') {
        if(!this.isValidName && !this.isValidMessage && !this.isValidContact && this.fileSrc === '' && !this.policyButton) {
          this.alertText = 'Enter the data'
        } else if(!this.isValid) {
          if (!this.isValidName) {
            this.alertText = 'Enter the name'
          } else if (!this.isValidContact) {
            this.alertText = 'Enter the email'
          } else if (!this.isValidMessage) {
            this.alertText = 'Enter the message'
          }
        } else if (this.fileSrc === undefined) {
          this.alertText = 'Enter the file'
        } else if (!this.policyButton) {
          this.alertText = 'Read the personal data processing policy'
        }
      }
        setTimeout(() => {
          this.alertText = ''
        }, 1000)
    },

    changeTextInput(text, dataIndex) {
      this.inputValues[dataIndex] = text;
    },
    changeActiveFile(file) {
      if(this.$i18n.locale === 'ru') {
        if(this.fileSrc === undefined) {
          this.alertText = 'Файл добавлен'
        } else {
          this.alertText = 'Файл обновлен'
        }
      } else {
        if(this.fileSrc === undefined) {
          this.alertText = 'File upload'
        } else {
          this.alertText = 'File update'
        }
      }

      this.$nextTick(() => {
        this.fileSrc = file
      })
      setTimeout(() => {
        this.alertText = ''
      }, 1000)
    },
    changeActivePolicy(status) {
      this.policyButton = status
    },

    async submitInfo() {
      this.$emit('closeModal');
      try {
        await api.main.mainControllerSendTeam({
          name: this.inputValues.name,
          contact: this.inputValues.contact,
          text: this.inputValues.text,
          file: this.fileSrc
        }).then(r => r.data);
      } catch (e) {
        console.log(e)
      }
    }
  }

}
</script>

<style scoped lang="scss">
  .team-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin-bottom: 24px;

    &.eng {
          font-variant: all-small-caps;
    }

    .single-input-container {
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      .text-content {
        margin-bottom: 8px;
      }
    }

    .input-file-container {
      height: 80px;
    }
    //
    //.textarea-container {
    //  height: 80px;
    //
    //  .input-container {
    //    height: 100%;
    //  }
    //}
  }

  @media (max-width: 2560px){
    .team-modal-container {
      row-gap: 28px;
      margin-bottom: 56px;

      .single-input-container {
        row-gap: 18px;

        .text-content {
          margin-bottom: 9px;
        }
      }

      .input-file-container {
        height: 93px;
      }

      //.textarea-container {
      //  height: 116px;
      //}
    }
  }




  @media (max-width: 1920px) {
    .team-modal-container {
      row-gap: 24px;
      margin-bottom: 24px;

      .single-input-container {
        row-gap: 16px;

        .text-content {
          margin-bottom: 8px;
        }
      }

      .input-file-container {
        height: 80px;
      }

      //.textarea-container {
      //  height: 80px;
      //}
    }
  }

  @media (max-width: 1600px) {
    .team-modal-container {
      row-gap: 16px;
      margin-bottom: 16px;

      .single-input-container {
        row-gap: 10px;

        .text-content {
          margin-bottom: 5px;
        }
      }

      .input-file-container {
        height: 54px;
      }

      //.textarea-container {
      //  height: 66px;
      //}
    }
  }

  @media (max-width: 1133px) {
      .team-modal-container {
        row-gap: 14px;
        margin-bottom: 14px;

        .single-input-container {
          row-gap: 9px;

          .text-content {
            margin-bottom: 5px;
          }
        }

        .input-file-container {
          height: 48px;
        }
      }
  }

  @media (max-width: 769px) {
    .team-modal-container {
      row-gap: 16px;
      margin-bottom: 16px;

      .single-input-container {
        row-gap: 8px;

        .text-content {
          font-size: 12px;
          margin-bottom: 4px;
        }
      }

      .input-file-container {
        height: 46px;
      }
    }
  }

  @media (max-width: 320px) {
    .team-modal-container {
      row-gap: 12px;
      margin-bottom: 12px;

      .single-input-container {
        row-gap: 8px;

        .text-content {
          font-size: 10px;
          margin-bottom: 4px;
        }
      }

      .input-file-container {
        height: 46px;
      }
    }
  }


  @media (min-width: 2561px) {
    .team-modal-container {
      row-gap: 56px;
      margin-bottom: 56px;

      .single-input-container {
        row-gap: 36px;

        .text-content {
          margin-bottom: 18px;
        }
      }

      .input-file-container {
        height: 186px;
      }

      //.textarea-container {
      //  height: 234px;
      //}
    }
  }

</style>