<template>
  <transition name="fade-translate-y-revert" >
    <div v-if="!filterActive && activeInfo?.length > 1" >
      <slider-frame :length-info="activeInfo?.length"
                    :active-type="'cases'"
                    :active-grab-cursor="true"
                    :active-gradient="activeGradient"
                    @changeActiveIndex="changeActiveIndex"
                    :class="{'active-case-full' : activeCaseFull,
                    'mobile' : activeMobile, 'active-page' : activeSubPage}"
                    :modules="modules"
                    :active-left-gradient="false"
                    :active-controller="activeNavigation"
                    :swiper-setting="settingSwiper">
        <swiper-slide v-for="(item, index) in lengthInfoSlider"
                      v-slot="{ isActive, isNext }"
                      :key="index"
                      class="clickable"
                      :virtual-index="index">
          <div class="content" v-if="index < activeInfo?.length"
               :class="{'active-slide' : isActive,
               'last-active' : index === activeInfo?.length - 1 && isActive,
               'next-slide' : isNext ,
               'not-active-person': !activePersonPage}">
            <card-case :active-case-full="activeCaseFull"
                       :case-info="activeInfo[index]"/>
          </div>
          <div class="card-news-container empty" v-else-if="index >= activeInfo?.length && activeInfo?.length > 1 && !activeMobile"></div>
        </swiper-slide>
      </slider-frame>
    </div>
    <div v-else-if="!filterActive && activeInfo?.length === 1">
      <slider-frame @changeActiveIndex="changeActiveIndex"
                    :active-type="'cases'"
                    :length-info="1"
                    :modules="modules"
                    :active-grab-cursor="true"
                    :active-left-gradient="false"
                    :active-controller="activeNavigation"
                    :swiper-setting="settingSwiper">
        <div class="content active-slide">
          <card-case :active-case-full="activeCaseFull"
                     :case-info="activeInfo[0]"/>
        </div>
      </slider-frame>
    </div>
    <div v-else-if="!filterActive && activeInfo?.length === 0" class="empty"></div>
  </transition>


</template>

<script>
import SliderFrame from "@/components/entities/SliderFrame.vue";
import CardCase from "@/components/entities/CardCase.vue";
import {Navigation, Virtual} from "swiper";
import SliderFrameMobile from "@/components/entities/SliderFrameMobile.vue";
import {mapActions} from "vuex";

export default {
  name: "SliderCase",
  props: {

    activeGradient: {
      type: Boolean,
      default: true
    },

    settingSwiper: {
      type: Object,
      required: true
    },
    activeSubPage: {
      type: Boolean,
      default: false
    },
    activePersonPage: {
      type: Boolean,
      default: false
    },
    activeInfo: {
      type: Array,
      required: true
    },
    activeCaseFull: {
      type: Boolean,
      default: true
    },
  },
  components: {SliderFrameMobile, CardCase, SliderFrame},
  data() {
    return {
      modules: [Virtual, Navigation],
      filterActive: false,
      activeIndex: 0,
      activeNavigation: {
        left: false,
        right: true
      },
    }
  },
  methods: {
    ...mapActions({
      getCase: 'case/getCase',
    }),

    changeActiveIndex(id) {
      this.activeIndex = id
      this.activeNavigation.right = id < this.activeInfo.length - 1;
        if(!this.activeMobile) {
          if(this.activeIndex > 0) {
            setTimeout(() => {
              this.activeNavigation.left = true;
            }, 1000)
          } else {
            setTimeout(() => {
              this.activeNavigation.left = false;
            }, 1000)
          }
      }

    },
  },

  computed: {
    lengthInfoSlider() {
      if(!this.activeMobile) {
        return this.activeInfo.length + 2;
      } else {
        return this.activeInfo.length;
      }
    }
  },

  watch: {
    activeInfo() {
      this.filterActive = true
      setTimeout(() => {
        this.filterActive = false
      }, 500)
    }
  }
}
</script>

<style scoped lang="scss">

.slider-content-container {
  position: relative;
  max-height: calc(var(--max-heigth-slider) + var(--max-padding-top));
  --max-padding-top: 70px;
  padding: 15px 0;
  height: 100%;
  overflow: hidden;

  &.active-page {
    overflow: hidden;
    --max-heigth-slider: 100%;
  }

  &.mobile {
    padding-left: 0 !important;
  }

  &::-webkit-scrollbar {
        display: none;
  }

  .content {
    width: 100%;
    position: relative;
    height: 100%;
    max-height: 100%;

    &.not-active-person {
      transform: scaleY(.9) scaleX(.95);
      transition: transform .8s ease, margin-left 1s ease;
      &.active-slide {
        transform: scaleY(1) scaleX(1);
        padding: 0;
      }
    }

  }
}



@media (max-width: 2560px){

  .slider-content-container {
    --max-heigth-slider: 520px;
    padding-left: 56px;

    &.active-page {
      padding-left: 48px;
    }

    &.active-case-full {
      --max-heigth-slider: 936px;
    }
  }
}

@media (max-width: 2240px){

  .slider-content-container {
    padding-left: 56px;
  }
}

@media (max-width: 1920px) {
  .slider-content-container {
    --max-heigth-slider: 589px;
    padding-left: 48px;

    &.active-page {
      padding-left: 24px;
    }

    &.active-case-full {
      --max-heigth-slider: 802px;
    }
  }
}


@media (max-width: 1600px) {
  .slider-content-container {
    --max-heigth-slider: 320px;
    padding-left: 24px;

    &.active-page {
      padding-left: 24px;
    }

    &.active-case-full {
      --max-heigth-slider: 534px;
    }
  }
}
@media (max-height: 800px) {
  .slider-content-container {
    --max-heigth-slider: 320px;
    &.active-case-full {
      --max-heigth-slider: 534px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {

  .slider-content-container {
    --max-heigth-slider: 550px;
    &.active-case-full {
      --max-heigth-slider: 692px;
    }
  }

}

@media (max-width: 1133px) {
  .slider-content-container {
    --max-heigth-slider: 283px;
    &.active-case-full {
      --max-heigth-slider: 492px;
    }
  }
}

@media (max-width: 769px) {
  .slider-content-container {
    overflow: hidden;
    padding: 0;
  --max-heigth-slider: 280px;
    --max-padding-top: 0;

    .content {
      margin-left: 0;

      &.last-active {
        margin-left: -32%;
      }
    }

    &.active-page {
      padding-left: 0;
      &.active-left-swiper-button {
        padding-left: 0;
      }
    }

  .content {
    padding: 0;

    &.not-active-person {
      transform: scale(1);
    }

    &.active-slide {
      padding: 0;
    }
  }
  &.active-case-full {
    --max-heigth-slider: 494px;
  }
}
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .slider-content-container {
    --max-heigth-slider: 320px;

    &.active-case-full {
      --max-heigth-slider: 575px;
    }
  }
}


@media (max-width: 375px){
  .slider-content-container {
    --max-heigth-slider: 276px;

    .content {
      margin-left: 0;

      &.last-active {
        margin-left: -50%;
      }
    }

    &.active-case-full {
      --max-heigth-slider: 398px;
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .slider-content-container {
    --max-heigth-slider: 280px;

    .content {
      margin-left: 0;

      &.last-active {
        margin-left: -30%;
      }
    }

    &.active-case-full {
      --max-heigth-slider: 469px;
    }
  }
}


@media (max-width: 320px) {
  .slider-content-container {
    --max-heigth-slider: 230px;
    &.active-case-full {
      --max-heigth-slider: 336px;
    }
  }
}



@media (min-width: 2561px) {

  .slider-content-container {
    --max-heigth-slider: 1083px;
    padding-left: 132px;

    &.active-page {
      padding-left: 72px;
    }

      &.active-case-full {
        --max-heigth-slider: 1872px;
      }
    }
  }


</style>