import {api} from "@/providers/api";

export const newsModules = {
    namespaced: true,
    state: () => ({
        activeNewsInfo: {},
        newsInfo: undefined,
        viewArray: []
    }),
    getters: {
        allPress: (state) => {
            return state.newsInfo
        },
        getAllSortedNews: (state) => {
            return state.newsInfo?.sort((a, b) => {
                return (new Date(a.publishDate)) - ( new Date(b.publishDate));
            }).reverse();
        },
        getActiveNewsInfo: (state) => {
            return state.activeNewsInfo
        },

        allViewNews: (state) => {
            return state.viewArray
        },
    },
    mutations: {
        SET_PRESS: (state, payload) => {
            state.newsInfo = payload
        },
        SET_ACTIVE_NEWS_INFO: (state, payload) => {
            state.activeNewsInfo = payload
        },

        SET_VIEW_ARRAY: (state, payload) => {
            let arr;
            payload.forEach(item => {
                let randomAddView = Math.round(2 + Math.random() * 5);

            })
            state.viewArray = arr;
            // state.viewArray = payload.map(item => (
            //     {
            //         id: item.id,
            //         view:
            //     }))
        },
    },
    actions:  {
        async getPress({commit}, { params  } = {params: {}}) {
            let response = await api.main.mainControllerGetCases({type: 'POST'},{
                ...params
            }).then(r => r.data);
            commit('SET_PRESS', response)
            commit('SET_VIEW_ARRAY', response)
        },
        async getActivePress({commit}, { slug, params  } = {slug ,params: {}}) {
            let response = await api.main.mainControllerGetPostSlug(slug,{
                ...params
            }).then(r => r.data);
            console.log(response)
            commit('SET_ACTIVE_NEWS_INFO', response)
        },
    }
}