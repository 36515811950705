<template>
  <div class="values-light-card-container" >

    <div class="values-light-card-top-content" >
      <transition name="fade-opacity-lazy">
      <main-paragraph v-if="activeTopContent">
        {{cardInfo.description}}
      </main-paragraph>
      </transition>
      <transition name="fade-opacity-lazy">
      <div class="values-image-container" v-if="activeBlockImg">
        <img class="values-image-content" :class="`slide-${activeIndexWorth}`" :src="getUrlAboutScreen('oil-paint-all.webp')" alt="oil">
      </div>
      </transition>
    </div>
    <transition name="fade-opacity-lazy">
    <div class="values-light-card-bottom-content" v-if="activeBottomContent">
      <large-title :active-cormorant="true" :epic-large="true">{{cardInfo.mainTitle}}</large-title>
    </div>
    </transition>
  </div>
</template>

<script>
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";

export default {
  name: "CardValuesLight",
  components: {LargeTitle, MainParagraph},
  props: {
    cardInfo: {
      type: Object,
      required: true
    },
    activeScreen: {
      type: Boolean,
      required: true
},
    activeIndexWorth: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      activeTopContent: false,
      activeBottomContent: false,
      activeBlockImg: false,
      actualWidth: 0
    }
  },
  watch: {
    activeScreen() {
      if(this.activeScreen) {
        this.activeAnimation();
      } else {
        this.activeTopContent = false;
        this.activeBottomContent = false;
      }
    },
    activeIndexWorth() {
      this.checkActiveWorth();
    }
  },
  mounted() {
    this.checkFactor(0)
    if(this.activeScreen) {
      this.activeAnimation();
    }
    addEventListener('resize', this.checkActiveWorth)
  },

  unmounted() {
    removeEventListener('resize', this.checkActiveWorth)
  },

  methods: {
    activeAnimation() {
      this.activeBlockImg = true
      this.activeTopContent = true;
      setTimeout(() => {
        this.activeBottomContent = true;
      }, 500)
    },
    checkActiveWorth() {
      let activeWidth = document.querySelector('.values-image-container').clientWidth;
      switch (this.activeIndexWorth) {
        case 0:
          this.actualWidth = 0;
          break;
        case 1:
          this.actualWidth = activeWidth * -1.0;
          break;
        case 2:
          this.actualWidth = activeWidth * -2.0;
          break;
        case 3:
          this.actualWidth = activeWidth * -3.0;
          break;
      }
      this.checkFactor(this.actualWidth);
    },
    checkFactor(factor) {
      document.querySelector('.values-light-card-container').style.setProperty('--active-width', factor + 'px');
    }
  }
}
</script>

<style scoped lang="scss">
.values-light-card-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;


  .values-light-card-top-content {
    display: inherit;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;



    .text-content {
      position: relative;
      line-height: 150%;
      align-self: flex-end;
      max-height: 100%;
    }

    .values-image-container {
      overflow: hidden;
      width: 100%;
      height: 100%;

      .values-image-content {
        transition: transform .6s ease;
        transform: translateX(var(--active-width));
      }

      & img {
        height: 100%;
      }
    }
  }
  .values-light-card-bottom-content {
    position: relative;
    align-self: flex-end;
  }
}

@media (max-width: 2560px){
  .values-light-card-container {
    margin-bottom: 168px;
    max-height: 656px;

    .values-light-card-top-content {

      .text-content {
        padding-bottom: 29px;
        max-width: 745px;
      }

      .values-image-container {
        max-width: 602px;
        max-height: 428px;
        min-height: 428px;
      }
    }

    .values-light-card-bottom-content {
      padding-right: 296px;
      .text-content {
        letter-spacing: 18.8px;
      }
    }
  }
}

@media (max-width: 2240px){
  .values-light-card-container {
    margin-bottom: 80px;
    max-height: 649px;

    .values-light-card-top-content {

      .text-content {
        padding-bottom: 42px;
        max-width: 745px;
      }

      .values-image-container {
        max-width: 602px;
        max-height: 428px;
        min-height: 428px;
      }
    }

    .values-light-card-bottom-content {
      padding-right: 296px;
      .text-content {
        letter-spacing: 18.8px;
      }
    }
  }
}



@media (max-width: 1920px) {
  .values-light-card-container {
    max-height: 556px;
    margin-bottom: 0;
    .values-light-card-top-content {

      .text-content {
        padding-bottom: 32px;
        max-width: 638px;
      }

      .values-image-container {
        max-width: 516px;
        max-height: 367px;
        min-height: 367px;
      }
    }

    .values-light-card-bottom-content {
      padding-right: 254px;
      .text-content {
        letter-spacing: 16px
      }
    }
  }
}


@media (max-width: 1600px) {
  .values-light-card-container {
    max-height: 378px;

    .values-light-card-top-content {

      .text-content {
        padding-bottom: 16px;
        max-width: 510px;
      }

      .values-image-container {
        max-width: 346px;
        max-height: 246px;
        min-height: 246px;
      }
    }

    .values-light-card-bottom-content {
      padding-right: 164px;
      .text-content {
        letter-spacing: 10.8px
      }
    }
  }
}
@media (max-height: 800px) {
  .values-light-card-container {
    max-height: 373px;

    .values-light-card-top-content {
      .text-content {
        padding-bottom: 16px;
        max-width: 510px;
      }

      .values-image-container {
        max-width: 346px;
        max-height: 246px;
        min-height: 246px;
      }
    }

    .values-light-card-bottom-content {
      padding-right: 170px;
      .text-content {
        letter-spacing: 10.8px
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .values-light-card-container {
    max-height: 378px;

    .values-light-card-top-content {

      .text-content {
        padding-bottom: 16px;
        max-width: 510px;
      }

      .values-image-container {
        max-width: 346px;
        max-height: 246px;
        min-height: 246px;
      }
    }

    .values-light-card-bottom-content {
      padding-right: 165px;
      .text-content {
        letter-spacing: 10.8px
      }
    }
  }
}

@media (max-width: 1133px) {
  .values-light-card-container {
    max-height: 328px;

    .values-light-card-top-content {

      .text-content {
        padding-bottom: 15px;
        max-width: 442px;
      }

      .values-image-container {
        max-width: 300px;
        max-height: 214px;
        min-height: 214px;
      }
    }
  }
}

@media (max-width: 769px) {
  .values-light-card-container {
    justify-content: flex-start;
    max-height: 366px;
    row-gap: 24px;
    .values-light-card-top-content {
      flex-direction: column;
      align-items: flex-end;
      row-gap: 24px;
      .text-content {
        padding-bottom: 0;
        max-width: 442px;
        height: 72px;
      }

      .values-image-container {
        max-width: 263px;
        max-height: 187px;
        min-height: 187px;

        .values-image-content {
          transform: translateX(var(--active-width)) scale(1.2);
        }
      }
    }

    .values-light-card-bottom-content {
      padding-right: 0;
      align-self: flex-start;

      .text-content {
        font-size: 26px;
        letter-spacing: normal;
        font-weight: 600;
      }
    }
  }
}

@media (max-width: 769px) and (min-height: 720px) and (max-height: 1000px){
  .values-light-card-container {
    .values-light-card-top-content {
      .text-content {
          height: 105px;
      }
    }
  }
}

@media (max-width: 375px){
  .values-light-card-container {
    max-height: 320px;
    row-gap: 18px;
    .values-light-card-top-content {
      row-gap: 18px;
    }

    .values-light-card-bottom-content {

      .text-content {
        font-size: 24px;
        height: 72px;
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .values-light-card-container {
    max-height: 340px;

    .values-light-card-bottom-content {

      .text-content {
        height: 72px;
      }
    }
  }
}


@media (max-width: 320px) {
  .values-light-card-container {
    max-height: 250px;
    row-gap: 14px;
    .values-light-card-top-content {
      row-gap: 14px;

      .values-image-container {
        max-width: 223px;
        max-height: 147px;
        min-height: 147px;
      }
    }

    .values-light-card-bottom-content {
      .text-content {
        height: 60px;
        font-size: 20px;
      }
    }
  }
}


@media (min-width: 2561px) {
  .values-light-card-container {
    margin-bottom: 228px;
    max-height: 1380px;

    .values-light-card-top-content {

      .text-content {
        padding-bottom: 75px;
        max-width: 1502px;
      }

      .values-image-container {
        max-width: 1213px;
        max-height: 863px;
        min-height: 863px;
      }
    }

    .values-light-card-bottom-content {
      padding-right: 597px;

      .text-content {
        letter-spacing: 37.6px;
      }
    }
  }
}

</style>