<template>
  <div class="check-box-container">
    <input :id="'toggler-'+nameCheck" :name="'toggler-'+nameCheck" type="checkbox" v-model="activeCheck">
    <label :for="'toggler-'+nameCheck">
      <span class="toggler-on"/>
      <span class="toggler-off"/>
    </label>
  </div>
</template>

<script>
export default {
  name: "CheckBoxMain",
  props: {
    nameCheck: {
      type: Number,
      required: true
    },
    activeMountCheck: {
      type: Boolean
    }
  },
  data() {
    return {
      activeCheck: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      if(this.activeMountCheck) {
        this.activeCheck = true
      }
    })
  },
  watch: {
    activeCheck() {
      this.$emit('changeActiveCheck', this.activeCheck)
    }
  }
}
</script>

<style scoped lang="scss">
      .check-box-container {
      }

      .check-box-container input {
        display: none;
      }

      .check-box-container label {
        display: block;
        position: relative;
        cursor: pointer;
        border-style: solid;
        border-color: var(--color-text);
      }

      .check-box-container label::after {
        display: block;
        border-radius: 50%;
        background-color: var(--color-background);
        border-style: solid;
        border-color: var(--color-text);
        content: '';
        animation-name: toggler-size;
        animation-duration: 0.15s;
        animation-timing-function: ease-out;
        animation-iteration-count: 1;
        animation-play-state: running;
      }

      .check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
        position: absolute;
        top: 50%;
        left: 33%;
        transform: translateY(-50%) translateX(-50%);
        transition: left 0.3s ease-in-out, background-color 0.35s ease-out, width 0.3s ease-in-out, height 0.3s ease-in-out, opacity 0.3s ease-in-out;
      }

      .check-box-container input:checked + label::after, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
        background-color: var(--color-text);
      }

      .check-box-container input:checked + label::after {
        animation-name: toggler-size2;
      }

      .check-box-container .toggler-on, .check-box-container .toggler-off {
        opacity: 1;
        z-index: 2;
      }

      .check-box-container input:checked + label .toggler-off, .check-box-container input:not(:checked) + label .toggler-on {
        width: 0;
        height: 0;
        opacity: 0;
      }


      @media (max-width: 2560px){
        .check-box-container {
          width: 46px;
        }

        .check-box-container label {
          border-width: 1px;
          width: 46px;
          height: 20px;
          padding: 3.5px;
          margin: 0 2px;
          border-radius: 38px;
        }

        .check-box-container label::after {
          border-width: 1px;
          width: 13px;
          height: 12px;
        }

        .check-box-container input:checked + label::after, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
          left: 78%;
        }

        .check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
          top: 50%;
          left: 25%;
          width: 13px;
          height: 12px;
        }
      }


      @media (max-width: 1920px) {
        .check-box-container {
          width: 32px;
        }

        .check-box-container label {
          width: 32px;
          height: 14px;
          padding: 4px;
          margin: 0 2px;
          border-radius: 23px;
          border-width: 0.6px;
        }

        .check-box-container label::after {
          border-width: 0.6px;
          width: 9px;
          height: 8px;
        }

        .check-box-container input:checked + label::after, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
          left: 78%;
        }

        .check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
          top: 50%;
          left: 25%;
          width: 9px;
          height: 8px;
        }
      }

      @media (max-width: 1600px) {
        .check-box-container {
          width: 28px;
        }

        .check-box-container label {
          width: 28px;
          height: 12px;
          padding: 4px;
          margin: 0 2px;
          border-radius: 23px;
        }

        .check-box-container label::after {
          width: 8px;
          height: 7px;
        }

        .check-box-container input:checked + label::after, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
          left: 77%;
        }

        .check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
          top: 50%;
          left: 23%;
          width: 8px;
          height: 7px;
        }
      }

      @media (max-width: 1400px) {
        .check-box-container {
          width: 28px;
        }

        .check-box-container label {
          width: 28px;
          height: 12px;
          padding: 2.5px;
          margin: 0 2px;
          border-radius: 23px;
        }

        .check-box-container label::after {
          width: 8px;
          height: 7px;
        }

        .check-box-container input:checked + label::after, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
          left: 77%;
        }

        .check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
          top: 50%;
          left: 23%;
          width: 8px;
          height: 7px;
        }
      }


      @media (max-width: 1133px) {
        .check-box-container {
          width: 24px;
        }

        .check-box-container label {
          width: 24px;
          height: 10px;
          padding: 2px;
          margin: 0 2px;
          border-radius: 23px;
          border-width: 0.5px;
        }

        .check-box-container label::after {
          width: 6px;
          height: 5px;
          border-width: 0.5px;
        }

        .check-box-container input:checked + label::after, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
          left: 77%;
        }

        .check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
          top: 50%;
          left: 23%;
          width: 6px;
          height: 5px;
        }
      }

      @media (max-width: 769px) {
        .check-box-container {
          width: 44px;
        }

        .check-box-container label {
          width: 44px;
          height: 18px;
          padding: 1px;
          margin: 0 2px;
          border-radius: 23px;
          border-width: 0.5px;
        }

        .check-box-container label::after {
          width: 6px;
          height: 5px;
          border-width: 0.5px;
        }

        .check-box-container input:checked + label::after, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
          left: 77%;
        }

        .check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
          top: 50%;
          left: 23%;
          width: 12px;
          height: 11px;
        }
      }


      @media (min-width: 2561px) {
        .check-box-container {
          width: 78px;
        }

        .check-box-container label {
          width: 78px;
          height: 34px;
          padding: 6px;
          margin: 0 2px;
          border-radius: 38px;
          border-width: 1.7px;
        }

        .check-box-container label::after {
          width: 22px;
          border-width: 1.7px;
          height: 21px;
        }

        .check-box-container input:checked + label::after, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
          left: 78%;
        }

        .check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
          top: 50%;
          left: 22%;
          width: 22px;
          height: 21px;
        }
      }

</style>