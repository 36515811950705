<template>
  <div class="modal-frame-container" :class="{'active-pointer-event' : activeModalAlert}">
    <transition name="fade-opacity-lazy">
      <div class="modal-frame-content column" v-if="activeModal">
        <main-title :active-cormorant="false">
          {{ alertText }}
        </main-title>
      </div>
    </transition>
    <div class="modal-background" :class="{'active-filter': activeFilter}">
    </div>
  </div>
</template>

<script>
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import PolicyButton from "@/components/shared/PolicyButton.vue";
import {mapActions} from "vuex";
import ButtonSub from "@/components/shared/ButtonSub.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import MainTitle from "@/components/shared/Text/MainTitle.vue";
export default {
  name: "ModalAlert",
  components: {MainTitle, SubParagraph, ButtonSub, PolicyButton, ButtonIcon, SubTitle},
  props: {
    alertText: {
      type: String
    },
    activeModalAlert: {
      type: Boolean
    }
  },
  data() {
    return {
      activeModal: false,
      activeAlert: false,
      activeFilter: false
    }
  },

  mounted() {
    this.changeStateModal();
  },

  watch: {
    activeModalAlert() {
      this.changeStateModal();
    }
  },

  methods: {
    ...mapActions(['ACTIVE_MODAL_FILTER']),

    changeStateModal() {
      if(this.activeModalAlert) {
        setTimeout(() => {
          this.ACTIVE_MODAL_FILTER(true)
          this.activeFilter = true
          setTimeout(() => {
            if(this.activeModalAlert) {
              this.activeModal = true
            }
          },250)
        }, 250)
      } else {
        if(this.activeModal) {
          this.$router.go(0)
        }
        this.closeModal();
      }
    },

    closeModal() {
      this.activeModal = false
      setTimeout(() => {
        this.ACTIVE_MODAL_FILTER(false)
        this.activeFilter = false
      }, 250)
    },
  }
}
</script>

<style scoped lang="scss">


.modal-frame-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  overflow: hidden;
  text-align: center;
  pointer-events: none;

  &.active-pointer-event {
    pointer-events: visible;
  }

  .modal-background {
    width: var(--calc-width);
    height: var(--calc-height);
    background-color: rgba(var(--color-background-rgba), 0.9);
    position: absolute;
    z-index: 998;
    backdrop-filter: blur(var(--active-blur));
    opacity: 0;
    transition: opacity .6s ease;

    &.active-filter {
      opacity: 1;
    }
  }


  .modal-frame-content {
    margin: 0 16px;
    z-index: 999;
    width: 100%;
    background-color: var(--color-background);
    border-style: solid;
    position: fixed;
    border-color: var(--color-stroke);


    .alert-content {
      position: absolute;
      top: 2.5%;
      &.eng {
        font-variant: all-small-caps;
      }
    }

    .main-title-container {
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &.eng {
        font-variant: all-small-caps;
      }

      .button-icon-container {
        opacity: .6;
        transition: opacity .5s ease;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }

    }


    .sub-button-container {
      align-items: center;
      justify-content: center;
      height: 100%;
      max-width: 100%;
      width: 100%;
    }
  }
}

@media (max-width: 2560px){
  .modal-frame-container {

    .modal-frame-content {
      max-width: 700px;
      padding: 36px;
      border-radius: 28px;
      border-width: 2.25px;

      .main-title-container {
        margin-bottom: 28px;
        padding: 19px 0;

        .button-icon-container {
          max-width: 18px;
          max-height: 18px;
          margin-right: 19px;
        }
      }


      .sub-button-container {
        max-height: 46px;
        min-height: 46px;
        margin-top: 28px;
      }
    }
  }
}



@media (max-width: 1920px) {
  .modal-frame-container {

    .modal-frame-content {
      max-width: 600px;
      padding: 32px;
      border-radius: 32px;
      border-width: 2px;

      .main-title-container {
        margin-bottom: 16px;
        padding: 16px 0;

        .button-icon-container {
          max-width: 16px;
          max-height: 16px;
          margin-right: 16px;
        }
      }


      .sub-button-container {
        max-height: 40px;
        min-height: 40px;
        margin-top: 24px;
      }
    }
  }
}

@media (max-width: 1600px) {
  .modal-frame-container {

    .modal-frame-content {
      max-width: 400px;
      padding: 20px;
      border-radius: 16px;
      border-width: 1px;

      .main-title-container {
        margin-bottom: 16px;
        padding: 11px 0;

        .button-icon-container {
          max-width: 12px;
          max-height: 12px;
          margin-right: 10px;
        }
      }


      .sub-button-container {
        max-height: 26px;
        min-height: 26px;
        margin-top: 16px;
      }
    }
  }
}


@media (max-width: 1133px) {
  .modal-frame-container {

    .modal-frame-content {
      max-width: 354px;
      padding: 20px;
      border-radius: 14px;
      border-width: 1px;

      .main-title-container {
        margin-bottom: 16px;
        padding: 9.3px 0;

        .button-icon-container {
          max-width: 10px;
          max-height: 10px;
          margin-right: 9px;
        }
      }


      .sub-button-container {
        max-height: 26px;
        min-height: 26px;
        margin-top: 16px;
      }
    }
  }
}

@media (max-width: 769px) {
  .modal-frame-container {

    .alert-content {
      font-size: 10px;
    }

    .modal-frame-content {
      max-width: 354px;
      padding: 18px;
      border-radius: 14px;
      border-width: 1px;

      .main-title-container {
        margin-bottom: 16px;
        padding: 0;

        .text-content {
          font-size: 12px;
        }

      }


      .sub-button-container {
        max-height: 23px;
        min-height: 23px;
        margin-top: 16px;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .modal-frame-container {


    .modal-frame-content {
      max-width: 354px;
      padding: 18px;
      border-radius: 14px;
      border-width: 1px;

      .main-title-container {
        margin-bottom: 16px;
        padding: 0;
        .text-content {
          font-size: 12px;
        }
      }


      .sub-button-container {
        max-height: 26px;
        min-height: 26px;
        margin-top: 16px;
      }
    }
  }
}

@media (max-width: 375px){
  .modal-frame-container {

    .alert-content {
      font-size: 10px;
    }

    .modal-frame-content {
      max-width: 339px;
      padding: 18px;
      border-radius: 14px;
      border-width: 1px;

      .main-title-container {
        margin-bottom: 16px;
        padding: 0;
        .text-content {
          font-size: 10px;
        }
      }


      .sub-button-container {
        max-height: 23px;
        min-height: 23px;
        margin-top: 16px;
      }
    }
  }
}



@media (max-width: 320px) {
  .modal-frame-container {
    align-items: flex-end;
    .alert-content {
      font-size: 10px;
    }

    .modal-frame-content {
      margin-bottom: 18px;
      max-width: 284px;
      padding: 12px;
      border-radius: 10px;
      border-width: 1px;
    }
  }
}




@media (min-width: 2561px) {
  .modal-frame-container {

    .modal-frame-content {
      max-width: 1440px;
      padding: 72px;
      border-radius: 56px;
      border-width: 4.5px;

      .main-title-container {
        margin-bottom: 56px;
        padding: 38px 0;

        .button-icon-container {
          max-width: 38px;
          max-height: 38px;
          margin-right: 37px;
        }
      }


      .sub-button-container {
        max-height: 92px;
        min-height: 92px;
        margin-top: 56px;
      }
    }
  }
}


</style>