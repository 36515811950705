<template>
  <div class="text-menu-container" :class="{'sub-item-active' : subItemActive}">
<!--    <transition name="fade-text">-->
      <div class="pointer-text-container" v-if="!subItemActive">
        <div class="lottie-wrapper" v-show="activeColor">
          <LottieAnimation :animationData="require('@/assets/lottie/BrashDarkTheme.json')"
                           ref="pointerAnimationDark"
                           :flex-growth="'None'"
                           :loop="false"
                           :speed="0.5"
                           :autoPlay="false"
                           :rendererSettings="{ preserveAspectRatio: 'xMaxYMid meet' }" />
        </div>
        <div class="lottie-wrapper" v-show="!activeColor">
          <LottieAnimation :animationData="require('@/assets/lottie/BrashLightTheme.json')"
                           ref="pointerAnimationLight"
                           :flex-growth="'None'"
                           :loop="false"
                           :speed="0.5"
                           :autoPlay="false"
                           :rendererSettings="{ preserveAspectRatio: 'xMaxYMid meet' }" />
        </div>
<!--        <img v-if="activeColor" :src="getUrlIcon(pointerIcon + '-dark.svg')" alt="icon">-->
<!--        <img v-else :src="getUrlIcon(pointerIcon + '-light.svg')" alt="icon">-->
      </div>
<!--    </transition>-->
    <text-menu :sub-item-active="subItemActive"
               :text-menu-item="textMenuItem.name"
        :class="{'not-active' : activeSlide !== textMenuItem.id }"/>
  </div>
</template>

<script>
import TextMenu from "@/components/shared/TextMenu.vue";
import LottieContainer from "@/components/entities/LottieContainer.vue";

export default {
  name: "TextMenuWithPointer",
  components: {LottieContainer, TextMenu},
  props: {
    textMenuItem: {
      type: Object,
      required: true
    },
    subItemActive: {
      type: Boolean,
      required: true
    },
    activeSlide: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      pointerIcon: 'pointer-menu',
      activeItem: false
    }
  },

  mounted() {
    setTimeout(() => {
      this.checkActiveSlide();
    }, 250)
  },

  watch: {
    activeSlide() {
      this.checkActiveSlide();
    }
  },

  methods: {
    checkActiveSlide() {
      try {
        if(this.activeSlide === this.textMenuItem.id) {
          this.activeItem = true
          setTimeout(() => {
            if(this.$refs.pointerAnimationDark !== undefined) {
              this.$refs["pointerAnimationDark"].setDirection("forward");
              this.$refs["pointerAnimationLight"].setDirection("forward");
              this.$refs["pointerAnimationDark"].play();
              this.$refs["pointerAnimationLight"].play();
            }
          }, 500)
        } else {
          if(this.$refs.pointerAnimationDark !== undefined) {
            this.$refs["pointerAnimationDark"].setDirection("reverse");
            this.$refs["pointerAnimationLight"].setDirection("reverse");
            this.$refs["pointerAnimationDark"].play();
            this.$refs["pointerAnimationLight"].play();
          }
          setTimeout(() => {
            this.activeItem = false
          }, 750)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">


  .text-menu-container {
    width: fit-content;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;

    &.sub-item-active {
      height: 15px;
    }

    &:hover {
      .text-menu-content {

        &.not-active {
          opacity: 1;
        }
      }
    }

    .pointer-text-container {
      position: absolute;

      .lottie-wrapper {
        width: 100%;
        height: 100%;
      }

      & img {
        width: 100%;
        height: 100%;
      }
    }

    .text-menu-content {
      transition: opacity .8s ease;
      margin-left: 48px;
      opacity: 1;

      &.not-active {
        opacity: .6;
      }
    }
  }

  @media (max-width: 2560px){
    .text-menu-container {
      height: 36px;

      &.sub-item-active {
        height: 17px;
      }

      .pointer-text-container {
        width: 40px;
        height: 36px;
      }


      .text-menu-content {
        margin-left: 50px;
      }
    }
  }


  @media (max-width: 1920px) {
    .text-menu-container {
      height: 32px;

      &.sub-item-active {
        height: 15px;
      }

      .pointer-text-container {
        width: 36px;
        height: 33px;
      }

      .text-menu-content {
        margin-left: 48px;
      }
    }
  }

  @media (max-width: 1600px) {
    .text-menu-container {
      height: 21px;

      &.sub-item-active {
        height: 10px;
      }

      .pointer-text-container {
        width: 24px;
        height: 21px;
      }


      .text-menu-content {
        margin-left: 33px;
      }
    }
  }
  @media (max-height: 800px) {
    .text-menu-container {
      height: 21px;

      &.sub-item-active {
        height: 10px;
      }

      .pointer-text-container {
        width: 24px;
        height: 21px;
      }


      .text-menu-content {
        margin-left: 33px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .text-menu-container {
      height: 21px;

      &.sub-item-active {
        height: 10px;
      }

      .pointer-text-container {
        width: 24px;
        height: 21px;
      }


      .text-menu-content {
        margin-left: 33px;
      }
    }
  }

  @media (max-width: 1133px) {
    .text-menu-container {
      height: 18px;

      &.sub-item-active {
        height: 8px;
      }

      .pointer-text-container {
        width: 20px;
        height: 18px;
      }


      .text-menu-content {
        margin-left: 27px;
      }
    }
  }


  @media (min-width: 2561px) {
    .text-menu-container {
      height: 74px;

      &.sub-item-active {
        height: 34px;
      }

      .pointer-text-container {
        width: 83px;
        height: 74px;
      }


      .text-menu-content {
        margin-left: 111px;
      }
    }
  }

</style>