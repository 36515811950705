<template>
  <div class="case-screen-main-container">
    <modal-filter v-show="activeModalFilter"
                                                          :active-screen="activeModalFilter"
                                                          @changeActivePractice="changeActivePractice"
                                                          @changeActivePerson="changeActivePerson"
                                                          @closeModal="changeModalFilter"/>
    <div class="case-screen-container main-container" :class="{'case-news-mobile': activeMobile}" v-show="!isLoading">

      <transition name="fade-translate-x">
        <button-main-mobile v-if="activeDropList && activeMobile"
                            @click="changeModalFilter"
                            :text-button="$t('mobileInfo.buttonFilter')"/>
      </transition>
      <transition name="fade-translate-y">
        <drop-list-filter-container :active-practice-list="translationPractice"
                                    v-if="activeDropList && !activeMobile"
                                    @changeActivePractice="changeActivePractice"
                                    @changeActivePerson="changeActivePerson"
                                    :active-person-list="activePersonList"/>
      </transition>

      <transition name="fade-translate-y-revert">
        <slider-case-all v-show="activeSlider"
                         class="slider-container"
                         :active-case-full="true"
                         :active-person-page="false"
                         :active-practice-text-filter="activePracticeTextFilter"
                         :active-person-text-filter="activePersonTextFilter"/>
      </transition>

    </div>
  </div>

</template>

<script>
import SliderCase from "@/components/entities/SliderCase.vue";
import DropListFilterContainer from "@/components/entities/DroplistFilterContainer.vue";
import ButtonMainMobile from "@/components/shared/ButtonMainMobile.vue";
import SliderCaseAll from "@/components/entities/SliderCaseAll.vue";
import ModalFilter from "@/components/entities/ModalFilter.vue";

export default {
  name: "CaseScreen",
  components: {ModalFilter, SliderCaseAll, ButtonMainMobile, DropListFilterContainer, SliderCase},
  data() {
    return {
      activePersonList: ['Иванов Иван', 'Ахматов Мансур', 'Василь Васильев'],
      activePracticeList: ['Банкротство', 'Страхование', 'Девелопмент'],
      activePracticeTextFilter: '',
      activePersonTextFilter: '',
      activeDropList: false,
      activeSlider: false,
      activeModalFilter: false
    }
  },

  created() {
    this.IS_LOADING(true)
  },

  mounted() {
    this.$router.push({name: 'cases'})
    if(!this.isLoading) {
      this.startAnimation();
    }
  },

  watch: {
    isLoading() {
      if(!this.isLoading) {
        this.startAnimation();
      }
    }
  },

  methods: {

    changeModalFilter() {
      this.activeModalFilter = !this.activeModalFilter;
    },

    startAnimation() {
      setTimeout(() => {
        this.activeSlider = true
        setTimeout(() => {
          this.activeDropList = true
        }, 1000)
      }, 250)
    },

    changeActivePractice(text) {
      this.activePracticeTextFilter = text
    },
    changeActivePerson(text) {
      this.activePersonTextFilter = text
    },
  }
}
</script>

<style scoped lang="scss">
    .main-button-container {
      position: absolute;
      top: calc(var(--main-padding-top));
      right: var(--controller-padding);
    }
  .slider-container {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
  }

  .case-screen-main-container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .case-screen-container {
    --padding-right: 0;
    .drop-list-filter-container {
      position: absolute;
      top: calc(var(--main-padding-top) + var(--breadcrumbs-height));
    }
  }



  @media (max-width: 2560px){
    .slider-container {
      margin: auto 0;
    }
    .case-screen-container {
      .drop-list-filter-container {
        right: 96px;
      }
    }
  }

    @media (max-width: 2240px){
      .slider-container {
        margin: auto 0 0;
      }
    }


    @media (max-width: 1920px) {
    .slider-container {
      margin: auto 0;
    }

    .case-screen-container {
      .drop-list-filter-container {
        right: 96px;
      }
    }
  }


  @media (max-width: 1600px) {
    .slider-container {
      margin: auto 0;
    }

    .case-screen-container {
      .drop-list-filter-container {
        right: 64px;
      }
    }
  }
  @media (max-height: 800px) {
    .slider-container {
      margin: auto 0;
    }
    .case-screen-container {
      .drop-list-filter-container {
        right: 30px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .slider-container {
      margin: auto 0;
    }
    .case-screen-container {
      .drop-list-filter-container {
        right: 64px;
      }
    }
  }

  @media (max-width: 1133px) {
    .slider-container {
      margin: auto 0;
    }

    .case-screen-container {
      .drop-list-filter-container {
        right: 56px;
      }
    }
  }

  @media (max-width: 769px) {
    .slider-container {
      margin: 0;
    }

    .main-container {
      --main-padding-bottom: 48px;
    }
    //iPhone 14
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .case-screen-container {
      position: relative;
    }
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    .main-container {
      --main-padding-bottom: 52px;
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .main-container {
      --main-padding-bottom: 46px;
    }
  }


  @media (max-width: 320px) {
    .main-container {
      --main-padding-bottom: 25px;
    }
  }


  @media (min-width: 2561px) {
    .slider-container {
      margin: auto 0;
    }

    .case-screen-container {
      .drop-list-filter-container {
        right: 192px;
      }
    }
  }

</style>