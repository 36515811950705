<template>
  <div class="main-card-frame-container main-container" v-if="infoContent !== {}">
    <div class="title-return-container" @click="routTo" :class="{'active-modal-filter' : activeModalFilter}">
      <button-icon img-name="single-arrow-left"/>
      <main-paragraph>
        {{ activeMainTitle }}
      </main-paragraph>
    </div>
    <card-title-news-case-mobile :class="{'active-modal-filter' : activeModalFilter}"
                          :publish-date="publishDate"
                          :info-content="infoContent"/>
    <controller-info-container @changeActiveCase="changeState"/>
    <card-info-news-case :info-content-text="infoContent?.translations"
                         :active-load-team="activeLoadTeam"
                         :info-content-photo="infoContent?.photo"/>

    <div class="info-content person" :class="{'active-modal-filter' : activeModalFilter}" v-if="infoContent?.users?.length !== 0">
      <sub-title :active-cormorant="true">{{$tm('caseNewsPage.mainTitle')[0]}}</sub-title>
      <div class="info-container">
        <card-person-mini v-for="item in infoContent?.users"
                          @loadImg="loadImg"
                          :key="item.id"
                          :person-info="item"/>
      </div>
    </div>
    <transition name="fade-opacity-lazy">
      <div class="bottom-info-container" v-if="activeSlider" :class="{'active-modal-filter' : activeModalFilter}">
        <slider-news-all class="news-slider"
                         :class="{'active-modal-filter' : activeModalFilter}"
                         :active-id="activeId"
                         :controller-setting="{
                           top: false,
                           bottom: true
                         }"
                         :active-news-full="false"
                         :setting-swiper="settingSwiperNews"/>
      </div>
    </transition>
    <transition name="fade-opacity-lazy">
      <div class="bottom-info-container"  v-if="!activeSlider" :class="{'active-modal-filter' : activeModalFilter}">
        <slider-case-all class="case-slider"
                         :type-router="'case'"
                         :active-case-full="false"

                         :active-id="activeId"/>
      </div>
    </transition>
  </div>
</template>

<script>
import CardTitleNewsCaseMobile from "@/components/entities/news-case-page/CardTitleNewsCaseMobile.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import CardInfoNewsCase from "@/components/entities/news-case-page/CardInfoNewsCase.vue";
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import CardPersonMini from "@/components/entities/CardPersonMini.vue";
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import SliderNewsAll from "@/components/entities/SliderNewsAll.vue";
import SliderCaseAll from "@/components/entities/SliderCaseAll.vue";
import ControllerInfoContainer from "@/components/shared/ControllerInfoContainer.vue";

export default {
  name: "CardNewsCaseMobile",
  components: {
    ControllerInfoContainer,
    SliderCaseAll,
    SliderNewsAll,
    LargeTitle,
    CardPersonMini,
    SubTitle,
    CardInfoNewsCase,
    ButtonIcon,
    MainParagraph,
    CardTitleNewsCaseMobile,},
  props: {
    publishDate: {
      type: String,
      required: true
    },
    activeRouterHref: {
      type: String,
      required: true
    },
    activeMainTitle: {
      type: String,
      required: true
    },
    infoContent: {
      type: Object,
      required: true
    },
    activeSlider: {
      type: Boolean,
      required: true
    },
    activeId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      settingSwiperNews: {
        direction: 'vertical',
        slidesPerView: 1.1,
        loop: false,
        centeredSlides: false,
        virtual: true
      },
      countAvatar: 0,
      activeLoadTeam: true,
      activeNewsCase: false
    }
  },

  computed: {
    activeModalFilter() {
      return this.$store.state.activeModalFilter;
    },
  },
  watch: {
    activeNewsCase() {
      this.$emit('changeActiveOverflow', this.activeNewsCase);
    },
    countAvatar() {
      if(this.countAvatar === this.infoContent?.users?.length) {
        this.activeLoadTeam = false
      }
    }
  },
  mounted() {
    if(this.infoContent?.users.length === 0) {
      this.activeLoadTeam = false
    }
  },
  methods: {

    loadImg() {
      this.countAvatar++;
    },

    routTo() {
      console.log(this.activeRouterHref)
      this.$router.push(this.activeRouterHref)
    },
    changeState() {
      this.activeNewsCase = !this.activeNewsCase;
    },
  }
}
</script>

<style scoped lang="scss">

.main-card-frame-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  .bottom-info-container {
    height: 100%;
    transition: filter .6s ease;
  }

  .card-title-news-case-container {
    transition: filter .6s ease;
  }

  .title-return-container {
    transition: filter .6s ease;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase;
    .text-content {
      font-family: 'CormorantUnicase-Regular', sans-serif;
      letter-spacing: 2.8px;
    }
  }
  .info-content {
    transition: filter .6s ease;
    .info-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 769px) {
  .main-card-frame-container {

    &.main-container {
      padding-top: 64px;
    }

    .bottom-info-container {
      height: 280px;
    }

    .title-return-container {
      column-gap: 4px;
      margin-bottom: 16px;
      .text-content {
        letter-spacing: 2.8px;
      }

      .button-icon-container {
        max-width: 16px;
        max-height: 16px;
        padding: 2px 5.5px;
      }
    }

    .card-title-news-case-container {
      margin-bottom: 32px;
    }

    .card-info-news-case-content {
      margin-bottom: 24px;
    }
    .info-content {
      margin-bottom: 24px;

      .text-content {
        font-size: 12px;
        margin-bottom: 8px;
      }

      .info-container {
        row-gap: 12px;
        column-gap: 12px;
        max-width: 244px;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {

  .main-card-frame-container {

    &.main-container {
      padding-top: 71px;
    }

    .title-return-container {
      column-gap: 4px;
      margin-bottom: 16px;
      .text-content {
        letter-spacing: 2.8px;
      }

      .button-icon-container {
        max-width: 19px;
        max-height: 19px;
        padding: 3px 6px;
      }
    }

    .card-title-news-case-container {
      margin-bottom: 32px;
    }

    .card-info-news-case-content {
      margin-bottom: 24px;
    }
    .info-content {
      margin-bottom: 24px;

      .text-content {
        font-size: 14px;
        margin-bottom: 8px;
      }

      .info-container {
        row-gap: 8px;
        column-gap: 8px;
        max-width: 260px;
      }
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}



@media (max-width: 375px){
  .main-card-frame-container {


    .bottom-info-container {
      height: 208px;
    }

    &.main-container {
      padding-top: 64px;
    }

    .title-return-container {
      column-gap: 4px;
      margin-bottom: 16px;
      .text-content {
        letter-spacing: 2.4px;
      }

      .button-icon-container {
        max-width: 16px;
        max-height: 16px;
        padding: 2px 5.5px;
      }
    }

    .card-title-news-case-container {
      margin-bottom: 32px;
    }

    .card-info-news-case-content {
      margin-bottom: 24px;
    }
    .info-content {
      margin-bottom: 24px;

      .text-content {
        font-size: 14px;
        margin-bottom: 8px;
      }

      .info-container {
        row-gap: 12px;
        column-gap: 12px;
        max-width: 244px;
      }
    }
  }
}


@media (max-width: 320px) {
  .main-card-frame-container {

    &.main-container {
      padding-top: 52px;
    }

    .bottom-info-container {
      height: 150px;
    }

    .title-return-container {
      column-gap: 2px;
      margin-bottom: 16px;
      .text-content {
        letter-spacing: 2.4px;
      }

      .button-icon-container {
        max-width: 12px;
        max-height: 12px;
        padding: 2px 4px;
      }
    }

    .card-title-news-case-container {
      margin-bottom: 16px;
    }

    .card-info-news-case-content {
      margin-bottom: 16px;
    }
    .info-content {
      margin-bottom: 16px;

      .text-content {
        font-size: 10px;
        margin-bottom: 8px;
      }

      .info-container {
        row-gap: 6px;
        column-gap: 6px;
        max-width: 202px;
      }
    }
  }
}




</style>