<template>
    <div class="photo-modal-container" @click.self="closeModalWindow">
      <div class="photo-modal-content">
<!--        <div class="cross-content" @click="closeModalWindow">-->
<!--          <button-icon img-name="cross"/>-->
<!--        </div>-->
        <img :src="urlPath" alt="picture">
      </div>
    </div>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
export default {
  name: "PhotoModal",
  components: {ButtonIcon},
  props: {
    urlPath: {
      type: String,
      require: true
    },
  },
  methods: {
    closeModalWindow() {
      this.$emit('closePhoto')
    }
  }
}
</script>

<style scoped lang="scss">
 .photo-modal-container {
   position: fixed;
   display: flex;
   align-items: center;
   justify-content: center;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   z-index: 999;
   margin: 0 15px;
   .photo-modal-content {
     position: relative;
     height: 100%;
     overflow: hidden;

     img {
       width: 100%;
       height: 100%;
     }
   }
 }

 @media (max-width: 2560px){
   .photo-modal-container {

     .photo-modal-content {
       border-radius: 5px;
       max-width: 950px;
       max-height: 950px;
     }
   }
 }

 @media (max-width: 2240px) {
   .photo-modal-container {

     .photo-modal-content {
       max-width: 850px;
       max-height: 850px;
     }
   }
 }


 @media (max-width: 1920px) {
   .photo-modal-container {

     .photo-modal-content {
       border-radius: 4px;
       max-width: 700px;
       max-height: 700px;
     }
   }
 }


 @media (max-width: 1600px) {
   .photo-modal-container {
     .photo-modal-content {
       border-radius: 3px;
       max-width: 600px;
       max-height: 600px;
     }
   }
 }
 @media (max-height: 800px) {
   .photo-modal-container {
     .photo-modal-content {
       max-width: 500px;
       max-height: 500px;
     }
   }
 }

 @media (max-width: 1400px) and (min-height: 801px) {
   .photo-modal-container {
     .photo-modal-content {
       max-width: 650px;
       max-height: 650px;
     }
   }
 }

 @media (max-width: 1133px) {
   .photo-modal-container {
     .photo-modal-content {
       max-width: 500px;
       max-height: 500px;
     }
   }
 }
 @media (max-width: 769px) {
   .photo-modal-container {
     .photo-modal-content {
       border-radius: 4px;
       max-width: 325px;
       max-height: 325px;
     }
   }
 }

 @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .photo-modal-container {
   .photo-modal-content {
     max-width: 350px;
     max-height: 350px;
   }
 }
 }


 @media (max-width: 320px) {
   .photo-modal-container {
     .photo-modal-content {
       max-width: 250px;
       max-height: 250px;
     }
   }
 }

 @media (min-width: 2561px) {
   .photo-modal-container {

     .photo-modal-content {
       border-radius: 10px;
       max-width: 1600px;
       max-height: 1600px;
     }
   }
 }


</style>