<template>
  <div class="main-controller-container" ref="teamInfoController" :class="{'active-modal-filter' : activeModalFilter}">
    <small-logo @click="changeMainSlide(0)" :active-color-logo="activeColor" :active-without="true"/>

    <div class="main-controller-content">
      <transition name="fade-opacity-lazy">
        <div class="main-text-controller-container">
          <div class="main-text-controller-content"
               v-for="item in mainControllerInfo"
               :class="{'active-first-sub-slide' :activeMainSlideLocal === 1,
                      'active-second-sub-slide' : activeMainSlideLocal === 2}"
               :key="item.id">

            <text-menu-with-pointer  :active-slide="activeMainSlideLocal"
                                     :sub-item-active="false"
                                     @click="changeMainSlide(item.id)"
                                     :text-menu-item="item"/>


            <transition name="fade-translate-x">

              <div class="sub-text-controller-container"
                   v-show="activeMainSlideLocal === 2 && activeMainSlideLocal === item.id">

                <div class="sub-text-controller-content"
                     :key="subItem.id"
                     v-for="(subItem, index) in item.subController">

                  <text-menu-with-pointer  :active-slide="activeTeamSlide"
                                           v-show="getActiveTypeTeam[index]"
                                           :sub-item-active="true"
                                           @click="changeSubSlide(subItem.id, item.type)"
                                           :text-menu-item="subItem"/>

                </div>
              </div>
            </transition>
            <transition name="fade-translate-x">

              <div class="sub-text-controller-container"
                   v-show="activeMainSlideLocal === 1 && activeMainSlideLocal === item.id">

                <div class="sub-text-controller-content"
                     :key="subItem.id"
                     v-for="(subItem, index) in item.subController">

                  <text-menu-with-pointer  :active-slide="activeAboutSlide"
                                           v-show="index !== 4 || activePartnersLength"
                                           :sub-item-active="true"
                                           @click="changeSubSlide(subItem.id, item.type)"
                                           :text-menu-item="subItem"/>

                </div>
              </div>
            </transition>
          </div>
        </div>
      </transition>

      <list-button-toggle :active-color-scheme="true"/>
    </div>
  </div>
</template>

<script>
import SmallLogo from "@/components/shared/SmallLogo.vue";
import TextMenuWithPointer from "@/components/shared/TextMenuWithPointer.vue";
import ButtonBlot from "@/components/shared/ButtonToggle.vue";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import {mapGetters} from "vuex";
import ListButtonToggle from "@/components/shared/ListButtonToggle.vue";

export default {
  name: "MainController",
  components: {ListButtonToggle, ButtonIcon, ButtonBlot, TextMenuWithPointer, SmallLogo},
  data() {
    return {
      activeMainSlideLocal: 0
    }
  },
  computed: {
    ...mapGetters('persons',['getActiveTypeTeam']),
    ...mapGetters('partners',['activePartnersLength']),
    activeMainSlide() {
      return this.$store.state.activeMainSlide;
    },

    countActiveTypeTeam() {
      return this.getActiveTypeTeam.filter(item => item === true).length;
    },


    activeTeamSlide() {
      return this.$store.state.persons.activeTeamSlide;
    },

    activeAboutSlide() {
      return this.$store.state.activeAboutSlide;
    },

    activeSubSlide() {
      if(this.activeMainSlide === 1) {
          return this.activeAboutSlide;
      } else {
          return this.activeTeamSlide;
      }
    },

    activeModalFilter() {
      return this.$store.state.activeModalFilter;
    },
  },
  mounted() {
    this.checkActiveMainSlide();
    this.checkBlockCounter();
  },
  watch: {
    countActiveTypeTeam() {
      this.checkBlockCounter();
    },
    activeMainSlide() {
      this.checkActiveMainSlide();
    },
    activePartnersLength() {
      this.checkBlockCounter();
    }
  },
  methods: {
    checkBlockCounter() {
      if(this.activePartnersLength) {
        this.$refs.teamInfoController.style.setProperty('--block-counter-about', `5`);
        this.$refs.teamInfoController.style.setProperty('--block-counter', `${this.countActiveTypeTeam}`);
      } else {
        this.$refs.teamInfoController.style.setProperty('--block-counter-about', `4`);
        this.$refs.teamInfoController.style.setProperty('--block-counter', `${this.countActiveTypeTeam}`);
      }
    },

    checkActiveMainSlide() {
      if(this.activeMainSlide === 2 || this.activeMainSlide === 1) {
        setTimeout(() => {
          this.activeMainSlideLocal = this.activeMainSlide
        }, 500)
      } else {
        this.activeMainSlideLocal = this.activeMainSlide
      }
    }
  }
}
</script>

<style scoped lang="scss">



  .main-controller-container {
    position: fixed;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    z-index: 2;

    .small-logo-container {
      transition: filter .6s ease;
      filter: blur(0px);
    }

    &.active-modal-filter {

      .small-logo-container {
        filter: blur(var(--active-blur));
      }

      .main-controller-content {
        filter: blur(var(--active-blur));
      }
    }

    .main-controller-content {
      transition: filter .6s ease;
      filter: blur(0px);
      display: flex;
      flex-direction: column;



      .main-text-controller-container {
        display: flex;
        flex-direction: column;
        transition: height .5s ease;

        .main-text-controller-content {
          transition: height .5s ease;


          &:last-child {
            margin-bottom: 0;
          }

          .sub-text-controller-container {
            .sub-text-controller-content {
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      .button-toggle-container {
        margin-top: 64px;
      }
    }
  }

  @media (max-width: 2560px){
    .main-controller-container {
      max-width: 268px;

      .main-controller-content {
        padding: 0 0 40px 36px;

        .main-text-controller-container {

          .main-text-controller-content {
            margin-bottom: 36px;
            height: 32px;

            &.active-first-sub-slide {
              &:first-child {
                height: calc((17px * var(--block-counter-about)) + 16px + 32px + (10px * (var(--block-counter-about) - 1)));
              }
            }

            &.active-second-sub-slide {
              &:nth-child(2) {
                height: calc((17px * var(--block-counter)) + 16px + 32px + (10px * var(--block-counter)));
              }
            }

            .sub-text-controller-container {
              margin-top: 16px;

              .sub-text-controller-content {
                margin-bottom: 10px;
              }
            }
          }
        }

        .button-toggle-container {
          margin-top: 64px;
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .main-controller-container {
      max-width: 236px;

      .main-controller-content {
        padding: 0 0 32px 32px;

        .main-text-controller-container {

          .main-text-controller-content {
            margin-bottom: 32px;
            height: 32px;

            &.active-first-sub-slide {
              &:first-child {
                height: calc((15px * (var(--block-counter-about))) + 16px + 32px + (8px * (var(--block-counter-about) - 1)));
              }
            }

            &.active-second-sub-slide {
              &:nth-child(2) {
                height: calc((15px * var(--block-counter)) + 16px + 32px + (8px * var(--block-counter)));
              }
            }

            .sub-text-controller-container {
              margin-top: 16px;

              .sub-text-controller-content {
                margin-bottom: 8px;
              }
            }
          }
        }

        .button-toggle-container {
          margin-top: 64px;
        }
      }
    }
  }

  @media (max-width: 1600px) {
    .main-controller-container {
      max-width: 158px;

      .main-controller-content {
        padding: 0 0 23px 18px;

        .main-text-controller-container {

          .main-text-controller-content {
            margin-bottom: 21px;
            height: 21px;

            &.active-first-sub-slide {
              &:first-child {
                height: calc((10px * (var(--block-counter-about))) + 13px + 21px + (6px * (var(--block-counter-about) - 1)));
              }
            }

            &.active-second-sub-slide {
              &:nth-child(2) {
                height: calc((10px * var(--block-counter)) + 13px + 21px + (6px * var(--block-counter)));
              }
            }

            .sub-text-controller-container {
              margin-top: 13px;

              .sub-text-controller-content {
                margin-bottom: 6px;
              }
            }
          }
        }

        .button-toggle-container {
          margin-top: 43px;
        }
      }
    }
  }
  @media (max-height: 800px) {
    .main-controller-container {
      max-width: 158px;

      .main-controller-content {
        padding: 0 0 23px 18px;

        .main-text-controller-container {

          .main-text-controller-content {
            margin-bottom: 21px;
            height: 21px;

            &.active-first-sub-slide {
              &:first-child {
                height: calc((10px * (var(--block-counter-about))) + 13px + 21px + (6px * (var(--block-counter-about) - 1)));
              }
            }

            &.active-second-sub-slide {
              &:nth-child(2) {
                height: calc((10px * var(--block-counter)) + 13px + 21px + (6px * var(--block-counter)));
              }
            }

            .sub-text-controller-container {
              margin-top: 13px;

              .sub-text-controller-content {
                margin-bottom: 6px;
              }
            }
          }
        }

        .button-toggle-container {
          margin-top: 43px;
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .main-controller-container {
      max-width: 158px;

      .main-controller-content {
        padding: 0 0 40px 18px;

        .main-text-controller-container {

          .main-text-controller-content {
            margin-bottom: 21px;
            height: 21px;

            &.active-first-sub-slide {
              &:first-child {
                height: calc((10px * (var(--block-counter-about))) + 13px + 21px + (6px * (var(--block-counter-about) - 1)));
              }
            }

            &.active-second-sub-slide {
              &:nth-child(2) {
                height: calc((10px * var(--block-counter)) + 13px + 21px + (6px * var(--block-counter)));
              }
            }

            .sub-text-controller-container {
              margin-top: 13px;

              .sub-text-controller-content {
                margin-bottom: 6px;
              }
            }
          }
        }

        .button-toggle-container {
          margin-top: 43px;
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .main-controller-container {
      max-width: 134px;

      .main-controller-content {
        padding: 0 0 24px 18px ;

        .main-text-controller-container {

          .main-text-controller-content {
            margin-bottom: 18px;
            height: 18px;

            &.active-first-sub-slide {
              &:first-child {
                height: calc((8px * (var(--block-counter-about))) + 9px + 18px + (5px * (var(--block-counter-about) - 1)));
              }
            }

            &.active-second-sub-slide {
              &:nth-child(2) {
                height: calc((8px * var(--block-counter)) + 9px + 18px + (5px * var(--block-counter)));
              }
            }

            .sub-text-controller-container {
              margin-top: 9px;

              .sub-text-controller-content {
                margin-bottom: 5px;
              }
            }
          }
        }

        .button-toggle-container {
          margin-top: 24px;
        }
      }
    }
  }

  @media (min-width: 2561px) {
    .main-controller-container {
      max-width: 549px;

      .main-controller-content {
        padding: 0 0 64px 72px ;

        .main-text-controller-container {

          .main-text-controller-content {
            margin-bottom: 72px;
            height: 74px;

            &.active-first-sub-slide {
              &:first-child {
                height: calc((34px * var(--block-counter-about)) + 32px + 72px + (20px * (var(--block-counter-about) - 1)));
              }
            }

            &.active-second-sub-slide {
              &:nth-child(2) {
                height: calc((34px * var(--block-counter)) + 32px + 72px + (20px * var(--block-counter)));
              }
            }

            .sub-text-controller-container {
              margin-top: 32px;

              .sub-text-controller-content {
                margin-bottom: 20px;
              }
            }
          }
        }

        .button-toggle-container {
          margin-top: 128px;
        }
      }
    }
  }

</style>