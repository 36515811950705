<template>
  <div class="menu-button-container" @click="changeModalMenu">
    <div class="menu-button-content">
          <transition name="fade-rotate">
            <button-icon v-if="activeMenu"
                         :img-name="'cross-2'"/>
            <button-icon v-else
                         :img-name="'menu-burger'"
                         :active-color-dynamic="true"/>
          </transition>
    </div>
  </div>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";

export default {
  name: "MenuButton",
  components: {ButtonIcon},
  props: {
    activeMenu: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    changeModalMenu() {
      this.$emit('changeModalMenu')
    },
  }
}
</script>

<style scoped lang="scss">
  .menu-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .menu-button-content {
      position: relative;
      .button-icon-container {
        top: 0;
        position: absolute;
        max-width: 100%;
      }
    }
  }
  @media (max-width: 769px) {
    .menu-button-container {
      width: 16px;
      .menu-button-content {
        width: 16px;
        height: 10px;
      }
    }
  }
  @media (max-width: 769px)  and (min-height: 730px) and (max-height: 1000px) {
    .menu-button-container {
      width: 20px;
      .menu-button-content {
        width: 20px;
        height: 14px;
      }
    }
  }


  @media (max-width: 375px) {
    .menu-button-container {
      width: 16px;
      .menu-button-content {
        width: 17px;
        height: 10px;
      }
    }
  }

</style>