<template>
  <div class="button-icon-container">
      <img v-if="activeColorIcon" :src="getUrlIcon(imgName + '-dark.svg')" alt="icon">
      <img v-else :src="getUrlIcon(imgName + '-light.svg')" alt="icon">
  </div>
</template>

<script>
export default {
  name: "ButtonIcon",
  props: {
    imgName: {
      type: String,
      required: true
    },
    activeColorDynamic: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    activeColorIcon() {
      if(this.activeColorDynamic) {
        if(this.$route.name.includes('person') && !this.isLoading) {
          return true;
        } else {
          return this.activeColor;
        }
      } else {
        return this.activeColor;
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .button-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
      max-width: 24px;
      max-height: 24px;
    width: 100%;
    height: 100%;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 2560px){
    .button-icon-container {
      max-width: 28px;
      max-height: 28px;
    }
  }



  @media (max-width: 1920px) {
    .button-icon-container {
      max-width: 24px;
      max-height: 24px;
    }
  }

  @media (max-width: 1600px) {
    .button-icon-container {
      max-width: 16px;
      max-height: 16px;
    }
  }


  @media (max-width: 1133px) {
    .button-icon-container {
      max-width: 14px;
      max-height: 14px;
    }
  }


  @media (min-width: 2561px) {
    .button-icon-container {
      max-width: 56px;
      max-height: 56px;
    }
  }

</style>