<template>
  <transition name="fade-translate-y-card-revert">
    <div class="menu-burger-container" v-if="activeMobileMenu">
      <div class="menu-burger-content">
        <div class="menu-burger-text-content">
          <transition-group name="fade-opacity-lazy">
            <div class="text-content-container" v-if="!activeSubController">
              <main-title v-for="item in mainControllerInfo"
                          :class="{'active-menu-text' : activeSlide === item.id}"
                          @click="changeActiveSlide(item.id)"
                          :active-cormorant="true">
                {{ item.name }}
                <button-icon v-if="item.id === 1" :img-name="imgPointer"/>
              </main-title>
            </div>
            <div class="text-content-container" v-else>
              <main-title v-for="item in mainControllerInfo[0].subController"
                          v-show="item.id !== 4 || activePartnersLength"
                          :class="{'active-menu-text' : activeAboutSlide === item.id}"
                          @click="changeActiveSubSlide(item.id)"
                          :active-cormorant="true">
                {{ item.name }}
              </main-title>
            </div>
          </transition-group>

        </div>

        <list-button-toggle :active-color-scheme="true"/>
        <div class="button-content-container">
          <p @click="changeActiveSlide(2)">{{ $t('teamScreen.teamStartScreen.textButton') }}</p>
          <p @click="changeActiveSlide(5)">{{ $t('teamScreen.teamStartScreen.pressText') }}</p>
        </div>
      </div>

      <div class="background-img-container">
        <div class="blur"></div>
        <img :src="getBackground('background-mobile-burger.webp')" alt="background">
      </div>
    </div>
  </transition>
</template>

<script>
import {mapGetters} from "vuex";
import TextMenu from "@/components/shared/TextMenu.vue";
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import ListButtonToggle from "@/components/shared/ListButtonToggle.vue";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";

export default {
  name: "MenuBurger",
  components: {ButtonIcon, ListButtonToggle, MainTitle, TextMenu},
  props: {
    activeSlide: {
      type: Number,
      required: true
    },
    activeMobileMenu: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      imgPointer: 'single-arrow-right',
      activeSubController: false
    }
  },
  computed: {

    ...mapGetters('partners',['activePartnersLength']),
    activeAboutSlide() {
      return this.$store.state.activeAboutSlide
    }
  },
  watch: {
    activeMobileMenu() {
      if(!this.activeMobileMenu) {
        this.activeSubController = false
      }
    }
  },
  methods: {
    changeActiveSlide(id) {
      if(id === 1) {
        this.activeSubController = true
      } else {
        this.$emit('changeActiveSlide', id, 'main')
      }
    },
    changeActiveSubSlide(id) {
      this.$emit('changeActiveSlide', id , 'sub')
      this.activeSubController = false
    }
  },
}
</script>

<style scoped lang="scss">
  .menu-burger-container {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 996;
    height: var(--calc-height);
    top: 0; left: 0; right: 0; bottom: 0;

    .background-img-container {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 991;

      .blur {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 992;
        background: rgba(var(--color-background-rgba), 0.80);
        backdrop-filter: blur(15px);
      }

      & img {
        position: absolute;
        z-index: 991;
        width: 100%;
        height: 100%;
      }
    }

    .menu-burger-content {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-items: center;
      z-index: 998;


      .button-content-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
      }

      .menu-burger-text-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 100%;

        .text-content-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .text-content {
            align-items: center;
            display: flex;
            flex-direction: row;
            transition: opacity .8s ease;
            opacity: .6;
            font-family: 'CormorantUnicase-Bold', sans-serif;

            &.active-menu-text {
              opacity: 1;
            }
          }
        }


      }

    }
  }
  @media (max-width: 769px) {
    .button-icon-container {
      max-width: 6px;
    }


    .menu-burger-container {

      .menu-burger-content {
        padding: 88px 32px 24px;

        .button-content-container {
          font-size: 12px;
        }

        .button-toggle-container {
          margin-bottom: 32px;
        }


        .menu-burger-text-content {
          row-gap: 47px;
          .text-content-container {
            row-gap: 40px;
            .text-content {
              letter-spacing: 4px;
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {

    .button-icon-container {
      max-width: 8px;
    }

    .menu-burger-container {

      .menu-burger-content {
        padding: 117px 40px 24px;

        .button-content-container {
          font-size: 12px;
        }

        .menu-burger-text-content {
          row-gap: 44px;

          .text-content-container {
            row-gap: 46px;

            .text-content {
              letter-spacing: 4.4px;
              font-size: 22px;
            }
          }
        }
      }
    }
  }


  @media (max-width: 375px){
    .button-icon-container {
      max-width: 6px;
    }


    .menu-burger-container {

      .menu-burger-content {
        padding: 80px 33px 18px;

        .button-content-container {
          font-size: 12px;
        }


        .menu-burger-text-content {
          row-gap: 32px;
          .text-content-container {
            row-gap: 32px;
            .text-content {
              letter-spacing: 4px;
              font-size: 20px;
            }
          }
        }
      }
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .menu-burger-container {

      .menu-burger-content {
        padding: 88px 32px 24px;

        .button-content-container {
          font-size: 12px;
        }


        .menu-burger-text-content {
          row-gap: 40px;
          .text-content-container {
            row-gap: 40px;
            .text-content {
              letter-spacing: 4px;
              font-size: 20px;
            }
          }
        }
      }
    }
  }


  @media (max-width: 320px) {
    .menu-burger-container {

      .menu-burger-content {
        padding: 80px 28px 19px;

        .button-content-container {
          font-size: 10px;
        }

        .button-toggle-container {
          margin-bottom: 25px;
        }


        .menu-burger-text-content {
          row-gap: 32px;
          .text-content-container {
            row-gap: 24px;
            .text-content {
              letter-spacing: 3.2px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }


</style>