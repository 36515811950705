<template>
  <div class="slider-content-container" :class="{'active-horizontal': swiperSetting.direction === 'vertical',
                                                  'not-active-left' : !activeLeftGradient}" v-if="lengthInfo > 1">
    <swiper @slide-change="onSwiperMain"
            @swiper="changeSettingSwiper"
            :slides-per-view="swiperSetting.slidesPerView"
            :virtual="true"
            :modules="modules"
            class="swiper"
            :speed="1500"
            :direction="'horizontal'"
            :allow-touch-move="true">
      <slot/>
    </swiper>
  </div>
  <div class="slider-content-container empty" v-else>
    <slot/>
  </div>
</template>

<script>
import {Virtual} from "swiper";
import ModalFrame from "@/components/entities/ModalFrame.vue";

export default {
  name: "SliderFrameMobile",
  components: {ModalFrame},
  props: {
    lengthInfo: {
      type: Number,
      required: true
    },
    swiperSetting: {
      type: Object,
      required: true
    },
    activeLeftGradient: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      modules: [Virtual],
      mainSwiper: '',
      activeFrameSlide: 0,
    }
  },
  mounted() {
    this.activeFrameSlide = 0
    this.$emit('changeActiveIndex', this.activeFrameSlide)
  },
  methods: {
    changeSettingSwiper(swiper) {
      this.mainSwiper = swiper
    },
    onSwiperMain(swiper) {
      this.activeFrameSlide = swiper.realIndex;
      swiper.update()
      this.$emit('changeActiveIndex', this.activeFrameSlide)
    },
  }
}
</script>

<style scoped lang="scss">
.slider-content-container {
  position: relative;

  .swiper {
    height: inherit;
    max-height: inherit;
    width: 100%;
  }

  &.empty {
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: none;
    }
    &:after {
      content: none;
    }
  }

  &.not-active-left {
    &:before {
      content: none;
    }
  }

  &.active-horizontal {
    &:after {
      width: 150%;
      height: 40%;
      pointer-events: none;
      background: linear-gradient(180deg,  rgba(34, 34, 59, 0.0) 0, var(--color-background) 100%);
    }

    &:before {
      width: 150%;
      height: 40%;
      pointer-events: none;
      background: linear-gradient(0deg,  rgba(34, 34, 59, 0.0) 0, var(--color-background) 100%);
    }
  }

  &:after {
    content: "";
    position: absolute;
    width: 30%;
    height: 150%;
    z-index: 2;
    bottom: -1%;
    right: -1%;
    /*    pointer-events: none;*/
    background: linear-gradient(90deg,  rgba(34, 34, 59, 0.00) 0, var(--color-background) 100%);
  }

  &:before {
    content: "";
    position: absolute;
    width: 30%;
    height: 150%;
    z-index: 2;
    top: -1%;
    left: -1%;
    //pointer-events: none;
    background: linear-gradient(270deg,  rgba(34, 34, 59, 0.00) 0, var(--color-background) 100%);
  }
}

@media (max-width: 769px) {
  .slider-content-container {
    position: relative;
    &.active-horizontal {
      &:after {
        content: ""
      }

      &:before {
        content: ""
      }
    }



    &:before {
      content: none;
    }
  }
}



.modal-frame-container {
  position: relative;
}

</style>