<template>
  <div class="modal-frame-container">
    <transition name="fade-opacity-lazy">
    <div class="modal-frame-content column" v-show="activeModal">
        <drop-list-filter-container @closeModal="closeModal"
                                    @changeActivePractice="changePracticeName"
                                    @changeActivePerson="changePersonName"/>
    </div>
    </transition>
    <div @click.self="closeModal" class="modal-background" :class="{'active-filter': activeFilter}">
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import DropListFilterContainer from "@/components/entities/DroplistFilterContainer.vue";

export default {
  name: "ModalFilter",
  components: {DropListFilterContainer},
  props: {
    activeScreen: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      activeModal: false,
      activeFilter: false
    }
  },

  watch: {
    activeScreen() {
      this.checkOpen();
    }
  },


  mounted() {
    this.checkOpen();
  },

  methods: {
    ...mapActions(['ACTIVE_MODAL_FILTER']),

    checkOpen() {
      if(this.activeScreen) {
        setTimeout(() => {
          this.ACTIVE_MODAL_FILTER(true)
          this.activeFilter = true
        }, 100)
        setTimeout(() => {
          this.activeModal = true
        },700)
      }
    },

    closeModal() {
      this.activeModal = false
      setTimeout(() => {
        this.ACTIVE_MODAL_FILTER(false)
        this.activeFilter = false
      }, 250)
      setTimeout(() => {
        this.$emit('closeModal')
      }, 600)
    },
    changePracticeName(text) {
      this.$emit('changeActivePractice', text)
    },
    changePersonName(text) {
      this.$emit('changeActivePerson', text)
    },
  }
}
</script>

<style scoped lang="scss">
.modal-frame-container {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  .modal-background {
    width: var(--calc-width);
    height: var(--calc-height);
    position: absolute;
    z-index: 998;
    backdrop-filter: blur(var(--active-blur));
    opacity: 0;
    transition: opacity .6s ease;

    &.active-filter {
      opacity: 1;
    }
  }

  .modal-frame-content {
    margin: 0 16px;
    z-index: 999;
    width: 100%;
    background-color: var(--color-background);
    border-style: solid;
    position: fixed;
    border-color: var(--color-stroke);
  }
}

@media (max-width: 769px) {
  .modal-frame-container {
    margin-top: 64px;
    .modal-frame-content {
      max-width: 326px;
      padding: 16px 24px 24px;
      border-width: 1px;
      border-radius: 16px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .modal-frame-container {
    margin-top: 64px;
    .modal-frame-content {
      max-width: 380px;
      padding: 16px 24px 24px;
      border-width: 1px;
      border-radius: 16px;
    }
  }
}

@media (max-width: 375px){
  .modal-frame-container {
    margin-top: 37px;
    .modal-frame-content {
      max-width: 326px;
      padding: 16px 24px 24px;
      border-width: 1px;
      border-radius: 16px;
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .modal-frame-container {
    margin-top: 68px;
  }
}


@media (max-width: 320px) {
  .modal-frame-container {
    margin-top: 41px;
    .modal-frame-content {
      max-width: 292px;
      padding: 14px 16px 16px;
      border-width: 1px;
      border-radius: 16px;
    }
  }
}

</style>