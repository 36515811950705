<template>
  <div class="mission-screen-container">
    <transition name="fade-opacity-lazy">
      <large-title v-if="activeTitle && !activeMobile" :active-cormorant="true">{{$t('aboutScreen.mission.mainTitle')}}</large-title>
    </transition>
    <transition name="fade-opacity-lazy">
      <large-title v-if="activeTitle && activeMobile" :active-cormorant="true">{{$t('aboutScreen.mission.mainTitleMobile')}}</large-title>
    </transition>

    <transition name="fade-opacity-lazy">
    <div class="background-image-container" v-if="activeBackground && !activeMobile">
      <div class="lottie-wrapper" v-show="activeColor">
        <LottieAnimation :animationData="require('@/assets/lottie/PatternDarkTheme.json')"
                         style="height: 100%"
                         class="lottie"
                         :flex-growth="'None'"
                         :loop="true"
                         :delay="500"
                         :rendererSettings="{ preserveAspectRatio: 'xMaxYMid slice' }" />
      </div>
      <div class="lottie-wrapper" v-show="!activeColor">
        <LottieAnimation :animationData="require('@/assets/lottie/PatternLightTheme.json')"
                         style="height: 100%"
                         class="lottie"
                         :flex-growth="'None'"
                         :loop="true"
                         :delay="500"
                         :rendererSettings="{ preserveAspectRatio: 'xMaxYMid slice' }" />
      </div>
    </div>
      <div class="background-image-container" v-else-if="activeBackground  && activeMobile">
        <div class="lottie-wrapper" v-show="activeColor">
          <LottieAnimation :animationData="require('@/assets/lottie/PatternDarkThemeMobile.json')"
                           style="height: 100%"
                           class="lottie"
                           :flex-growth="'None'"
                           :loop="true"
                           :delay="500"
                           :rendererSettings="{ preserveAspectRatio: 'xMaxYMid slice' }" />
        </div>
        <div class="lottie-wrapper" v-show="!activeColor">
          <LottieAnimation :animationData="require('@/assets/lottie/PatternLightThemeMobile.json')"
                           style="height: 100%"
                           class="lottie"
                           :flex-growth="'None'"
                           :loop="true"
                           :delay="500"
                           :rendererSettings="{ preserveAspectRatio: 'xMaxYMid slice' }" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import LottieContainer from "@/components/entities/LottieContainer.vue";

export default {
  name: "MissionScreen",
  components: {LottieContainer, LargeTitle},
  data() {
    return {
      activeTitle: false,
      activeBackground: false,
      backgroundImg: 'big-line',
      backgroundImgMobile: 'big-line-mobile',
    }
  },
  mounted() {
    setTimeout(() => {
    this.activeTitle = true;
    this.activeBackground = true
    }, 250)
  }
}
</script>

<style scoped lang="scss">
.mission-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  .text-content {
    line-height: 170%;
  }

  .background-image-container {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    max-height: var(--calc-height);
    height: 100%;

    .lottie-wrapper {
      width: 100% !important;
      height: 100% !important;
    }

    & img {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 2560px) and (min-width: 2241px)  {
  .mission-screen-container {
    max-height: 484px;

    .text-content {
      font-size: 38px;
      letter-spacing: 7.6px;
    }
  }
}

@media (max-width: 2240px) and (min-width: 1921px)   {
  .mission-screen-container {
    max-height: 476px;

    .text-content {
      font-size: 38px;
      letter-spacing: 7.467px;
    }
  }
}


@media (max-width: 1920px) {
  .mission-screen-container {
    max-height: 440px;

    .text-content {
      letter-spacing: 6.4px;
    }
  }
}

@media (max-width: 1600px) {
  .mission-screen-container {
    max-height: 232px;

    .text-content {
      letter-spacing: 4.4px;
    }
  }
}
@media (max-height: 800px) {
  .mission-screen-container {
    max-height: 232px;

    .text-content {
      letter-spacing: 4.4px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .mission-screen-container {
    max-height: 218px;

    .text-content {
      font-size: 24px;
      letter-spacing: 4.8px;
    }
  }
}

@media (max-width: 1133px) {
  .mission-screen-container {
    max-height: 128px;

    .text-content {
      letter-spacing: 3.8px;
    }
  }
}

@media (max-width: 769px) {
  .mission-screen-container {
    margin-top: 80px;
    max-height: 100%;
    .text-content {
      font-family: 'Raleway', sans-serif;
      font-weight: 400;
      text-transform: inherit;
      font-size: 16px;
      letter-spacing: normal;
      line-height: 140%;
    }
    .background-image-container {
      position: absolute;
      top: auto;
      bottom: 0;
      max-height: 55%;

      .lottie-wrapper {
        height: auto !important;
      }

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .mission-screen-container {
    margin-top: 96px;
    .text-content {
      font-family: 'Raleway', sans-serif;
      font-weight: 400;
      text-transform: inherit;
      font-size: 18px;
      letter-spacing: normal;
      line-height: 140%;
    }


    .background-image-container {
      max-height: 55%;
      width: 100%;
      height: 100%;
      bottom: 0;

      .lottie-wrapper {
        height: auto !important;
      }

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
}


@media (max-width: 375px){
  .mission-screen-container {
    margin-top: 40px;
    .text-content {
      font-size: 15px;
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .mission-screen-container {
    margin-top: 60px;
    .text-content {
      font-size: 16px;
    }

  }
}


@media (max-width: 320px) {
  .mission-screen-container {
    margin-top: 47px;
    .text-content {
      font-size: 14px;
    }
  }
}



@media (min-width: 2561px) {
  .mission-screen-container {
    max-height: 1032px;

    .text-content {
      letter-spacing: 14.8px;
    }
  }
}

</style>