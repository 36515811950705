<template>
  <header class="main-controller">
    <menu-burger @changeActiveSlide="changeActiveSlide"
                 :active-mobile-menu="activeMobileMenu"
                 :active-slide="activeMainSlide"/>

    <div class="main-controller-container mobile controller-fixed"
         :class="{'active-menu' : activeMobileMenu,
         'active-main-page' : !activeSubPage,
         'active-team-page' : $route.name.includes('person'),
         'active-modal-filter' : activeModalFilter }">
      <div class="top-content">
        <transition name="fade-opacity-lazy">
          <div class="logo-text-container" :key="activeMobileMenu">
            <transition name="fade-translate-x-revert">
              <div class="logo-text-content" v-if="activeMobileMenu || activeMainSlide !== 0" @click="clickLogo">
                <img  v-if="activeLogoColor" :src="getUrlLogo(imgName + '-dark.svg')" alt="logo">
                <img v-else :src="getUrlLogo(imgName + '-light.svg')" alt="logo">
              </div>
            </transition>
          </div>
        </transition>

          <menu-button @changeModalMenu="changeModalMenu"
                       :active-menu="activeMobileMenu"/>
      </div>
    </div>
  </header>
</template>

<script>
import MenuBurger from "@/components/entities/MenuBurger.vue";
import SmallLogo from "@/components/shared/SmallLogo.vue";
import MenuButton from "@/components/shared/MenuButton.vue";

export default {
  name: "HeaderMobile",
  components: {MenuButton, SmallLogo, MenuBurger},
  data() {
    return {
      activeMobileMenu: false,
      imgName: 'logo-mobile'
    }
  },
  computed: {

    activeLogoColor() {
      if(this.$route.name.includes('person') && !this.isLoading) {
        if(this.activeMobileMenu) {
          return this.activeColor;
        }
        return true;
      } else {
        this.activeAnimation = ''
        return this.activeColor;
      }
    },

    activeMainSlide() {
      return this.$store.state.activeMainSlide;
    },
    activeSubPage() {
      return this.$store.state.activeSubPage;
    },
    activeStartSlide() {
      return this.$store.state.activeStartSlide;
    },
    activeModalFilter() {
      return this.$store.state.activeModalFilter;
    }
  },
  methods: {

    clickLogo() {
      this.changeMainSlide(0);
      this.activeMobileMenu = false
    },

    changeActiveSlide(id, type) {
      if(this.activeMainSlide !== 0 || this.activeStartSlide !== 0) {
        if(type === 'sub') {
            this.changeSubSlide(id, 'company')
        } else {
          this.changeMainSlide(id);
        }
      }
      this.activeMobileMenu = false
    },

    changeModalMenu() {
        this.activeMobileMenu = !this.activeMobileMenu;
    }
  },
}
</script>

<style scoped lang="scss">
.main-controller {
  width: 100%;
  z-index: 998;
  position: fixed;

  .main-controller-container {
    background-color: var(--color-background);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .5s ease, filter .5s ease;
    &.active-menu {
      background-color: rgba(0,0,0,0);
    }
    &.active-main-page {
      background-color: rgba(0,0,0,0);
    }
    &.active-team-page {
      background-color: rgba(0,0,0,0);
    }

    &.mobile {
      position: absolute;
      z-index: 997;
      flex-direction: column;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      .top-content {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .logo-text-container {
          .logo-text-content {
            position: relative;
            & img {
              width: 100%;
              height: 100%;
            }
          }

        }
      }
    }

  }
}
//
//@media (orientation: landscape) {
//  .main-controller {
//    display: flex;
//    justify-content: center;
//
//    .main-controller-container {
//      max-width: 500px;
//    }
//  }
//}

@media (max-width: 769px) {

  .main-controller {
    height: 48px;
  .main-controller-container {

    &.mobile {
      max-height: 48px;
      padding: 9px 17px;
    }

    .logo-text-container {
      max-width: 72px;
      max-height: 30px;
    }
  }
}
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {

  .main-controller {
    height: 58px;

  .main-controller-container {

    &.mobile {
      max-height: 58px;
      padding: 11px 22px;
    }

    .logo-text-container {
      max-width: 85px;
      max-height: 36px;
    }
  }}
}
@media (max-width: 375px){

  .main-controller {
    height: 48px;
  .main-controller-container {
    &.mobile {
      max-height: 48px;
      padding: 9px 17px;
    }

    .logo-text-container {
      max-width: 71px;
      max-height: 30px;
    }
  } }
}

@media (max-width: 320px) {

  .main-controller {
    height: 44px;
  .main-controller-container {
    &.mobile {
      max-height: 44px;
      padding: 8.5px 16px;
    }

    .logo-text-container {
      max-width: 67px;
      max-height: 27px;
    }
  }  }
}
</style>