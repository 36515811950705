<template>
  <div class="drop-list-filter-container" @click.self="clickClose" v-if="!activeMobile">
    <div class="main-title-container">
      <sub-paragraph :active-cormorant="true">
        {{$t('filterTitle')}}
      </sub-paragraph>

      <p class="sub-title" @click="resetFilter">
        {{$t('filterReset')}}
      </p>
    </div>

    <dropdown-box :name-button="$t('filterButton.practiceName')"
                  :active-scroll-news="activeScrollNews"
                  :reset-filter-state="resetFilterState"
                  @changeActualName="changePracticeName"
                  :info-list="translationPractice"/>
    <dropdown-box :name-button="$t('filterButton.personName')"
                  :active-scroll-news="activeScrollNews"
                  :reset-filter-state="resetFilterState"
                  @changeActualName="changePersonName"
                  :info-list="translationUsers"/>
  </div>
  <div class="drop-list-filter-container" @click.self="clickClose" v-else>
    <div class="main-title-container">
      <sub-paragraph :active-cormorant="true">
        {{$t('filterTitle')}}
      </sub-paragraph>
      <button-icon @click="closeModal" :img-name="'cross'"/>
    </div>

    <dropdown-box :name-button="$t('filterButton.practiceName')"
                  :reset-filter-state="resetFilterState"
                  @changeActualName="changePracticeName"
                  :info-list="translationPractice"/>
    <dropdown-box :name-button="$t('filterButton.personName')"
                  :reset-filter-state="resetFilterState"
                  @changeActualName="changePersonName"
                  :info-list="translationUsers"/>

    <p class="sub-title" @click="resetFilter">
      {{$t('filterReset')}}
    </p>
  </div>
</template>

<script>
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import DropdownBox from "@/components/shared/DropdownBox.vue";
import {mapActions, mapGetters} from "vuex";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";

export default {
  name: "DropListFilterContainer",
  components: {ButtonIcon, DropdownBox, SubParagraph},

  props: {
    activeScrollNews: {
      type: Boolean,
      default: false
    }
  },

  async created() {
    await this.checkInfo();
  },

  data() {
    return {
      resetFilterState: false
    }
  },

  computed: {
    ...mapGetters({
      allPerson: 'persons/usersAll',
      allPractice: 'practice/getPractices'
    }),


    translationPractice() {
      try {
        return this.checkKeysList(this.allPractice, 'modifications', 'name')
      } catch (e) {
        return undefined
      }
    },
    translationUsers() {
      try {
        return this.checkKeysList(this.allPerson, 'profiles', 'fullName')
      } catch (e) {
        return undefined
      }
    }
  },

  methods: {
    ...mapActions({
      getUsers: 'persons/getUsers',
      getPractice: 'practice/getPractice',
    }),
    async checkInfo() {
      let responsePerson = undefined; let responsePractice = undefined;
      if(this.allPerson.length === 0) {
        responsePerson = await this.getUsers();
      }
      if(this.allPractice.length === 0) {
        responsePractice = await this.getPractice();
      }
      try {
        if (responsePerson === undefined && responsePractice === undefined) {
          await Promise.all([responsePerson, responsePractice])
        } else if (responsePerson === undefined) {
          await responsePerson
        } else if (responsePractice === undefined) {
          await responsePractice
        }
      } catch (e) {
        console.log(e)
      }
    },

    closeModal() {
      this.$emit('closeModal')
    },

    resetFilter() {
      this.resetFilterState = true;
      this.$nextTick(() => {
        this.resetFilterState = false;
      })
    },

    changePracticeName(text) {
      this.$emit('changeActivePractice', text)
    },
    changePersonName(text) {
      this.$emit('changeActivePerson', text)
    },
  }

}
</script>

<style scoped lang="scss">
  .drop-list-filter-container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    z-index: 12;

    .main-title-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .button-icon-container {
        max-width: 10px;
        max-height: 10px;
      }
    }
    .sub-title {
      cursor: pointer;
      color: #9A8C98;
    }
    .text-content {
      letter-spacing: 2.8px;
    }
  }

  @media (max-width: 2560px) and (min-width: 2241px){
    .drop-list-filter-container {
      row-gap: 18px;

      .main-title-container {
        .sub-title {
          font-size: 14px;
        }
      }

      .text-content {
        font-size: 24px;
        letter-spacing: 4.8px;
      }
    }
  }

  @media (max-width: 2240px){
    .drop-list-filter-container {
      row-gap: 16px;
    }
  }



  @media (max-width: 1920px) {
    .drop-list-filter-container {
      row-gap: 12px;

      .main-title-container {
        .sub-title {
          font-size: 12px;
        }
      }
      .text-content {
        font-size: 18px;
        letter-spacing: 3.6px;
      }
    }
  }


  @media (max-width: 1600px) {
    .drop-list-filter-container {
      row-gap: 10px;

      .main-title-container {
        .sub-title {
          font-size: 8px;
        }
      }

      .text-content {
        font-size: 10px;
        letter-spacing: 2px;
      }
    }
  }
  @media (max-height: 800px) {
    .drop-list-filter-container {

      .main-title-container {
        .sub-title {
          font-size: 8px;
        }
      }

      .text-content {
        font-size: 10px;
        letter-spacing: 2px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .drop-list-filter-container {

      .main-title-container {
        .sub-title {
          font-size: 8px;
        }
      }
      .text-content {
        font-size: 11px;
        letter-spacing: 2.2px;
      }
    }
  }

  @media (max-width: 1133px) {
    .drop-list-filter-container {
      .main-title-container {
        .sub-title {
          font-size: 7px;
        }
      }
      .text-content {
        font-size: 8.85px;
        letter-spacing: 1.8px;
      }
    }
  }

  @media (max-width: 769px) {
    .drop-list-filter-container {
      row-gap: 0;

      .main-title-container {
        margin-bottom: 20px;
      }

      .drop-down-container {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 20px;
        }
      }

      .sub-title {
        margin: 0 auto;
        font-weight: 500;
        font-size: 12px;
      }

      .text-content {
        font-size: 12px;
        letter-spacing: 2.4px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .drop-list-filter-container {
    row-gap: 0;

    .main-title-container {
      margin-bottom: 24px;
    }

    .drop-down-container {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 24px;
      }
    }

    .sub-title {
      margin: 0 auto;
      font-weight: 500;
      font-size: 14px;
    }

    .text-content {
      font-size: 14px;
      letter-spacing: 2.8px;
    }
  }
  }


  @media (max-width: 375px) {
    .drop-list-filter-container {
      row-gap: 0;

      .main-title-container {
        margin-bottom: 20px;
      }

      .drop-down-container {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 20px;
        }
      }

      .sub-title {
        font-size: 12px;
      }

      .text-content {
        font-size: 12px;
        letter-spacing: 2.4px;
      }
    }
  }


  @media (max-width: 320px) {
    .drop-list-filter-container {
      row-gap: 0;

      .main-title-container {
        margin-bottom: 16px;
      }

      .drop-down-container {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 16px;
        }
      }

      .sub-title {
        font-size: 12px;
      }

      .text-content {
        font-size: 10px;
        letter-spacing: 2px;
      }
    }
  }



  @media (min-width: 2561px) {
    .drop-list-filter-container {
      row-gap: 24px;

      .main-title-container {
        .sub-title {
          font-size: 16px;
        }
      }

      .text-content {
        letter-spacing: 6.4px;
      }
    }
  }

</style>