<template>
 <footer class="footer-container" :class="{'active-color-scheme': activeColorScheme}">
   <modal-consulting v-if="activeModal"
                     @closeModal="changeStateModal"
                     :main-title="$t('modalContent.modalConsulting.mainTitleNews')"/>
   <div class="footer-content">
       <div class="left-content">
         <small-logo @click="changeMainSlide(0)" v-if="activeColorScheme" :active-color-logo="activeColor"/>
         <small-logo @click="changeMainSlide(0)" v-else :active-color-logo="true"/>
         <sub-paragraph :active-cormorant="false" class="im-legal" :class="{'active-color-scheme' : activeColorScheme}">
           {{titleStatic}}
         </sub-paragraph>
      <list-button-toggle :active-color-scheme="activeColorScheme"/>
       </div>

         <footer-list v-for="(item, index) in centralInfo" :key="index"
                      @openModal="changeStateModal"
                      :active-sub-page="activeSubPage"
                      :active-color-scheme="activeColorScheme"
                      :info-footer="item"/>

       <div class="right-content">

         <office-content :active-color-scheme="activeColorScheme"/>
         <div class="right-bottom-content" :class="{'eng' : activeEng }">
           <sub-paragraph :active-cormorant="false"  :class="{'active-color-scheme' : activeColorScheme}">
             {{ $t('footerScreen.mainFooter.subTitleButton') }}
           </sub-paragraph>
         </div>

<!--         <div class="look-button-container" :class="{'active-color-scheme' : activeColorScheme}">-->
<!--           <span class="look-title-content">-->
<!--             {{$t('footerScreen.lookTitle')}}-->
<!--           </span>-->

<!--           <img class="gradient-logo" v-if="activeColorScheme && !activeColor" :src="getUrlLogo(logoDark)" alt="">-->
<!--           <img class="gradient-logo" v-else :src="getUrlLogo(logoLight)" alt="">-->
<!--         </div>-->
       </div>
   </div>
   </footer>
</template>

<script>
import FooterTitle from "@/components/shared/footer/FooterTitle.vue";
import FooterList from "@/components/shared/footer/FooterList.vue";
import OfficeContent from "@/components/shared/OfficeContent.vue";
import SmallLogo from "@/components/shared/SmallLogo.vue";
import ListButtonToggle from "@/components/shared/ListButtonToggle.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import ModalConsulting from "@/components/entities/ModalConsulting.vue";

export default {
  name: "MainFooter",
  components: {ModalConsulting, SubParagraph, ListButtonToggle, SmallLogo, OfficeContent, FooterList, FooterTitle},
  props: {
    activeColorScheme: {
      type: Boolean,
      required: true
    },
    activeSubPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      logoDark: 'look-dark.svg',
      logoLight: 'look-light.svg',
      titleStatic: '© 2024 IM LEGAL',
      activeModal: false
    }
  },
  computed: {
    centralInfo() {
      return this.$tm('footerScreen.mainFooter.centralInfo')
    },
  },
  methods: {
    changeStateModal() {
      this.activeModal = !this.activeModal
    },
  }
}
</script>

<style scoped lang="scss">

.text-content {
  color: #0D1B2AFF;

  &.im-legal {
    color: #F2E9E4FF;
  }

  &.active-color-scheme {
    color: var(--color-text);
  }
}

  .footer-container {
    position: relative;
    width: 100%;

    background: url('@/assets/background/background-footer.webp') 0 0/cover no-repeat;


    &.active-color-scheme {
      background-color: var(--color-card);
      backdrop-filter: none;

      &:after {
        background: var(--color-text);
      }
    }

    .footer-content {
      background-color: rgba(244, 233, 228, 0.30);
      backdrop-filter: blur(15px);
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      flex-direction: row;

      .left-content {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .text-content {
          font-family: 'ProximaNova-Regular', sans-serif;
        }

        .left-paragraph {
          display: inherit;
          flex-direction: column;
          & p {
            line-height: 120%;
          }
        }
      }

      .right-content {
        display: flex;
        flex-direction: column;


        .right-bottom-content {
          &.eng {
            font-variant: all-small-caps !important;
          }
          .text-content {
            cursor: pointer;
            transition: opacity .6s ease;
            opacity: .6;
            line-height: 120%;

            &:hover {
              opacity: 1;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }


        .look-button-container {
          align-self: flex-end;
          display: flex;
          border-style: solid;
          border-color: #F2E9E4FF;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: fit-content;
          white-space: nowrap;
          .look-title-content {
            font-family: 'ProximaNova-Semibold', sans-serif;

            color: #F2E9E4FF;
          }

          .gradient-logo {
            width: 100%;
            height: 100%;
          }

          &.active-color-scheme {
            border-color: var(--color-text);
            .look-title-content {
              color: var(--color-text);
            }
          }
        }
      }
    }

  }

@media (max-width: 2560px){
  .footer-container {

    .footer-content {
      padding: 72px;
      font-size: 16px;
      .left-content {
        max-width: 275px;
        .small-logo-container {
          max-height: 115px;
          padding: 0 96px;
        }

        .text-content {
          font-size: 16px;
        }
      }

      .right-content {
        .office-content {
          margin-bottom: 28px;
        }

        .right-bottom-content {
          margin-bottom: 28px;
          .text-content {
            font-size: 16px;
            margin-bottom: 10px;
          }
        }

        .look-button-container {
          column-gap: 6px;
          border-width: 2px;
          padding: 7.5px 10px;
          border-radius: 56px;
          max-width: 200px;
          .look-title-content {
            font-size: 14px;
            color: #F2E9E4FF;
          }

          .gradient-logo {
            max-width: 42px;
            max-height: 15px;
          }
        }
      }

      .central-content {
        max-width: 1900px;
      }
    }

    &:after {
      height: 2px;
    }
  }
}



  @media (max-width: 1920px) {
    .footer-container {

      .footer-content {
        padding: 64px;
        font-size: 14px;

        .left-content {
          max-width: 236px;
          .small-logo-container {
            max-width: 218px;
            max-height: 96px;
            padding: 0 80px;
          }

          .text-content {
            font-size: 14px;
          }
        }

        .right-content {

          .office-content {
            margin-bottom: 24px;
          }

          .right-bottom-content {
            margin-bottom: 24px;
            .text-content {
              font-size: 14px;
              margin-bottom: 8px;
            }
          }

          .look-button-container {
            column-gap: 4px;
            border-width: 2px;
            padding: 8px;
            border-radius: 24px;
            max-width: 160px;

            .look-title-content {
              font-size: 12px;
              color: #F2E9E4FF;
            }

            .gradient-logo {
              max-width: 38px;
              max-height: 14px;
            }
          }
        }

        .central-content {
          max-width: 967px;
        }
      }

      &:after {
        height: 2px;
      }
    }
  }

@media (max-width: 1600px) {
  .footer-container {

    .footer-content {
      padding: 42px;
      font-size: 9px;

      .left-content {
        max-width: 158px;
        .small-logo-container {
          max-height: 66px;
          padding: 0 59px;
        }

        .text-content {
          font-size: 9px;
        }
      }

      .right-content {


        .office-content {
          margin-bottom: 18px;
        }

        .right-bottom-content {
          margin-bottom: 18px;

          .text-content {
            font-size: 9px;
            margin-bottom: 5px;
          }
        }

        .look-button-container {
          border-width: 1px;
          padding: 3px 5px;
          border-radius: 16px;
          max-width: 110px;
          .look-title-content {
            font-size: 8px;
            color: #F2E9E4FF;
          }

          .gradient-logo {
            max-width: 26px;
            max-height: 8px;
          }
        }
      }

      .central-content {
        max-width: 625px;
      }
    }

    &:after {
      height: 1px;
    }
  }
}
@media (max-height: 800px) {
  .footer-container {

    .footer-content {
      padding: 42px;
      font-size: 9px;

      .left-content {
        max-width: 158px;
        .small-logo-container {
          max-height: 70px;
          padding: 0 59px;
        }

        .text-content {
          font-size: 9px;
        }
      }

      .right-content {
        .office-content {
          margin-bottom: 18px;
        }

        .right-bottom-content {
          margin-bottom: 18px;

          .text-content {
            font-size: 9px;
            margin-bottom: 5px;
          }
        }

        .look-button-container {
          border-width: 1px;
          padding: 3px 5px;
          border-radius: 16px;
          max-width: 110px;
          .look-title-content {
            font-size: 8px;
            color: #F2E9E4FF;
          }

          .gradient-logo {
            max-width: 26px;
            max-height: 8px;
          }
        }
      }

      .central-content {
        max-width: 625px;
      }
    }

    &:after {
      height: 1px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .footer-container {
    .footer-content {
      padding: 44px;
      font-size: 10px;

      .left-content {
        max-width: 168px;
        .small-logo-container {
          max-width: 155px;
          padding: 0 56px;
          max-height: 70px;
        }

        .text-content {
          font-size: 10px;
        }
      }

      .right-content {
        .office-content {
          margin-bottom: 20px;
        }
        .right-bottom-content {
          margin-bottom: 20px;
          .text-content {
            font-size: 11px;
            margin-bottom: 3px;
          }
        }

        .look-button-container {
          border-width: 1px;
          padding: 5px 7px;
          border-radius: 17px;
          max-width: 110px;
          .look-title-content {
            font-size: 8px;
            color: #F2E9E4FF;
          }

          .gradient-logo {
            max-width: 26.7px;
            max-height: 9.8px;
          }
        }
      }

      .central-content {
        max-width: 619px;
      }
    }

    &:after {
      height: 1px;
    }
  }
}

@media (max-width: 1133px) {
  .footer-container {
    .footer-content {
      padding: 36px;
      font-size: 8px;

      .left-content {
        max-width: 140px;
        .small-logo-container {
          max-width: 134px;
          max-height: 58px;
          padding: 0 48px;
        }

        .text-content {
          font-size: 8px;
        }
      }

      .right-content {
        .office-content {
          margin-bottom: 16px;
        }
        .right-bottom-content {
          margin-bottom: 16px;
          .text-content {
            font-size: 8px;
            margin-bottom: 5px;
          }
        }

        .look-button-container {
          border-width: 1px;
          padding: 3px 5px;
          border-radius: 14px;
          max-width: 94px;
          .look-title-content {
            font-size: 7px;
            color: #F2E9E4FF;
          }

          .gradient-logo {
            max-width: 22px;
            max-height: 8px;
          }
        }
      }

      .central-content {
        max-width: 483px;
      }
    }

    &:after {
      height: 1px;
    }
  }
}

@media (min-width: 2561px) {
  .footer-container {
    max-height: 850px;
    height: 100%;

    .footer-content {
      height: 100%;
      padding: 152px;
      font-size: 32px;

      .left-content {
        max-width: 408px;
        .small-logo-container {
          padding: 0 125px;
          max-height: 260px;
        }

        .left-paragraph {
          margin-bottom: 32px;

          .paragraph {
            font-size: 32px;
            margin-bottom: 4px;
          }
        }
      }

      .right-content {

        .office-content {
          margin-bottom: 56px;
        }

        .right-bottom-content {
          margin-bottom: 56px;
          .text-content {
            margin-bottom: 16px;
          }
        }

        .look-button-container {
          border-width: 4.7px;
          padding: 13px 20px;
          border-radius: 56px;
          max-width: 401px;
          column-gap: 12px;
          .look-title-content {
            font-size: 28px;
            color: #F2E9E4FF;
          }

          .gradient-logo {
            max-width: 86px;
            max-height: 24px;
          }
        }
      }

      .central-content {
        max-width: 1900px;
      }
    }

    &:after {
      height: 4.5px;
    }
  }
}


</style>