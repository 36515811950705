<template>
    <slider-case :active-info="filterInfo"
                 :active-gradient="activeGradient"
                 :active-sub-page="activeSubPage"
                 :active-person-page="activePersonPage"
                 v-if="!isLoading && activeCaseFull"
                 :active-case-full="activeCaseFull"
                 :setting-swiper="settingSwiper"/>
  <slider-news-mobile v-else-if="!isLoading  && !activeCaseFull && activeMobile"
                      :type-router="typeRouter"
                      :type-slider="'case'"
                      :setting-swiper="settingSwiper"
                      :active-info="filterInfo"/>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SliderCase from "@/components/entities/SliderCase.vue";
import CardCase from "@/components/entities/CardCase.vue";
import SliderNewsMobile from "@/components/entities/SliderNewsMobile.vue";

export default {
  name: "SliderCaseAll",
  components: {SliderNewsMobile, CardCase, SliderCase},
  props: {
    activeGradient: {
      type: Boolean,
      default: true
    },

    activePersonTextFilter: {
      type: String,
    },
    typeRouter: {
      type: String,
      default: 'news'
    },
    activePracticeTextFilter: {
      type: String,
    },
    activeCaseFull: {
      type: Boolean,
      default: true
    },
    activePersonPage: {
      type: Boolean,
      default: false
    },
    activeId: {
      type: Number,
      default: undefined
    },
    activeSubPage: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      settingSwiper: {
        direction: 'horizontal',
        slidesPerView: 'auto',
        loop: false,
        centeredSlides: false,
        virtual: true,
        spaceBetween: 0
      },
      activeIdState: false
    }
  },

  async created() {
    await this.checkInfo();
  },

  mounted() {
    this.activeIdState = this.activeId !== undefined;
    this.checkSize();
    addEventListener('resize', this.checkSize);
  },
  unmounted() {
    removeEventListener('resize', this.checkSize)
  },

  computed: {
    ...mapGetters({
      caseAll: 'case/allCase',
      sortedCaseAll: 'case/getAllSortedCase',
    }),


    activeLangFilter() {
      return this.sortedCaseAll?.filter(item => {
        return item?.translations?.find(item => item.lang === this.$i18n.locale)
      })
    },

    filterInfo() {
      if(!this.activeIdState) {
        if (this.activePracticeTextFilter !== '' && this.activePersonTextFilter !== '') {
          return this.sortedCaseAll?.filter(item => this.changeActivePractice(item) && this.changeActivePersons(item));
        } else if (this.activePracticeTextFilter !== '') {
          return this.activeLangFilter?.filter(item => this.changeActivePractice(item));
        } else if (this.activePersonTextFilter !== '') {
              return this.sortedCaseAll?.filter(item => this.changeActivePersons(item))
        } else {
          return this.activeLangFilter
        }
      } else {
        return this.activeLangFilter?.filter(item => item?.id !== this.activeId)
      }
    }
  },

  watch: {
    filterInfo() {
      if(this.filterInfo.length === 0 && this.activeId !== undefined) {
        this.activeIdState = false
      } else {
        this.activeIdState = this.activeId !== undefined;
      }
    },
    activeId() {
      this.activeIdState = this.activeId !== undefined;
    }
  },

  methods: {
    ...mapActions('case', ['getCase']),
    async checkInfo() {
      if(this.caseAll === undefined) {
        try {
          await this.getCase();
        } catch (e) {
          console.log(e)
        } finally {
          this.IS_LOADING(false)
        }
      } else {
        this.IS_LOADING(false)
      }
    },

    checkSize() {
      if(window.innerWidth > 2560) {
        this.settingSwiper.spaceBetween = 86
        this.settingSwiper.slidesPerView = 2.95
      } else if((window.innerWidth <= 2560 && window.innerWidth > 2240))  {
        this.settingSwiper.spaceBetween = 42
        this.settingSwiper.slidesPerView = 3.3
      } else if((window.innerWidth <= 2240 && window.innerWidth > 1920))  {
        this.settingSwiper.spaceBetween = 37
        this.settingSwiper.slidesPerView = 2.88
      } else if(window.innerWidth < 1600 && window.innerWidth > 1400) {
        this.settingSwiper.spaceBetween = 32
        this.settingSwiper.slidesPerView = 3.1
      } else if (window.innerWidth < 1400 && window.innerWidth > 1280 && window.innerHeight <= 800) {
        this.settingSwiper.spaceBetween = 24
        this.settingSwiper.slidesPerView = 3.0
      } else if (window.innerWidth < 1400 && window.innerWidth > 1280) {
        this.settingSwiper.spaceBetween = 24
        this.settingSwiper.slidesPerView = 2.5
      } else if(window.innerWidth <= 1133) {
        this.settingSwiper.spaceBetween = 24
        this.settingSwiper.slidesPerView = 2.8
      } else {
        this.settingSwiper.spaceBetween = 48
        this.settingSwiper.slidesPerView = 2.8
      }

       if (window.innerWidth <= 769 && !this.activeCaseFull){
        this.settingSwiper.slidesPerView = 1.1
      } else if (window.innerWidth <= 769){
         if(window.innerWidth <= 769 && window.innerWidth > 375) {
           this.settingSwiper.slidesPerView = 1.15
         } else {
           if(window.innerWidth <= 320) {
             this.settingSwiper.slidesPerView = 1.15
             this.settingSwiper.spaceBetween = 8
           } else {
             this.settingSwiper.slidesPerView = 1.25
             this.settingSwiper.spaceBetween = 16
           }
         }

       }
    },

    changeActivePractice(item) {
      return item?.practices.find(item => item.id === this.activePracticeTextFilter)
    },
    changeActivePersons(item) {
      return item?.users.find(item => item.id === this.activePersonTextFilter)
    },
}
}
</script>

<style scoped>

</style>