<template>
  <div class="card-info-news-case-content">
    <transition name="fade-scale">
      <photo-modal v-if="activePhotoModal"
                   @closePhoto="closePhotoModal"
                   :url-path="activeUrlImg + activePhotoUrl"/>
    </transition>
    <div class="frame-info-content top" :class="{'active-modal-filter' : activeModalFilter}" v-if="infoContentPhoto?.preview?.name !== undefined">
      <div class="main-img-container">
        <img  @load="checkLoadingPreview" :src="activeUrlImg + infoContentPhoto?.preview.name || infoContentPhoto?.preview" alt="preview">
      </div>
    </div>
    <div class="content-text-container" v-html="parsedHTML" :class="{'active-modal-filter' : activeModalFilter}"></div>
      <div class="sub-img-container" v-if="infoContentPhoto?.photo?.length !== 0" :class="{'active-modal-filter' : activeModalFilter}">
        <slider-img :slider-setting="sliderSetting" :length-info="infoContentPhoto?.photo.length">
          <swiper-slide v-for="(item, index) in infoContentPhoto?.photo"
                        class="clickable"
                        @click="openModalPhoto(item)"
                        style="transition: transform .8s ease"
                        :key="index">

            <div class="sub-img-content">
              <img @load="checkLoadingMorePhoto" :src="activeUrlImg + item?.name" alt="photo">
            </div>
          </swiper-slide>
        </slider-img>
      </div>
  </div>
</template>

<script>
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import SliderImg from "@/components/entities/SliderImg.vue";
import PhotoModal from "@/components/entities/PhotoModal.vue";
import {mapActions} from "vuex";
import Loader from "@/components/Loader.vue";
export default {
  name: "CardInfoNewsCase",
  components: {Loader, PhotoModal, SliderImg, MainTitle, MainParagraph},
  props: {
    infoContentText: {
      type: Object,
      required: true
    },
    infoContentPhoto: {
      type: Object,
      required: true
    },
    activeLoadTeam: {
      type: Boolean
    }
  },
  data() {
    return {
      sliderSetting: {
        loop: false,
        slidesPerView: 1.6,
        centeredSlides: true,
        grabCursor: false
      },
      activePhotoModal: false,
      activePhotoUrl: '',
      parsedHTML: '',
      countMorePhoto: 0,
      morePhoto: true,
      preview: true,
      stateContent: true,
      loadTeam: true,
    }
  },

  computed: {
    activeModalFilter() {
      return this.$store.state.activeModalFilter;
    },
    activeUrlImg() {
      let url = window.location.href
      if(url.includes('imlegal.ru')) {
        return 'https://s3.imlegal.ru/im-legal/'
      } else {
        return 'https://s3.roseblade.xyz/im-legal-dev/'
      }
    },
    statePhoto() {
      if (!this.morePhoto && !this.preview && !this.stateContent && !this.loadTeam) {
        return false
      } else {
        return true
      }
    }
  },
  mounted() {
    this.parserContent()
    setTimeout(() => {
      if (this.infoContentPhoto?.photo.length === 0) {
        this.morePhoto = false
      }
      if(this.infoContentPhoto?.preview === null)  {
        this.preview = false
      }
    }, 500)

  },
  watch: {
    infoContentText() {
      this.parserContent()
    },
    activeLoadTeam() {
      if(!this.activeLoadTeam) {
        this.loadTeam = false
      }
    },
    'infoContentPhoto?.preview'() {
      if(this.infoContentPhoto?.preview === null)  {
        this.preview = false
      }
    },
    statePhoto() {
      if(!this.statePhoto) {
        this.IS_LOADING_IMG(false)
      }
    }
  },

  methods: {
    ...mapActions(['ACTIVE_MODAL_FILTER', 'IS_LOADING_IMG']),

    openModalPhoto(item) {
      this.ACTIVE_MODAL_FILTER(true)
      this.activePhotoModal = true
      this.activePhotoUrl = item?.name
    },
    closePhotoModal() {
      this.ACTIVE_MODAL_FILTER(false)
      this.activePhotoModal = false
    },

    parserContent() {
      setTimeout(() => {
        try {
          const parser = new DOMParser();
          const doc = parser.parseFromString(this.infoContentText?.content, 'text/html');
          this.parsedHTML = doc.body.innerHTML;
        } catch (e) {
          console.log(e)
        } finally {
          this.stateContent = false
        }
      }, 250)
    },
    checkLoadingMorePhoto() {
      this.countMorePhoto = this.countMorePhoto + 1;
      if (this.countMorePhoto === this.infoContentPhoto?.photo.length) {
        this.morePhoto = false
      }
    },

    checkLoadingPreview() {
      this.preview = false
    }
  }
}
</script>

<style scoped lang="scss">


.info-content {
  display: flex;
  flex-direction: column;

  &.text {
    opacity: 0.8;
  }
}

.card-info-news-case-content {
  position: relative;
  background-color: var(--color-card);
  display: flex;
  flex-direction: column;

  .content-text-container {
    transition: filter .6s ease;
  }

  .frame-info-content {

    transition: filter .5s ease;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 8%;
    z-index: 1;
    top: 0;
    left: 0;
    background: linear-gradient(0deg,  rgba(34, 34, 59, 0.0) 0%, var(--color-background) 75%);
  }



  .sub-img-container {

    transition: filter .5s ease;
    width: 100%;
    display: flex;
    flex-direction: row;

    .sub-img-content {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: zoom-in;
      width: 100%;
      overflow: hidden;

      .loader {
        height: fit-content;
      }

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .main-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;

    .loader {
      height: fit-content;
    }
    & img {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 2560px) and (min-width: 2241px){


  .card-info-news-case-content {
    row-gap: 84px;
    padding: 158px 36px 36px;
    .frame-info-content {
      row-gap: 46px;
    }

    .info-content {
      row-gap: 46px;

      .text-content {
        font-size: 21px;
      }
    }

    .sub-img-container {
      column-gap: 38px;

      .sub-img-content {
        border-radius: 5px;
        max-width: 453px;
        height: 453px;
      }
    }

    .main-img-container {
      border-radius: 5px;
      height: 420px;
    }
  }
}

@media (max-width: 2240px){


  .card-info-news-case-content {
    row-gap: 72px;
    padding: 194px 36px 36px;

    .frame-info-content {
      row-gap: 44px;
    }


    .info-content {
      row-gap: 44px;
    }

    .sub-img-container {
      column-gap: 38px;

      .sub-img-content {
        max-width: 373px;
        height: 373px;
      }
    }

    .main-img-container {
      height: 420px;
    }
  }
}


@media (max-width: 1920px) {

      .card-info-news-case-content {
        row-gap: 48px;
        padding: 158px 32px 32px;
        width: 100%;

        .frame-info-content {
          row-gap: 26px;
        }

        .info-content {
          row-gap: 26px;
        }

        .sub-img-container {
          column-gap: 32px;

          .sub-img-content {
            border-radius: 4px;
            max-width: 375px;
            height: 375px;
          }
        }

        .main-img-container {
          border-radius: 4px;
          height: 360px;
        }
      }
}

@media (max-width: 1600px) {

  .card-info-news-case-content {
    row-gap: 48px;
    padding: 90px 22px 22px;

    .frame-info-content {
      row-gap: 26px;
    }

    .info-content {
      row-gap: 26px;

      .text-content {
        font-size: 12px;
      }
    }

    .sub-img-container {
      column-gap: 22px;

      .sub-img-content {
        border-radius: 3px;
        max-width: 320px;
        height: 320px;
      }
    }

    .main-img-container {
      border-radius: 3px;
      height: 240px;
    }
  }
}
@media (max-height: 800px) {

  .card-info-news-case-content {
    row-gap: 48px;
    padding: 110px 22px 22px;

    .frame-info-content {
      row-gap: 26px;
    }

    .info-content {
      row-gap: 26px;

      .text-content {
        font-size: 12px;
      }
    }

    .sub-img-container {
      column-gap: 22px;

      .sub-img-content {
        max-width: 230px;
        height: 230px;
      }
    }

    .main-img-container {
      height: 240px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {

  .card-info-news-case-content {
    row-gap: 52px;
    padding: 81px 22px 22px;

    .frame-info-content {
      row-gap: 28px;
    }

    .info-content {
      row-gap: 28px;

      .text-content {
        font-size: 12px;
      }
    }

    .sub-img-container {
      column-gap: 22px;

      .sub-img-content {
        max-width: 273px;
        height: 273px;
      }
    }

    .main-img-container {
      height: 256px;
    }
  }
}

@media (max-width: 1133px) {
  .content-text-container {
    font-size: 12px !important;
  }

  .card-info-news-case-content {
    row-gap: 40px;
    padding: 98px 18px 18px;

    .frame-info-content {
      row-gap: 24px;
    }

    .info-content {
      row-gap: 18px;

      .text-content {
        text-align: left;
        font-size: 11px;
      }
    }

    .sub-img-container {
      column-gap: 18px;

      .sub-img-content {
        max-width: 228px;
        height: 228px;
      }
    }

    .main-img-container {
      height: 212px;
    }
  }
}

@media (max-width: 769px) {

  .card-info-news-case-content {
    background-color: rgba(1,1,1,0);
    row-gap: 24px;
    padding: 0;

    &:before {
      display: none;
    }

    .frame-info-content {
      row-gap: 24px;
    }

    .info-content {
      row-gap: 18px;

      .text-content {
        text-align: left;
        font-size: 11px;
      }
    }

    .sub-img-container {
      border-radius: 4px;
      column-gap: 18px;

      .sub-img-content {
        border-radius: 4px;
        max-width: 228px;
        height: 228px;
      }
    }

    .main-img-container {
      border-radius: 4px;
      height: 114px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .content-text-container {
  font-size: 14px !important;
}

  .card-info-news-case-content {
    background-color: rgba(1,1,1,0);
    row-gap: 24px;
    padding: 0;

    &:before {
      display: none;
    }

    .frame-info-content {
      row-gap: 24px;
    }

    .info-content {
      row-gap: 18px;

      .text-content {
        text-align: left;
        font-size: 11px;
      }
    }

    .sub-img-container {
      border-radius: 4px;
      column-gap: 18px;

      .sub-img-content {
        max-width: 228px;
        height: 228px;
      }
    }

    .main-img-container {
      border-radius: 4px;
      height: 114px;
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}



@media (max-width: 375px){
  .card-info-news-case-content {
    overflow: hidden;
    background-color: rgba(1,1,1,0);
    row-gap: 24px;
    padding: 0;

    &:before {
      display: none;
    }

    .frame-info-content {
      row-gap: 24px;
    }

    .info-content {
      row-gap: 16px;

      .text-content {
        text-align: left;
        font-size: 12px;
      }
    }

    .sub-img-container {
      border-radius: 4px;
      column-gap: 18px;

      .sub-img-content {
        max-width: 180px;
        height: 180px;
      }
    }

    .main-img-container {
      border-radius: 4px;
      height: 114px;
    }
  }
}


@media (max-width: 320px) {
  //iPhone SE
}




@media (min-width: 2561px) {

  .card-info-news-case-content {
    row-gap: 168px;
    padding: 387px 72px 72px;


    .frame-info-content {
      row-gap: 88px;
    }

    .info-content {
      row-gap: 88px;
    }

    .sub-img-container {
      column-gap: 75px;

      .sub-img-content {
        border-radius: 10px;
        max-width: 920px;
        height: 920px;
      }
    }

    .main-img-container {
      border-radius: 10px;
      height: 840px;
    }
  }
}

</style>