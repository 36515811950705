<template>
  <div class="lottie-wrapper">
    <LottieAnimation :animationData="jsonData"
                     class="lottie"
                     :flex-growth="'None'"
                     :loop="loopSetting"
                     :delay="delaySetting"
                     :rendererSettings="{ preserveAspectRatio: 'xMaxYMid meet' }" />
  </div>
</template>

<script>
export default {
  name: "LottieContainer",
  props: {
    jsonData: {
      type: Object,
      required: true
    },
    loopSetting: {
      type: Boolean,
      default: false
    },
    delaySetting: {
      type: Number,
      default: 1000
    }
  },
}
</script>

<style scoped lang="scss">

.lottie-wrapper {
  width: 100%;
  height: 100%;

  .lottie {
    bottom: 0;
  }
}

@media screen and (min-width:1400px) {
  .lottie-wrapper {
    left: 4%;
    justify-items: center;
    position: absolute;
    height: 100%;
    width: 96%;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 481px) and (max-width:1399px) {
  .lottie-wrapper {
    justify-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }
}
</style>