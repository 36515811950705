<template>
  <div class="about-screen-container-swiper" >
    <transition name="fade-opacity-lazy">
      <mobile-controller @slideTo="slideNext" v-if="activeMobile"/>
    </transition>
    <swiper @slide-change="onSwiperMain"
            @swiper="changeSettingSwiper"
            :effect="'fade'"
            :modules="modules"
            :allow-slide-next="true"
            :allow-slide-prev="true"
            class="swiper"
            :mousewheel="true"
            :speed="1000"
            :allow-touch-move="false"
            direction="vertical">
      <swiper-slide>
        <transition name="fade-opacity-lazy">
          <div class="about-screen-container im-legal main-container" v-if="activeImLegalScreen">
            <i-m-legal-screen/>
          </div>
        </transition>
      </swiper-slide>
      <swiper-slide>
        <transition name="fade-opacity-lazy">
        <div class="about-screen-container mission main-container" v-if="activeMissionScreen">
          <mission-screen/>
        </div>
        </transition>
      </swiper-slide>
      <swiper-slide>
        <transition name="fade-opacity-lazy">
        <div class="about-screen-container values main-container" v-if="activeValuesScreen">
            <value-dark-screen v-if="activeColor"/>
            <value-light-screen v-else/>
        </div>
        </transition>
      </swiper-slide>
      <swiper-slide>
        <transition name="fade-opacity-lazy">
        <div class="about-screen-container im-invest main-container" v-if="activeIMInvest">
          <i-m-invest-screen/>
        </div>
        </transition>
      </swiper-slide>
      <swiper-slide v-if="activePartnersLength">
        <transition name="fade-opacity-lazy">
          <div class="about-screen-container partners main-container" v-if="activePartners">
              <partners-screen :active-screen="activePartners"/>
          </div>
        </transition>
      </swiper-slide>
    </swiper>
  </div>

</template>

<script>

import IMLegalScreen from "@/components/features/about-screen/IMLegalScreen.vue";
import MissionScreen from "@/components/features/about-screen/MissionScreen.vue";
import IMInvestScreen from "@/components/features/about-screen/IMInvestScreen.vue";
import ValueDarkScreen from "@/components/features/about-screen/ValueDarkScreen.vue";
import ValueLightScreen from "@/components/features/about-screen/ValueLightScreen.vue";
import {EffectFade, History, Mousewheel, Navigation} from "swiper";
import {mapActions, mapGetters} from "vuex";
import PartnersScreen from "@/components/features/about-screen/PartnersScreen.vue";
import LoaderSlot from "@/components/entities/LoaderSlot.vue";
import MobileController from "@/components/entities/MobileController.vue";

export default {
  name: "AboutScreen",
  components: {
    MobileController,
    LoaderSlot,
    PartnersScreen, ValueLightScreen, ValueDarkScreen, IMInvestScreen, MissionScreen, IMLegalScreen},
  props: {
    activeScreen: {
      type: Boolean,
      required: true
    },
    hrefAbout: {
      type: Array
    }
  },
  data() {
    return {
      modules: [EffectFade, Mousewheel, Navigation, History],
      aboutSwiper: '',
      blockSwiper: false,

      activeMainTitle: '',
      activeSubTitle: '',


      activeImLegalScreen: false,
      activeMissionScreen: false,
      activeValuesScreen: false,
      activeIMInvest: false,
      activePartners: false,
    }
  },

  computed: {

    ...mapGetters('partners',['activePartnersLength']),
    activeMainSlide() {
      return this.$store.state.activeMainSlide;
    },

    activeAboutSlideIndex() {
      return this.$store.state.activeAboutSlide;
    },
    allowTouchMoveState() {
      return this.$store.state.allowTouchMoveState;
    },
    activeSubPage() {
      return this.$store.state.activeSubPage;
    },
    activeCookieModal() {
      return this.$store.state.activeCookieModal;
    }
  },

  mounted() {
    if(this.$route.name === 'about') {
        this.hrefAbout.forEach((item, index) => {
          if(item === this.$route.params.typeAbout) {
            this.aboutSwiper.slideTo(index, 0, false)
          }
        })
    }
    this.aboutSwiper.allowTouchMove = this.allowTouchMoveState;
    if(this.activeAboutSlideIndex !== 0) {
      setTimeout(() => {
        this.changeAboutSwiper();
      }, 1000)
    } else {
      this.checkMountedScreen();
      this.activeMainTitle = this.mainControllerInfo[0].name;
    }
  },

  watch: {

    activeMainSlide() {
      if(this.activeMainSlide === 0) {
        this.aboutSwiper.slideTo(0)
        this.$emit('blockPrevSlide', false)
        this.$emit('blockNextSlide', false)
      }
    },

    '$route.path'() {
      this.changeActiveUrl();
    },


    activeScreen() {
      this.checkMountedScreen();
    },
    activeAboutSlideIndex() {
        setTimeout(() => {
          this.changeAboutSwiper();
        }, 100)
    },
    allowTouchMoveState() {
      this.aboutSwiper.allowTouchMove = this.allowTouchMoveState
    },
    activeCookieModal() {
      if(this.activeCookieModal) {
        this.aboutSwiper.disable();
      } else {
        this.aboutSwiper.enable();
      }
    }
  },

  methods: {
    ...mapActions(['ACTIVE_ABOUT_SLIDE']),

    changeActiveUrl() {
      if(this.$route.path?.includes('about/im')) {
        this.aboutSwiper.slideTo(0, 0)
      }
      if(this.$route.path?.includes('about/mission')) {
        this.aboutSwiper.slideTo(1, 0)
      }
      if(this.$route.path?.includes('about/values')) {
        this.aboutSwiper.slideTo(2, 0)
      }
      if(this.$route.path?.includes('about/iminvest')) {
        this.aboutSwiper.slideTo(3, 0)
      }
      if(this.$route.path?.includes('about/partners')) {
        this.aboutSwiper.slideTo(4, 0)
      }
    },


    slideNext() {
      this.aboutSwiper.slideNext();
    },

    checkMountedScreen() {
      if(this.activeScreen) {
        this.IS_LOADING(false)
        if(!this.activeCookieModal) {
          this.aboutSwiper.disable();
          setTimeout(() => {
            this.aboutSwiper.enable();
            this.checkTitle();
            this.checkBlockMainSwipe();
            this.checkActiveMainSlide();
          }, 500)
        } else {
          this.checkTitle();
          this.checkBlockMainSwipe();
          this.checkActiveMainSlide();
        }
      } else {
        this.hideScreen()
      }
    },

    checkTitle() {
      this.activeSubTitle = '';
      this.activeSubTitle = this.mainControllerInfo[0].subController[this.activeAboutSlideIndex].name;
    },

    changeAboutSwiper() {
      this.blockSwiper = true
      this.aboutSwiper.slideTo(this.activeAboutSlideIndex);
      this.checkBlockMainSwipe();
      this.blockSwiper = false
      this.checkActiveMainSlide();
    },

    changeSettingSwiper(swiper) {
        this.aboutSwiper = swiper
        if(this.activeAboutSlideIndex !== 0) {
          setTimeout(() => {
            this.aboutSwiper.slideTo(this.activeAboutSlideIndex)
          }, 500)
        }
    },

    onSwiperMain(swiper) {
      if(!this.blockSwiper) {
        this.ACTIVE_ABOUT_SLIDE(swiper.activeIndex)
        this.checkBlockMainSwipe();
        this.checkActiveMainSlide();
        if(this.$route.name.includes('about')) {
          switch (this.activeAboutSlideIndex) {
            case 0:
              this.$router.push({name: 'about', params: {typeAbout: 'im'}})
              break;
            case 1:
              this.$router.push({name: 'about', params: {typeAbout: 'mission'}})
              break;
            case 2:
              this.$router.push({name: 'about', params: {typeAbout: 'values'}})
              break;
            case 3:
              this.$router.push({name: 'about', params: {typeAbout: 'iminvest'}})
              break;
            case 4:
              this.$router.push({name: 'about', params: {typeAbout: 'partners'}})
              break;
          }
        }
      }
    },


  hideScreen() {
    this.activeImLegalScreen = false;
    this.activeMissionScreen = false;
    this.activeValuesScreen = false;
    this.activeIMInvest = false;
    this.activePartners = false;
  },

    checkActiveMainSlide() {
      this.hideScreen();
      switch (this.activeAboutSlideIndex) {
        case 0:
          this.activeImLegalScreen = true;
          break;
        case 1:
          this.activeMissionScreen = true;
          break;
        case 2:
          this.activeValuesScreen = true;
          break;
        case 3:
          this.activeIMInvest = true;
          break;
        case 4:
          this.activePartners = true;
          break;
      }
    },

    checkBlockMainSwipe() {
      let slide = 0;
      if(this.activePartnersLength) {
        slide = 4
      } else {
        slide = 3
      }
      if(this.activeScreen) {
        this.checkTitle();
        if(this.aboutSwiper.isBeginning) {
          this.$emit('blockNextSlide', true)
          setTimeout(() => {
            this.$emit('blockPrevSlide', false)
          },100)
        } else if(this.aboutSwiper.isEnd){
          this.$emit('blockPrevSlide', true)
          setTimeout(() => {
            this.$emit('blockNextSlide', false)
          },100)
        } else if(this.activeAboutSlideIndex >= 1 && this.activeAboutSlideIndex < slide) {
          this.$emit('blockPrevSlide', true)
          this.$emit('blockNextSlide', true)
        }
      }

    }
  },

}
</script>

<style scoped lang="scss">
.swiper-slide {
  background-color: var(--color-background);
}
    .about-screen-container {
      justify-content: center;

      &.mission {
        justify-content: flex-end;
      }
    }

@media (max-width: 1600px) {
  .main-container {
    padding-bottom: 86px;
  }

  .about-screen-container {
    justify-content: flex-end;

    &.partners {
      justify-content: center;
    }

    &.values {
      justify-content: center;
    }

    &.im-invest {
      justify-content: center;
    }
  }
}

@media (max-height: 800px) {
  .about-screen-container {

    &.partners {
      justify-content: flex-end;
      --padding-right: 16px;
    }

    &.values {
      justify-content: flex-end;
    }
    &.im-invest {
      justify-content: center;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .main-container {
    padding-bottom: 86px;
  }

  .about-screen-container {
    justify-content: flex-end;

    &.values {
      justify-content: center;
    }

    &.im-invest {
      justify-content: flex-end;
    }
  }
}

@media (max-width: 1133px) {
.main-container {
  padding-bottom: 72px;
  justify-content: flex-end;
}

  .about-screen-container {

    &.partners {
      --padding-right: 32px;
    }

    &.im-invest {
      justify-content: flex-end;
    }
  }
}

@media (max-width: 769px) {
  .main-container {
    padding-bottom: 48px;
    justify-content: flex-end;
  }

  .about-screen-container {

    &.im-legal {
      padding-bottom: 48px;
    }


    &.values {
      padding-bottom: 56px;
    }

    &.im-invest {
      justify-content: flex-end;
    }
  }
}

@media (max-width: 769px) and (min-height: 720px) and (max-height: 1000px){
  .main-container {
    padding-bottom: 56px;
    justify-content: flex-end;
  }

  .about-screen-container {

    &.im-legal {
      padding-bottom: 50px;
    }

    &.values {
      padding-bottom: 28px;
    }

    &.partners {
      justify-content: center;
    }

    &.im-invest {
      justify-content: flex-end;
    }
  }
}

@media (max-width: 375px){
  .main-container {
    padding-bottom: 48px;
    justify-content: flex-end;
  }

  .about-screen-container {

    &.values {
      padding-bottom: 30px;
    }

    &.partners {
      --padding-right: 32px;
    }

    &.im-invest {
      justify-content: flex-end;
    }
  }
}

@media (max-width: 375px) {

  .about-screen-container {

    &.im-legal {
      padding-bottom: 50px;
    }
  }
}

@media (max-width: 375px) and (min-height: 630px) {
  .main-container {
    padding-bottom: 48px;
    justify-content: flex-end;
  }

  .about-screen-container {

    &.im-legal {
      padding-bottom: 46px;
    }

    &.values {
      padding-bottom: 40px;
    }

    &.partners {
      --padding-right: 32px;
    }

    &.im-invest {
      justify-content: flex-end;
    }
  }
}


@media (max-width: 320px) {

  .about-screen-container {
    &.partners {
      padding-bottom: 32px;
    }
    &.im-legal {
      padding-bottom: 44px;
    }
  }
}
</style>