import { createStore } from 'vuex'
import {newsModules} from "@/store/newsModule";
import {personModules} from "@/store/personModule";
import {caseModules} from "@/store/caseModule";
import {industryModules} from "@/store/industryModule";
import {api} from "@/providers/api";
import {practiceModules} from "@/store/practiceModules";
import {serviceModules} from "@/store/serviceModules";
import {partnersModule} from "@/store/partnersModule";
import {ratingModule} from "@/store/ratingModules";
export default createStore({
  state: {
    activeColor: '-light',

    allowTouchMoveState: false,

    isLoadingLang: false,

    activeMainSlide: 0,
    activeAboutSlide: 0,
    activeStartSlide: 0,
    isLoadingImg: false,

    activeMobile: true,
    activeMobileMenu: false,

    activeMouseScroll: true,


    returnIndexActive: -1,
    changeActiveMainSlide: false,
    changeActiveSubSlide: false,

    isLoading: true,

    activeModalFilter: false,
    activeSubPage: false,
    activeCookieModal: false,

    activePost: {},

  },

  getters: {
    getActivePost: (state) => {
      return state.activePost
    },
  },

  mutations: {
    SET_IS_LOADING: (state, payload) => {
      state.isLoading = payload
    },

    SET_IS_LOADING_IMG: (state, payload) => {
      state.isLoadingImg = payload
    },

    SET_IS_LOADING_LANG: (state, payload) => {
      state.isLoadingLang = payload
    },

    SET_ACTIVE_MOUSE_SCROLL: (state, payload) => {
      state.activeMouseScroll = payload
    },


    SET_ACTIVE_COLOR: (state, payload) => {
      state.activeColor = payload
    },

    SET_ACTIVE_MAIN_SLIDE: (state, payload) => {
      state.activeMainSlide = payload
    },

    SET_ACTIVE_START_SLIDE: (state, payload) => {
      state.activeStartSlide = payload
    },

    SET_ACTIVE_ABOUT_SLIDE: (state, payload) => {
      state.activeAboutSlide = payload
    },


    SET_RETURN_INDEX_ACTIVE: (state, payload) => {
      state.returnIndexActive = payload
    },


    SET_ALLOW_TOUCH_MOVE_STATE: (state, payload) => {
      state.allowTouchMoveState = payload
    },

    SET_ACTIVE_SUB_PAGE: (state, payload) => {
      state.activeSubPage = payload
    },

    SET_ACTIVE_MODAL_FILTER: (state, payload) => {
      state.activeModalFilter = payload
    },



    SET_ACTIVE_COOKIE_MODAL: (state, payload) => {
      state.activeCookieModal = payload
    },


    SET_CHANGE_ACTIVE_MAIN_SLIDE: (state, payload) => {
      state.changeActiveMainSlide = payload
    },

    SET_CHANGE_ACTIVE_SUB_SLIDE: (state, payload) => {
      state.changeActiveSubSlide = payload
    },

    SET_ACTIVE_POST: (state, payload) => {
      state.activePost = payload
    },

    SET_ACTIVE_MOBILE: (state, payload) => {
      state.activeMobile = payload
    },
    SET_ACTIVE_MOBILE_MENU: (state, payload) => {
      state.activeMobileMenu = payload
    },
  },


  actions: {
    IS_LOADING: (context, payload) => {
      context.commit('SET_IS_LOADING', payload)
    },

    IS_LOADING_IMG: (context, payload) => {
      context.commit('SET_IS_LOADING_IMG', payload)
    },

    IS_LOADING_LANG: (context, payload) => {
      context.commit('SET_IS_LOADING_LANG', payload)
    },


    ACTIVE_MOUSE_SCROLL: (context, payload) => {
      context.commit('SET_ACTIVE_MOUSE_SCROLL', payload)
    },

    ACTIVE_COLOR: (context, payload) => {
      context.commit('SET_ACTIVE_COLOR', payload)
    },

    ACTIVE_MAIN_SLIDE: (context, payload) => {
      context.commit('SET_ACTIVE_MAIN_SLIDE', payload)
    },

    ACTIVE_START_SLIDE: (context, payload) => {
      context.commit('SET_ACTIVE_START_SLIDE', payload)
    },


    ACTIVE_ABOUT_SLIDE: (context, payload) => {
      context.commit('SET_ACTIVE_ABOUT_SLIDE', payload)
    },


    RETURN_INDEX_ACTIVE: (context, payload) => {
      context.commit('SET_RETURN_INDEX_ACTIVE', payload)
    },

    ALLOW_TOUCH_MOVE_STATE: (context, payload) => {
      context.commit('SET_ALLOW_TOUCH_MOVE_STATE', payload)
    },

    ACTIVE_SUB_PAGE: (context, payload) => {
      context.commit('SET_ACTIVE_SUB_PAGE', payload)
    },


    ACTIVE_MODAL_FILTER: (context, payload) => {
      context.commit('SET_ACTIVE_MODAL_FILTER', payload)
    },

    ACTIVE_COOKIE_MODAL: (context, payload) => {
      context.commit('SET_ACTIVE_COOKIE_MODAL', payload)
    },


    CHANGE_ACTIVE_MAIN_SLIDE: (context, payload) => {
      context.commit('SET_CHANGE_ACTIVE_MAIN_SLIDE', payload)
    },

    CHANGE_ACTIVE_SUB_SLIDE: (context, payload) => {
      context.commit('SET_CHANGE_ACTIVE_SUB_SLIDE', payload)
    },

    ACTIVE_MOBILE: (context, payload) => {
      context.commit('SET_ACTIVE_MOBILE', payload)
    },
    ACTIVE_MOBILE_MENU: (context, payload) => {
      context.commit('SET_ACTIVE_MOBILE_MENU', payload)
    },

    async getActivePost({commit}, { slug, params  } = {slug, params: {}}) {
      let response = await api.main.mainControllerGetPostSlug(slug,{
        ...params
      }).then(r => r.data);
      commit('SET_ACTIVE_POST', response)
    },

  },
  modules: {
    news: newsModules,
    persons: personModules,
    case: caseModules,
    industry: industryModules,
    practice: practiceModules,
    service: serviceModules,
    partners: partnersModule,
    rating: ratingModule
  }
})
