<template>
    <div class="team-card-container"
         :class="{'active-card' : activeCard}"
         @click="clickToDescription"
         @mouseenter="focusTrue"
         @mouseleave="focusFalse">
      <div class="person-info-container">
        <div class="person-info-content"
             :ref="'parentBlock' + infoPerson?.id"
             :class="{'active-description' : focusParam}">
          <div class="title-container main" ref="titleBlock">
            <large-title v-for="(item, index) in nameSlice" :key="index" :active-cormorant="true">
              {{ item }}
            </large-title>
          </div>

          <div class="title-container sub" ref="subTitleBlock">
            <main-paragraph v-if="activeProfilesLang?.position !== ''">
              {{ activeProfilesLang?.position }}
            </main-paragraph>
            <main-paragraph v-if="activeProfilesLang?.status !== ''">
              {{ activeProfilesLang?.status }}
            </main-paragraph>
          </div>

          <transition name="fade-translate-y-revert">
            <div class="title-container description" v-show="activeDescription">
              <main-paragraph class="title-container description" >
                {{ activeProfilesLang?.shortCaption }}
              </main-paragraph>
            </div>
          </transition>

          <div class="title-container description" style="opacity: 0; cursor: default; position: absolute"
               ref="descriptionBlock">
            <main-paragraph>
              {{ activeProfilesLang?.shortCaption }}
            </main-paragraph>
          </div>
        </div>


        <div class="controller-container">
          <p class="controller-button-content"
             @click.self="routToTeamPage(typeScroll[index], infoPerson.slug)"
             v-for="(item, index) in $tm('teamInfo.buttonController')"
             :key="item.id">
            {{item.name}}
          </p>
        </div>
      </div>

      <div class="person-img-container">
        <transition name="fade-opacity-lazy">
          <img v-show="!load" @load="loadImg" :src="infoPerson?.profilePhoto" alt="avatar">
        </transition>
        <transition name="fade-opacity-lazy">
          <img style="filter: blur(10px)" v-if="load" :src="infoPerson?.profilePhoto" alt="avatar">
        </transition>
      </div>
    </div>

</template>

<script>
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import {mapActions} from "vuex";

export default {
  name: "CardTeam",
  components: {LargeTitle, MainParagraph},
  props: {
    infoPerson: {
        type: Object,
        required: true
    },
    activeIndex: {
        type: Boolean,
      required: true
    },
  },
  data() {
    return {
      activeCard: false,
      focusParam: false,
      activeDescription: false,
      activeAnimation: '',
      load: true,
      typeScroll: ['about', 'cases', 'news'],
      typeHref: ['partners', 'exes', 'empl'],
      activeClick: false
    }
  },

  mounted() {
    setTimeout(() => {
      this.getBlockHeight();
    }, 500)
    window.addEventListener('resize', this.getBlockHeight)
    if(this.activeMobile) {
      setTimeout(() => {
        this.activeCard = true
      }, 750)
      window.addEventListener('click', this.closeOptions);
    } else {
      this.activeCard = true
    }
  },

  unmounted() {
    window.removeEventListener('click', this.closeOptions);
    window.removeEventListener('resize', this.getBlockHeight)
  },


  methods: {
    ...mapActions(['ACTIVE_SUB_PAGE']),

    getBlockHeight() {
         const parentBlockElement = this.$refs['parentBlock' + this.infoPerson?.id]
          if(parentBlockElement !== null) {
          const blockElement = this.$refs.descriptionBlock.clientHeight;
          const titleBlockElement = this.$refs.titleBlock.clientHeight
          const subTitleBlockElement = this.$refs.subTitleBlock.clientHeight
            parentBlockElement.style.setProperty('--height-description', `${blockElement}px`);
            parentBlockElement.style.setProperty('--height-title', `${titleBlockElement}px`);
            parentBlockElement.style.setProperty('--height-sub-title', `${subTitleBlockElement}px`);
        }
    },

    loadImg() {
      this.load = false
    },

    closeOptions(event) {
      if (!this.$refs['parentBlock']?.contains(event.target)) {
        this.focusParam = false;
      }
    },

    clickToDescription() {
      // this.focusParam = !this.focusParam
      this.routToTeamPage(this.typeScroll[0], this.infoPerson?.slug)
    },

    routToTeamPage(type, slug) {
        if(!this.activeClick) {
          this.activeClick = true;
          this.ACTIVE_SUB_PAGE(true)
          this.$router.push({name: 'person-page', params: {slug: slug, slide: type, typeUser: this.typeHref[this.activeTeamIndex - 1]}})
          setTimeout(() => {
            this.activeClick = false;
          }, 100)
        }
    }
  },

  watch: {
    focusParam() {
      this.activeDescription = this.focusParam;
    },
  },

  computed: {
    nameSlice() {
      return this.activeProfilesLang?.fullName.split(' ');
    },

    activeTeamIndex() {
      return this.$store.state.persons.activeTeamSlide;
    },

    activeProfilesLang() {
      return this.infoPerson?.profiles.find(item => item.lang === String(this.$i18n.locale))
    },

    activeRolesLang() {
      let result = [];
      if(this.$i18n.locale === 'ru') {
        this.infoPerson?.roles.forEach(item => {
          switch(item) {
            case "PARTNER":
              result.push("Управляющий партнер");
              break;
            case "LEADER":
              result.push("Учредитель");
              break;
            case "EMPLOYEES":
              result.push("Сотрудник");
              break;
          }
        })
      } else {
        this.infoPerson?.roles.forEach(item => {
          switch (item) {
            case "PARTNER":
              result.push("Partners");
              break;
            case "LEADER":
              result.push("Executives");
              break;
            case "EMPLOYEES":
              result.push("Employees");
              break;
          }
        })
      }
      return result
    }
  }
}
</script>

<style scoped lang="scss">


.team-card-container {
  margin: -1px;
  padding: 1px;
  overflow: hidden;
  background-color: var(--color-card);
  position: relative;
  height: 100%;
  z-index: 5;
  transition: box-shadow .6s ease, opacity 1s ease;
  cursor: pointer;
  opacity: 0;

  &.active-card {
    opacity: 1;
  }

  &:hover {
    box-shadow: 0px 4px 35px 0px rgba(var(--color-box-shadow-box));
  }

  .person-img-container {
    position: relative;
    z-index: 2;
    width: 100%;
    & img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .person-info-container {
    opacity: 1 !important;
    visibility: visible !important;
    position: absolute;
    z-index: 4;
    bottom: 0;

    .person-info-content {
      display: flex;
      flex-direction: column;
      transition: height .8s ease;
      row-gap: var(--custom-row-gap);
      --calc-default-height: calc(var(--height-title) + var(--height-sub-title) + var(--custom-row-gap));
      height: var(--calc-default-height);

      &.active-description {
        height: calc(var(--calc-default-height) + var(--height-description) + var(--padding-top-controller));
      }

      .title-container {
        &.main {
          .text-content {
            line-height: 97%;
          }
        }

        .text-content {
          line-height: 150%;
        }

        &.description {
          position: relative;
          height: fit-content;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }



    .controller-container {
      background: linear-gradient(180deg, rgba(34, 34, 59, 0.00) 0%, var(--color-background) 90%);;
      position: relative;
      z-index: 5;
      padding-top: var(--padding-top-controller);
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .controller-button-content {
        font-family: 'CormorantUnicase-Regular', sans-serif;
        text-transform: uppercase;
        cursor: pointer;
        transition: opacity .8s ease;
        opacity: .6;
        
        &:hover {
          opacity: 1;
        }
      }
    }
  }



  &:after {
    opacity: 1 !important;
    visibility: visible !important;
    content: "" !important;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    bottom: 0;
    right: 0;
    pointer-events: none;
    background: linear-gradient(180deg, rgba(34, 34, 59, 0.00) 35%, var(--color-background) 85%);
  }
}

@media (max-width: 2560px){
  .team-card-container {
    border-radius: 6px;
    --padding-top-controller: 26px;
    max-width: 686px;

    .person-info-container {
      left: 40px;
      right: 40px;


      .person-info-content {
        --custom-row-gap: 16px;
        --height-parent: 182px;

        .title-container {
          &.main {
            .text-content {
              letter-spacing: 10.4px;
              font-size: 52px;
            }
          }
        }
      }


      .controller-container {
        padding-bottom: 40px;

        .controller-button-content {
          letter-spacing: 3px;
          font-size: 15px
        }
      }
    }
  }
}

@media (max-width: 2240px) {
  .team-card-container {
    border-radius: 5px;
    --padding-top-controller: 28px;
    max-width: 630px;

    .person-info-container {
      left: 36px;
      right: 36px;


      .person-info-content {
        --custom-row-gap: 14px;
        --height-parent: 170px;

        .title-container {
          &.main {
            .text-content {
              letter-spacing: 9.4px;
              font-size: 47px;
            }
          }
        }
      }


      .controller-container {
        padding-bottom: 36px;

        .controller-button-content {
          letter-spacing: 2.8px;
          font-size: 14px
        }
      }
    }
  }
}


@media (max-width: 1920px) {
  .team-card-container {
    --padding-top-controller: 24px;
    max-width: 540px;

    .person-info-container {
      left: 32px;
      right: 32px;


      .person-info-content {
        --custom-row-gap: 12px;
        --height-parent: 144px;

        .title-container {
          &.main {
            .text-content {
              letter-spacing: 9.4px;
              font-size: 40px;
            }
          }
        }
      }


      .controller-container {
        padding-bottom: 32px;

        .controller-button-content {
          letter-spacing: 2.8px;
          font-size: 12px;
        }
      }
    }
  }
}


@media (max-width: 1600px) {
  .team-card-container {
    border-radius: 4px;
    --padding-top-controller: 16px;
    max-width: 360px;

    .person-info-container {
      left: 20px;
      right: 20px;


      .person-info-content {
        --height-parent: 94px;
        --custom-row-gap: 8px;
        .title-container {
          &.main {
            .text-content {
              letter-spacing: 5.2px;
              font-size: 26px;
            }
          }

          .text-content {
            font-size: 12px;
          }
        }

        .text-content {
          font-size: 12px;
        }
      }


      .controller-container {
        padding-bottom: 20px;

        .controller-button-content {
          letter-spacing: 1.6px;
          font-size: 8px;
        }
      }
    }
  }
}
@media (max-height: 800px) {
  .team-card-container {
    --padding-top-controller: 16px;
    max-width: 360px;


    .person-info-container {
      left: 20px;
      right: 20px;


      .person-info-content {
        --height-parent: 94px;
        --custom-row-gap: 8px;
        .title-container {
          &.main {
            .text-content {
              letter-spacing: 5.2px;
              font-size: 26px;
            }
          }

          .text-content {
            font-size: 12px;
          }
        }

        .text-content {
          font-size: 12px;
        }
      }


      .controller-container {
        padding-bottom: 20px;

        .controller-button-content {
          letter-spacing: 1.6px;
          font-size: 8px;
        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .team-card-container {
    --padding-top-controller: 20px;
    max-width: 466px;


    .person-info-container {
      left: 28px;
      right: 28px;


      .person-info-content {
        --height-parent: 128px;
        --custom-row-gap: 10px;
        .title-container {
          &.main {
            .text-content {
              letter-spacing: 7.2px;
              font-size: 26px;
            }
          }

          .text-content {
            font-size: 16px;
          }
        }

        .text-content {
          font-size: 16px;
        }
      }


      .controller-container {
        padding-bottom: 28px;

        .controller-button-content {
          letter-spacing: 2px;
          font-size: 10px;
        }
      }
    }
  }
}

@media (max-width: 1133px) {
  .team-card-container {
    --padding-top-controller: 18px;
    max-width: 318px;

    .person-info-container {
      left: 18px;
      right: 18px;


      .person-info-content {
        --height-parent: 88px;
        --custom-row-gap: 7px;
        .title-container {
          &.main {
            .text-content {
              letter-spacing: 4.8px;
              font-size: 24px;
            }
          }

          .text-content {
            font-size: 11px;
          }
        }

        .text-content {
          font-size: 11px;
        }
      }


      .controller-container {
        padding-bottom: 18px;

        .controller-button-content {
          letter-spacing: 1.4px;
          font-size: 7px;
        }
      }
    }
  }
}

@media (max-width: 769px) {
  .team-card-container {
    border-radius: 4px;
    --padding-top-controller: 16px;
    max-width: 328px;


    &:hover {
      box-shadow: 0 0 0 0 rgba(0,0,0,0);
    }

    .person-info-container {
      left: 22px;
      right: 22px;


      .person-info-content {
        --height-parent: 0px;
        --custom-row-gap: 16px;
        .title-container {
          &.main {
            .text-content {
              font-size: 26px;
              font-weight: 400;
              line-height: 97.1%;
              letter-spacing: 5.2px;
            }
          }

          .text-content {
            font-size: 12px;
          }
        }

        .text-content {
          font-size: 12px;
        }
      }


      .controller-container {
        padding-bottom: 22px;

        .controller-button-content {
          text-transform: none;
          font-family: 'Raleway', sans-serif;
          font-weight: 400;
          letter-spacing: normal;
          font-size: 12px;
        }
      }
    }
    &:after {
      height: 75%;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .team-card-container {
    --padding-top-controller: 16px;
    max-width: 358px;

    .person-info-container {
      left: 29px;
      right: 29px;
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  .team-card-container {
    border-radius: 4px;
    --padding-top-controller: 16px;
    max-width: 316px;


    .person-info-container {
      left: 22px;
      right: 22px;


      .person-info-content {
        --height-parent: 0px;
        --custom-row-gap: 16px;
        .title-container {
          &.main {
            .text-content {
              font-size: 26px;
              font-weight: 400;
              line-height: 97.1%;
              letter-spacing: 5.2px;
            }
          }

          .text-content {
            font-size: 10px;
          }
        }

        .text-content {
          font-size: 10px;
        }
      }


      .controller-container {
        padding-bottom: 16px;

        .controller-button-content {
          text-transform: none;
          font-family: 'Raleway', sans-serif;
          font-weight: 400;
          letter-spacing: normal;
          font-size: 10px;
        }
      }
    }
    &:after {
      height: 75%;
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .team-card-container {
    border-radius: 4px;
    --padding-top-controller: 16px;
    max-width: 328px;


    .person-info-container {
      left: 22px;
      right: 22px;


      .person-info-content {
        --height-parent: 0px;
        --custom-row-gap: 16px;
        .title-container {
          &.main {
            .text-content {
              font-size: 26px;
              font-weight: 400;
              line-height: 97.1%;
              letter-spacing: 5.2px;
            }
          }

          .text-content {
            font-size: 12px;
          }
        }

        .text-content {
          font-size: 12px;
        }
      }


      .controller-container {
        padding-bottom: 22px;

        .controller-button-content {
          text-transform: none;
          font-family: 'Raleway', sans-serif;
          font-weight: 400;
          letter-spacing: normal;
          font-size: 12px;
        }
      }
    }
    &:after {
      height: 75%;
    }
  }
}


@media (max-width: 320px) {
  .team-card-container {
    border-radius: 3px;
    --padding-top-controller: 16px;
    max-width: 266px;


    .person-info-container {
      left: 16px;
      right: 16px;


      .person-info-content {
        --height-parent: 0px;
        --custom-row-gap: 16px;
        .title-container {
          &.main {
            .text-content {
              font-size: 22px;
              font-weight: 400;
              line-height: 97.1%;
              letter-spacing: 4.4px;
            }
          }

          .text-content {
            font-size: 9px;
          }
        }

        .text-content {
          font-size: 9px;
        }
      }


      .controller-container {
        padding-bottom: 22px;

        .controller-button-content {
          text-transform: none;
          font-family: 'Raleway', sans-serif;
          font-weight: 400;
          letter-spacing: normal;
          font-size: 9px;
        }
      }
    }
    &:after {
      height: 100%;
    }
  }
}



@media (min-width: 2561px) {
  .team-card-container {
    border-radius: 8px;
    --padding-top-controller: 56px;
    max-width: 1192px;


    .person-info-container {
      left: 72px;
      right: 72px;


      .person-info-content {
        --custom-row-gap: 28px;
        --height-parent: 336px;

        .title-container {
          &.main {
            .text-content {
              letter-spacing: 18.8px;
              font-size: 94px;
            }
          }
        }
      }


      .controller-container {
        padding-bottom: 72px;

        .controller-button-content {
          letter-spacing: 5.6px;
          font-size: 28px;
        }
      }
    }
  }
}

</style>