<template>
  <div class="im-invest-screen-container">

    <div class="main-paragraph-container">
      <transition-group name="fade-opacity-1">
      <main-paragraph v-for="(item,index) in paragraphInfo"
                      v-show="isVisible(index)"
                      :key="index">
        {{item}}
      </main-paragraph>
      </transition-group>
    </div>

    <transition name="fade-opacity-animation" >
      <div class="background-image-container" v-if="activeBackground">
        <img v-if="activeColor" :src="'/about-screen/' + backgroundImg + '-dark.webp'" alt="icon">
        <img v-else :src="'/about-screen/' + backgroundImg + '-light.webp'" alt="icon">
      </div>
    </transition>

  </div>
</template>

<script>
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import Loader from "@/components/Loader.vue";
export default {
  name: "IMInvestScreen",
  components: {Loader, SubParagraph, MainParagraph},
  data() {
    return {
      backgroundImg: 'm-png',
      activeItem: -1,
      activeBackground: false
    }
  },

  mounted() {
    this.showNextItem();
    setTimeout(() => {
      this.activeBackground = true
    }, 1000)
  },
  computed: {
    paragraphInfo() {
      return this.$tm('aboutScreen.imInvest.mainTitle')
    }
  },
  methods: {
    showNextItem() {
      if (this.activeItem < this.paragraphInfo.length) {
        setTimeout(() => {
          this.activeItem++
          this.showNextItem();
        }, 500)
      } else {
      }
    },

    isVisible(index) {
      return index <= this.activeItem
    },
  }
}
</script>

<style scoped lang="scss">
.im-invest-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 472px;
  height: 100%;

  .main-paragraph-container {
    display: inherit;
    flex-direction: column;
    row-gap: 32px;
    position: relative;
    z-index: 2;
    height: 297px;
    max-width: 644px;

    .text-content {
      line-height: 150%;
    }
  }

  .background-image-container {
    height: 100%;
    position: absolute;
    width: 100%;
    right: 0;
    top: 0;
    z-index: 1;

    & img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 2560px){
  .im-invest-screen-container {
    max-height: 550px;

    .main-paragraph-container {
      row-gap: 32px;
      height: 112px;
      max-width: 860px;
    }

    .background-image-container {
      max-width: 1608px;
      top: -20%;
      & img {
        width: 110%;
        height: 150%;
      }
    }
  }
}

@media (max-width: 2240px) {
  .im-invest-screen-container {
    max-height: 550px;

    .main-paragraph-container {
      row-gap: 32px;
      height: 352px;
      max-width: 752px;
    }

    .background-image-container {
      max-width: 1208px;
    }
  }
}


@media (max-width: 1920px) {
  .im-invest-screen-container {
    max-height: 472px;

    .main-paragraph-container {
      row-gap: 32px;
      height: 297px;
      max-width: 644px;
    }

    .background-image-container {
      max-width: 1408px;
      top: -20%;
      & img {
        width: 125%;
        height: 150%;
      }
    }
  }
}

@media (max-width: 1600px) {
  .im-invest-screen-container {
    max-height: 315px;

    .main-paragraph-container {
      row-gap: 18px;
      height: 50px;
      max-width: 494px;
    }

    .background-image-container {
      max-width: 808px;
      top: -15%;
      & img {
        width: 104%;
        height: 130%;
      }
    }
  }
}
@media (max-height: 800px) {
  .im-invest-screen-container {
    max-height: 315px;

    .main-paragraph-container {
      row-gap: 18px;
      height: 100px;
      max-width: 506px;
    }

    .background-image-container {
      max-width: 808px;
      top: -10%;
      & img {
        width: 120%;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .im-invest-screen-container {
    max-height: 450px;

    .main-paragraph-container {
      row-gap: 18px;
      height: 245px;
      max-width: 494px;
    }

    .background-image-container {
      max-width: 985px;
      top: -10%;
      & img {
        width: 115%;
      }
    }
  }
}

@media (max-width: 1133px) {
  .im-invest-screen-container {
    justify-content: center;
    max-height: 500px;

    .main-paragraph-container {
      row-gap: 18px;
      height: 187px;
      max-width: 393px;
    }

    .background-image-container {
      max-width: 712px;
      top: -5%;
      & img {
        width: 105%;
      }
    }
  }
}

@media (max-width: 769px) {
  .im-invest-screen-container {
    justify-content: flex-start;
    max-height: 100%;

    .main-paragraph-container {
      row-gap: 18px;
      height: auto;
      max-width: 100%;

      .text-content {
        line-height: 140%;
      }
    }
    .background-image-container {
      max-width: 100%;
      top: auto;
      bottom: -25%;
      right: 5%;
      & img {
        width: 100%;
      }
    }
  }
}

@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  .im-invest-screen-container {
    justify-content: flex-start;
    max-height: 100%;

    .main-paragraph-container {
      row-gap: 16px;
      height: auto;
      max-width: 100%;
    }

    .background-image-container {
      max-width: 270px;
      right: 20%;
      bottom: -30%;
      & img {
        width: 100%;
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .im-invest-screen-container {
    justify-content: flex-start;
    max-height: 100%;

    .main-paragraph-container {
      row-gap: 18px;
      height: auto;
      max-width: 100%;
    }

    .background-image-container {
      right: 5%;
      bottom: -30%;
      & img {
        width: 100%;
      }
    }
  }
}


@media (max-width: 320px) {
  .im-invest-screen-container {
    justify-content: flex-start;
    max-height: 100%;

    .main-paragraph-container {
      row-gap: 8px;
      height: auto;
      max-width: 100%;
    }

    .background-image-container {
      right: 10%;
      bottom: -35%;
      & img {
        width: 100%;
      }
    }
  }
}


@media (min-width: 2561px) {
  .im-invest-screen-container {
    max-height: 1101px;

    .main-paragraph-container {
      row-gap: 56px;
      height: 850px;
      max-width: 1502px;
    }

    .background-image-container {
      max-width: 2814px;
      top: -10%;
      & img {
        height: 150%;
      }
    }
  }
}

</style>