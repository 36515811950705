<template>
  <div class="person-mini-card-container" @click="routToPerson" :class="{'active-super-mini' : activeSuperMini && !activeMobile}">
    <div class="person-img-container">
      <img :src="activeAvatar" alt="user-avatar" @load="loadImg"/>
    </div>
    <div class="text-info-content">
      <sub-paragraph :active-cormorant="false">
        {{ sliceName }}
      </sub-paragraph>
    </div>
  </div>
</template>

<script>
import ButtonLarge from "@/components/shared/ButtonLarge.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import {mapActions} from "vuex";

export default {
  name: "CardPersonMini",
  components: {SubParagraph, ButtonLarge},
  props: {
    personInfo: {
      type: Object,
      required: true
    },
    activeSuperMini: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sliceName() {
      return this.personInfo?.profiles.find(item => item.lang === this.$i18n.locale)?.fullName.replace(' ', '\n').trim();
      // return this.personInfo?.fullName.replace(' ', '\n')
    },

    activeAvatar() {
      if(this.personInfo?.avatar !== null && this.personInfo?.avatar !== undefined) {
        return this.activeUrlImg + (this.personInfo?.avatar?.name || this.personInfo?.avatar)
      } else {
        return this.getUrlTeam('avatar-default.webp')
      }
    },


    activeUrlImg() {
      let url = window.location.href
      if(!this.activeSuperMini) {
        if(url.includes('imlegal.ru')) {
          return 'https://s3.imlegal.ru/im-legal/'
        } else {
          return 'https://s3.roseblade.xyz/im-legal-dev/'
        }
      } else {
        return ''
      }

    }
  },
  methods: {
    ...mapActions(['ACTIVE_SUB_PAGE']),

    loadImg() {
      this.$emit('loadImg')
    },

    routToPerson() {
      let href;
      switch (this.personInfo?.roles[0]) {
        case "PARTNER":
          href = 'partners'
          break;
        case "LEADER":
          href = 'exes'
          break;
        case "EMPLOYEES":
          href = 'empl'
          break;
      }
      this.$router.push({name: 'person-page', params: {slug: this.personInfo?.slug, slide: 'about', typeUser: href}})
    }
  },
}
</script>

<style scoped lang="scss">
.person-mini-card-container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;

  &.active-super-mini {
    cursor: default;
    .text-info-content {
      background-color: var(--color-card-light);

      &:hover {
        box-shadow: none;
      }
    }
  }

  .text-info-content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--color-card);
    transition: box-shadow .6s ease;
    &:hover {
      box-shadow: 0px 4px 35px 0px rgba(var(--color-box-shadow-box));
    }

    .text-content {
      font-variant: small-caps;
      font-weight: 500;
      line-height: normal;
    }
  }

  .person-img-container {
    position: absolute;
    border-radius: 50%;
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 2560px){
  .person-mini-card-container {
    max-width: 310px;
    height: 74px;

    &.active-super-mini {
      max-width: 170px;
      height: 42px;

      .person-img-container {
        margin-right: 24px;
        width: 42px;
        height: 42px;
      }

      .text-info-content {
        padding-left: 66px;
        border-radius: 21px 5px 5px 21px;

        .text-content {
          font-size: 9px;
        }
      }
    }

    .person-img-container {
      width: 74px;
      height: 74px;
    }

    .text-info-content {
      padding-left: 108px;
      border-radius: 38px 9px 9px 38px;

      .text-content {
        font-size: 16px;
      }
    }
  }
}


@media (max-width: 1920px) {
  .person-mini-card-container {
    max-width: 274px;
    height: 64px;

    &.active-super-mini {
      max-width: 170px;
      height: 42px;

      .person-img-container {
        margin-right: 24px;
        width: 42px;
        height: 42px;
      }

      .text-info-content {
        padding-left: 66px;
        border-radius: 21px 5px 5px 21px;

        .text-content {
          font-size: 9px;
        }
      }
    }

    .person-img-container {
      width: 64px;
      height: 64px;
    }

    .text-info-content {
      padding-left: 96px;
      border-radius: 32px 8px 8px 32px;

      .text-content {
        font-size: 14px;
      }
    }
  }
}


@media (max-width: 1600px) {
  .person-mini-card-container {
    max-width: 170px;
    height: 42px;

    &.active-super-mini {
      max-width: 98px;
      height: 30px;

      .person-img-container {
        margin-right: 8px;
        width: 30px;
        height: 30px;
      }

      .text-info-content {
        padding-left: 38px;
        border-radius: 20px 5px 5px 20px;

        .text-content {
          font-size: 8px;
        }
      }
    }

    .person-img-container {
      width: 42px;
      height: 42px;
    }

    .text-info-content {
      padding-left: 66px;
      border-radius: 21px 5px 5px 21px;

      .text-content {
        font-size: 9px;

      }
    }
  }
}
@media (max-height: 800px) {
  .person-mini-card-container {
    max-width: 170px;
    height: 42px;

    &.active-super-mini {
      max-width: 98px;
      height: 30px;

      .person-img-container {
        margin-right: 8px;
        width: 30px;
        height: 30px;
      }

      .text-info-content {
        padding-left: 38px;
        border-radius: 20px 5px 5px 20px;

        .text-content {
          font-size: 8px;
        }
      }
    }

    .person-img-container {
      width: 42px;
      height: 42px;
    }

    .text-info-content {
      padding-left: 66px;
      border-radius: 21px 5px 5px 21px;

      .text-content {
        font-size: 9px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .person-mini-card-container {
    max-width: 190px;
    height: 44px;

    &.active-super-mini {
      max-width: 150px;
      height: 40px;

      .person-img-container {
        margin-right: 16px;
        width: 40px;
        height: 40px;
      }

      .text-info-content {
        padding-left: 56px;
        border-radius: 20px 5px 5px 20px;

        .text-content {
          font-size: 8px;
        }
      }
    }

    .person-img-container {
      width: 44px;
      height: 44px;
    }

    .text-info-content {
      padding-left: 68px;
      border-radius: 30px 6px 6px 30px;

      .text-content {
        font-size: 10px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .person-mini-card-container {
    max-width: 150px;
    height: 40px;


    &.active-super-mini {
      max-width: 92px;
      height: 27px;

      .person-img-container {
        margin-right: 8px;
        width: 27px;
        height: 27px;
      }

      .text-info-content {
        padding-left: 35px;
        border-radius: 20px 5px 5px 20px;

        .text-content {
          font-size: 6px;
        }
      }
    }

    .person-img-container {
      width: 40px;
      height: 40px;
    }

    .text-info-content {
      padding-left: 56px;
      border-radius: 20px 5px 5px 20px;

      .text-content {
        font-size: 8px;
      }
    }
  }
}

@media (max-width: 769px) {
  .person-mini-card-container {
    max-width: 116px;
    height: 34px;


    .text-info-content {
      background-color: var(--color-background);
    }

    .person-img-container {
      width: 34px;
      height: 34px;
    }

    .text-info-content {
      padding-left: 50px;
      border-radius: 20px 5px 5px 20px;


      &:hover {
        box-shadow: 0 0 0 0 rgba(0,0,0,0);
      }

      .text-content {
        font-size: 8px;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .person-mini-card-container {
    max-width: 126px;
    height: 36px;

    .person-img-container {
      width: 36px;
      height: 36px;
    }

    .text-info-content {
      padding-left: 52px;
      border-radius: 20px 5px 5px 20px;

      .text-content {
        font-size: 8px;
      }
    }
  }
}


@media (max-width: 375px){
  .person-mini-card-container {
    max-width: 116px;
    height: 34px;

    &.active-super-mini {
      max-width: 98px;
      height: 30px;

      .person-img-container {
        margin-right: 8px;
        width: 30px;
        height: 30px;
      }

      .text-info-content {
        padding-left: 38px;
        border-radius: 20px 5px 5px 20px;

        .text-content {
          font-size: 8px;
        }
      }
    }

    .person-img-container {
      width: 34px;
      height: 34px;
    }

    .text-info-content {
      padding-left: 50px;
      border-radius: 20px 5px 5px 20px;

      .text-content {
        font-size: 8px;
      }
    }
  }
}



@media (max-width: 320px) {
  .person-mini-card-container {
    max-width: 98px;
    height: 30px;


    .person-img-container {
      width: 30px;
      height: 30px;
    }

    .text-info-content {
      padding-left: 38px;
      border-radius: 20px 5px 5px 20px;

      .text-content {
        font-size: 8px;
      }
    }
  }
}



@media (min-width: 2561px) {
  .person-mini-card-container {
    max-width: 535px;
    height: 151px;

    &.active-super-mini {
      max-width: 310px;
      height: 64px;

      .person-img-container {
        margin-right: 32px;
        width: 74px;
        height: 74px;
      }

      .text-info-content {
        padding-left: 106px;
        border-radius: 38px 9px 9px 38px;

        .text-content {
          font-size: 16px;
        }
      }
    }

    .person-img-container {
      margin-right: 64px;
      width: 151px;
      height: 151px;
    }

    .text-info-content {
      padding-left: 215px;
      border-radius: 80px 18px 18px 80px;
    }
  }
}

</style>