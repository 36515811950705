import {api} from "@/providers/api";
import store from "@/store/index";

export const personModules = {
    namespaced: true,
    state: () => ({
        activeInfo: [],

        activeTeamSlide: 0,
        teamInfo: [],

        activeCaseUser: [],
        activePressUser: [],
        activeUser: {},
        activeTheme: ''
    }),
    getters: {
        getActiveTypeTeam: (state) => {
          let arrRoles = state.teamInfo.map(item => item.roles);
          let countPartner = 0;
          let countLeader = 0;
          let countEmployees = 0;
          arrRoles.forEach(item => item.forEach(item => {
              switch (item) {
                  case 'EMPLOYEES':
                      countEmployees++;
                      break;
                  case 'LEADER':
                      countLeader++;
                      break;
                  case 'PARTNER':
                      countPartner++;
                      break;
              }
          }))
          return [countPartner > 0, countLeader > 0, countEmployees > 0]
        },
        usersAll: (state) => {
            return state.teamInfo;
        },
        activeUser: (state) => {
            return state.activeUser;
        },
        activeUserCaseAll: (state) => {
            return state.activeCaseUser;
        },
        activeUserPressAll: (state) => {
            return state.activePressUser;
        }
    },
    mutations: {
        SET_ACTIVE_TEAM_SLIDE: (state, payload) => {
            state.activeTeamSlide = payload
        },

        SET_USERS: (state, payload) => {
            state.teamInfo = payload
        },
        SET_ACTIVE_CASE_USER: (state, payload) => {
            state.activeCaseUser = payload
        },
        SET_ACTIVE_PRESS_USER: (state, payload) => {
            state.activePressUser = payload
        },

        SET_ACTIVE_USER: (state, payload) => {
            state.activeUser = payload
        },


        SET_THEME: (state, payload) => {
            state.activeTheme = payload
        },

    },

    actions: {
        ACTIVE_TEAM_SLIDE: (context, payload) => {
            context.commit('SET_ACTIVE_TEAM_SLIDE', payload)
        },


        async getUsers({commit}, {params } = {params: {}}) {
            const response = await api.main.mainControllerGetUsers( {
                ...params
            }).then(r => r.data);
            let indexAkhmatow = response.findIndex(item => item?.profiles.find(itemTwo => itemTwo?.fullName === 'Ахматов Мансур' || itemTwo?.fullName === 'Akhmatov Mansur'))
            let responseRemove = response.splice(indexAkhmatow, 1)[0];
            response.unshift(responseRemove)
            commit('SET_USERS', response?.filter(item => item.id !== 1))
        },

        async getCaseUser({commit}, { id, params } = {id, params: {}}) {
            const response = await api.main.mainControllerGetUserCases(id, {
                ...params
            }).then(r => r.data);
            commit('SET_ACTIVE_CASE_USER', response)
        },
        async getNewsUser({commit}, { id, params } = {id, params: {}}) {
            const response = await api.main.mainControllerGetUserPress(id, {
                ...params
            }).then(r => r.data);
            commit('SET_ACTIVE_PRESS_USER', response)
        },
        async getActiveUser({commit}, {slug, params } = {slug, params: {}}) {
             const response = await api.main.mainControllerGetUserSlug(slug, {
                ...params
            }).then(r => r.data);
            commit('SET_ACTIVE_USER', response)
        },

        async getTheme({commit}, {params } = {params: {}}) {
            const response = await api.main.mainControllerGetTheme().then(r => r.data);
            switch (response.theme) {
                case 'LIGHT':
                    localStorage.setItem('theme', 'light-theme');
                    await store.dispatch('ACTIVE_COLOR', false)
                    document.body.className = 'light-theme'
                    break;
                case 'DARK':
                    localStorage.setItem('theme', 'dark-theme');
                    await store.dispatch('ACTIVE_COLOR', true)
                    document.body.className = 'dark-theme'
                    break;
            }
        }

    }
}