<template>
    <div class="team-screen-container main-container" :class="{'active-type-team-screen' : activeTypeTeamScreen}">
      <transition name="fade-opacity-lazy" mode="out-in">
      <team-start-screen v-if="activeTeamStartScreen"
                          @openTeamSliderScreen="openTeamSliderScreen"/>
        <team-type-screen v-else-if="activeTypeTeamScreen" :active-team-type="activeTeamTypeArr"/>
      </transition>
    </div>
</template>

<script>
import TeamStartScreen from "@/components/features/team-screen/TeamStartScreen.vue";
import {mapActions, mapGetters} from "vuex";
import TeamTypeScreen from "@/components/features/team-screen/TeamTypeScreen.vue";

export default {
  name: "TeamScreen",
  components: {TeamTypeScreen, TeamStartScreen},
  data() {
    return {
      activeTeamStartScreen: true,
      activeTypeTeamScreen: false,
      teamTypeHref: [ "partners", "exes", "empl"],
      teamType: ["PARTNER", "LEADER", "EMPLOYEES"]
    }
  },

  computed: {
    ...mapGetters({
      personsAll: 'persons/usersAll',
    }),

    activeMainSlide() {
      return this.$store.state.activeMainSlide;
    },
    activeTeamTypeArr() {
      return this.personsAll.filter(item => item?.roles.includes(this.teamType[this.activeTeamIndex - 1]) === true)
    },

    activeTeamType() {
      return this.teamTypeHref[this.activeTeamIndex - 1];
    },

    activeTeamIndex() {
      return this.$store.state.persons.activeTeamSlide;
    },

    activeTeamMainSlide() {
      return this.$store.state.changeActiveMainSlide;
    },
  },

  mounted() {
    if(this.$route.params.typeTeam) {
      this.IS_LOADING(true)
      this.checkMountRoute();
    } else {
      this.$router.push({name: 'team'})
    }
  },

  watch: {
    activeTeamIndex() {
      this.changeStateTeam();
    },
    '$route'() {
        this.changeActiveUrl();
    },
  },
  methods: {
    ...mapActions(['CHANGE_ACTIVE_SUB_SLIDE', 'CHANGE_ACTIVE_SUB_SLIDE', 'IS_LOADING_IMG']),
    ...mapActions('persons',['getUsers', 'ACTIVE_TEAM_SLIDE']),

    changeActiveUrl() {
      if(this.$route.name === 'team-type') {
        this.checkMountRoute();
      } else if(this.$route.name === 'team'){
        this.openStartTeamScreen();
      }
    },

    checkMountRoute() {
      this.CHANGE_ACTIVE_SUB_SLIDE(true)
      this.changeStateTeam();
      switch(this.$route.params.typeTeam) {
        case 'partners':
           this.ACTIVE_TEAM_SLIDE(1)
           break;
        case 'exes':
          this.ACTIVE_TEAM_SLIDE(2)
          break;
        case 'empl':
          this.ACTIVE_TEAM_SLIDE(3)
          break;
      }
    },

    changeStateTeam() {
      this.IS_LOADING(true)
      this.activeTeamStartScreen = false
      this.activeTypeTeamScreen = false
      if(this.activeTeamIndex !== 0) {
        setTimeout(() => {
          this.$router.push({name: 'team-type', params: {typeTeam: this.activeTeamType}})
          this.activeTypeTeamScreen = true
        }, 500)
      }
    },

    openTeamSliderScreen(id) {
        this.ACTIVE_TEAM_SLIDE(id)
        this.CHANGE_ACTIVE_MAIN_SLIDE(false)
        this.activeTeamStartScreen = false
        this.CHANGE_ACTIVE_SUB_SLIDE(true)
        this.changeStateTeam();
    },

    openStartTeamScreen(state) {
      this.CHANGE_ACTIVE_SUB_SLIDE(false)
      this.activeTypeTeamScreen = false
      if(state) {
        this.$router.push({name: 'team'})
      }
      setTimeout(() => {
        this.activeTypeTeamScreen = false
        this.activeTeamStartScreen = true
        this.IS_LOADING(false)
      }, 500)
    },
  }
}
</script>

<style scoped lang="scss">
    .team-screen-container {
      &.active-type-team-screen {
        --padding-right: 0;
      }
    }

    @media (max-width: 769px) {
      .main-container {
        padding-bottom: 42px;
      }
    }

    @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
      .main-container {
        padding-bottom: 34px;
      }
    }
    @media (min-height: 600px) and (max-height: 630px){
      //для экранов 8 плюс
    }

    @media (max-width: 375px){
      .main-container {
        padding-bottom: 21px;
      }
    }


    @media (max-width: 375px) and (min-height: 630px) {
      .main-container {
        padding-bottom: 20px;
      }
    }


    @media (max-width: 320px) {
      .main-container {
        padding-bottom: 16px;
      }
    }

</style>