<template>
    <div class="industry-title-container" :class="{'not-active' : !activePracticeScreen && !activeServicesScreen}">
      <transition name="fade-translate-y">
        <large-title v-if="activePracticeScreen || activeServicesScreen" :active-cormorant="true">
          {{activeMainTitle}}
        </large-title>
      </transition>
      <transition name="fade-translate-x">
      <main-title :active-cormorant="true" v-if="activeServicesScreen">
        {{activeSubTitle}}
      </main-title>
      </transition>
    </div>

</template>

<script>
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";

export default {
  name: "IndustryTitleContainer",
  components: {LargeTitle, SubTitle, MainTitle},
  props: {
    activeMainTitle: {
      type: String,
      required: true
    },
    activeSubTitle: {
      type: String
    },
    activePracticeScreen: {
      type: Boolean,
      required: true
    },
    activeServicesScreen: {
      type: Boolean,
      required: true
    },
  }
}
</script>

<style scoped lang="scss">
  .industry-title-container {
    z-index: 3;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    transition: margin-bottom .6s ease;
    &.not-active {
      margin-bottom: 0;
    }

    .text-content {
      position: relative;
    }
  }

  @media (max-width: 2560px){
    .industry-title-container {
      row-gap: 10px;
      margin-bottom: 24px;
      .text-content {
        &.main-title {
          font-size: 28px !important;
        }

        &.large {
          letter-spacing: 14px;
          font-size: 70px;
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .industry-title-container {
      row-gap: 8px;
      margin-bottom: 18px;
      .text-content {
        &.main-title {
          font-size: 24px !important;
        }
        &.large {
          letter-spacing: 12px;
          font-size: 60px;
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .industry-title-container {
      row-gap: 6px;
      .text-content {
        &.main-title {
          font-size: 16px !important;
        }
        &.large {
          letter-spacing: 8px;
          font-size: 40px;
        }
      }
    }
  }
  @media (max-height: 800px) {
    .industry-title-container {
      row-gap: 6px;
      .text-content {
        &.main-title {
          font-size: 16px !important;
        }
        &.large {
          letter-spacing: 8px;
          font-size: 40px;
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .industry-title-container {
      row-gap: 0;
      .text-content {
        &.main-title {
          font-size: 18px !important;
        }
        &.large {
          letter-spacing: 8.8px;
          font-size: 44px;
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .industry-title-container {
      row-gap: 0;
      .text-content {
        &.main-title {
          font-size: 14px !important;
        }
        &.large {
          letter-spacing: 7.2px;
          font-size: 36px;
          margin-bottom: 4px;
        }
      }
    }
  }

  @media (max-width: 769px) {
    .industry-title-container {
      row-gap: 0;
      margin-bottom: 0;
      .text-content {
        &.main-title {
          font-size: 12px !important;
        }
        &.large {
          margin-bottom: 0;
          font-size: 26px !important;
        }
      }
    }
  }

  @media (max-width: 375px) {
    .industry-title-container {
      row-gap: 0;
      margin-bottom: 0;
      .text-content {
        &.main-title {
          font-size: 10px !important;
        }
        &.large {
          margin-bottom: 0;
          font-size: 20px !important;
        }
      }
    }
  }

  @media (max-width: 375px) and (min-height: 630px) {
    .industry-title-container {
      row-gap: 0;
      margin-bottom: 0;
      .text-content {
        &.main-title {
          font-size: 12px !important;
        }
        &.large {
          margin-bottom: 0;
          font-size: 26px !important;
        }
      }
    }
  }



  @media (min-width: 2561px) {
    .industry-title-container {
      row-gap: 18px;
      margin-bottom: 32px;
      .text-content {
        &.large {
          letter-spacing: 28px;
          font-size: 140px;
        }
      }
    }
  }

</style>