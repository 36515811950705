<template>
  <div class="button-toggle-container">
    <button-toggle-lang :active-color-scheme="activeColorScheme"/>
    <button-toggle @click="toggleTheme" :active-color-scheme="activeColorScheme" @mouseleave="focusFalse" @mouseenter="focusTrue">
      <button-icon v-if="activeColorScheme" img-name="toggle"/>
      <div v-else class="button-icon-container">
        <div class="button-icon-content">
          <img v-if="activeColor" :src="getUrlIcon('toggle-light.svg')" alt="button">
          <img v-else :src="getUrlIcon('toggle-dark-hover.svg')" alt="button">
        </div>
      </div>
    </button-toggle>
  </div>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import ButtonToggle from "@/components/shared/ButtonToggle.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import ButtonToggleLang from "@/components/entities/ButtonToggleLang.vue";

export default {
  name: "ListButtonToggle",
  components: {ButtonToggleLang, MainParagraph, ButtonToggle, ButtonIcon},
  props: {
    activeColorScheme: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      focusParam: false
    }
  }
}
</script>

<style scoped lang="scss">
p {
  font-family: 'CormorantUnicase-Regular', sans-serif;
}
.button-icon-container {
  .button-icon-content {
    & img {
      width: 100%;
      height: 100%;
    }
  }
}

.button-toggle-container {
  justify-content: center;
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media (max-width: 2560px){
  p {
    font-size: 19px;
  }
  .button-icon-container {
    height: 24px;
    width: 24px;
  }
  .button-toggle-container {
    column-gap: 16px;
  }
}


@media (max-width: 1920px) {
  p {
    font-size: 16px;
  }
  .button-icon-container {
    height: 18px;
    width: 18px;
  }
  .button-toggle-container {
    column-gap: 16px;
  }
}

@media (max-width: 1600px) {
  p {
    font-size: 11px;
  }
  .button-icon-container {
    height: 16px;
    width: 16px;
  }
  .button-toggle-container {
    column-gap: 12px;
  }
}
@media (max-height: 800px) {
  p {
    font-size: 11px;
  }
  .button-icon-container {
    height: 16px;
    width: 16px;
  }
  .button-toggle-container {
    column-gap: 12px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  p {
    font-size: 9px;
  }
  .button-icon-container {
    height: 14px;
    width: 14px;
  }
  .button-toggle-container {
    column-gap: 12px;
  }
}

@media (max-width: 1133px) {
  p {
    font-size: 9px;
  }
  .button-icon-container {
    height: 12px;
    width: 12px;
  }
  .button-toggle-container {
    column-gap: 10px;
  }
}

@media (max-width: 769px) {
  .button-toggle-container {
    justify-content: flex-start;

    p {
      font-size: 18px;
    }
    .button-icon-container {
      max-height: 22px;
      height: 100%;
      width: 100%;
      max-width: 22px;
    }
    .button-toggle-container {
      column-gap: 10px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  p {
    font-size: 18px;
  }
  .button-icon-container {
    max-height: 22px;
    height: 100%;
    width: 100%;
    max-width: 22px;
  }
  .button-toggle-container {
    column-gap: 10px;
  }

  .button-toggle-container {
    justify-content: flex-start;
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  .button-toggle-container {
    justify-content: flex-start;

    p {
      font-size: 15px;
    }
    .button-icon-container {
      max-height: 18px;
      height: 100%;
      width: 100%;
      max-width: 18px;
    }
    .button-toggle-container {
      column-gap: 9px;
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .button-toggle-container {
    justify-content: flex-start;

    p {
      font-size: 18px;
    }
    .button-icon-container {
      max-height: 22px;
      height: 100%;
      width: 100%;
      max-width: 22px;
    }
    .button-toggle-container {
      column-gap: 10px;
    }
  }
}


@media (max-width: 320px) {
  .button-toggle-container {
    p {
      font-size: 15px;
    }

    .button-icon-container {
      max-height: 18px;
      height: 100%;
      width: 100%;
      max-width: 18px;
    }

    .button-toggle-container {
      column-gap: 9px;
    }
  }
}


@media (min-width: 2561px) {
  p {
    font-size: 38px;
  }
  .button-icon-container {
    height: 46px;
    width: 46px;
  }
  .button-toggle-container {
    column-gap: 32px;
  }
}

</style>