<template>
  <div class="main-card-frame-container" v-if="infoContent !== {}">

    <div class="top-info-container">

    <card-title-news-case :active-main-title="activeMainTitle"
                          @loadAvatar="loadAvatar"
                          :class="{'active-modal-filter' : activeModalFilter}"
                          :publish-date="publishDate"
                          :info-content="infoContent"/>

      <div class="card-info-container">
        <controller-info-container @changeActiveCase="changeState"/>
        <card-info-news-case :info-content-text="infoContent?.translations"
                             :active-load-team="activeLoadTeam"
                             :info-content-photo="infoContent?.photo"/>
      </div>
    </div>

    <transition name="fade-opacity-lazy">
      <div class="bottom-info-container news" v-if="activeSlider" v-show="activeNewsCase">
        <large-title :class="{'active-modal-filter' : activeModalFilter}"
                     :active-cormorant="false">
          {{ $tm('caseNewsPage.mainTitle')[3] }}
        </large-title>
        <slider-news-all class="slider-container"
                         :active-gradient="false"
                         :class="{'active-modal-filter' : activeModalFilter}"
                         :active-id="activeId"
                         :controller-setting="{
                           top: false,
                           bottom: true
                         }"
                         :setting-swiper="settingSwiperNews"/>
      </div>
    </transition>
    <transition name="fade-opacity-lazy">
      <div class="bottom-info-container case" v-if="!activeSlider" v-show="activeNewsCase">
        <large-title :class="{'active-modal-filter' : activeModalFilter}" :active-cormorant="false">
          {{ $tm('caseNewsPage.mainTitle')[2] }}
        </large-title>
        <slider-case-all class="slider-container"
                         :active-gradient="false"
                         :active-sub-page="true"
                         :class="{'active-modal-filter' : activeModalFilter}"
                         :active-id="activeId"/>
      </div>
    </transition>

  </div>
</template>

<script>
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import ButtonLarge from "@/components/shared/ButtonLarge.vue";
import CardPersonMini from "@/components/entities/CardPersonMini.vue";
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import SliderCase from "@/components/entities/SliderCase.vue";
import SliderNews from "@/components/entities/SliderNews.vue";
import MainTitlePage from "@/components/entities/MainTitlePage.vue";
import CardInfoNewsCase from "@/components/entities/news-case-page/CardInfoNewsCase.vue";
import CardTitleNewsCase from "@/components/entities/news-case-page/CardTitleNewsCase.vue";
import ControllerInfoContainer from "@/components/shared/ControllerInfoContainer.vue";
import SliderNewsAll from "@/components/entities/SliderNewsAll.vue";
import SliderCaseAll from "@/components/entities/SliderCaseAll.vue";

export default {
  name: "CardNewsCase",
  components: {
    SliderCaseAll,
    SliderNewsAll,
    ControllerInfoContainer,
    CardTitleNewsCase,
    CardInfoNewsCase,
    MainTitlePage,
    SliderNews,
    SliderCase, LargeTitle, MainParagraph, SubParagraph, SubTitle, CardPersonMini, ButtonLarge, MainTitle},
  props: {
    publishDate: {
      type: String,
      required: true
    },
    activeMainTitle: {
      type: String,
      required: true
    },
    infoContent: {
      type: Object,
      required: true
    },
    activeSlider: {
      type: Boolean,
      required: true
    },
    activeId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      activeNewsCase: false,
      settingSwiperNews: {
        direction: 'vertical',
        slidesPerView: 2.1,
        loop: false,
        centeredSlides: false,
        virtual: true,
      },
      activeLoadTeam: true,
    }
  },
  computed: {
    activeModalFilter() {
      return this.$store.state.activeModalFilter;
    },
  },
  unmounted() {
    // this.activeNewsCase = true;
    removeEventListener('resize', this.checkSize)
  },
  watch: {
    activeNewsCase() {
      this.$emit('changeActiveOverflow', this.activeNewsCase);
    },
  },
  mounted() {
    this.checkSize();
    addEventListener('resize', this.checkSize);
  },
  methods: {

    changeState() {
        this.activeNewsCase = !this.activeNewsCase;
    },
    loadAvatar(state) {
      this.activeLoadTeam = state
    },

    checkSize() {
      if (window.innerWidth > 2560)  {
        this.settingSwiperNews.slidesPerView = 2.2
      } else if (window.innerWidth <= 1920 && window.innerWidth > 1600) {
        this.settingSwiperNews.slidesPerView = 2.4
      }
      if(window.innerWidth <= 769) {
        this.settingSwiperNews.slidesPerView = 1.1
      }
    },
  }
}
</script>

<style scoped lang="scss">


  .main-card-frame-container {
    position: relative;
    height: 100%;

    .top-info-container {
      height: 100%;
      display: flex;
      position: relative;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .card-info-container {
        position: relative;
        width: 100%;
      }
    }

    .bottom-info-container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;

      .slider-container {
        transition: filter .6s ease;
        position: relative;
      }

      .text-content {
        transition: filter .5s ease;
        font-weight: 600;

        &.eng {
          font-variant: all-small-caps !important;
        }
      }

      .case-slider {
        height: 100%;
        transition: filter .5s ease;
      }

      .news-slider {
        height: 100%;
        transition: filter .5s ease;
      }
    }
  }

  @media(min-width: 770px) {
    .main-card-frame-container {
      .bottom-info-container {
        overflow: hidden;
        background-color: var(--color-card);

        .text-content {
          z-index: 3;
          position: relative;
        }

        &.case {
          &:after {
            visibility: visible;
            content: "";
            position: absolute;
            width: 30%;
            height: 110%;
            z-index: 2;
            top: -1%;
            right: -1%;
            pointer-events: none;
            background: linear-gradient(90deg,  rgba(34, 34, 59, 0.00) 0, var(--color-background) 100%);
          }

          &:before {
            visibility: visible;
            content: "";
            position: absolute;
            width: 5%;
            height: 110%;
            z-index: 2;
            top: -1%;
            left: -1%;
            pointer-events: none;
            background: linear-gradient(270deg,  rgba(34, 34, 59, 0.00) 0, var(--color-background) 80%);
          }
        }

        &.news {
          &:before {
            transition: all .6s ease;
            overflow: hidden;
            pointer-events: none;
            width: 100%;
            height: 20%;
            background: linear-gradient(0deg,  rgba(34, 34, 59, 0.0) 0, var(--color-background) 50%);
            content: "";
            position: absolute;
            z-index: 2;
            top: -3%;
            left: 0;
          }

          &:after {
            transition: all .6s ease;
            overflow: hidden;
            pointer-events: none;
            width: 100%;
            height: 20%;
            background: linear-gradient(180deg,  rgba(34, 34, 59, 0.0) 0, var(--color-background) 100%);
            content: "";
            position: absolute;
            z-index: 2;
            left: 0;
            bottom: -1%;
          }
        }



        .slider-news-content-container {
          overflow: visible;
        }
      }
    }
  }

  @media (max-width: 2560px){

    .slider-news-content-container {
      max-height: 890px;
    }

    .main-card-frame-container {

      .top-info-container {
        margin-bottom: 64px;
        .card-info-container {
          max-width: 1236px;
        }
      }

      .bottom-info-container {
        padding: 36px;
        .text-content {
          font-size: 37px;
          margin-bottom: 36px;
          font-weight: 600;
        }

        .slider-container {
          height: 936px;
        }

        //.case-slider {
        //  height: 936px;
        //}
        //
        //.news-slider {
        //  height: 825px;
        //}
      }
    }
  }

  @media (max-width: 2240px) {
    .slider-news-content-container {
      max-height: 865px;
    }

    .main-card-frame-container {
      .top-info-container {
        margin-bottom: 64px;
        .card-info-container {
          max-width: 996px;
        }
      }

      .bottom-info-container {
        padding: 32px;
        .text-content {
          margin-bottom: 36px;
          font-weight: 600;
        }

        //.case-slider {
        //  height: 932px;
        //}
        //
        //.news-slider {
        //  height: 750px;
        //}
      }
    }
  }


  @media (max-width: 1920px) {

    .slider-news-content-container {
      max-height: 700px;
    }

    .main-card-frame-container {

      .top-info-container {
        margin-bottom: 64px;

        .card-info-container {
          max-width: 848px;
        }
      }

      .bottom-info-container {
        padding: 32px;
        .text-content {
          font-size: 32px;
          margin-bottom: 32px;
          font-weight: 600;
        }


        .slider-container {
          height: 802px;
        }

        //.case-slider {
        //  height: 800px;
        //}
        //
        //.news-slider {
        //  height: 692px;
        //}
      }
    }
  }

  @media (max-width: 1600px) {

    .slider-news-content-container {
      max-height: 430px;
    }

    .main-card-frame-container {
      .top-info-container {
        margin-bottom: 42px;
        .card-info-container {
          max-width: 692px;
        }
      }

      .bottom-info-container {
        padding: 32px;
        .text-content {
          font-size: 22px;
          margin-bottom: 22px;
          font-weight: 600;
        }

        .slider-container {
          height: 534px;
        }


        //.case-slider {
        //  height: 532px;
        //}
        //
        //.news-slider {
        //  height: 408px;
        //}
      }
    }
  }
  @media (max-height: 800px) {


    .slider-news-content-container {
      max-height: 512px;
    }

    .main-card-frame-container {

      .top-info-container {
        margin-bottom: 42px;
        .card-info-container {
          max-width: 552px;
        }
      }

      .bottom-info-container {
        padding: 24px;
        .text-content {
          font-size: 24px;
          margin-bottom: 24px;
          font-weight: 600;
        }

        //.case-slider {
        //  height: 532px;
        //}
        //
        //.news-slider {
        //  height: 450px;
        //}
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {

    .slider-news-content-container {
      max-height: 564px;
    }

    .main-card-frame-container {

      .top-info-container {
        margin-bottom: 42px;
        .card-info-container {
          max-width: 618px;
        }
      }

      .bottom-info-container {
        padding: 24px;
        .text-content {
          font-size: 24px;
          margin-bottom: 24px;
          font-weight: 600;
        }

        //.case-slider {
        //  height: 584px;
        //}
        //
        //.news-slider {
        //  height: 480px;
        //}
      }
    }
  }

  @media (max-width: 1133px) {


    .slider-news-content-container {
      max-height: 435px;
    }

    .main-card-frame-container {

      .top-info-container {
        margin-bottom: 32px;
        .card-info-container {
          max-width: 504px;
        }
      }

      .bottom-info-container {
        padding: 24px;
        .text-content {
          font-size: 20px;
          margin-bottom: 18px;
          font-weight: 600;
        }

        .slider-container {
          height: 492px;
        }


        //.case-slider {
        //  height: 471px;
        //}
        //
        //.news-slider {
        //  height: 426px;
        //}
      }
    }
  }


  @media (min-width: 2561px) {

    .slider-news-content-container {
      max-height: 1544px;
    }

    .main-card-frame-container {

      .top-info-container {
        margin-bottom: 64px;
        .card-info-container {
          max-width: 2019px;
        }
      }

      .bottom-info-container {
        padding: 64px;
        .text-content {
          margin-bottom: 72px;
          font-weight: 600;
        }

        .slider-container {
          height: 1803px;
        }

        //.case-slider {
        //  height: 1872px;
        //}
        //
        //.news-slider {
        //  height: 1500px;
        //}
      }
    }
  }

</style>