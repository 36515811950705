<template>
  <p class="text-content main-text" :class="{'cormoranti-unicase-active': activeCormorant}">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "SubParagraph",
  props: {
    activeCormorant: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
@media (max-width: 2560px){
  .text-content {
    &.main-text {
      font-size: 18px;
    }
  }

}

@media (max-width: 2240px) {
  .text-content {
    &.main-text {
      font-size: 16px;
    }
  }

}


@media (max-width: 1920px) {
  .text-content {
    &.main-text {
      font-size: 14px;
    }
  }

}


@media (max-width: 1600px) {
  .text-content {
    &.main-text {
      font-size: 10px;
    }
  }

}
@media (max-height: 800px) {
  .text-content {
    &.main-text {
      font-size: 10px;
    }
  }

}

@media (max-width: 1400px) and (min-height: 801px) {
  .text-content {
    &.main-text {
      font-size: 10px;
    }
  }

}

@media (max-width: 1133px) {
  .text-content {
    &.main-text {
      font-size: 8px;
    }
  }
}

@media (max-width: 769px) {
  .text-content {
    &.main-text {
      font-size: 14px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .text-content {
    &.main-text {
      font-size: 14px;
    }
  }
}


@media (max-width: 375px){
  .text-content {
    &.main-text {
      font-size: 12px;
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .text-content {
    &.main-text {
      font-size: 12px;
    }
  }
}


@media (max-width: 320px) {
  .text-content {
    &.main-text {
      font-size: 10px;
    }
  }
}



@media (min-width: 2561px) {
  .text-content {
    &.main-text {
      font-size: 32px;
    }
  }

}
</style>