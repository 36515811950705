<template>
  <div class="line-controller-container">
    <div class="line-controller-content" v-for="lineText in lineController" :key="lineText.id">
      <transition name="fade-opacity-lazy">
        <line-controller @changeActiveLine="changeActiveLine"
                         v-show="isVisible(lineText.id)"
                         :line-text="lineText"
                         :active-line="activeIndexLine"/>
      </transition>
    </div>
  </div>
</template>

<script>
import LineController from "@/components/shared/LineController.vue";

export default {
  name: "LineControllerContainer",
  components: {LineController},
  props: {
    lineController: {
      type: Array,
      required: true
    },
    activeIndexLine: {
      type: Number,
      required: true
    },
    activeScreen: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      activeItem: -1,
      blockClick: false
    }
  },

  mounted() {
    if(this.activeScreen) {
      this.showNextItem();
    }
  },

  watch: {
    activeScreen() {
      if(this.activeScreen) {
        this.showNextItem();
      }
    }
  },

  methods: {

    changeActiveLine(id, position) {
      if(!this.blockClick) {
        this.$emit('changeActiveLine', id, position)
        this.blockClick = true;
        setTimeout(() => {
          this.blockClick = false;
        })
      }

    },

    showNextItem() {
      if (this.activeItem < this.lineController.length) {
        setTimeout(() => {
          this.activeItem++
          this.showNextItem();
        }, 500)
      }
    },

    isVisible(index) {
      return index <= this.activeItem
    },
  }
}
</script>

<style scoped lang="scss">
.line-controller-container {
  position: relative;
  display: flex;
  width: 100%;
  z-index: 999;
  height: 100%;
  flex-direction: row;

  .line-controller-content {
    height: 100%;
    width: 100%;
  }
}



@media (max-width: 2560px){
  .line-controller-container {
    max-height: 62px;
  }
}

@media (max-width: 2240px) {
  .line-controller-container {
    max-height: 62px;
  }
}


@media (max-width: 1920px) {
  .line-controller-container {
    max-height: 54px;
  }
}

@media (max-width: 1600px) {
  .line-controller-container {
    max-height: 36px;
  }
}
@media (max-height: 800px) {
  .line-controller-container {
    max-height: 36px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .line-controller-container {
    max-height: 36px;
  }
}

@media (max-width: 1133px) {
  .line-controller-container {
    max-height: 32px;
  }
}
@media (max-width: 769px) {
  .line-controller-container {
    max-height: 30px;
  }
}

@media (max-width: 375px){
  .line-controller-container {
    max-height: 24px;
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .line-controller-container {
    max-height: 30px;
  }
}


@media (max-width: 320px) {
  //iPhone SE
}


@media (min-width: 2561px) {
    .line-controller-container {
      max-height: 123px;
    }
}


</style>