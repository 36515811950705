<template>
    <div class="card-worth-item-content">
      <transition name="fade-opacity-1">
        <div class="worth-main-title-container" v-show="activeTopTitle">
          <large-title :active-cormorant="true">{{worthItem.mainTitle[0]}}</large-title>
          <large-title class="two" v-if="worthItem.mainTitle[1] !== undefined" :active-cormorant="true">&nbsp;{{worthItem.mainTitle[1]}}</large-title>
        </div>
      </transition>

      <transition name="fade-opacity-0-6">
      <main-title v-show="activeBottomTitle" :active-cormorant="true">
        {{worthItem.subTitle}}
      </main-title>
      </transition>
    </div>
</template>

<script>
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import MainTitle from "@/components/shared/Text/MainTitle.vue";

export default {
  name: "CardWorthItem",
  components: {MainTitle, LargeTitle},
  props: {
    worthItem: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeTopTitle: false,
      activeBottomTitle: false,
      activeAnimation: 'fade-translate-y'
    }
  },
  mounted() {
      this.activeTopTitle = true
      this.activeBottomTitle = true
    if(this.activeMobile) {
      this.activeAnimation = 'fade-translate-x'
    } else {
      this.activeAnimation = 'fade-translate-y'
    }
  }
}
</script>

<style scoped lang="scss">
.worth-item-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  .worth-main-title-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
}
@media (max-width: 2560px){
  .worth-item-content {
    max-height: 274px;
    .worth-main-title-container {
      .text-content {
        &.large {
          font-size: 187px;
          letter-spacing: -9.35px;
          &.two {
            letter-spacing: -5.35px;
            font-size: 107px;
          }
        }
      }
    }
  }
}

@media (max-width: 2240px) {
  .worth-item-content {
    max-height: 243px;
    .worth-main-title-container {
      .text-content {
        &.large {
          font-size: 164px;
          letter-spacing: -8.2px;
          &.two {
            letter-spacing: -4.7px;
            font-size: 94px;
          }
        }
      }
    }
  }
}


@media (max-width: 1920px) {
  .worth-item-content {
    max-height: 224px;
    .worth-main-title-container {
      .text-content {
        &.large {
          font-size: 140px;
          letter-spacing: -7px;
          &.two {
            letter-spacing: -4px;
            font-size: 80px;
          }
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .worth-item-content {
    max-height: 224px;
    .worth-main-title-container {
      .text-content {
        &.large {
          font-size: 105px;
          letter-spacing: -5.25px;
          &.two {
            letter-spacing: -3px;
            font-size: 60px;
          }
        }
      }
    }
  }
}
@media (max-height: 800px) {
  .worth-item-content {
    max-height: 138px;
    .worth-main-title-container {
      .text-content {
        &.large {
          font-size: 93px;
          letter-spacing: -4.65px;
          &.two {
            letter-spacing: -2.65px;
            font-size: 53px;
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .worth-item-content {
    max-height: 152px;
    .worth-main-title-container {
      .text-content {
        &.large {
          font-size: 100px;
          letter-spacing: -5px;
          &.two {
            letter-spacing: -2.85px;
            font-size: 57px;
          }
        }
      }
    }

  }
}

@media (max-width: 1133px) {
  .worth-item-content {
    max-height: 123px;
    .worth-main-title-container {
      .text-content {
        &.large {
          font-size: 83px;
          letter-spacing: -4.15px;
          &.two {
            letter-spacing: -2.35px;
            font-size: 47px;
          }
        }
      }
    }
  }
}


@media (max-width: 769px) {
  .worth-item-content {
    max-height: 82px;
    .text-content {
      &.main-title {
        font-size: 12px;
      }
    }
    .worth-main-title-container {
      .text-content {
        &.large {
          font-size: 60px;
          letter-spacing: -3px;
          &.two {
            letter-spacing: -1.8px;
            font-size: 36px;
          }
        }
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .worth-item-content {
    max-height: 123px;
    .text-content {
      &.main-title {
        font-size: 14px;
      }
    }
    .worth-main-title-container {
      .text-content {
        &.large {
          font-size: 64px;
          letter-spacing: -3.2px;
          &.two {
            letter-spacing: 2.8px;
            font-size: 38px;
          }
        }
      }
    }
  }
}


@media (max-width: 375px){
  .worth-item-content {
    max-height: 84px;
    .text-content {
      &.main-title {
        font-size: 12px;
      }
    }
    .worth-main-title-container {
      .text-content {
        line-height: 110%;
        &.large {
          font-size: 54px;
          letter-spacing: -2.7px;
          &.two {
            letter-spacing: -1.6px;
            font-size: 32px;
          }
        }
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .worth-item-content {
    max-height: 84px;
    .text-content {
      &.main-title {
        font-size: 12px;
      }
    }
    .worth-main-title-container {
      margin-bottom: 2px;
      .text-content {
        &.large {
          font-size: 60px;
          letter-spacing: -3px;
          &.two {
            letter-spacing: -1.8px;
            font-size: 38px;
          }
        }
      }
    }
  }
}


@media (max-width: 320px) {
  .worth-item-content {
    max-height: 64px;
    .text-content {
      &.main-title {
        font-size: 10px;
      }
    }
    .worth-main-title-container {
      .text-content {
        line-height: 120%;
        &.large {
          font-size: 36px;
          letter-spacing: -1.8px;
          &.two {
            letter-spacing: -1.2px;
            font-size: 24px;
          }
        }
      }
    }
  }
}



@media (min-width: 2561px) {
  .worth-item-content {
    max-height: 486px;
    .worth-main-title-container {
      .text-content {
        &.large {
          font-size: 327px;
          letter-spacing: -16.35px;
          &.two {
            letter-spacing: -9.35px;
            font-size: 187px;
          }
        }
      }
    }
  }
}


</style>