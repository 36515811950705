import {api} from "@/providers/api";

export const practiceModules = {
    namespaced: true,
    state: () => ({
        allPractices: [],
    }),
    getters: {
        getPractices: (state) => {
            return state.allPractices
        },
    },
    mutations: {
        SET_ALL_PRACTICE: (state, payload) => {
            state.allPractices = payload
        },
    },
    actions:{
        async getPractice({commit}, {params  } = {params: {}}) {
            let response = await api.main.mainControllerGetPractices().then(r => r.data);
            commit('SET_ALL_PRACTICE', response)
        },
    }
}