<template>
  <div class="im-legal-screen-container" v-if="!activeMobile">
    <div class="top-content">
      <transition name="fade-opacity-0-6">
        <main-title v-show="activeSubTitle" :active-cormorant="true">
          {{$t('aboutScreen.imLegal.subTitle')}}
        </main-title>
      </transition>

      <div class="worth-item-container">
        <div class="worth-item-content"
             v-for="item in worthInfo"
             :key="item.id">
          <card-worth-item v-if="isVisible(item.id)" :worth-item="item"/>
        </div>
      </div>
    </div>


      <div class="slogan-title-container">
        <transition name="fade-opacity-1">
          <main-paragraph v-if="activeBottomTitle">
            {{$t('aboutScreen.imLegal.sloganParagraph')}}
          </main-paragraph>
        </transition>


        <div class="brand-logo-container" v-if="ratingFilterUndefined.length > 0">
            <div class="brand-logo-content"  v-for="(item, index) in ratingFilterUndefined" :key="item?.id">
                          <transition name="fade-opacity-1">
                            <div class="image-container" v-if="activeBrand && item !== undefined">
                              <img v-if="activeColor" :src="getUrlBrand(item.ru + '-dark.svg')" alt="icon">
                              <img v-else :src="getUrlBrand(item.ru + '-light.svg')" alt="icon">
                            </div>
                          </transition>
            </div>
        </div>
        </div>
  </div>



  <div class="im-legal-screen-container" v-else>

    <div class="top-content">
      <transition name="fade-translate-x">
        <main-paragraph v-if="activeBottomTitle">
          {{$t('aboutScreen.imLegal.sloganMobileParagraph')}}
        </main-paragraph>
      </transition>
      <transition name="fade-translate-y">
        <main-title v-show="activeSubTitle" :active-cormorant="true">
          {{$t('aboutScreen.imLegal.subTitle')}}
        </main-title>
      </transition>

      <div class="worth-item-container">
        <div class="worth-item-content"
             v-for="item in worthInfo"
             :key="item.id">
          <card-worth-item v-if="isVisible(item.id)" :worth-item="item"/>
        </div>
      </div>
    </div>

    <transition name="fade-opacity-lazy">
      <div class="slogan-title-container" v-if="activeBrand && ratingFilterUndefined.length > 0">
        <div class="brand-logo-container">
          <swiper class="brand-logo-content"
                  @slide-change="onSwiperMain"
                  @swiper="changeSettingSwiper"
                  :allow-slide-next="true"
                  :watch-overflow="true"
                  :autoplay="{
                     delay: 1500,
                     disableOnInteraction: false
                  }"
                  :slidesPerGroup="1"
                  :slidesPerView="4"
                  :spaceBetween="41"
                  :modules="modules"
                  direction="horizontal"
                  :speed="2000"
                  :loop="true"
                  :allow-touch-move="true">
            <swiper-slide v-for="item in ratingFilterUndefined" :key="item.id">
              <div class="image-container" >
                <img v-if="activeColor" :src="getUrlBrand(item.ru + '-dark.svg')" alt="icon">
                <img v-else :src="getUrlBrand(item.ru + '-light.svg')" alt="icon">
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import CardWorthItem from "@/components/entities/CardWorthItem.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import {Autoplay} from "swiper";
import {mapGetters} from "vuex";

export default {
  name: "IMLegalScreen",
  components: {MainParagraph, CardWorthItem, MainTitle},
  data() {
    return {
      activeItem: -1,
      activeBrand: false,
      activeSubTitle: false,
      activeBottomTitle: false,
      imgBrandAll: [],
      modules: [Autoplay],
      mainSwiper: '',
      activeFrameSlide: 0,
      arrBrand: ['Chambers and Partners', 'Коммерсантъ',
                 'ПРАВО 300', 'Forbes Club', 'Financial Times', 'Best Lawyers', 'LEGAL 500'],
    }
  },
  mounted() {
    // this.checkColorLogo();
    this.startAnimation();
  },

  watch: {
    // activeColor() {
    //   this.checkColorLogo();
    // }
  },

  computed: {
    ...mapGetters('rating',['ratingAll', 'ratingAllPhoto']),
    worthInfo() {
      return this.$tm('aboutScreen.imLegal.mainTitle');
    },

    ratingFilter() {
      let arrResult = [];
      this.ratingAll.forEach(item => {
        this.arrBrand.forEach((itemBrand, index) => {
          if(itemBrand === item.ru) {
            arrResult[index] = item
          }
        })
      })
      return arrResult
    },

    ratingFilterUndefined() {
      return this.ratingFilter.filter(item => item !== undefined)
    }

  },
  methods: {

    changeSettingSwiper(swiper) {
      this.mainSwiper = swiper
    },
    onSwiperMain(swiper) {
      this.activeFrameSlide = swiper.activeIndex;
    },


    startAnimation() {
      if(!this.activeMobile) {
        this.activeSubTitle = true;
        setTimeout(() => {
          this.showNextItem();
        }, 250)
      } else {
        // this.imgBrandAll = this.imgBrandOne.concat(this.imgBrandTwo)
        this.activeBottomTitle = true;
        setTimeout(() => {
          this.activeSubTitle = true;
          this.showNextItemMobile();
        }, 250)
      }
    },

    showNextItem() {
        if (this.activeItem < this.worthInfo.length) {
          setTimeout(() => {
            this.activeItem++
            this.showNextItem();
          }, 500)
        } else {
          this.activeBottomTitle = true
          setTimeout(() => {
            this.activeBrand = true
          }, 300)
        }
    },

    showNextItemMobile() {
      if (this.activeItem < this.worthInfo.length) {
        setTimeout(() => {
          this.activeItem++
          this.showNextItem();
        }, 500)
      } else {
          this.activeBrand = true
      }
    },


    isVisible(index) {
      return index <= this.activeItem
    },
  }
}
</script>

<style scoped lang="scss">
  .im-legal-screen-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

    .top-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .worth-item-container {
        display: flex;
        flex-direction: row;
        width: 100%;
      }
    }



    .slogan-title-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .text-content {
        line-height: 150%;
      }

      .brand-logo-container {
        justify-content: flex-end;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;

        .brand-logo-content {
          height: 100%;

            .image-container {
              width: 100%;
              height: 100%;

              & img {
                object-fit: contain;
                width: 100%;
                height: 100%;
              }
            }
        }
        //.brand-logo-content {
        //  width: 30%;
        //
        //  &:nth-child(2) {
        //    width: 10%;
        //  }
        //  &:nth-child(3) {
        //    width: 10%;
        //  }
        //
        //  &:nth-last-child(3) {
        //    width: 8%;
        //  }
        //  &:last-child {
        //    width: 8%;
        //  }
        //  .image-container {
        //    width: 100%;
        //    height: 100%;
        //
        //    & img {
        //      object-fit: contain;
        //      width: 100%;
        //      height: 100%;
        //    }
        //  }
        //}
      }
    }
}
  @media (max-width: 2560px){
    .im-legal-screen-container {
      max-height: 959px;

      .top-content {
        max-height: 372px;

        .worth-item-container {
          column-gap: 195px;
        }
      }

      .slogan-title-container {
        height: 148px;

        .brand-logo-container {
          max-width: 620px;
          column-gap: 52px;
          row-gap: 32px;
          .brand-logo-content {
            max-width: 180px;
            max-height: 52px;
          }
        }
      }
    }
  }

  @media (max-width: 2240px) {
    .im-legal-screen-container {
      max-height: 782px;

      .top-content {
        max-height: 373px;
        .worth-item-container {
          column-gap: 130px;
        }
      }

      .slogan-title-container {
        height: 106px;

        .brand-logo-container {
          max-width: 430px;
          column-gap: 36px;
          row-gap: 32px;
          .brand-logo-content {
            max-width: 126px;
            max-height: 38px;
          }
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .im-legal-screen-container {
      max-height: 674px;

      .top-content {
        max-height: 317px;
        .worth-item-container {
          column-gap: 100px;
        }
      }

      .slogan-title-container {
        height: 96px;

        .brand-logo-container {
          max-width: 375px;
          column-gap: 32px;
          row-gap: 32px;
          .brand-logo-content {
            max-width: 110px;
            max-height: 32px;
          }
        }
      }
    }
  }

  @media (max-width: 1600px) {
    .im-legal-screen-container {
      margin-top: 86px;
      max-height: 628px;

      .top-content {
        max-height: 215px;
        .worth-item-container {
          column-gap: 86px;
        }
      }

      .slogan-title-container {
        height: 75px;

        .brand-logo-container {
          max-width: 270px;
          column-gap: 18px;
          row-gap: 32px;
          .brand-logo-content {
            max-width: 96px;
            max-height: 21px;
          }
        }
      }
    }
  }
  @media (max-height: 800px) {
    .im-legal-screen-container {
      margin-top: 0;
      max-height: 446px;

      .top-content {
        max-height: 222px;
        .worth-item-container {
          column-gap: 86px;
        }
      }

      .slogan-title-container {
        height: 75px;

        .brand-logo-container {
          max-width: 270px;
          column-gap: 20px;
          row-gap: 32px;
          .brand-logo-content {
            max-width: 96px;
            max-height: 21px;
          }
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .im-legal-screen-container {
      margin-top: 0;

      max-height: 640px;

      .top-content {
        max-height: 254px;
        .worth-item-container {
          column-gap: 60px;
        }
      }

      .slogan-title-container {
        height: 122px;

        .brand-logo-container {
          max-width: 324px;
          column-gap: 22px;
          row-gap: 0;
          .brand-logo-content {
            max-height: 34px;
          }
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .im-legal-screen-container {
      max-height: 489px;

      .top-content {
        max-height: 198px;
        .worth-item-container {
          column-gap: 78px;
        }
      }

      .slogan-title-container {
        height: 70px;

        .text-content {
          font-size: 12px;
        }

        .brand-logo-container {
          max-width: 238px;
          column-gap: 12px;
          row-gap: 32px;
          .brand-logo-content {
            max-height: 19px;
            max-width: 76px;
          }
        }
      }
    }
  }

  @media (max-width: 769px) {
    .im-legal-screen-container {
      max-height: 500px;

      .top-content {
        max-height: 409px;

        .text-content {
          text-align: justify;
          line-height: 150%;

          &.main-title {
            font-size: 12px;
            margin-top: 22px;
          }
        }

        .worth-item-container {
          height: 304px;
          flex-direction: column;
          row-gap: 8px;
        }
      }

      .slogan-title-container {
        width: 100%;
        max-height: 20px;
        height: 100%;

        .brand-logo-container {
          display: block;
          max-width: 100%;
          .brand-logo-content {
            width: 100%;
            height: 100%;
            max-height: 20px;
            max-width: 100%;

            &:last-child {
              width: 100%;
              margin-right: 0;
            }

          }
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .im-legal-screen-container {
      max-height: 100%;

      .top-content {
        max-height: 464px;

        .text-content {
          text-align: justify;
          line-height: 140%;

          &.main-title {
            font-size: 14px;
            margin-top: 22px;
          }
        }

        .worth-item-container {
          height: 336px;
          flex-direction: column;
          row-gap: 12px;
        }
      }

      .slogan-title-container {
        width: 100%;
        max-height: 20px;
        height: 100%;

        .brand-logo-container {
          display: block;
          max-width: 100%;
          .brand-logo-content {
            width: 100%;
            height: 100%;
            max-height: 19px;

            &:last-child {
              width: 100%;
              margin-right: 0;
            }

          }
        }
      }
    }
  }
  @media (min-height: 600px) and (max-height: 630px){
    .im-legal-screen-container {
      max-height: 456px;
      .top-content {
        max-height: 305px;
      }
    }
  }

  @media (max-width: 375px){
    .im-legal-screen-container {
      max-height: 395px;

      .top-content {
        max-height: 350px;

        .text-content {
          text-align: justify;
          line-height: 150%;

          &.main-title {
            margin-top: 0;
          }
        }

        .worth-item-container {
          margin-top: -12px;
          height: 260px;
          flex-direction: column;
          row-gap: 0;
        }
      }

      .slogan-title-container {
        max-height: 20px;

        .brand-logo-container {
          .brand-logo-content {
            max-height: 20px;
          }
        }
      }
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .im-legal-screen-container {
      max-height: 478px;

      .top-content {
        max-height: 409px;

        .text-content {
          text-align: justify;
          line-height: 150%;

          &.main-title {
            font-size: 12px;
            margin-top: 0;
          }
        }

        .worth-item-container {
          margin-top: -32px;
          height: 288px;
          flex-direction: column;
          row-gap: 8px;
        }
      }

      .slogan-title-container {
        max-height: 20px;

        .brand-logo-container {
          .brand-logo-content {
            max-height: 20px;
          }
        }
      }
    }
  }


  @media (max-width: 320px) {
    .im-legal-screen-container {
      max-height: 310px;

      .top-content {
        max-height: 278px;

        .text-content {
          text-align: justify;
          line-height: 150%;

          &.main-title {
            font-size: 10px;
            margin-top: 0;
          }
        }

        .worth-item-container {
          margin-top: -12px;
          height: 202px;
          flex-direction: column;
          row-gap: 0;
        }
      }

      .slogan-title-container {
        max-height: 16px;

        .brand-logo-container {
          .brand-logo-content {
            max-height: 16px;
          }
        }
      }
    }
  }


  @media (min-width: 2561px) {
    .im-legal-screen-container {
      max-height: 1540px;

      .top-content {
        max-height: 740px;
        .worth-item-container {
          column-gap: 260px;
        }
      }



      .slogan-title-container {
        height: 182px;

        .brand-logo-container {
          max-width: 836px;
          column-gap: 74px;
          row-gap: 32px;
          .brand-logo-content {
            max-width: 232px;
            max-height: 75px;
          }
        }
      }
    }
  }

</style>