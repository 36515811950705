<template>
  <div class="policy-container">
    <gradient-button-agreement :active-modal="true" @changeActivePolicy="changeActivePolicy"/>
    <sub-paragraph :active-cormorant="false" class="policy-text"><slot/></sub-paragraph>
  </div>
</template>

<script>
import GradientButtonAgreement from "@/components/shared/GradientButtonAgreement.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";

export default {
  name: "PolicyButton",
  components: {SubParagraph, GradientButtonAgreement},
  methods: {
    changeActivePolicy(status) {
      this.$emit('changeActivePolicy', status)
    }
  }
}
</script>

<style scoped lang="scss">
  .policy-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    z-index: 999;
    column-gap: 24px;

    .policy-text {
      opacity: .6;
      line-height: 120%;
    }
  }

  @media (max-width: 2560px){
    .policy-container {
      column-gap: 28px;
    }
  }



  @media (max-width: 1920px) {
    .policy-container {
      column-gap: 24px;
    }
  }

  @media (max-width: 1600px) {
    .policy-container {
      column-gap: 16px;
    }
  }


  @media (max-width: 1133px) {
  }

  @media (max-width: 769px) {
    .policy-container {
      column-gap: 15px;

      .text-content {
        font-size: 10px;
      }
    }
  }

  @media (min-width: 2561px) {
    .policy-container {
      column-gap: 56px;
    }
  }


</style>