<template>
  <p class="text-content main-text">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "MainParagraph"
}
</script>

<style scoped lang="scss">
.text-content {
  line-height: 1.2;
}
@media (max-width: 2560px){
  .text-content {
    &.main-text {
      font-size: 22px;
    }
  }
}

@media (max-width: 2240px) {
  .text-content {
    &.main-text {
      font-size: 22px;
    }
  }
}


@media (max-width: 1920px) {
  .text-content {
    &.main-text {
      font-size: 18px;
    }
  }
}


@media (max-width: 1600px) {
  .text-content {
    &.main-text {
      font-size: 14px;
    }
  }
}
@media (max-height: 800px) {
  .text-content {
    &.main-text {
      font-size: 14px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .text-content {
    &.main-text {
      font-size: 14px;
    }
  }
}

@media (max-width: 1133px) {
  .text-content {
    &.main-text {
      font-size: 12px;
    }
  }
}

@media (max-width: 769px) {
  .text-content {
    &.main-text {
      font-size: 12px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .text-content {
    &.main-text {
      font-size: 14px;
    }
  }
}



@media (max-width: 320px) {
  .text-content {
    &.main-text {
      font-size: 10px;
    }
  }
}


@media (min-width: 2561px) {
  .text-content {
    &.main-text {
      font-size: 42px;
    }
  }
}
</style>