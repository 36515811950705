// let mode = 3;
//


import store from '@/store/index';

(function () {
    if (localStorage.getItem('theme') === 'light-theme'){
        localStorage.setItem('theme', 'light-theme');
        store.dispatch('ACTIVE_COLOR', false)
        document.body.className = 'light-theme'
    } else {
        localStorage.setItem('theme', 'dark-theme');
        document.body.className = 'dark-theme';
        store.dispatch('ACTIVE_COLOR', true)
    }
})();



export  default  {
    methods: {
            setTheme(themeName) {
            localStorage.setItem('theme', themeName);
            document.body.className = themeName;
            this.changeActiveColor();
        },

        changeActiveColor() {
            if(localStorage.getItem('theme') === 'light-theme') {
                this.$store.dispatch('ACTIVE_COLOR', false)
            } else {
                this.$store.dispatch('ACTIVE_COLOR', true)
            }
        },

        toggleTheme() {
            if (localStorage.getItem('theme') === 'light-theme'){
                this.setTheme('dark-theme');
            } else {
                this.setTheme('light-theme');
            }
        },

    },

}
