<template>
    <div class="loader-slot-container" :class="{'main-container' : activeMainSlide > 0 || activeSubPage}">
      <loader v-if="isLoadingImg || isLoading || isLoadingLang"/>
    </div>
</template>

<script>
import Loader from "@/components/Loader.vue";

export default {
  name: "LoaderSlot",
  components: {Loader},
  computed: {
    activeMainSlide() {
      return this.$store.state.activeMainSlide;
    },
    activeSubPage() {
      return this.$store.state.activeSubPage;
    },
    isLoadingLang() {
      return this.$store.state.isLoadingLang;
    }
  }
}
</script>

<style scoped lang="scss">
.loader-slot-container {
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 998;
}
</style>