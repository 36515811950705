<template>
  <div class="cookie-buttons-content">
    <main-paragraph>
      {{ $t('cookieModal.cookieText') }}
    </main-paragraph>
    <div class="button-container">
      <div class="button-content" v-for="(item, index) in $tm('buttonCookieMobile')" @click="changeModalCookie(index)">
        <main-paragraph>{{ item }}</main-paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";

export default {
  name: "CookieStartButtonMobile",
  components: {MainParagraph},
  methods: {
    changeModalCookie(id) {
      switch (id) {
        case 0:
          this.closeCookie();
          break;
        case 1:
          this.changeModalCookieSetting();
          break;
      }
    },
    changeModalCookieSetting() {
      this.$emit('changeModalCookieSetting')
    },
    closeCookie() {
      this.$emit('closeCookie')
    }
  }
}
</script>

<style scoped lang="scss">
.cookie-buttons-content {
  position: absolute;
  bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-card);
  text-align: center;

  .button-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .button-content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      border-color: var(--color-field);
      border-style: solid;
      background: var(--color-background);
    }
  }
}

@media (max-width: 769px) {
  .cookie-buttons-content {
    max-height: 126px;
    padding: 24px 18px 16px;

    .button-container {
      max-height: 44px;
      column-gap: 10px;

      .button-content {
        max-width: 172px;
        border-radius: 10px;
        border-width: 1px;
      }
    }
  }
}
@media (max-width: 769px) and (min-height: 748px) and (max-height: 1000px) {
  .cookie-buttons-content {
    max-height: 144px;
    padding: 32px 18px 16px;

    .button-container {
      max-height: 44px;
      column-gap: 10px;

      .button-content {
        max-width: 191px;
        border-radius: 10px;
        border-width: 1px;
      }
    }
  }
}

@media (max-width: 375px) {
  .cookie-buttons-content {
    max-height: 126px;
    padding: 24px 18px 16px;

    .button-container {
      max-height: 42px;
      column-gap: 10px;

      .button-content {
        max-width: 164px;
        border-radius: 10px;
        border-width: 1px;
      }
    }
  }
}
@media (max-width: 320px) {
  .cookie-buttons-content {
    max-height: 112px;

    .button-container {
      max-height: 32px;

      .button-content {
        max-width: 137px;
      }
    }
  }
}
</style>