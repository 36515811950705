<template>
    <div class="main-title-router-container mobile" :class="{'not-active-bread-crumbs' : staticActiveBreadcrumbs}">
      <transition name="fade-opacity-lazy">
        <title-router-main-page v-if="activeBreadcrumbs"
                                :active-main-slide="activeMainSlide"/>
      </transition>

      <transition name="fade-opacity-lazy">
        <main-title-page v-if="(activeMainSlide > 0 && activeMainSlide < 6) && activeStateMainTitle"
                         :active-main-title="activeMainTitle"/>
      </transition>
    </div>
</template>

<script>
import TitleRouterMainPage from "@/components/shared/TitleRouterMainPage.vue";
import MainTitlePage from "@/components/entities/MainTitlePage.vue";
import {mapActions} from "vuex";

export default {
  name: "MainTitleRouterMobile",
  components: {MainTitlePage, TitleRouterMainPage},
  props: {
    activeMainSlide: {
      type: Number,
      required: true
    },
    activeMainTitle: {
      type: String,
      required: true
    },
    activeStateMainTitle: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      activeBreadcrumbs: false,
      staticActiveBreadcrumbs: false
    }
  },
  computed: {
    changeActiveSubSlide() {
      return this.$store.state.changeActiveSubSlide;
    }
  },
  mounted() {
    this.checkActiveBreadcrumbs();
  },
  watch: {
    activeMainTitle() {
      this.checkActiveBreadcrumbs();
    },
    changeActiveSubSlide() {
      if(this.changeActiveSubSlide === false) {
        if(this.activeMainSlide === 2) {
          this.changeTeam();
        }
      }
    }
  },
  methods: {
    ...mapActions(['persons/ACTIVE_TEAM_SLIDE']),
    changeTeam() {
      this.ACTIVE_TEAM_SLIDE(0);
      this.checkActiveBreadcrumbs();
    },
    checkActiveBreadcrumbs() {
      // console.log(this.changeActiveSubSlide)
      if(this.changeActiveSubSlide === false || this.$route.name.includes('team-type')) {
        this.activeBreadcrumbs = false
        setTimeout(() => {
          this.activeBreadcrumbs = this.activeMainSlide > 0 && this.activeMainSlide < 4;
          this.$nextTick(() => {
            this.staticActiveBreadcrumbs = this.activeMainSlide === 4 || this.activeMainSlide === 5;
          })
        }, 500)
      }
    }
  }
}
</script>

<style scoped lang="scss">

.main-title-router-container {
  width: 100%;
  position: absolute;
  top: var(--main-padding-top);
  &.not-active-bread-crumbs {
    .main-title-page {
      top: 0;
      margin-top: 0 !important;
    }
  }

}

.main-title-page {
  width: fit-content;
  position: relative;
  margin-top: 0;
  left: var(--controller-padding);
}

.title-router-main-page {
  position: absolute;
  top: 0;
}


@media (max-width: 769px) {
  .main-title-router-container {
    height: 52px;
    &.not-active-bread-crumbs {
      height: 32px;
    }
  }

  .main-title-page {
    top: 20px;
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .main-title-router-container {
    height: 52px;
    &.not-active-bread-crumbs {
      height: 32px;
    }
  }

  .main-title-page {
    top: 20px;
  }
}

@media (max-width: 375px){
  .main-title-router-container {
    height: 45px;
    &.not-active-bread-crumbs {
      height: 24px;
    }
  }

  .main-title-page {
    top: 16px;
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .main-title-router-container {
    height: 52px;
    &.not-active-bread-crumbs {
      height: 32px;
    }
  }

  .main-title-page {
    top: 16px;
  }
}


@media (max-width: 320px) {
  .main-title-router-container {
    height: 38px;
    &.not-active-bread-crumbs {
      height: 24px;
    }
  }

  .main-title-page {
    top: 14px;
  }
}


</style>