import {mapActions} from "vuex";


export default {
    methods: {
        ...mapActions('persons', ['ACTIVE_TEAM_SLIDE']),
        ...mapActions(['ACTIVE_ABOUT_SLIDE', 'ACTIVE_MAIN_SLIDE', 'CHANGE_ACTIVE_SUB_SLIDE', 'CHANGE_ACTIVE_MAIN_SLIDE', 'ACTIVE_SUB_PAGE', 'IS_LOADING']),
        focusTrue() {
            if(!this.activeMobile) {
                this.focusParam = true;
            }

        },
        focusFalse() {
            if(!this.activeMobile) {
                this.focusParam = false;
            }
        },

        getUrlBrand(picture) {
            return require('../assets/brands/' + picture)
        },

        getUrlImg(picture) {
            return require('../assets/' + picture)
        },

        getUrlAboutScreen(picture) {
            return require('../assets/about-screen/' + picture)
        },

        getUrlLogo(picture) {
            return require('../assets/logo/' + picture)
        },

        getUrlIcon(picture) {
            return require('../assets/icons/' + picture)
        },

        getBackground(picture) {
            return require('../assets/background/' + picture)
        },
        getUrlTeam(picture) {
            return require('../assets/team-screen/team/' + picture)
        },
        getUrlTeamScreen(picture) {
            return require('../assets/team-screen/' + picture)
        },

        checkSortedInfo(array, search, nameKey) {
            return array.filter(item => item[nameKey].toLowerCase().includes(search.toLowerCase()))
        },

        checkId(array,id) {
            return array.find(item => item.id === id);
        },

        changeMainSlide(id) {
            let href = ['im', 'mission', 'values', 'iminvest', 'partners']
                if(!this.activeSubPage) {
                    this.CHANGE_ACTIVE_MAIN_SLIDE(true)
                    this.CHANGE_ACTIVE_SUB_SLIDE(false)
                    this.ACTIVE_SUB_PAGE(false)
                }
                    switch (id) {
                        case 0:
                            this.$router.push({name: 'main'})
                            break;
                        case 1:
                            this.$router.push({name: 'about', params: {typeAbout: href[this.activeAboutSlide]}})
                            break;
                        case 2:
                            this.$router.push({name: 'team'})
                            break;
                        case 3:
                            this.$router.push({name: 'industry'})
                            break;
                        case 4:
                            this.$router.push({name: 'cases'})
                            break;
                        case 5:
                            this.$router.push({name: 'press'})
                            break;
                        case 6:
                            this.$router.push({name: 'contacts'})
                            break;
                    }

        },

        changeSubSlide(id, type) {
            this.changeSubActiveMainSlide(id, type)
        },



        changeSubActiveMainSlide(id, type) {
            if(type === 'company') {
                this.ACTIVE_ABOUT_SLIDE(id)
                switch(id) {
                    case 0:
                        this.$router.push({name: 'about', params: {typeAbout: 'im'}})
                        break;
                    case 1:
                        this.$router.push({name: 'about', params: {typeAbout: 'mission'}})
                        break;
                    case 2:
                        this.$router.push({name: 'about', params: {typeAbout: 'values'}})
                        break;
                    case 3:
                        this.$router.push({name: 'about', params: {typeAbout: 'iminvest'}})
                        break;
                    case 4:
                        this.$router.push({name: 'about', params: {typeAbout: 'partners'}})
                        break;
                }
            } else if(type === 'team') {
                switch(id) {
                    case 1:
                        this.$router.push({name: 'team-type', params: {typeTeam: 'partners'}})
                        break;
                    case 2:
                        this.$router.push({name: 'team-type', params: {typeTeam: 'exes'}})
                        break;
                    case 3:
                        this.$router.push({name: 'team-type', params: {typeTeam: 'empl'}})
                        break;
                }
                this.ACTIVE_TEAM_SLIDE(id)
            }
            this.CHANGE_ACTIVE_SUB_SLIDE(true)
            this.CHANGE_ACTIVE_MAIN_SLIDE(false)
        },

        datePublicationFormatChange(item) {
            let date = new Date(item);
            let options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            };
            if(this.$i18n.locale === 'ru') {
                return new Date(date.getFullYear(), date.getMonth(), date.getDate()).toLocaleString("ru", options);
            } else {
                return new Date(date.getFullYear(), date.getMonth(), date.getDate()).toLocaleString("en-UK", options);
            }
        }
    },


    computed: {
        activeEng() {
            return String(this.$i18n.locale) === 'en';
        },
        activeColor() {
            return this.$store.state.activeColor
        },

        mainControllerInfo() {
            return this.$tm('mainController.mainControllerInfo')
        },

        mainTitlePage() {
            return this.$tm('mainTitlePage')
        },

        activeMobile() {
            return this.$store.state.activeMobile;
        },

        activeSubPage() {
            return this.$store.state.activeSubPage;
        },
        activeAboutSlide() {
            return this.$store.state.activeAboutSlide;
        }
    }
}