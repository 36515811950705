<template>
  <div class="value-light-screen-container" @touchmove="changeTouch" @touchstart="changeTouchStart" @touchend="startX = null;
        this.stopTrigger = false">
    <line-controller-container :active-screen="true"
                               @changeActiveLine="changeActiveLine"
                               :active-index-line="activeIndexWorth"
                               :line-controller="infoCard"/>
      <div class="values-card-container">
          <transition name="fade-opacity-lazy">
            <card-value-light :active-screen="activeAnimation" :active-index-worth="activeIndexWorth" :card-info="activeInfoCard"/>
          </transition>
      </div>
  </div>
</template>

<script>
import LineControllerContainer from "@/components/entities/LineControllerContainer.vue";
import CardValueLight from "@/components/entities/CardValuesLight.vue";

export default {
  name: "ValueLightScreen",
  components: {CardValueLight, LineControllerContainer},
  computed: {
    infoCard() {
      return this.$tm('aboutScreen.values.valuesCard');
    },
    activeInfoCard() {
      return this.infoCard[this.activeIndexWorth];
    }
  },
  data() {
    return {
      activeIndexWorth: 0,
      activeAnimation: false,
      startX: null,
      stopTrigger: false
    }
  },
  mounted() {
    setTimeout(() => {
      this.activeAnimation = true
    }, 1000)
  },
  methods: {
    changeActiveLine(id) {
      this.activeIndexWorth = id
      this.activeAnimation = false
      setTimeout(() => {
        this.activeAnimation =  true
      }, 500)
    },

    changeTouch(e) {
      if(!this.stopTrigger) {
        this.stopTrigger = true;
        let currentX = e.touches[0].clientX;
        let deltaX = currentX - this.startX;
        if (deltaX > 0) {
          if(this.activeIndexWorth < 3) {
            this.changeActiveLine(this.activeIndexWorth + 1)
          }
        } else if (deltaX < 0) {
          if(this.activeIndexWorth !== 0) {
            this.changeActiveLine(this.activeIndexWorth - 1)
          }
        }
      }
    },
    changeTouchStart(e) {
      this.startX = e.touches[0].clientX
    }
  }
}
</script>

<style scoped lang="scss">
.value-light-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .values-card-container {
    display: inherit;
    align-items: center;
    width: inherit;
    height: 100%;
    position: relative;
  }
}

@media (max-width: 2560px){
  .value-light-screen-container {
    .values-card-container {
      max-height: 649px;
    }
  }
}

@media (max-width: 1920px) {
  .value-light-screen-container {
    .values-card-container {
      max-height: 774px;
    }
  }

}

@media (max-width: 1600px) {
  .value-light-screen-container {
    .values-card-container {
      max-height: 378px;
    }
  }
}
@media (max-height: 800px) {
  .value-light-screen-container {
    .values-card-container {
      max-height: 373px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .value-light-screen-container {
    margin-bottom: 128px;
    .values-card-container {
      max-height: 378px;
    }
  }
}

@media (max-width: 1133px) {
  .value-light-screen-container {
    .values-card-container {
      max-height: 328px;
    }
  }
}

@media (max-width: 769px) {
  .value-light-screen-container {
    .values-card-container {
      max-height: 100%;
    }
  }
}


@media (min-width: 2561px) {
  .value-light-screen-container {
    .values-card-container {
      max-height: 1380px;
    }
  }
}

</style>