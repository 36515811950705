import {api} from "@/providers/api";

export const industryModules = {
    namespaced: true,
    state: () => ({
        activeIndustrySlide: 0,
        activeIndustryTitle: '',
        activeIndustry: {},
        industryInfoRu: [],
        industryInfo: []
    }),
    getters: {
        industryAll: (state) => {
            return state.industryInfo
        },
        getActiveIndustry: (state) => {
            return state.activeIndustry
        },

    },
    mutations: {
        SET_ACTIVE_INDUSTRY_SLIDE: (state, payload) => {
            state.activeIndustrySlide = payload
        },
        SET_ACTIVE_INDUSTRY_TITLE: (state, payload) => {
            state.activeIndustryTitle = payload
        },
        SET_INDUSTRY: (state, payload) => {
            state.industryInfo = payload
        },
        SET_ACTIVE_INDUSTRY: (state, payload) => {
            state.activeIndustry = payload
        },
    },
    actions:{
        ACTIVE_INDUSTRY_SLIDE: (context, payload) => {
            context.commit('SET_ACTIVE_INDUSTRY_SLIDE', payload)
        },
        ACTIVE_INDUSTRY_TITLE: (context, payload) => {
            context.commit('SET_ACTIVE_INDUSTRY_TITLE', payload)
        },

        async getIndustry({commit}, { params } = { params: {}}) {
            let response = await api.main.mainControllerGetIndustries({
                ...params
            }).then(r => r.data);
            commit('SET_INDUSTRY', response)
        },
        async getActiveIndustry({commit}, { slug, params  } = {slug, params: {}}) {
            console.log('response')
            let response = await api.main.mainControllerGetIndustrySlug(slug,{
                ...params
            }).then(r => r.data);
            commit('SET_ACTIVE_INDUSTRY', response)
        },
    }
}