<template>
  <div class="loader">
    <large-title :active-cormorant="true"> {{ counter }}% </large-title>
    <lottie-container v-if="!activeColor"
                      :delay-setting="0"
                      :loop-setting="true"
                      :json-data="require('@/assets/lottie/PreloaderDarkTheme.json')"/>
    <lottie-container v-else
                      :delay-setting="0"
                      :loop-setting="true"
                      :json-data="require('@/assets/lottie/PreloaderLightTheme.json')"/>
  </div>

<!--  <span class="loader"></span>-->
</template>

<script>
import LottieContainer from "@/components/entities/LottieContainer.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";

export default {
  name: "Loader",
  components: {LargeTitle, MainTitle, SubParagraph, LottieContainer},
  data() {
    return {
      counter: 0,
      interval: ''
    }
  },
  mounted() {
    this.interval = setInterval(() => {
        this.numberCounter()
    }, 70)
  },
  unmounted() {
    clearInterval(this.interval)
  },
  methods: {
    numberCounter() {
      if(this.counter < 100) {
        this.counter ++;
      } else {
        this.counter = 0;
      }

    }
  }
}
</script>

<style scoped lang="scss">
.loader {
  position: absolute;
  max-width: 400px;
  display: flex;
  row-gap: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  width: 100%;
  pointer-events: none;

  .lottie-wrapper {
    width: 100%;
    position: relative;
  }
}



@media (max-width: 2560px){
  .loader {
    max-width: 500px;
    row-gap: 12px;
  }
}

@media (max-width: 2240px) {
  .loader {
    max-width: 450px;
    row-gap: 10px;
  }
}


@media (max-width: 1920px) {
  .loader {
    max-width: 400px;
    row-gap: 8px;
  }
}


@media (max-width: 1600px) {
  .loader {
    max-width: 375px;
    row-gap: 8px;
  }
}
@media (max-height: 800px) {
  .loader {
    max-width: 300px;
    row-gap: 6px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .loader {
    max-width: 375px;
    row-gap: 8px;
  }
}

@media (max-width: 1133px) {
  .loader {
    max-width: 250px;
    row-gap: 4px;
  }
}

@media (max-width: 769px) {
  //iPhone 14
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 2561px) {
  .loader {
    max-width: 900px;
    row-gap: 32px;
  }
}

</style>
