<template>
  <div class="footer-list-container" :class="{'eng' : activeEng }" v-if="infoFooter.id === 2">
    <footer-title :active-color-scheme="activeColorScheme">{{infoFooter.title}}</footer-title>
    <div class="href-container"
         :class="{'active-right' : activeRight}"
         @click="changeSlide(infoText)"
         v-show="getActiveTypeTeam[index]"
         v-for="(infoText, index) in infoFooter.info"
         :key="infoText.id">
      <sub-paragraph :active-cormorant="false" :class="{'active-color-scheme' : activeColorScheme}">
        {{ infoText.name }}
      </sub-paragraph>
    </div>
  </div>
  <div class="footer-list-container" :class="{'eng' : activeEng }" v-else-if="infoFooter.id === 0">
    <footer-title :active-color-scheme="activeColorScheme">{{infoFooter.title}}</footer-title>
    <div class="href-container"
         :class="{'active-right' : activeRight}"
         @click="changeSlide(infoText)"
         v-show="infoText.id !== 4 || activePartnersLength"
         v-for="(infoText, index) in infoFooter.info"
         :key="infoText.id">
      <sub-paragraph :active-cormorant="false" :class="{'active-color-scheme' : activeColorScheme}">
        {{ infoText.name }}
      </sub-paragraph>
    </div>
  </div>
  <div class="footer-list-container" :class="{'eng' : activeEng }" v-else>
    <footer-title :active-color-scheme="activeColorScheme">{{infoFooter.title}}</footer-title>
    <div class="href-container"
         :class="{'active-right' : activeRight}"
         @click="changeSlide(infoText)"
         v-for="(infoText, index) in infoFooter.info"
         :key="infoText.id">
      <sub-paragraph :active-cormorant="false" :class="{'active-color-scheme' : activeColorScheme}">
        {{ infoText.name }}
      </sub-paragraph>
    </div>
  </div>
</template>

<script>
import FooterTitle from "@/components/shared/footer/FooterTitle.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import {mapActions, mapGetters} from "vuex";

export default  {
  name: "FooterList",
  components: {SubParagraph, FooterTitle},
  props: {
    infoFooter: {
      type: Object,
      required: true
    },
    activeColorScheme: {
      type: Boolean,
      required: true
    },
    activeSubPage: {
      type: Boolean,
      default: false
    },
    activeRight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('persons', ['getActiveTypeTeam']),
    ...mapGetters('partners',['activePartnersLength']),
  },
  methods: {
    ...mapActions(['ACTIVE_ABOUT_SLIDE']),

    changeSlide(item) {
      if (item.typeTeam === undefined) {
        if(item.typeAbout === undefined) {
          if(item.id === 0) {
            this.$emit('openModal')
          } else {
            this.changeMainSlide(item.typePage)
          }
        } else {
            this.changeSubSlide(item.id, 'company')
        }
      } else {
        this.changeSubSlide(item.typeTeam, 'team')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.footer-list-container {

  &.eng {
    font-variant: all-small-caps !important;
  }

  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .small-title-container {
      margin-bottom: 0;
    }

  }

  .text-content {
    transition: opacity .6s ease;
    opacity: .6;
    color: #22223B;

    &:hover {
      opacity: 1;
    }

    &.active-color-scheme {
      color: var(--color-text);
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .href-container {
    display: flex;
    cursor: pointer;
    align-items: center;

    &.active-right {
     justify-content: flex-end;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}


@media (max-width: 2560px){
  .footer-list-container {

    .href-container {
      margin-bottom: 18px;

      .text-content {
        font-size: 16px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


@media (max-width: 1920px) {
  .footer-list-container {

    .href-container {
      margin-bottom: 16px;

      .text-content {
        font-size: 14px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
@media (max-width: 1600px) {
  .footer-list-container {

    .href-container {
      margin-bottom: 10px;

      .text-content {
        font-size: 9px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
@media (max-height: 800px) {
  .footer-list-container {

    .href-container {
      margin-bottom: 10px;

      .text-content {
        font-size: 9px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .footer-list-container {

    .href-container {
      margin-bottom: 11px;

      .text-content {
        font-size: 11px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 1133px) {
  .footer-list-container {

    .href-container {
      margin-bottom: 10px;

      .text-content {
        font-size: 8px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 769px) {
  .footer-list-container {

    .href-container {
      margin-bottom: 10px;

      .text-content {
        line-height: 120%;
        font-size: 13px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .footer-list-container {

    .href-container {
      margin-bottom: 12px;

      .text-content {
        line-height: 120%;
        font-size: 14px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


@media (max-width: 375px){
  .footer-list-container {

    .href-container {
      margin-bottom: 8px;

      .text-content {
        line-height: 120%;
        font-size: 11px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .footer-list-container {

    .href-container {
      margin-bottom: 10px;

      .text-content {
        line-height: 120%;
        font-size: 13px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


@media (max-width: 320px) {
  .footer-list-container {

    .href-container {
      margin-bottom: 8px;

      .text-content {
        line-height: 120%;
        font-size: 11px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}



@media (min-width: 2561px) {
  .footer-list-container {

    .href-container {
      margin-bottom: 36px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

</style>