<template>
  <div class="sub-controller-container">
    <div class="sub-text-controller-container">
      <div class="sub-text-controller-content"
           v-for="item in $tm('mainController.mainControllerInfo')"
           @click="changeMainSlide(item.id)"
           :key="item.id">
      <text-menu :text-menu-item="item.name" :sub-item-active="false"/>
      </div>
    </div>
    <div class="background-controller-container">
       <lottie-container :json-data="require('@/assets/lottie/MainBrash.json')"/>
    </div>
<!--    <div class="background-controller-container">-->
<!--      <img :src="getBackground(backgroundImg)" alt="background">-->
<!--    </div>-->
  </div>
</template>

<script>
import TextMenu from "@/components/shared/TextMenu.vue";
import LottieContainer from "@/components/entities/LottieContainer.vue";

export default {
  name: "SubController",
  components: {LottieContainer, TextMenu}
}
</script>

<style scoped lang="scss">
  .sub-controller-container {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;

    .sub-text-controller-container {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      height: 100%;
      .sub-text-controller-content {
        opacity: .6;
        transition: opacity .8s ease;

        &:hover {
          cursor: pointer;
          opacity: 1;
        }

        .text-menu-content {
          color: var(--color-first-text)
        }
      }

    }

    .background-controller-container {
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      z-index: 0;

      & img {
        width: 100%;
        height: 100%;
      }
    }

  }
  @media (min-width: 3840px) {
    .sub-controller-container {
      max-height: 320px;
      .sub-text-controller-container {
        padding: 0 1061px 72px 1160px;
      }
    }
  }


  @media (max-width: 2560px){
    .sub-controller-container {
      max-height: 180px;
      .sub-text-controller-container {
        padding: 0 732px 32px 781px;
      }
    }
  }

  @media (max-width: 2240px) {
    .sub-controller-container {
      max-height: 160px;
      .sub-text-controller-container {
        padding: 0 627px 32px 682px;
      }
    }
  }


  @media (max-width: 1920px) {
    .sub-controller-container {
      max-height: 135px;
      .sub-text-controller-container {
        padding: 0 595px 32px;
      }
    }
  }


  @media (max-width: 1600px) {
    .sub-controller-container {
      max-height: 120px;
      .sub-text-controller-container {
        padding: 0 428px 22px;
      }
    }
  }
  @media (max-height: 800px) {
    .sub-controller-container {
      max-height: 110px;
      .sub-text-controller-container {
        padding: 0 340px 22px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .sub-controller-container {
      .sub-text-controller-container {
        padding: 0 393px 22px 365px;
      }
    }
  }

  @media (max-width: 1133px) {
    .sub-controller-container {
      max-height: 85px;
      .sub-text-controller-container {
        padding: 0 328px 18px;
      }
    }
  }


  @media (min-width: 3841px) {
    .sub-controller-container {
      max-height: 320px;
      .sub-text-controller-container {
        padding: 0 1200px 72px 1450px;
      }
    }
  }

</style>