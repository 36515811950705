<template>
  <transition name="fade-opacity-lazy">
    <div class="pointer-container" v-if="(activeIndex === null) || (activeIndex === itemId)">
      <button-icon :img-name="imgPointer"/>
    </div>
  </transition>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";

export default {
  name: "PointerContainer",
  components: {ButtonIcon},
  props: {
    activeIndex: {
      default: null
    },
    itemId: {
      default: null
    },
    imgPointer: {
      default: 'single-arrow-right'
    }
  }
}
</script>

<style scoped lang="scss">
.pointer-container {
  position: absolute;
  width: 100%;
  height: 100%;

  .button-icon-container {
    max-width: inherit;
    max-height: inherit;
  }
  & img {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 2560px){
  .pointer-container {
    max-width: 16px;
    max-height: 32px;
  }
}


@media (max-width: 1920px) {
  .pointer-container {
    max-width: 12px;
    max-height: 24px;
  }
}

@media (max-width: 1600px) {
  .pointer-container {
    max-width: 9px;
    max-height: 18px;
  }
}
@media (max-height: 800px) {
  .pointer-container {
    max-width: 9px;
    max-height: 18px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .pointer-container {
    max-width: 9px;
    max-height: 18px;
  }
}

@media (max-width: 1133px) {
  .pointer-container {
    max-width: 8px;
    max-height: 16px;
  }
}

@media (max-width: 769px) {
  .pointer-container {
    max-width: 6px;
    max-height: 12px;
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .pointer-container {
      max-width: 6px;
      max-height: 12px;
    }
}


@media (max-width: 375px){
  .pointer-container {
  max-width: 5px;
  max-height: 10px;
}
}


@media (max-width: 320px) {
  .pointer-container {
    max-width: 4px;
    max-height: 8px;
  }
}


@media (min-width: 2561px) {
  .pointer-container {
    max-width: 24px;
    max-height: 48px;
  }
}

</style>