<template>
  <modal-frame :main-title="mainTitle"
               @submitInfo="submitInfo"
               :alert-text="alertText"
               @changeStateAlert="changeStateAlert"
               :disabled-button="disabledButtonTeam"
               @changeActivePolicy="changeActivePolicy">
    <div class="team-modal-container">
      <div class="single-input-container">
        <div class="single-input-content">
          <sub-paragraph :active-cormorant="false">{{$tm('modalContent.modalConsulting.singleNameInput').titleName}}</sub-paragraph>
          <input-main :active-glass="false"
                      @changeTextInput="changeTextInputActive"
                      :name-block="'single-input-container'"
                      :info="$tm('modalContent.modalConsulting.singleNameInput')"/>
        </div>
        <div class="single-company-position-container">
          <div class="single-input-content" v-for="info in $tm('modalContent.modalConsulting.singleInput')" :key="info.id">
            <sub-paragraph v-if="info.id !== 2" :active-cormorant="false">{{info.titleName}}</sub-paragraph>
            <input-main :active-glass="false"
                        @changeTextInput="changeTextInput"
                        :name-block="'single-input-container'"
                        :info="info"/>
          </div>
        </div>
        <div class="single-input-content">
          <sub-paragraph :active-cormorant="false">{{$tm('modalContent.modalConsulting.singleEmailInput').titleName}}</sub-paragraph>
          <input-main :active-glass="false"
                      @changeTextInput="changeTextInputActive"
                      :name-block="'single-input-container'"
                      :info="$tm('modalContent.modalConsulting.singleEmailInput')"/>
        </div>
      </div>

      <div class="textarea-container">
        <input-main :active-glass="false"
                    :length-text-area="300"
                    @changeTextInput="changeTextInputActive"
                    :name-block="'textarea-container'"
                    :info="$tm('modalContent.modalTeam.messageInput')"/>
      </div>
    </div>

  </modal-frame>
</template>

<script>
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import InputMain from "@/components/shared/InputMain.vue";
import ModalFrame from "@/components/entities/ModalFrame.vue";
import {api} from "@/providers/api";

export default {
  name: "ModalConsulting",
  components: {SubParagraph, InputMain, ModalFrame},
  props: {
    mainTitle: {
      type: String,
      required: true
    },
    typeSubmit: {
      type: String,
      default: 'consulting'
    }
  },
  data() {
    return {
      alertText: '',
      inputValuesActive: {
        name: '',
        contact: '',
        text: ''
      },
      inputValues: {
        company: '',
        post: '',
      },
      message: '',
      policyButton: false
    }
  },
  computed: {
    isValid() {
      return Object.values(this.inputValuesActive).every(v => v.length)
    },
    isValidName() {
      return this.inputValuesActive.name.length !== 0;
    },
    isValidContact() {
      return this.inputValuesActive.contact.length !== 0;
    },
    isValidMessage() {
      return this.inputValuesActive.text.length !== 0;
    },
    disabledButtonTeam() {
      return !(this.isValid && this.policyButton);
    }
  },

  methods: {
    changeStateAlert() {
      if(this.$i18n.locale === 'ru') {
        if(!this.isValidName && !this.isValidMessage && !this.isValidContact && !this.policyButton) {
          this.alertText = 'Введите все данные'
        } else if(!this.isValid) {
          if (!this.isValidName) {
            this.alertText = 'Введите имя'
          } else if (!this.isValidContact) {
            this.alertText = 'Введите почту'
          } else if (!this.isValidMessage) {
            this.alertText = 'Введите сообщение'
          }
        } else if (!this.policyButton) {
          this.alertText = 'Ознакомьтесь с Политикой в области обработки персональных данных'
        }
      } else if (this.$i18n.locale === 'en') {
        if(!this.isValidName && !this.isValidMessage && !this.isValidContact && !this.policyButton) {
          this.alertText = 'Enter the data'
        } else if(!this.isValid) {
          if (!this.isValidName) {
            this.alertText = 'Enter the name'
          } else if (!this.isValidContact) {
            this.alertText = 'Enter the email'
          } else if (!this.isValidMessage) {
            this.alertText = 'Enter the message'
          }
        } else if (!this.policyButton) {
          this.alertText = 'Read the personal data processing policy'
        }
      }
      setTimeout(() => {
        this.alertText = ''
      }, 1000)
    },

    changeTextInputActive(text, dataIndex) {
      this.inputValuesActive[dataIndex] = text;
    },
    changeTextInput(text, dataIndex) {
      this.inputValues[dataIndex] = text;
    },
    changeActivePolicy(status) {
      this.policyButton = status
    },

    async submitInfo() {
      this.$emit('closeModal');
      if(this.typeSubmit === 'consulting') {
        try {
          let response = await api.main.mainControllerSendCommercial({
            name: this.inputValuesActive.name,
            company: this.inputValues.company,
            post: this.inputValues.post,
            contact: this.inputValuesActive.contact,
            text: this.inputValuesActive.text
          }).then(r => r.data);
          console.log(response)
        } catch (e) {
          console.log(e)
        }
      } else {
        try {
          let response = await api.main.mainControllerSendPress({
            name: this.inputValuesActive.name,
            company: this.inputValues.company,
            post: this.inputValues.post,
            contact: this.inputValuesActive.contact,
            text: this.inputValuesActive.text
          }).then(r => r.data);
          console.log(response)
        } catch (e) {
          console.log(e)
        }
      }

    }
  }

}
</script>

<style scoped lang="scss">
.team-modal-container {
  display: flex;
  flex-direction: column;

  .single-input-container {
    display: flex;
    flex-direction: column;
  }

  .single-company-position-container {
    display: flex;
    flex-direction: column;
  }

  .textarea-container {

    .input-container {
      height: 100%;
    }
  }
}

@media (max-width: 2560px){
  .team-modal-container {
    row-gap: 28px;
    margin-bottom: 56px;

    .single-input-container {
      row-gap: 18px;

      .text-content {
        margin-bottom: 9px;
      }
    }

    .single-company-position-container {
      row-gap: 9px;
    }


    //.textarea-container {
    //  height: 116px;
    //}
  }
}



@media (max-width: 1920px) {
  .team-modal-container {
    row-gap: 24px;
    margin-bottom: 24px;

    .single-input-container {
      row-gap: 16px;

      .text-content {
        margin-bottom: 8px;
      }
    }

    .single-company-position-container {
      row-gap: 8px;
    }

    //.textarea-container {
    //  height: 80px;
    //}
  }
}


@media (max-width: 1600px) {
  .team-modal-container {
    row-gap: 16px;
    margin-bottom: 16px;

    .single-input-container {
      row-gap: 10px;

      .text-content {
        margin-bottom: 5px;
      }
    }

    .single-company-position-container {
      row-gap: 5px;
    }

    .file-input-content {
      height: 54px;
    }

    //.textarea-container {
    //  height: 66px;
    //}
  }
}


@media (max-width: 1133px) {
  .team-modal-container {
    row-gap: 14px;
    margin-bottom: 14px;

    .single-input-container {
      row-gap: 9px;

      .text-content {
        margin-bottom: 5px;
      }
    }

    .file-input-content {
      height: 48px;
    }

    //.textarea-container {
    //  height: 60px;
    //}
  }
}

@media (max-width: 769px) {
  .team-modal-container {
    row-gap: 16px;
    margin-bottom: 16px;

    .single-input-container {
      row-gap: 8px;

      .text-content {
        font-size: 12px;
        margin-bottom: 4px;
      }
    }
  }
}

@media (max-width: 320px) {
  .team-modal-container {
    row-gap: 12px;
    margin-bottom: 12px;

    .single-input-container {
      row-gap: 8px;

      .text-content {
        font-size: 10px;
        margin-bottom: 4px;
      }
    }
  }
}


@media (min-width: 2561px) {
  .team-modal-container {
    row-gap: 56px;
    margin-bottom: 56px;

    .single-input-container {
      row-gap: 36px;

      .text-content {
        margin-bottom: 18px;
      }
    }

    .single-company-position-container {
      row-gap: 18px;
    }

    //.textarea-container {
    //  height: 234px;
    //}
  }
}

</style>