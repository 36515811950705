<template>
  <div class="team-screen-start-container">
      <modal-team v-if="activeModalTeam"
                @closeModal="changeModalTeam"/>
    <div class="team-screen-container">
      <div class="team-start-top-content">
        <div class="team-paragraph-container">
          <transition name="fade-opacity-animation">
            <large-title :active-cormorant="true" v-show="activeText">
              {{$t('teamScreen.teamStartScreen.paragraphContent')}}
            </large-title>
          </transition>
        </div>
        <controller-team v-if="activeTeamController" :active-team-index="activeTeamIndex" @changeTeamActive="changeTeamActive"/>
      </div>
      <div class="team-start-bottom-content">
        <transition name="fade-opacity-animation">
          <div class="team-button-container" v-if="activeButtonModal && !activeMobile">
            <button-large :nameImg="'user'"  :main-title="$t('teamScreen.teamStartScreen.textButton')" @clickToState="changeModalTeam"/>
<!--            <main-paragraph>{{$t('teamScreen.teamStartScreen.textButton')}}</main-paragraph>-->
          </div>
          <div class="team-button-container" v-else-if="activeButtonModal && activeMobile" @click="changeModalTeam">
            <button-main-mobile :text-button="$t('teamScreen.teamStartScreen.textButton')"/>
          </div>
        </transition>
      </div>
    </div>
  </div>



</template>

<script>
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import ModalTeam from "@/components/entities/ModalTeam.vue";
import {mapActions} from "vuex";
import PointerContainer from "@/components/shared/PointerContainer.vue";
import ControllerTeam from "@/components/entities/ControllerTeam.vue";
import ButtonMainMobile from "@/components/shared/ButtonMainMobile.vue";
import ButtonLarge from "@/components/shared/ButtonLarge.vue";

export default {
  name: "TeamStartScreen",
  components: {ButtonLarge, ButtonMainMobile, ControllerTeam, PointerContainer, ModalTeam, MainParagraph, LargeTitle},
  data() {
    return {
      activeModalTeam: false,
      activeTeamController: false,
      activeButtonModal: false,
      activeText: false
    }
  },
  mounted() {
    this.showNextItem();
    // this.loadingData();
  },
  computed: {
    activeTeamIndex() {
      return this.$store.state.persons.activeTeamSlide;
    },
  },
  methods: {
    ...mapActions('persons', ['ACTIVE_TEAM_SLIDE']),
    ...mapActions(['CHANGE_ACTIVE_MAIN_SLIDE', 'IS_LOADING_IMG']),

    // loadingData() {
    //   this.IS_LOADING(false)
    //   this.IS_LOADING_IMG(false)
    // },

    changeTeamActive(id) {
      this.$emit('openTeamSliderScreen', id)
    },
    changeModalTeam() {
      this.activeModalTeam = !this.activeModalTeam
    },

    showNextItem() {
      this.activeText = true;
        setTimeout(() => {
          this.activeTeamController = true
          setTimeout(() => {
            this.activeButtonModal = true
          }, 500)
        }, 500)

    },
  }
}
</script>

<style scoped lang="scss">
.team-screen-start-container {
  width: 100%;
  position: relative;
  height: 100%;
  background: var(--color-background);

  .team-screen-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    justify-content: flex-end;

    .team-start-top-content {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-basis: 96%;

      .team-paragraph-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        .text-content {
          font-weight: 400;
          line-height: 170%;
        }
      }
    }


    .team-start-bottom-content {
      position: relative;
      z-index: 1;
      .team-button-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .text-content {
          cursor: pointer;
          text-shadow: 0 0 6px var(--color-text);
          text-transform: uppercase;
          font-family: 'CormorantUnicase-Bold', sans-serif;
        }

      }
    }
  }
}

@media (max-width: 2560px){
  .team-screen-start-container {
    .team-screen-container {
      .team-start-top-content {
        .team-paragraph-container {
          max-width: 714px;
          height: 297px;
          row-gap: 32px;

          .text-content {
            font-size: 38px;
          }
        }
      }


      .team-start-bottom-content {
        height: 72px;
        .team-button-container {
          max-width: 778px;

          .text-content {
            letter-spacing: 4.2px
          }
        }
      }
    }
  }
}

@media (max-width: 2240px) {
  .team-screen-start-container {
    .team-screen-container {
      .team-start-top-content {
        .team-paragraph-container {
          max-width: 719px;
        }
      }

      .team-start-bottom-content {
        height: 66px;
        .team-button-container {
          max-width: 650px;
        }
      }
    }
  }
}


@media (max-width: 1920px) {
  .team-screen-start-container {
    .team-screen-container {

      .team-start-top-content {
        .team-paragraph-container {
          max-width: 520px;
          height: 205px;
          row-gap: 32px;
          .text-content {
            font-size: 24px;
          }
        }
      }


      .team-start-bottom-content {
        margin-bottom: 32px;
        height: 60px;
        .team-button-container {
          max-width: 650px;
          .text-content {
            letter-spacing: 3.6px;
          }
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .team-screen-start-container {
    .team-screen-container {
      .team-start-top-content {
        .team-paragraph-container {
          max-width: 434px;
          height: 189px;
          row-gap: 32px;
          .text-content {
            font-size: 22px;
          }
        }
      }

      .team-start-bottom-content {
        margin-bottom: 20px;
        height: 40px;
        .team-button-container {
          max-width: 420px;
        }
      }
    }
  }
}
@media (max-height: 800px) {
  .team-screen-start-container {
    .team-screen-container {
      .team-start-top-content {
        .team-paragraph-container {
          max-width: 421px;
          height: 189px;
          row-gap: 24px;

          .text-content {
            font-size: 20px;
          }
        }
      }


      .team-start-bottom-content {
        margin-bottom: 20px;
        height: 40px;
        .team-button-container {
          max-width: 420px;
        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .team-screen-start-container {
    .team-screen-container {
      .team-start-top-content {
        .team-paragraph-container {
          max-width: 378px;
          height: 302px;
          row-gap: 24px;
          .text-content {
            font-size: 24px;
          }
        }
      }


      .team-start-bottom-content {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 1133px) {
  .team-screen-start-container {
    .team-screen-container {
      .team-start-top-content {
        .team-paragraph-container {
          max-width: 342px;
          height: 162px;
          row-gap: 24px;

          .text-content {
            font-size: 19px;
          }
        }
      }


      .team-start-bottom-content {
        margin-bottom: 36px;
        height: 34px;
        .team-button-container {
          max-width: 350px;
        }
      }
    }
  }
}

@media (max-width: 769px) {
  .team-screen-start-container {
    .team-screen-container {
      .team-start-top-content {
        flex-direction: column;
        .team-paragraph-container {
          flex-basis: 10%;
          max-width: 100%;
          height: 100%;

          .text-content {
            text-transform: inherit;
            line-height: 140%;
            letter-spacing: normal;
            font-family: 'Raleway', sans-serif;
            font-weight: 400;
            font-size: 12px;
          }
        }
      }


      .team-start-bottom-content {
        height: 38px;
        margin-left: 0;
        margin-bottom: 0;
        .team-button-container {
          max-width: 100%;

          .main-button-container {
            border-radius: 12px;
            padding: 14px 24px;
          }
        }
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .team-screen-start-container {
    .team-screen-container {
      .team-start-top-content {
        flex-direction: column;
        .team-paragraph-container {
          flex-basis: 10%;
          max-width: 100%;
          height: 100%;

          .text-content {
            font-weight: 400;
            font-size: 14px;
          }
        }
      }


      .team-start-bottom-content {
        height: 41px;
        margin-left: 0;
        margin-bottom: 16px;
        .team-button-container {
          max-width: 100%;

          .main-button-container {
            border-radius: 12px;
            padding: 14px 24px;
          }
        }
      }
    }
  }
}


@media (max-width: 375px){
  .team-screen-start-container {
    .team-screen-container {
      .team-start-top-content {
        .team-paragraph-container {
          .text-content {
            font-size: 12px;
          }
        }
      }


      .team-start-bottom-content {
        height: 29px;
        margin-left: 0;
        margin-bottom: 26px;
        .team-button-container {
          max-width: 100%;

          .main-button-container {
            border-radius: 8px;
            padding: 10px 12px;
          }
        }
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .team-screen-start-container {
    .team-screen-container {
      .team-start-bottom-content {
        height: 38px;
        margin-left: 0;
        .team-button-container {
          max-width: 100%;

          .main-button-container {
            border-radius: 12px;
            padding: 14px 24px;
          }
        }
      }
    }
  }
}


@media (max-width: 320px) {
  .team-screen-start-container {
    .team-screen-container {
      .team-start-top-content {
        .team-paragraph-container {
          .text-content {
            font-size: 10px;
          }
        }
      }


      .team-start-bottom-content {
        height: 27px;
        margin-left: 0;
        margin-bottom: 24px;
        .team-button-container {
          max-width: 100%;

          .main-button-container {
            border-radius: 8px;
            padding: 10px 18px;
          }
        }
      }
    }
  }
}


@media (min-width: 2561px) {
  .team-screen-start-container {
    .team-screen-container {
      .team-start-top-content {
        .team-paragraph-container {
          max-width: 1497px;
          height: 567px;
          row-gap: 64px;
        }
      }


      .team-start-bottom-content {
        margin-bottom: -8px;
        height: 128px;
        .team-button-container {
          max-width: 1404px;

          .text-content {
            letter-spacing: 8.4px
          }
        }
      }
    }
  }
}

</style>