import { createRouter, createWebHistory } from 'vue-router'
import MainPage from "@/views/main-page.vue";
import NewsPage from "@/views/news-page.vue";
import CasePage from "@/views/case-page.vue";
import TeamPage from "@/views/team-page.vue";
import MainLayout from "@/layout/MainLayout.vue";
import store from "@/store";
import IndustryScreen from "@/components/features/IndustryScreen.vue";
import TeamScreen from "@/components/features/TeamScreen.vue";
import AboutScreen from "@/components/features/AboutScreen.vue";
import CaseScreen from "@/components/features/CaseScreen.vue";
import NewsScreen from "@/components/features/NewsScreen.vue";
import FooterScreen from "@/components/features/FooterScreen.vue";
import StartScreen from "@/components/features/StartScreen.vue";

const routes = [
    {
        path: '/',
        name: 'MainLayout',
        component: MainLayout,
        children: [
            {
                path: '/:pathMatch(.*)*',
                name: 'not-found-page',
                component: MainPage
            },
            {
                path: '/',
                name: 'home-page',
                meta: {
                    activeMain: true
                },
                component: MainPage,
                beforeEnter: (to, from, next) => {
                    store.dispatch('ACTIVE_SUB_PAGE', false)
                    document.body.style.setProperty('overflow-y', 'auto');
                    next();
                },
                children: [
                    {   path: '/main',
                        name: 'main',
                        meta: {
                            activeMain: true
                        },
                        component: StartScreen,
                    },
                    {   path: '/contacts',
                        name: 'contacts',
                        meta: {
                            activeMain: true
                        },
                        component: FooterScreen,
                    },
                    {   path: '/cases',
                        name: 'cases',
                        meta: {
                            activeMain: true
                        },
                        component: CaseScreen,
                    },
                    {   path: '/press',
                        name: 'press',
                        meta: {
                            activeMain: true
                        },
                        component: NewsScreen,
                    },
                    {   path: '/area',
                        name: 'industry',
                        meta: {
                            activeMain: true
                        },
                        props: true,
                        component: IndustryScreen,
                        children: [
                            {   path: 'practice/:slugIndustry',
                                props: true,
                                name: 'industry-practice',
                                component: IndustryScreen,
                                children: [
                                    {   path: ':slugPractice/service',
                                        name: 'industry-service',
                                        props: true,
                                        component: IndustryScreen
                                    },
                                ]
                            },
                        ]
                    },
                    {   path: '/about/team',
                        name: 'team',
                        meta: {
                            activeMain: true
                        },
                        component: TeamScreen,
                        children: [
                            {   path: ':typeTeam',
                                props: true,
                                name: 'team-type',
                                component: TeamScreen,
                            },
                        ]
                    },
                    {   path: '/about/:typeAbout',
                        name: 'about',
                        meta: {
                            activeMain: true
                        },
                        props: true,
                        component: AboutScreen
                    },
                ]
            },
            {
                path: '/about/team/:typeUser/:slug/:slide',
                props: true,
                name: 'person-page',
                meta: {
                    activeMain: false
                },
                component: TeamPage,
                beforeEnter: (to, from, next) => {
                    store.dispatch('ACTIVE_SUB_PAGE', true)
                    document.body.style.setProperty('overflow-y', 'hidden');
                    next();
                }
            },
            {
                path: '/cases/:slug',
                name: 'case-page',
                props: true,
                meta: {
                    activeMain: false
                },
                component: CasePage,
                beforeEnter: (to, from, next) => {
                    store.dispatch('ACTIVE_SUB_PAGE', true)
                    document.body.style.setProperty('overflow-y', 'auto');
                    next();
                }
            },
            {
                path: '/press/:slug',
                name: 'news-page',
                props: true,
                meta: {
                    activeMain: false
                },
                component: NewsPage,
                beforeEnter: (to, from, next) => {
                    store.dispatch('ACTIVE_SUB_PAGE', true)
                    document.body.style.setProperty('overflow-y', 'auto');
                    next();
                }
            },
        ]
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { left: 0, top: 0, behavior: 'smooth' }
        }
    },
})

router.beforeEach((to, from, next) => {
    next();
})

export default router
