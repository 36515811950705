<template>
  <div class="slider-industry-content-container">
<!--    <div class="swiper-button swiper-button-top">-->
<!--      <transition name="fade-opacity-lazy">-->
<!--        <div>-->
<!--          <img v-if="activeColor" :src="getUrlIcon(imgNameTop + '-dark.svg')" alt="icon">-->
<!--          <img v-else :src="getUrlIcon(imgNameTop + '-light.svg')" alt="icon">-->
<!--        </div>-->
<!--      </transition>-->
<!--    </div>-->
    <slider-frame :modules="modules"
                  :active-overflow-hide="false"
                  :activeMouseWheel="true"
                  :active-controller="{
                    left: false,
                    right: false
                  }"
                  :active-gradient="false"
                  :change-slide="changeSlideScroll"
                  :name-navigation="{
                    nextEl: '.swiper-button-bottom',
                    prevEl: '.swiper-button-top'
                  }"
                  :class="{'industry' : activeNameClass === 'industry',
                            'practice' : activeNameClass === 'practice',
                            'services' : activeNameClass === 'services'}"
                  :swiper-setting="settingSwiper"
                  :length-info="activeInfo.length"
                  @changeRealIndex="changeActiveIndex">
      <swiper-slide v-for="(item, index) in lengthInfo"
                    class="clickable"
                    v-slot="{ isActive }"
                    :key="index">
        <div class="industry-title-content" :class="{'active-slide' : isActive }" @click="chooseInfo(index, activeInfo[index]?.slug, isActive)">
          <large-title :class="{'industry' : activeNameClass === 'industry', 'service' : activeNameClass === 'services'}"
                       @mouseenter="focusTrue(isActive)" @mouseleave="focusFalse"
                       :active-cormorant="true">
            {{ activeInfo[index]?.name }}
          </large-title>
          <pointer-container  :class="{ 'active': index === activeIndexSlide  }" v-if="activeNameClass !== 'services'"/>
<!--          <transition name="fade-opacity-lazy">-->
<!--            <pointer-container :class="{ 'active': focusParam && index === activeIndexSlide }" v-if="!activeMobile && activeNameClass !== 'services'"/>-->
<!--          </transition>-->
        </div>
      </swiper-slide>
    </slider-frame>
<!--    <div class="swiper-button swiper-button-bottom">-->
<!--      <transition name="fade-opacity-lazy">-->
<!--        <div>-->
<!--          <img v-if="activeColor" :src="getUrlIcon(imgNameBottom + '-dark.svg')" alt="icon">-->
<!--          <img v-else :src="getUrlIcon(imgNameBottom + '-light.svg')" alt="icon">-->
<!--        </div>-->
<!--      </transition>-->
<!--    </div>-->
  </div>

</template>

<script>
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import {Mousewheel, Navigation} from "swiper";
import PointerContainer from "@/components/shared/PointerContainer.vue";
import SliderFrame from "@/components/entities/SliderFrame.vue";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";

export default {
  name: "SliderIndustryScreen",
  components: {ButtonIcon, PointerContainer, SliderFrame, LargeTitle},
  props: {
    activeNameClass: {
      type: String,
      required: true
    },
    settingSwiper: {
      type: Object,
      required: true
    },
    activeInfo: {
      type: Array,
      required: true
    },
    nameId: {
      type: String
    }
  },
  data() {
    return {
      activeIndexSlide: 0,
      modules: [Navigation, Mousewheel],
      focusParam: false,
      imgNameTop: 'single-arrow-bottom',
      imgNameBottom: 'single-arrow-top',
      changeSlideScroll: false
    }
  },
  computed: {
    lengthInfo() {
      return this.activeInfo.length;
    }
  },
  methods: {

    focusTrue(stateTitle) {
      if(stateTitle) {
        this.focusParam = true
      }
    },


    changeActiveIndex(id) {
      this.activeIndexSlide = id
      this.$emit('changeInfoActive', id)
    },
    chooseInfo(idSlide, idSlug, stateSlide) {
      if(stateSlide) {
        this.$emit('changeIndexActive', idSlug)
      } else {
        this.changeSlide(idSlide)
      }

    },
      changeSlide(index) {
        this.changeSlideScroll = {
          id: index,
          state: true
        }
        setTimeout(() => {
          this.changeSlideScroll = {
            id: -1,
            state: false
          }
        })
      }
   }
}
</script>

<style scoped lang="scss">
.pointer-container {
  left: 0;
}

.slider-industry-content-container {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-height: 100%;
.swiper-button {
z-index: 998;
position: absolute;
width: 100%;
height: 100%;

& img {
  cursor: pointer;
  opacity: .6;
  transition: opacity .6s ease;

  &:hover {
    opacity: 1;
  }
}

&.swiper-button-top {
  top: -1%;
}

&.swiper-button-bottom {
  bottom: 3px;
}


& img {
  width: 100%;
  height: 100%;
}
}

}


.slider-content-container {
max-height: inherit;
width: 100%;
height: 100%;
position: relative;

  .industry-title-content {
    .pointer-container {
      opacity: .6;
    }
    &:hover {
      .pointer-container {
        opacity: 1;
      }
    }
  }

  .industry-title-content {
    transform: scale(0.8);
    &.active-slide {
      transform: scale(1)
    }
  }

&.industry {
.industry-title-content {
  text-transform: uppercase;
}

}

&.practice {
.industry-title-content {
  .text-content {
    letter-spacing: normal !important;
    text-transform: inherit;
  }
}
}

&.services {
.industry-title-content {
  .text-content {
    letter-spacing: normal !important;
    text-transform: inherit;
  }
}


//.industry-title-content {
//  width: 100%;
//  transform: scale(1);
//  opacity: .6;
//  transition: opacity 1.5s ease;
//  &.active-slide {
//    opacity: 1;
//  }
//  .text-content {
//    font-family: 'Raleway', sans-serif;
//    font-weight: 400;
//    line-height: 150%;
//    text-transform: none;
//    letter-spacing: normal;
//  }
//
//}
}

}



.industry-title-content {
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
transition: all  1.5s ease;
position: relative;
text-align: center;
justify-content: center;
  cursor: pointer;

.text-content {
width: fit-content;
}

.pointer-container {
position: relative;
transition: all .6s ease;
max-height: 0;
max-width: 0;
width: 100%;
height: 100%;
}


}

@media (max-width: 2560px){


.swiper-button {
max-width: 61px;
max-height: 29px;
}

.slider-content-container {

.industry-title-content {
  padding: 0 36px;
  column-gap: 18px;
}

&.industry {
  .industry-title-content {
    .text-content {
      font-size: 80px;
      letter-spacing: 16px;
    }

    .pointer-container {
      &.active {
        max-height: 48px;
        max-width: 24px;
      }
    }
  }
}

&.practice {
  .industry-title-content {
    .text-content {
      font-size: 32px;
      letter-spacing: 3.2px;
    }
    .pointer-container {
      &.active {
        max-height: 24px;
        max-width: 12px;
      }
    }
  }
}

&.services {
  .industry-title-content {
    .text-content {
      font-size: 32px;
    }
  }
}
}
}


@media (max-width: 2240px) {
  .swiper-button {
    max-width: 61px;
    max-height: 29px;
  }

  .slider-content-container {

    .industry-title-content {
      padding: 0 32px;
      column-gap: 18px;
    }

    &.industry {
      .industry-title-content {
        .text-content {
          font-size: 64px;
          letter-spacing: 14px;
        }

        .pointer-container {
          &.active {
            max-height: 48px;
            max-width: 24px;
          }
        }
      }
    }

    &.practice {
      .industry-title-content {
        .text-content {
          font-size: 28px;
          letter-spacing: 2.8px;
        }
        .pointer-container {
          &.active {
            max-height: 24px;
            max-width: 12px;
          }
        }
      }
    }

    &.services {
      .industry-title-content {
        .text-content {
          font-size: 28px;
        }
      }
    }
  }
}

@media (max-width: 1920px) {

.swiper-button {
max-width: 39px;
max-height: 18px;

&.swiper-button-bottom {
  bottom: 16px !important;
}
}

.slider-content-container {

.industry-title-content {
  column-gap: 16px;
}


&.industry {
  .industry-title-content {
    .text-content {
      font-size: 48px;
      letter-spacing: 12px;
    }

    .pointer-container {
      &.active {
        max-height: 38px;
        max-width: 19px;
      }
    }
  }
}

&.practice {
  .industry-title-content {
    .text-content {
      font-size: 24px;
      letter-spacing: 2.4px;
    }
    .pointer-container {
      &.active {
        height: 18px;
        width: 9px;
      }
    }
  }
}

&.services {
  .industry-title-content {
    .text-content {
      font-size: 24px;
    }
  }
}
}
}


@media (max-width: 1600px) {
.swiper-button {
max-width: 30px;
max-height: 15px;
}


.slider-content-container {

  .industry-title-content {
    padding: 0 32px;
  }

&.industry {
  .industry-title-content {
    .text-content {
      font-size: 40px;
      letter-spacing: 8px;
    }

    .pointer-container {
      &.active {
        max-height: 32px;
        max-width: 15px;
      }
    }
  }
}

&.practice {
  max-height: 718px;
  .industry-title-content {
    .text-content {
      font-size: 16px;
      letter-spacing: 1.8px;
    }
    .pointer-container {
      &.active {
        max-height: 16px;
        max-width: 8px;
      }
    }
  }
}

&.services {
  max-height: 662px;
  .industry-title-content {
    .text-content {
      font-size: 16px;
    }
  }
}
}
}
@media (max-height: 800px) {
.swiper-button {
max-width: 30px;
max-height: 15px;
}

.slider-content-container {

  .industry-title-content {
    padding: 0 24px;
  }

&.industry {
  .industry-title-content {
    .text-content {
      font-size: 32px;
      letter-spacing: 8px;
    }
  }
}

&.practice {
  .industry-title-content {
    .text-content {
      font-size: 16px;
      letter-spacing: 1.6px;
    }
  }
}

&.services {
  .industry-title-content {
    .text-content {
      font-size: 16px;
    }
  }
}
}
}

@media (max-width: 1400px) and (min-height: 801px) {
.swiper-button {
max-width: 26px;
max-height: 12px;
}

.slider-content-container {

  .industry-title-content {
    padding: 0 24px;
  }

&.industry {
  .industry-title-content {
    .text-content {
      font-size: 36px;
      letter-spacing: 8.8px;
    }
  }
}

&.practice {
  .industry-title-content {
    .text-content {
      font-size: 18px;
      letter-spacing: 1.8px;
    }
  }
}

&.services {
  .industry-title-content {
    .text-content {
      font-size: 18px;
    }
  }
}
}
}

@media (max-width: 1133px) {

.swiper-button {
max-width: 26px;
max-height: 12px;
}

.slider-content-container {

  .industry-title-content {
    padding: 0 24px;
  }

&.industry {
  .industry-title-content {
    .text-content {
      font-size: 28px;
      letter-spacing: 7.2px;
    }
  }
}

&.practice {
  .industry-title-content {
    .text-content {
      font-size: 14px;
      letter-spacing: 1.6px;
    }
  }
}

&.services {
  .industry-title-content {
    .text-content {
      font-size: 14px;
    }
  }
}
}
}

@media (max-width: 769px) {
.text-content {
line-height: 100%;
text-transform: inherit;
font-family: 'Raleway', sans-serif;
font-weight: 400;
}

.swiper-button {
display: none;
}

.slider-content-container {

.industry-title-content {
  column-gap: 16px;
}

.industry-title-content {
  transform: scale(0.85);
  text-transform: none !important;
  .text-content {
    font-variant: small-caps;
    letter-spacing: normal !important;
  }
}
}
.slider-content-container {
&.industry {
  .industry-title-content {
    .text-content {
      font-size: 16px;
      letter-spacing: normal;
    }

    .pointer-container {
      &.active {
        margin-top: 5px;
        max-height: 16px;
        max-width: 8px;
      }
    }
  }
}

&.practice {
  .industry-title-content {
    .text-content {
      font-size: 15px;
      letter-spacing: normal;
    }
    .pointer-container {
      &.active {
        margin-top: 5px;
        max-height: 15px;
        max-width: 7.5px;
      }
    }
  }
}

&.services {
  .industry-title-content {
    transition: transform .6s ease;

    &.active-slide {
      transform: scale(1);
    }

    .text-content {
      font-size: 14px;
    }
  }
}
}
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
.text-content {
line-height: 100%;
text-transform: inherit;
font-family: 'Raleway', sans-serif;
font-weight: 400;
}

.slider-content-container {
&.industry {
  .industry-title-content {
    .text-content {
      font-size: 18px;
      letter-spacing: normal;
    }

    .pointer-container {
      &.active {
        margin-top: 5px;
        max-height: 18px;
        max-width: 9px;
      }
    }
  }
}

&.practice {
  .industry-title-content {
    .text-content {
      font-size: 16px;
    }

    .pointer-container {
      &.active {
        margin-top: 3px;
        max-height: 16px;
        max-width: 8px;
      }
    }
  }
}

&.services {
  .industry-title-content {
    .text-content {
      font-size: 14px;
    }
  }
}
}
}


@media (max-width: 375px){
.slider-content-container {
&.industry {
  .industry-title-content {
    .text-content {
      font-size: 16px;
    }

    .pointer-container {
      &.active {
        margin-top: 5px;
        max-height: 16px;
        max-width: 8px;
      }
    }
  }
}
&.practice {
  .industry-title-content {
    .text-content {
      font-size: 14px;
    }
    .pointer-container {
      &.active {
        margin-top: 4px;
        max-height: 14px;
        max-width: 7px;
      }
    }
  }
}

&.services {
  .industry-title-content {
    .text-content {
      font-size: 12px;
    }
  }
}
}
}


@media (max-width: 375px) and (min-height: 630px) {

.slider-content-container {
&.industry {
  .industry-title-content {
    .text-content {
      font-size: 16px;
    }
    .pointer-container {
          &.active {
            margin-top: 4px;
            max-height: 16px;
            max-width: 8px;
          }
        }
  }
}

&.practice {
  .industry-title-content {
    .text-content {
      font-size: 15px;
    }

    .pointer-container {
      &.active {
        margin-top: 2px;
        max-height: 15px;
        max-width: 7.5px;
      }
    }
  }
}

&.services {
  .industry-title-content {
    .text-content {
      font-size: 14px;
    }


  }
}
}
}


@media (max-width: 320px) {
.slider-content-container {

.industry-title-content {
  column-gap: 8px;
}


&.industry {
  .industry-title-content {
    .text-content {
      font-size: 14px;
    }
    .pointer-container {
      &.active {
        margin-top: 5px;
        max-height: 14px;
        max-width: 7px;
      }
    }
  }
}

&.practice {
  .industry-title-content {
    .text-content {
      font-size: 13px;
    }
    .pointer-container {
      &.active {
        margin-top: 0;
      }
    }
  }
}

&.services {
  .industry-title-content {
    .text-content {
      font-size: 12px;
    }
  }
}
}
}



@media (min-width: 2561px) {
.swiper-button {
max-width: 110px;
max-height: 53px;

&.swiper-button-bottom {
  bottom: 32px;
}
}


.slider-content-container {

.industry-title-content {
  padding: 0 64px;
  column-gap: 24px;
}

&.industry {
  .industry-title-content {
    .text-content {
      font-size: 140px;
      letter-spacing: 24px;
    }

    .pointer-container {
      &.active {
        max-height: 128px;
        max-width: 64px;
      }
    }
  }
}

&.practice {
  .industry-title-content {
    .text-content {
      letter-spacing: 5.6px;
      font-size: 56px;
    }

    .pointer-container {
      &.active {
        max-height: 56px;
        max-width: 28px;
      }
    }
  }
}

&.services {
  .industry-title-content {
    .text-content {
      font-size: 56px;
    }
  }
}
}
}

</style>