<template>
  <div class="agreement-button-container" :class="{'active-button' : activeParam, 'active-modal' : activeModal}" @click="changeActiveParam">
    <transition name="fade-opacity-lazy">
      <button-icon v-if="activeParam" :img-name="'small-blot'" />
    </transition>
  </div>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";

export default {
  name: "GradientButtonAgreement",
  components: {ButtonIcon},
  props: {
    activeModal: {
      type: Boolean
    }
  },
  data() {
    return {
      activeParam: false
    }
  },
  methods: {
    changeActiveParam() {
      this.activeParam = !this.activeParam
      this.$emit('changeActivePolicy', this.activeParam)
    }
  }
}
</script>

<style scoped lang="scss">
  .agreement-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: var(--color-background);
    border-style: solid;
    border-color: var(--color-field);
    opacity: .6;
    transition: all .8s ease;

    &:hover {
      border-color: var(--color-text);
    }

    &.active-button {
      border-color: var(--color-text);
      opacity: 1;
    }
  }

  .button-icon-container {
    cursor: pointer;
  }



  @media (max-width: 2560px){
    .agreement-button-container {
      max-width:  38px;
      height: 38px;
      padding: 7px 0;
      border-radius: 7px;
      border-width:  1px;
    }

    .button-icon-container {
      max-width: 23px;
      max-height: 24px;
    }
  }



  @media (max-width: 1920px) {
    .agreement-button-container {
      max-width:  32px;
      height: 32px;
      padding: 7px 0;
      border-radius: 6px;
      border-width:  1px;
    }

    .button-icon-container {
      max-width: 19px;
      max-height: 20px;
    }
  }



  @media (max-width: 1600px) {
    .agreement-button-container {
      max-width:  21px;
      height: 21px;
      padding: 3.5px 0;
      border-radius: 4px;
      border-width:  0.8px;
    }

    .button-icon-container {
      max-width: 13px;
      max-height: 14px;
    }
  }

  @media (max-width: 1133px) {
    .agreement-button-container {
      max-width:  19px;
      height: 19px;
      padding: 3.1px 0;
      border-radius: 4px;
      border-width:  0.72px;
    }

    .button-icon-container {
      max-width: 11.6px;
      max-height: 12.6px;
    }
  }

  @media (max-width: 769px) {
    .agreement-button-container {
      max-width:  38px;
      height: 38px;
      padding: 9px 0;
      border-radius: 10px;
      border-width:  1px;

      &.active-modal {
        max-width:  17px;
        height: 17px;
        padding: 3.2px 0;
        border-radius: 3.5px;
        border-width:  0.6px;

        .button-icon-container {
          max-width: 10px;
          max-height: 11px;
        }
      }
    }

    .button-icon-container {
      max-width: 20px;
      max-height: 20px;
    }
  }

  @media (max-width: 769px) and (min-height: 748px) and (height: 1000px) {
    .agreement-button-container {
      max-width:  40px;
      height: 40px;
      padding: 9.4px 0;
      border-radius: 10px;
      border-width:  1.05px;

      &.active-modal {
        max-width:  17px;
        height: 17px;
        padding: 3.2px 0;
        border-radius: 3.5px;
        border-width:  0.6px;

        .button-icon-container {
          max-width: 10px;
          max-height: 11px;
        }
      }
    }

    .button-icon-container {
      max-width: 20px;
      max-height: 21px;
    }
  }



  @media (max-width: 375px) {
    .agreement-button-container {
      max-width:  38px;
      height: 38px;
      padding: 9px 0;
      border-radius: 10px;
      border-width:  1px;
    }

    .button-icon-container {
      max-width: 20px;
      max-height: 19px;
    }
  }
  @media (max-width: 320px) {
    .agreement-button-container {
      max-width:  32px;
      height: 32px;
      padding: 8px 0;
      border-radius: 10px;
      border-width:  1px;
    }

    .button-icon-container {
      max-width: 16px;
      max-height: 16px;
    }
  }

  @media (min-width: 2561px) {
    .agreement-button-container {
      max-width:  73px;
      height: 73px;
      padding: 14px 0;
      border-radius: 14px;
      border-width:  2px;
    }

    .button-icon-container {
      max-width: 44px;
      max-height: 46px;
    }
  }

</style>