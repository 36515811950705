<template>
  <div class="toggle-button-container" :class="{'active-color-scheme' : activeColorScheme}">
    <div class="toggle-button-content">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonToggle",
  props: {
    activeColorScheme: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  .toggle-button-container {
    position: relative;
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-style: solid;
    transition: all .5s ease;

    color: #0D1B2A;
    border-color: #F2E9E4;
    background-color: #F2E9E4;

    &.active-color-scheme {
      color: var(--color-text);
      border-color: var(--color-field);
      background-color: rgba(1,1,1,0);
      &:hover {
        background-color: var(--color-field);
      }
    }
  }

  @media (max-width: 2560px){
    .toggle-button-container {
      height: 46px;
      padding: 11px;
      border-width: 1px;
      border-radius: 12px;
    }
  }

  @media (max-width: 1920px) {
    .toggle-button-container {
      height: 40px;
      padding: 10px;
      border-width: 1px;
      border-radius: 12px;
    }
  }

  @media (max-width: 1600px) {
    .toggle-button-container {
      height: 32px;
      padding: 8px;
      border-width: 1px;
      border-radius: 10px;
    }
  }
  @media (max-height: 800px) {
    .toggle-button-container {
      height: 32px;
      padding: 8px;
      border-width: 1px;
      border-radius: 10px;
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .toggle-button-container {
      height: 28px;
      padding: 7px;
      border-width: 1px;
      border-radius: 9px;
    }
  }

  @media (max-width: 1133px) {
    .toggle-button-container {
      height: 24px;
      padding: 6px;
      border-width: 0.7px;
      border-radius: 7px;
    }
  }

  @media (max-width: 769px) {
    .toggle-button-container {
      height: 46px;
      padding: 12px;
      border-width: 1px;
      border-radius: 14px;

      .toggle-button-content {
        width: 100%;
        height: 100%;
      }

      &.active-color-scheme {
        border-color: var(--color-text);
        background-color: rgba(var(--color-button-toggle-mobile), .6);
        &:hover {
          background-color: rgba(var(--color-button-toggle-mobile), .2);
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .toggle-button-container {
      height: 46px;
      padding: 12px;
      border-width: 1px;
      border-radius: 14px;

      .toggle-button-content {
        width: 100%;
        height: 100%;
      }

      &.active-color-scheme {
        border-color: var(--color-text);
        background-color: rgba(var(--color-button-toggle-mobile), .6);
        &:hover {
          background-color: rgba(var(--color-button-toggle-mobile), .2);
        }
      }
    }
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    .toggle-button-container {
      height: 38px;
      padding: 10px;
      border-width: 1px;
      border-radius: 14px;
      .toggle-button-content {
        line-height: 100%;
      }
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .toggle-button-container {
      height: 46px;
      padding: 12px;
      border-width: 1px;
      border-radius: 14px;

      .toggle-button-content {
        line-height: normal;
      }
    }
  }


  @media (max-width: 320px) {
    .toggle-button-container {
      height: 38px;
      padding: 10px;
      border-width: 1px;
      border-radius: 14px;
    }
  }


  @media (min-width: 2561px) {
    .toggle-button-container {
      height: 94px;
      padding: 24px;
      border-width: 2px;
      border-radius: 28px;
    }
  }

</style>