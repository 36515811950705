<template>
    <card-person v-if="!activeMobile" v-show="!isLoadingImg"
                 @checkActiveLengthNews="checkActiveLengthNews"
                 @checkActiveLengthCase="checkActiveLengthCase"
                 :slice-name="sliceName"
                 :slide-active="slideActive"
                 :main-title-content="mainTitleContent"
                 :person-info="personInfo"
                 :button-is-active="buttonIsActive"
                 :setting-swiper-case="settingSwiperCase"
                 :setting-swiper-news="settingSwiperNews"
                 :active-lang="activeLang"
                 :active-lang-user="activeLangUser"
                 :active-lang-role="activeLangRole"
                 :active-lang-publication="activeLangPublication"/>

    <card-person-mobile v-else v-show="!isLoadingImg"
                        @checkActiveLengthNews="checkActiveLengthNews"
                        @checkActiveLengthCase="checkActiveLengthCase"
                        :slice-name="sliceName"
                        :slide-active="slideActive"
                        :main-title-content="mainTitleContent"
                        :person-info="personInfo"
                        :button-is-active="buttonIsActive"
                        :setting-swiper-case="settingSwiperCase"
                        :setting-swiper-news="settingSwiperNews"
                        :active-lang="activeLang"
                        :active-lang-user="activeLangUser"
                        :active-lang-role="activeLangRole"
                        :active-lang-publication="activeLangPublication"/>
</template>

<script>
import CardPerson from "@/components/entities/CardPerson.vue";
import CardPersonMobile from "@/components/entities/CardPersonMobile.vue";
import {mapGetters} from "vuex";

export default {
  name: "CardPersonPage",
  components: {
    CardPersonMobile,
    CardPerson},
  props: {
    personInfo: {
      type: Object,
      required: true
    },
    slideActive: {
      type: Number,
      required: true
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.checkSize();
      addEventListener('resize', this.checkSize)
    })
  },

  unmounted() {
    removeEventListener('resize', this.checkSize);
  },

  data() {
    return {
      settingSwiperCase: {
        direction: 'horizontal',
        slidesPerView: 1.5,
        loop: false,
        centeredSlides: false,
        virtual: true,
        spaceBetween: 0
      },
      settingSwiperNews: {
        direction: 'vertical',
        slidesPerView: 1.5,
        centeredSlides: false,
        virtual: true
      },
      lengthNewsSlider: 0,
      lengthCaseSlider: 0
    }
  },
  computed: {
    ...mapGetters('persons',['activeUserPressAll']),
    ...mapGetters('persons',['activeUserCaseAll']),
    buttonIsActive() {
      let aboutContent = this.activeLangUser?.profiles?.caption !== ''
          || this.activeLangUser?.profiles?.professionalInterests?.length !== 0
          || this.activeLangUser?.profiles?.awards?.length !== 0
      let educationContent = this.activeLangUser?.educations?.length !== 0 && this.activeLangUser?.educations !== undefined
      let publicationContent = this.activeLangPublication !== undefined && this.activeLangPublication?.length !== 0
      let caseContent = this.activeUserCaseAll?.length !== 0 && this.activeUserCaseAll !== undefined
      let newsContent = this.activeUserPressAll?.length !== 0 && this.activeUserPressAll !== undefined
      return [aboutContent, educationContent, publicationContent, caseContent, newsContent]
    },

    activeLang() {
      if(this.$i18n.locale === 'en') {
        return 'eng';
      } else {
        return 'ru';
      }
    },

    mainTitleContent() {
      return this.$tm('teamPage.mainTitle')
    },
    sliceName() {
      return this.activeLangUser?.profiles?.fullName.replace(' ', '\n')
    },

    activeLangUser() {
      return this.checkLangUserInfo(this.personInfo, String(this.$i18n.locale))
    },

    activeLangPublication() {
      return this.checkKeysList(this.personInfo?.publication, 'translations', 'text')?.filter(item => item?.name !== undefined);
    },

    activeLangRole() {
      let result = [];
      if(this.$i18n.locale === 'ru') {
        this.personInfo?.roles.forEach(item => {
          switch(item) {
            case "PARTNER":
              result.push("Управляющий партнер");
              break;
            case "LEADER":
              result.push("Учредитель");
              break;
            case "EMPLOYEES":
              result.push("Сотрудник");
              break;
          }
        })
      } else {
        this.personInfo?.roles.forEach(item => {
          switch (item) {
            case "PARTNER":
              result.push("Partners");
              break;
            case "LEADER":
              result.push("Executives");
              break;
            case "EMPLOYEES":
              result.push("Employees");
              break;
          }
        })
      }
      return result
    }
  },


  methods: {

    checkSize() {
      if(window.innerWidth <= 4480 && window.innerWidth > 2560) {
        this.settingSwiperCase.slidesPerView = 1.5
        this.settingSwiperNews.slidesPerView = 1.8
        this.settingSwiperCase.spaceBetween = 78
      } else if(window.innerWidth <= 2560 && window.innerWidth > 2240) {
        this.settingSwiperCase.slidesPerView = 1.8
        this.settingSwiperNews.slidesPerView = 2.1
        this.settingSwiperCase.spaceBetween = 38
      } else if (window.innerWidth <= 2240 && window.innerWidth > 1920) {
        this.settingSwiperCase.slidesPerView = 1.5
        this.settingSwiperNews.slidesPerView = 1.7
        this.settingSwiperCase.spaceBetween = 38
      } else if (window.innerWidth <= 1920 && window.innerWidth > 1600) {
        this.settingSwiperCase.slidesPerView = 1.5
        this.settingSwiperNews.slidesPerView = 1.6
        this.settingSwiperCase.spaceBetween = 32
      } else if(window.innerWidth <= 1400 && window.innerHeight >= 801) {
        this.settingSwiperCase.slidesPerView = 1.2
        this.settingSwiperNews.slidesPerView = 2.7
        this.settingSwiperCase.spaceBetween = 22
      } else if(window.innerWidth <= 1600 && window.innerWidth > 1280) {
        this.settingSwiperCase.slidesPerView = 1.7
        this.settingSwiperNews.slidesPerView = 2.3
        this.settingSwiperCase.spaceBetween = 22
      } else if (window.innerWidth <= 1280 && window.innerWidth > 1133) {
        this.settingSwiperCase.slidesPerView = 1.5
        this.settingSwiperNews.slidesPerView = 1.9
        this.settingSwiperCase.spaceBetween = 22
      }  else if (window.innerWidth <= 1133) {
        this.settingSwiperCase.slidesPerView = 1.5
        this.settingSwiperNews.slidesPerView = 2.1
        this.settingSwiperCase.spaceBetween = 18
      } else {
        this.settingSwiperNews.slidesPerView = 1.9
        this.settingSwiperCase.spaceBetween = 20
      }
      if(window.innerWidth <= 769) {
        this.settingSwiperNews.slidesPerView = 1.11
        this.settingSwiperCase.slidesPerView = 1.11
        this.settingSwiperCase.spaceBetween = 0
      }
    },
    checkActiveLengthNews(length) {
      this.lengthNewsSlider = length
    },

    checkActiveLengthCase(length) {
      this.lengthCaseSlider = length
    },
  }
}
</script>

<style scoped>

</style>