<template>
  <div class="team-type-screen-container" :class="{'not-active-slide' : activeTeamTypeLength < 1}">
<!--    <slider-team v-if="activeTeamType.length > 1" :team-info="activeTeamType"/>-->
      <slider-team v-if="activeTeamTypeLength > 0" :team-info="activeTeamType"/>
  </div>
</template>

<script>
import SliderTeam from "@/components/entities/SliderTeam.vue";
import CardTeam from "@/components/entities/CardTeam.vue";

export default {
  name: "TeamTypeScreen",
  components: {CardTeam, SliderTeam},
  props: {
    activeTeamType: {
      type: Array,
      required: true
    }
  },

  computed: {
    activeTeamTypeLength() {
      return this.activeTeamType.length
    }
  },

  mounted() {
    if(this.activeTeamType.length === 0) {
      this.$router.push({name: 'team'})
    }
  }

}
</script>

<style scoped lang="scss">

.team-type-screen-container {
  margin: auto 0 0;
  position: relative;
  height: 100%;
  overflow-x: hidden;
  overflow-y: visible;
  padding: 25px 0;
  max-height: calc(var(--height-slider-team) + 25px);
  &::-webkit-scrollbar {
    display: none;
  }

  &.not-active-slide {
    display: flex;
    align-items: center;

    .team-card-container {
      width: 100%;
    }
  }
}

@media (max-width: 2560px){
  .team-type-screen-container {
    margin: auto 0 5%;
    --height-slider-team: 930px;
  }
}

@media (max-width: 2240px) {
  .team-type-screen-container {
    margin: auto 0 2.5%;
    --height-slider-team: 854px;
  }
}


@media (max-width: 1920px) {
.team-type-screen-container {
  margin: auto 0;
  --height-slider-team: 819px;
}
}


@media (max-width: 1600px) {
  .team-type-screen-container {
    margin: auto 0;
    --height-slider-team: 488px;
  }
}
@media (max-height: 800px) {
  .team-type-screen-container {
    margin: auto 0;
    --height-slider-team: 488px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .team-type-screen-container {
    margin: auto 0 5%;
    --height-slider-team: 630px;
  }
}

@media (max-width: 1133px) {
  .team-type-screen-container {
    margin: auto 0;
    --height-slider-team: 432px;
  }
}

@media (max-width: 769px) {
  .team-type-screen-container {
    margin: auto 0;
    max-height: 472px;
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .team-type-screen-container {
    margin: auto 0;
    max-height: 530px;
  }
}


@media (max-width: 375px){
  .team-type-screen-container {
    margin: auto 0;
    max-height: 420px;
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .team-type-screen-container {
    margin: auto 0;
    max-height: 472px;
  }
}


@media (max-width: 320px) {
  .team-type-screen-container {
    margin: auto 0;
    max-height: 335px;
  }
}



@media (min-width: 2561px) {
  .team-type-screen-container {
    margin: auto 0;
    --height-slider-team: 1708px;
  }
}

</style>