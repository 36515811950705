<template>
  <div class="slider-content-container" :class="{'active-horizontal': swiperSetting.direction === 'vertical',
  'active-mini-gradient' : activeController.left && activeGradient,
  'not-active-left' : !activeLeftGradient, 'not-active-gradient' : !activeGradient && !activeMobile}"
       v-if="lengthInfo > 1">
    <div class="swiper-button swiper-button-left" >
      <transition name="fade-opacity-lazy">
      <div v-if="activeController.left">
        <img v-if="activeColor" :src="getUrlIcon(imgNameLeft + '-dark.svg')" alt="icon">
        <img v-else :src="getUrlIcon(imgNameLeft + '-light.svg')" alt="icon">
      </div>
      </transition>
    </div>
    <swiper @slide-change="onSwiperMain"
            @swiper="changeSettingSwiper"
            :slides-per-view="swiperSetting.slidesPerView"
            :virtual="swiperSetting.virtual"
            :navigation = "{
                  nextEl: nameNavigation.nextEl,
                  prevEl: nameNavigation.prevEl
              }"
            :breakpoints="{
              0: {
                allowTouchMove: true
              },
              1600: {
                allowTouchMove: false
              }
            }"
            :mousewheel="activeMouseWheel"
            :centered-slides="swiperSetting.centeredSlides"
            :modules="modules"
            :space-between="swiperSetting?.spaceBetween || 0"
            class="swiper"
            ref="customSwiper"
            :class="{'swiper-overflow-none' : activeOverflowHide}"
            :loop="swiperSetting.loop"
            :speed="1000"
            :direction="swiperSetting.direction">
          <slot/>
    </swiper>
    <div class="swiper-button swiper-button-right">
      <transition name="fade-opacity-lazy">
        <div v-if="activeController.right">
          <img v-if="activeColor" :src="getUrlIcon(imgNameRight + '-dark.svg')" alt="icon">
          <img v-else :src="getUrlIcon(imgNameRight + '-light.svg')" alt="icon">
        </div>
      </transition>
    </div>
  </div>
  <div class="slider-content-container empty" v-else>
    <slot/>
  </div>
</template>

<script>
import ModalFrame from "@/components/entities/ModalFrame.vue";

export default {
  name: "SliderFrame",
  components: {ModalFrame},
  props: {
    activeType: {
      type: String,
      default: ''
    },

    activeGrabCursor: {
      type: Boolean,
      default: false
    },
    activeMouseWheel: {
      type: Boolean,
      default: false
    },
    activeGradient: {
      type: Boolean,
      default: true
    },

    lengthInfo: {
      type: Number,
      required: true
    },
    activeOverflowHide: {
      type: Boolean,
      default: true
    },
    swiperSetting: {
      type: Object,
      required: true
    },
    activeController: {
      type: Object,
      required: true
    },
    modules: {
      type: Array,
      required: true
    },
    activeLeftGradient: {
      type: Boolean,
      default: true
    },
    nameNavigation: {
      type: Object,
      default: {
        nextEl: '.swiper-button-right',
        prevEl: '.swiper-button-left'
      }
    },
    changeSlide: {
      type: Object,
      default: false
    }
  },
  data() {
    return {
      mainSwiper: '',
      activeFrameSlide: 0,
      realIndex: 0,
      imgNameLeft: 'single-arrow-left',
      imgNameRight: 'single-arrow-right',
      allowTouch: false,
      blockSwiper: false,
      slidesPerView: 0,
      mountActive: false
    }
  },
  computed: {
    allowTouchMove() {
      return this.$store.state.allowTouchMoveState;
    }
  },
  watch: {
    allowTouchMove() {
      this.checkAllowTouch();
    },
    changeSlide() {
      if(this.changeSlide.state) {
        this.mainSwiper.slideToLoop(this.changeSlide.id, 1000);
      }
    },
  },
  mounted() {
    this.checkAllowTouch();
    this.activeFrameSlide = 0
    this.$emit('changeActiveIndex', this.activeFrameSlide)
  },
  methods: {

    checkAllowTouch() {
      if(this.mainSwiper !== '') {
        if(this.swiperSetting.direction === 'horizontal') {
          this.mainSwiper.allowTouchMove = true
        } else {
          if(this.allowTouchMove) {
            this.mainSwiper.allowTouchMove = true
          } else {
            this.mainSwiper.allowTouchMove = false
          }
        }
        this.mainSwiper?.update();
      }
    },

    changeSettingSwiper(swiper) {
      this.mainSwiper = swiper
      if(!this.mountActive) {
        if(swiper.realIndex !== 0) {
          this.mainSwiper.slideTo(0, 1000)
        }
        this.mountActive = true
      }
    },
    onSwiperMain(swiper) {
      this.activeFrameSlide = swiper.activeIndex;
      this.$emit('changeActiveIndex', this.activeFrameSlide)
      this.$emit('changeRealIndex', swiper.realIndex)
    },
  }
}
</script>

<style scoped lang="scss">
.slider-content-container {
  padding-left: 0;
  position: relative;
  display: flex;
  align-items: center;

  &.empty {
    &:after {
      opacity: 0;
    }
    &:before {
      opacity: 0;
    }
  }

  .swiper-button {
    z-index: 900;
    position: absolute;
    width: 100%;
    height: 100%;


    &.swiper-button-left {
      left: 0;
    }


    & div {
      position: relative;
    }

    & img {
      width: 100%;
      height: 100%;
      opacity: .6;
      transition: opacity .6s ease;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  .swiper {
    height: inherit;
    max-height: inherit;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.empty {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.not-active-left {
    &:before {
      opacity: 0;
    }
  }

  &.not-active-gradient {
    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 0;
    }
  }

  &.active-mini-gradient {
    &:before {
      opacity: 1;
      width: 5%;
      height: 150%;
    }
  }


  &.active-horizontal {
    &:after {
      opacity: 1;
      width: 100%;
      height: 40%;
      pointer-events: none;
      background: linear-gradient(180deg,  rgba(34, 34, 59, 0.0) 0, var(--color-background) 100%);
    }

    &:before {
      opacity: 1;
      width: 100%;
      height: 40%;
      pointer-events: none;
      background: linear-gradient(0deg,  rgba(34, 34, 59, 0.0) 0, var(--color-background) 100%);
    }
  }

  &:after {
    opacity: 1;
    content: "";
    position: absolute;
    width: 30%;
    height: 100%;
    z-index: 2;
    bottom: -1%;
    right: -1%;
    pointer-events: none;
    transition: all .8s ease;
    background: linear-gradient(90deg,  rgba(34, 34, 59, 0.00) 0, var(--color-background) 100%);
  }

  &:before {
    opacity: 1;
    content: "";
    position: absolute;
    width: 30%;
    height: 100%;
    z-index: 2;
    top: -1%;
    left: -1%;
    pointer-events: none;
    transition: all .8s ease;
    background: linear-gradient(270deg,  rgba(34, 34, 59, 0.00) 0, var(--color-background) 100%);
  }
}

@media (max-width: 2560px){
  .slider-content-container {
    .swiper-button {
      padding: 8px;
      max-width: 45px;
      max-height: 77px;

      &.swiper-button-right {
        right: 50px;
      }
    }
  }
}

@media (max-width: 2560px){
  .slider-content-container {
    .swiper-button {

      &.swiper-button-right {
        right: 66px;
      }
    }
  }
}

@media (max-width: 1920px) {
  .slider-content-container {

    .swiper-button {
      max-width: 34px;
      max-height: 55px;

      &.swiper-button-right {
        right: 56px;
      }
    }
  }
}

@media (max-width: 1600px) {
  .slider-content-container {

    .swiper-button {
      padding: 6px;
      max-width: 27px;
      max-height: 42px;

      &.swiper-button-right {
        right: 30px;
      }
    }
  }
}

@media (max-width: 769px) {
  .slider-content-container {
    position: relative;

    .swiper-button {
      padding: 10px;
      max-width: 27px;
      max-height: 35px;

      &.swiper-button-right {
        right: 0;
      }
    }

    &.not-active-gradient {
      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 0;
      }
    }

    &.active-horizontal {
      &:after {
        content: ""
      }

      &:before {
        content: ""
      }
    }

    &:after {
      height: 110%;
    }

    &:before {
      height: 110%;
    }
    //
    //&:before {
    //  content: none;
    //}
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .slider-content-container {
    position: relative;

    .swiper-button {
      padding: 10px;
      max-width: 27px;
      max-height: 35px;
    }
  }
}

@media (max-width: 320px) {
  .slider-content-container {
    position: relative;

    .swiper-button {
      max-width: 26px;
      max-height: 32px;
    }
  }
}


@media (min-width: 2561px) {
  .slider-content-container {

    .swiper-button {
      padding: 16px;
      max-width: 85px;
      max-height: 142px;

      &.swiper-button-right {
        right: 120px;
      }
    }
  }
}


.modal-frame-container {
  position: relative;
}

</style>