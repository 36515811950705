<template>
  <button-toggle @click="changeLanguage" :active-color-scheme="activeColorScheme">
    <p>
      {{ $t('toggleLang') }}
    </p>
  </button-toggle>
</template>

<script>
import ButtonToggle from "@/components/shared/ButtonToggle.vue";
import {mapActions} from "vuex";

export default {
  name: "ButtonToggleLang",
  components: {ButtonToggle},
  props: {
    activeColorScheme: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    ...mapActions(['IS_LOADING_LANG']),
    changeLanguage() {
      this.IS_LOADING_LANG(true)
      setTimeout(() => {
        this.$i18n.locale === 'en' ? this.$i18n.locale = 'ru' :  this.$i18n.locale = 'en'
        localStorage.setItem('active-lang', this.$i18n.locale)
      }, 500)
      setTimeout(() => {
        this.IS_LOADING_LANG(false)
      }, 1000)
    },
    // startLanguage() {
    //   let languageActive = process.env.VUE_APP_TARGET_LANG_CODE
    //   console.log(languageActive)
    //   this.$i18n.locale = languageActive
    //   this.activeButton = languageActive !== 'en'
    // },
  },
  // mounted() {
  //   this.startLanguage();
  // }
}
</script>

<style scoped lang="scss">
p {
  font-family: 'CormorantUnicase-Regular', sans-serif;
}

.button-toggle-container {
  justify-content: center;
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media (max-width: 2560px){
  p {
    font-size: 19px;
  }
}


@media (max-width: 1920px) {
  p {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  p {
    font-size: 11px;
  }
}
@media (max-height: 800px) {
  p {
    font-size: 11px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  p {
    font-size: 9px;
  }
}

@media (max-width: 1133px) {
  p {
    font-size: 9px;
  }
}

@media (max-width: 769px) {
    p {
      font-size: 18px;
    }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  p {
    font-size: 18px;
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
    p {
      font-size: 15px;
    }
}


@media (max-width: 375px) and (min-height: 630px) {
    p {
      font-size: 18px;
    }
}


@media (max-width: 320px) {
    p {
      font-size: 15px;
    }
}


@media (min-width: 2561px) {
  p {
    font-size: 38px;
  }
}

</style>