<template>
  <div class="card-title-news-case-container">
    <main-title-page :active-main-title="activeMainTitle"/>
    <div class="card-title-news-case-content">
      <div class="main-title-container">
        <main-paragraph class="date-content" :class="{'active-en' : activeEng}">{{ datePublicationFormat }}</main-paragraph>
        <large-title class="main-title-content" :active-cormorant="false">
          {{ infoContent?.translations?.name }}
        </large-title>
        <div class="views-info-container">
          <div class="eye-img-container">
            <img :src="getUrlIcon('eye.svg')" alt="eye">
          </div>
          <sub-paragraph :active-cormorant="false">
            {{ infoContent?.views }}
          </sub-paragraph>
        </div>
      </div>

      <div class="info-content person"  v-if="infoContent?.users?.length !== 0">
        <sub-title :active-cormorant="true">{{$tm('caseNewsPage.mainTitle')[0]}}</sub-title>
        <div class="info-container">
          <card-person-mini v-for="item in infoContent?.users"
                            @loadImg="loadImg"
                            :key="item.id"
                            :person-info="item"/>
        </div>
      </div>

      <div class="info-content practice" v-if="infoContent?.practices?.length !== 0">
        <sub-title :active-cormorant="true">{{ $tm('caseNewsPage.mainTitle')[1] }}</sub-title>
        <div class="info-container">
          <sub-paragraph class="practice" v-for="(item, index) in infoContent?.practices"
                         :key="index" :active-cormorant="false">
            #{{ item?.name.toLowerCase().split(' ').join('_') }}
          </sub-paragraph>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import CardPersonMini from "@/components/entities/CardPersonMini.vue";
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import MainTitlePage from "@/components/entities/MainTitlePage.vue";

export default {
  name: "CardTitleNewsCase",
  props: {
    publishDate: {
      type: String,
      required: true
    },
    infoContent: {
      type: Object,
      required: true
    },
    activeMainTitle: {
      type: String,
      required: true
    }
  },
  components: {MainTitlePage, SubTitle, CardPersonMini, SubParagraph, LargeTitle, MainParagraph},
  data() {
    return {
      countAvatar: 0,
    }
  },
  watch: {
    countAvatar() {
      if(this.countAvatar === this.infoContent?.users?.length) {
        this.$emit('loadAvatar', false)
      }
    }
  },
  mounted() {
    if(this.infoContent?.users.length === 0) {
      this.$emit('loadAvatar', false)
    }
  },
  computed: {
    datePublicationFormat() {
      return this.datePublicationFormatChange(this.publishDate)
    },
  },
  methods: {
    loadImg() {
      this.countAvatar++;
    },
  }
}
</script>

<style scoped lang="scss">
.main-title-page {
  top: var(--title-padding);
}

.card-title-news-case-container {
  transition: filter .5s ease;
  display: flex;
  flex-direction: column;
  width: 100%;
  .card-title-news-case-content {
    display: flex;
    flex-direction: column;

    .main-title-container {
      .date-content {
        opacity: .6;
        font-weight: 500;

        &.active-en {
          font-variant: all-small-caps !important;
        }
      }
      .main-title-content {
        font-weight: 400;
      }

      .views-info-container {
        align-items: center;
        display: flex;
        flex-direction: row;

        .eye-img-container {
          display: flex;
          align-items: center;
          justify-content: center;
          & img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }

        .text-content {

          line-height: 1.2;
          height: inherit;
          font-weight: 500;
          text-transform: lowercase;
          color: #646271;
        }
      }
    }

    .info-content {
      display: flex;
      flex-direction: column;

      .text-content {
        &.practice {
          margin-bottom: 0;
        }
      }

      .info-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .person-mini-card-container {
          width: 100%;
        }
      }
    }
  }
}


@media (max-width: 2560px) and (min-width: 1921px){
  .card-title-news-case-container {
    row-gap: 72px;
    .card-title-news-case-content {
      row-gap: 56px;
      max-width: 676px;

      .main-title-container {
        .date-content {
          margin-bottom: 28px;
        }

        .main-title-content {
          margin-bottom: 10px;
          font-size: 37px;
        }

        .views-info-container {
          height: 22px;
          column-gap: 4px;

          .eye-img-container {
            max-width: 21px;
          }
        }
      }

      .info-content {

        &.practice {
          column-gap: 8px;

          .text-content {
            font-size: 16px;
          }

          .info-container {
            row-gap: 6px;
            column-gap: 14px;
          }
        }

        .text-content {
          margin-bottom: 28px;
        }

        .info-container {
          column-gap: 28px;
          row-gap: 28px;
        }
      }
    }
  }
}



@media (max-width: 1920px) {
  .card-title-news-case-container {
    row-gap: 64px;
    .card-title-news-case-content {
      row-gap: 48px;;
      max-width: 580px;

      .main-title-container {
        .date-content {
          margin-bottom: 24px;
        }

        .main-title-content {
          margin-bottom: 8px;
        }

        .views-info-container {
          height: 22px;
          column-gap: 4px;

          .eye-img-container {
            max-width: 16px;
          }
        }
      }

      .info-content {

        &.practice {
          .info-container {
            row-gap: 6px;
            column-gap: 12px;
          }
        }

        .text-content {
          margin-bottom: 24px;
        }

        .info-container {
          column-gap: 24px;
          row-gap: 24px;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .card-title-news-case-container {
    row-gap: 42px;
    .card-title-news-case-content {
      row-gap: 32px;
      max-width: 386px;

      .main-title-container {
        .date-content {
          font-size: 12px;
          margin-bottom: 16px;
        }

        .main-title-content {
          margin-bottom: 6px;
        }

        .views-info-container {
          height: 15px;
          column-gap: 3px;

          .eye-img-container {
            max-width: 11px;
          }
        }
      }

      .info-content {

        &.practice {
          .info-container {
            column-gap: 8px;
            row-gap: 4px;
            .text-content {
              font-size: 9px;
            }
          }
        }

        .text-content {
          margin-bottom: 16px;
        }

        .info-container {
          column-gap: 16px;
          row-gap: 16px;

        }
      }
    }
  }
}
@media (max-height: 800px) {
  .card-title-news-case-container {
    row-gap: 42px;
    .card-title-news-case-content {
      row-gap: 32px;
      max-width: 386px;

      .main-title-container {
        .date-content {
          font-size: 12px;
          margin-bottom: 16px;
        }

        .main-title-content {
          margin-bottom: 6px;
        }

        .views-info-container {
          height: 15px;
          column-gap: 3px;

          .eye-img-container {
            max-width: 11px;
          }
        }
      }

      .info-content {

        &.practice {
          .info-container {
            column-gap: 8px;
            row-gap: 4px;
            .text-content {
              font-size: 9px;
            }
          }
        }

        .text-content {
          margin-bottom: 16px;
        }

        .info-container {
          column-gap: 16px;
          row-gap: 16px;

        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .card-title-news-case-container {
    row-gap: 46px;
    .card-title-news-case-content {
      row-gap: 30.5px;
      max-width: 413px;

      .main-title-container {
        .date-content {
          font-size: 14px;
          margin-bottom: 18px;
        }

        .main-title-content {
          margin-bottom: 6px;
        }

        .views-info-container {
          height: 16px;
          column-gap: 3px;

          .eye-img-container {
            max-width: 12px;
          }
        }
      }

      .info-content {

        &.practice {
          .info-container {
            column-gap: 8px;
            row-gap: 4px;
            .text-content {
              font-size: 10px;
            }
          }
        }

        .text-content {
          margin-bottom: 18px;
        }

        .info-container {
          column-gap: 18px;
          row-gap: 18px;

        }
      }
    }
  }
}

@media (max-width: 1133px) {
  .card-title-news-case-container {
    row-gap: 40px;
    .card-title-news-case-content {
      row-gap: 28px;
      max-width: 342px;

      .main-title-container {
        .date-content {
          font-size: 11px;
          margin-bottom: 16px;
        }

        .main-title-content {
          margin-bottom: 8px;
        }

        .views-info-container {
          height: 12px;
          column-gap: 2px;

          .eye-img-container {
            max-width: 10px;
          }
        }
      }

      .info-content {

        &.practice {
          .info-container {
            column-gap: 8px;
            row-gap: 3px;
            .text-content {
              font-size: 8px;
            }
          }
        }

        .text-content {
          margin-bottom: 16px;
        }

        .info-container {
          column-gap: 16px;
          row-gap: 16px;
        }
      }
    }
  }
}


@media (min-width: 2561px) {
  .card-title-news-case-container {
    row-gap: 152px;
    .card-title-news-case-content {
      row-gap: 112px;
      max-width: 1240px;


      .main-title-container {
        .date-content {
          margin-bottom: 56px;
        }

        .main-title-content {
          margin-bottom: 19px;
        }

        .views-info-container {
          height: 46px;
          column-gap: 9px;

          .eye-img-container {
            max-width: 38px;
          }
        }
      }

      .info-content {

        &.practice {
          .info-container {
            row-gap: 12px;
            column-gap: 12px;
          }
        }

        .text-content {
          margin-bottom: 56px;
        }

        .info-container {
          column-gap: 56px;
          row-gap: 56px;
        }
      }
    }
  }
}

</style>