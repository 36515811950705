<template>
  <div class="cookie-buttons-content">
    <button-sub class="cookie-text" :active-button="false" :disabled-button="false">
        {{ $t('cookieModal.cookieText') }}
    </button-sub>
    <button-sub  @click="changeModalCookieSetting"
                 class="cookie-text icon"
                  :active-button="false"
                  :disabled-button="false">
      <button-icon :img-name="settingImg"/>
    </button-sub>
    <button-sub @click="closeCookie"
                class="cookie-text icon"
                 :active-button="false"
                 :disabled-button="false">
      <button-icon :img-name="checkMarkImg"/>
    </button-sub>
  </div>
</template>

<script>
import ButtonSub from "@/components/shared/ButtonSub.vue";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";

export default {
  name: "CookieStartButton",
  components: {SubParagraph, ButtonIcon, ButtonSub},
  data() {
    return {
      settingImg: 'setting-icon',
      checkMarkImg: 'check-mark-circle',
    }
  },
  methods: {
    changeModalCookieSetting() {
      this.$emit('changeModalCookieSetting')
    },
    closeCookie() {
      this.$emit('closeCookie')
    }
  }
}
</script>

<style scoped lang="scss">
.cookie-buttons-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  width: 100%;

  .cookie-text {
    width: fit-content;
    background-color: var(--color-background);
    border-color: var(--color-field);
    height: 100%;
    line-height: 100%;

    &:last-child {
      margin-right: 0;
    }
  }

}

@media (max-width: 2560px) {
  .cookie-buttons-content {
    bottom: 86px;

    .cookie-text {
      border-radius: 28px;
      margin-right: 22px;
      max-width: 920px;
      padding: 32px 42px;
      max-height: 82px;
      min-height: 82px;

      &.icon {
        padding: 25px;
        max-width: 82px;
        min-width: 82px;
      }
    }
  }
}
@media (max-width: 2240px) {
  .cookie-buttons-content {
    bottom: 65px;

    .cookie-text {
      border-radius: 24px;
      margin-right: 16px;
      max-width: 820px;
      padding: 28px 32px;
      max-height: 70px;
      min-height: 70px;
      &.icon {
        padding: 22px;
        max-width: 72px;
        min-width: 72px;
      }
    }
  }
}

@media (max-width: 1920px) {
  .cookie-buttons-content {
    bottom: 64px;

    .cookie-text {
      border-radius: 18px;
      margin-right: 16px;
      max-width: 714px;
      padding: 22px 32px;
      max-height: 64px;
      min-height: 64px;
      &.icon {
        padding: 19px;
        max-width: 64px;
        min-width: 64px;
      }
    }
  }
}


@media (max-width: 1600px) {
  .cookie-buttons-content {
    bottom: 48px;

    .cookie-text {
      border-radius: 16px;
      margin-right: 12px;
      max-width: 513px;
      padding: 18px 24px;
      max-height: 46px;
      min-height: 46px;
      &.icon {
        padding: 14px;
        max-width: 46px;
        min-width: 46px;
      }
    }
  }
}

@media (max-height: 800px) {
  .cookie-buttons-content {
    bottom: 48px;

    .cookie-text {
      border-radius: 16px;
      margin-right: 12px;
      max-width: 513px;
      padding: 18px 24px;
      max-height: 46px;
      min-height: 46px;
      &.icon {
        padding: 14px;
        max-width: 46px;
        min-width: 46px;
      }
  }
}
}

@media (max-width: 1400px) and (min-height: 801px) {
  .cookie-buttons-content {
    bottom: 48px;

    .cookie-text {
      border-radius: 16px;
      margin-right: 12px;
      max-width: 520px;
      padding: 18px 24px;
      max-height: 46px;
      min-height: 46px;
      &.icon {
        padding: 14px;
        max-width: 46px;
        min-width: 46px;
      }
    }
  }
}


@media (max-width: 1133px) {
  .cookie-buttons-content {
    bottom: 37px;

    .cookie-text {
      border-radius: 12px;
      margin-right: 10px;
      max-width: 415px;
      padding: 14px 20px;
      max-height: 36px;
      min-height: 36px;
      &.icon {
        padding: 10px;
        max-width: 36px;
        min-width: 36px;
      }
    }
  }
}

@media (max-width: 769px) {

}
@media (max-width: 769px)  and (min-height: 748px) and (max-height: 1000px) {

}

@media (min-height: 600px) and (max-height: 630px){

}

@media (max-width: 375px){

}

@media (max-width: 375px) and (min-height: 630px) {

}
@media (max-width: 320px) {

}
@media (min-width: 2561px) {
  .cookie-buttons-content {
    bottom: 150px;

    .cookie-text {
      border-radius: 48px;
      margin-right: 40px;
      max-width: 1634px;
      padding: 56px 64px;
      max-height: 144px;
      min-height: 144px;
      &.icon {
        padding: 44px;
        max-width: 144px;
        min-width: 144px;
      }
    }
  }
}

</style>