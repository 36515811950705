<template>
  <footer class="footer-container">
    <modal-consulting v-if="activeModal"
                      @closeModal="changeStateModal"
                      :main-title="$t('modalContent.modalConsulting.mainTitleNews')"/>
    <div class="footer-content">
      <div class="top-content">
        <footer-list class="item" :active-sub-page="false"
                     :active-color-scheme="false"
                     :info-footer="centralInfo[0]"/>
        <footer-list class="item"  :active-sub-page="false"
                     :active-right="true"
                     :active-color-scheme="false"
                     :info-footer="centralInfo[2]"/>
        <office-content class="item"  :active-color-scheme="false"/>
        <footer-list class="item"  :active-sub-page="false"
                     :active-right="true"
                     @openModal="changeStateModal"
                     :active-color-scheme="false"
                     :info-footer="centralInfo[1]"/>
      </div>

      <div class="bottom-content">
          <sub-paragraph :active-cormorant="false">
            {{ $tm('footerScreen.mainFooter.subTitleButton') }}
          </sub-paragraph>
      </div>
    </div>
  </footer>
</template>

<script>
import FooterList from "@/components/shared/footer/FooterList.vue";
import OfficeContent from "@/components/shared/OfficeContent.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import ModalConsulting from "@/components/entities/ModalConsulting.vue";

export default {
  name: "MainFooterMobile",
  components: {ModalConsulting, SubParagraph, OfficeContent, FooterList},
  data() {
    return {
        activeModal: false
    }
  },
  computed: {
    centralInfo() {
      return this.$tm('footerScreen.mainFooter.centralInfo')
    }
  },
  methods: {
    changeStateModal() {
      this.activeModal = !this.activeModal
    },
  }
}
</script>

<style scoped lang="scss">
  .footer-container {
    height: 100%;


    &:before {
      content: '';
      position: absolute;
      top: 0;
      height: 1px;
      background-color: #22223B;
    }

    .footer-content {
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      position: relative;



      .top-content {
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        .item {
          flex-basis: 50%;
          &:nth-child(2n) {
            text-align: right;
          }
        }
      }

      .bottom-content {
        display: flex;
        justify-content: flex-end;
        .text-content {
          opacity: .6;
          color: #0D1B2A;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @media (max-width: 769px) {
    .footer-container {
      padding: 73px 0 20px;
      max-height: 453px;

      &:before {
        left: 16px;
        width: calc(var(--calc-width) - 179px);
      }

      .footer-content {
        padding: 0 14px;
        max-height: 100%;

        .top-content {
          row-gap: 24px;
        }

        .bottom-content {
          .text-content {
            font-size: 13px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .footer-container {
      padding: 71px 0 24px;
      max-height: 501px;


      &:before {
        left: 16px;
        width: calc(var(--calc-width) - 217px);
      }

      .footer-content {
        padding: 0 16px;
        max-height: 100%;

        .top-content {
          row-gap: 32px;
        }

        .bottom-content {
          .text-content {
            font-size: 14px;
            margin-bottom: 12px;
          }
        }
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){
    .footer-container {
      max-height: 435px;
    }
  }


  @media (max-width: 375px){
    .footer-container {
      padding: 51px 0 16px;
      max-height: 374px;


      &:before {
        left: 16px;
        width: calc(var(--calc-width) - 164px);
      }

      .footer-content {
        padding: 0 12px;

        .top-content {
          row-gap: 24px;
        }

        .bottom-content {
          .text-content {
            font-size: 11px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .footer-container {
      padding: 60px 0 20px;
      max-height: 440px;


      &:before {
        left: 16px;
        width: calc(var(--calc-width) - 164px);
      }

      .footer-content {
        padding: 0 14px;
        max-height: 392px;

        .top-content {
          row-gap: 24px;
        }

        .bottom-content {
          .text-content {
            font-size: 13px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }


  @media (max-width: 320px) {
    .footer-container {
      padding: 26px 0 16px;
      max-height: 290px;

      &:before {
        left: 18px;
        width: calc(var(--calc-width) - 109px);
      }

      .footer-content {
        padding: 0 12px;

        .top-content {
          row-gap: 12px;
        }

        .bottom-content {
          display: none;
          .text-content {
            font-size: 10px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }

</style>