<template>
    <div class="value-dark-screen-container" v-if="!activeMobile">
      <div class="value-dark-card-content left">
        <transition-group name="fade-opacity-lazy">
          <card-value-dark :direction="false"
                           v-show="isVisible(item.id)"
                           v-for="item in sliceLeftInfoCard"
                           :key="item.id" :card-info="item"/>
        </transition-group>
      </div>
      <div class="value-dark-card-content right">
        <transition-group name="fade-opacity-lazy">
        <card-value-dark :direction="true"
                         v-show="isVisible(item.id)"
                         v-for="item in sliceRightInfoCard"
                         :key="item.id"
                         :card-info="item"/>
        </transition-group>
      </div>
    </div>
    <div class="value-dark-screen-container" v-else-if="activeMobile">
      <div class="value-dark-card-content">
        <transition-group name="fade-opacity-lazy">
          <card-value-dark :direction="item.id > 1"
                           v-show="isVisible(item.id)"
                           v-for="item in infoCard"
                           :key="item.id" :card-info="item"/>
        </transition-group>
      </div>
    </div>
</template>

<script>
import CardValueDark from "@/components/entities/CardValuesDark.vue";

export default {
  name: "ValueDarkScreen",
  components: {CardValueDark},
  mounted() {
    this.showNextItem();
  },
  data() {
    return {
      activeItem: -1,
    }
  },
  computed: {
    infoCard() {
      return this.$tm('aboutScreen.values.valuesCard');
    },

    sliceLeftInfoCard() {
      return this.infoCard.slice(0,2)
    },
    sliceRightInfoCard() {
      return this.infoCard.slice(2,4)
    },
  },
  methods: {
    showNextItem() {
      if (this.activeItem < this.infoCard.length) {
        setTimeout(() => {
          this.activeItem++
          this.showNextItem();
        }, 500)
      }
    },


    isVisible(index) {
      return index <= this.activeItem
    },
  }
}
</script>

<style scoped lang="scss">
  .value-dark-screen-container {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;

    .value-dark-card-content {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 2560px){
    .value-dark-screen-container {
      max-height: 840px;
      row-gap: 40px;
      column-gap: 40px;

      .value-dark-card-content {
        max-width: 850px;
        row-gap: 40px;

        &.left {
          padding-bottom: 64px;
        }

        &.right {
          padding-top: 64px;
        }
      }
    }
  }

  @media (max-width: 2240px) {
    .value-dark-screen-container {
      max-height: 736px;
      row-gap: 36px;
      column-gap: 36px;

      .value-dark-card-content {
        max-width: 744px;
        row-gap: 36px;

        &.left {
          padding-bottom: 56px;
        }

        &.right {
          padding-top: 56px;
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .value-dark-screen-container {
      max-height: 632px;
      row-gap: 32px;
      column-gap: 32px;

      .value-dark-card-content {
        max-width: 638px;
        row-gap: 32px;

        &.left {
          padding-bottom: 48px;
        }

        &.right {
          padding-top: 48px;
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .value-dark-screen-container {
      max-height: 422px;
      row-gap: 22px;
      column-gap: 22px;

      .value-dark-card-content {
        max-width: 425px;
        row-gap: 22px;

        &.left {
          padding-bottom: 32px;
        }

        &.right {
          padding-top: 32px;
        }
      }
    }
  }
  @media (max-height: 800px) {
    .value-dark-screen-container {
      max-height: 422px;
      row-gap: 22px;
      column-gap: 22px;

      .value-dark-card-content {
        max-width: 425px;
        row-gap: 22px;

        &.left {
          padding-bottom: 32px;
        }

        &.right {
          padding-top: 32px;
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .value-dark-screen-container {
      max-height: 499px;
      row-gap: 25px;
      column-gap: 25px;

      .value-dark-card-content {
        max-width: 502px;
        row-gap: 25px;

        &.left {
          padding-bottom: 38px;
        }

        &.right {
          padding-top: 38px;
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .value-dark-screen-container {
      max-height: 370px;
      row-gap: 18px;
      column-gap: 18px;

      .value-dark-card-content {
        max-width: 374px;
        row-gap: 18px;

        &.left {
          padding-bottom: 28px;
        }

        &.right {
          padding-top: 28px;
        }
      }
    }
  }

  @media (max-width: 769px) {
    .value-dark-screen-container {
      padding-top: 24px;
      height: 100%;
      max-height: 100%;
      row-gap: 4px;

      .value-dark-card-content {
        max-width: 100%;
        row-gap: 4px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 740px) and (max-height: 1000px) {
    .value-dark-screen-container {
      padding-top: 16px;
      height: 100%;
      max-height: 100%;
      row-gap: 12px;

      .value-dark-card-content {
        max-width: 100%;
        row-gap: 12px;
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){
    .value-dark-screen-container {
      padding-top: 0;
    }
  }


  @media (max-width: 375px){
    .value-dark-screen-container {
      padding-top: 3px;
      row-gap: 4px;

      .value-dark-card-content {
        row-gap: 4px;
      }
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .value-dark-screen-container {
      padding-top: 8px;
      row-gap: 4px;

      .value-dark-card-content {
        row-gap: 4px;
      }
    }
  }


  @media (max-width: 320px) {
    .value-dark-screen-container {
      padding-top: 10px;
      row-gap: 2px;

      .value-dark-card-content {
        row-gap: 2px;
      }
    }
  }



  @media (min-width: 2561px) {
    .value-dark-screen-container {
      max-height: 1472px;
      row-gap: 72px;
      column-gap: 72px;

      .value-dark-card-content {
        max-width: 1488px;
        row-gap: 72px;

        &.left {
          padding-bottom: 112px;
        }

        &.right {
          padding-top: 112px;
        }
      }
    }
  }

</style>