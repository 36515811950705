<template>
  <div class="main-card-person-container">
    <mobile-controller @slideTo="changeTextFull" v-if="buttonIsActive.some(item => item === true)"/>
    <main-title-page :active-person-title="true" :active-main-title="sliceName" :active-static-color="true"/>
    <transition name="fade-translate-y-card-revert" >
      <div class="text-info-container"  :class="{'active-height' : activeFullText}" v-if="activePersonInfo">
          <div class="button-controller-container" >
            <button-main v-for="item in $tm('teamPage.buttonController')"
                         v-show="buttonIsActive[item.id]"
                         :key="item.id"
                         @click="changeBlockClick(item.id)"
                         :active-button="activeIndex === item.id"
                         :disabled-button="false">
              {{item.name}}
            </button-main>
          </div>
        <div class="person-info-text-container"
             :class="{
              'active-gradient' : activeCase || activeNews
             }"
             v-if="buttonIsActive.some(item => item === true)">
          <div class="person-info-text-content">
                    <transition-group name="fade-opacity-lazy">
                      <section class="info-text-container about-content" id="section-0" v-if="activeAbout">

                        <div class="info-container paragraph-container" v-if="activeLangUser?.profiles?.caption !== ''">
                          <main-paragraph>
                            {{ activeLangUser?.profiles?.caption }}
                          </main-paragraph>
                        </div>

                        <div class="info-container professional-interest" v-if="activeLangUser?.profiles?.professionalInterests?.length !== 0">
                          <main-title :active-cormorant="true">{{mainTitleContent[0]}}</main-title>
                          <div class="info-content" >
                            <main-paragraph v-for="item in activeLangUser?.profiles?.professionalInterests">{{item}}</main-paragraph>
                          </div>
                        </div>

                        <div class="info-container awards" v-if="activeLangUser?.profiles?.awards?.length  !== 0">
                          <main-title :active-cormorant="true">{{mainTitleContent[1]}}</main-title>
                          <div class="info-content">
                            <main-paragraph v-for="item in  activeLangUser?.profiles?.awards">{{item}}</main-paragraph>
                          </div>
                        </div>
                        <div class="empty-block"></div>
                      </section>
                      <section class="info-text-container education" id="section-1" v-else-if="activeEducation">
                        <div class="info-container">
                          <main-title :active-cormorant="true">{{mainTitleContent[2]}}</main-title>
                          <div class="education-info-container">
                            <div class="info-content"  v-for="item in activeLangUser?.educations">
                              <div class="education-content top">
                                <main-paragraph>{{ new Date(item?.startDate).getFullYear() }} - {{ new Date(item?.endDate).getFullYear() }}</main-paragraph>
                                <main-paragraph>{{ item?.name }}</main-paragraph>
                              </div>
                              <div class="education-content central">
                                <main-paragraph>{{ item?.universityName }}</main-paragraph>
                              </div>
                              <div class="education-content bottom">
                                <main-paragraph>{{ item?.specialityName }}</main-paragraph>
                                <main-paragraph>{{ item?.educationLevel?.[activeLang] }}</main-paragraph>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section class="info-text-container publication" id="section-2" v-else-if="activePublication">
                        <div class="info-container">
                          <main-title  :active-cormorant="true">{{mainTitleContent[3]}}</main-title>
                          <div class="info-content">
                            <main-paragraph v-for="item in activeLangPublication">
                              {{ item?.name }}
                            </main-paragraph>
                          </div>
                        </div>
                      </section>
           </transition-group>
          <transition name="fade-opacity-lazy">
            <section class="slider-container" id="section-3" v-show="activeCase">
              <main-title :active-cormorant="true">
                {{ mainTitleContent[4] }}
              </main-title>
              <slider-case-user class="slider-content-container" @activeLength="checkActiveLengthCase"
                                :setting-swiper="settingSwiperCase"
                                :active-case-full="true"/>
              <div class="empty-block"></div>
            </section>
          </transition>
          <transition name="fade-opacity-lazy">
            <section class="slider-container" id="section-4" v-show="activeNews">
              <main-title :active-cormorant="true">{{mainTitleContent[5]}}</main-title>
              <slider-news-user class="slider-content-container" :setting-swiper="settingSwiperNews"
                                @activeLength="checkActiveLengthNews"
                                :active-news-full="false"
                                :controller-setting="{
                              top: false,
                              bottom: false
                              }"/>
              <div class="empty-block"></div>
            </section>
          </transition>
          </div>
      </div>
   </div>
</transition>
    <div class="empty-background" @touchmove="changeTouch"
         @touchstart="changeTouchStart"
         @touchend="startY = null;
       this.stopTrigger = false"></div>
    <transition name="fade-opacity-animation">
      <div class="person-image-container" v-if="activeImage"
           >
          <img @load="loadImg" :src="personInfo?.profilePhoto" alt="person">
      </div>
    </transition>
  </div>
</template>

<script>
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import MainTitlePage from "@/components/entities/MainTitlePage.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import SliderCaseUser from "@/components/entities/SliderCaseUser.vue";
import SliderNewsUser from "@/components/entities/SliderNewsUser.vue";
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import MobileController from "@/components/entities/MobileController.vue";
import {mapActions} from "vuex";

export default {
  name: "CardPersonMobile",
  components: {
    MobileController,
    SubParagraph, MainTitlePage, ButtonMain, MainParagraph, SliderCaseUser, SliderNewsUser, MainTitle},
  props: {
    slideActive: {
      type: String,
      required: true
    },
    personInfo: {
      type: Object,
      required: true
    },
    sliceName: {
      type: String,
      required: true
    },
    activeLangUser: {
      type: Object,
      required: true
    },
    activeLangPublication: {
      type: Object,
      required: true
    },
    activeLangRole: {
      type: Object,
      required: true
    },
    activeLang: {
      type: String,
      required: true
    },
    mainTitleContent: {
      type: Array,
      required: true
    },
    buttonIsActive: {
      type: Array,
      required: true
    },
    settingSwiperNews: {
      type: Object,
      required: true
    },
    settingSwiperCase: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeParentHeight: 0,
      activeAbout: false,
      activeEducation: false,
      activePublication: false,
      activeCase: false,
      activeNews: false,
      activeIndex: 0,
      prevTimer: null,
      activeImage: false,
      activePersonInfo: false,
      stopTrigger: false,
      startY: null,
      activeFullText: false,
    }
  },

  mounted() {
    this.activePersonInfo = true;
    this.activeImage = true;
    if(this.personInfo?.profilePhoto === null) {
      this.loadImg();
    }
    if(this.buttonIsActive[0] === false && this.slideActive !== 'news') {
      this.startSlide('cases');
    } else {
      this.startSlide(this.slideActive);
    }
  },

  watch: {
    slideActive() {
      this.startSlide(this.slideActive)
    }
  },

  methods: {
    ...mapActions(['IS_LOADING']),

    changeTouch(e) {
      if(!this.stopTrigger) {
        this.stopTrigger = true;
        let currentY = e.touches[0].clientY;
        let deltaY = currentY - this.startY;
        if (deltaY > 0) {
            this.activeFullText = false
        } else if (deltaY < 0) {
          this.activeFullText = true
        }
      }
    },
    changeTouchStart(e) {
      this.startY = e.touches[0].clientY
    },

    changeTextFull() {
        this.activeFullText = !this.activeFullText
    },

    startSlide(id) {
      this.hideBlock();
      setTimeout(() => {
        switch (id) {
          case 'cases':
            if(this.buttonIsActive[3]) {
              this.activeCase = true
              this.activeIndex = 3;
            } else {
              let firstActiveIndex = this.buttonIsActive.findIndex(item => item === true)
              this.openActiveBlock(firstActiveIndex)
            }
            break
          case 'news':
            if(this.buttonIsActive[4]) {
              this.activeNews = true
              this.activeIndex = 4;
            } else {
              let firstActiveIndex = this.buttonIsActive.findIndex(item => item === true)
              this.openActiveBlock(firstActiveIndex)
            }
            break
          case 'about':
            if(this.buttonIsActive[0]) {
              this.activeIndex = 0;
              this.activeAbout = true
            } else {
              let firstActiveIndex = this.buttonIsActive.findIndex(item => item === true)
              this.openActiveBlock(firstActiveIndex)
            }
            break
          case 'edu':
            this.activeIndex = 1;
            this.activeEducation = true
            break
          case 'pub':
            this.activeIndex = 2;
            this.activePublication = true
            break
        }
      }, 500)
    },

    openActiveBlock(id) {
      switch (id) {
        case 0:
          this.activeIndex = 0;
          this.activeAbout = true
          break;
        case 3:
          this.activeCase = true
          this.activeIndex = 3;
          break;
        case 4:
          this.activeNews = true
          this.activeIndex = 4;
          break;
      }
    },

    changeBlock(id) {
      switch (id) {
        case 0:
          this.$router.push({name: 'person-page', params: {slide: 'about'}})
          break
        case 1:
          this.$router.push({name: 'person-page', params: {slide: 'edu'}})
          break
        case 2:
          this.$router.push({name: 'person-page', params: {slide: 'pub'}})
          break
        case 3:
          this.$router.push({name: 'person-page', params: {slide: 'cases'}})
          break
        case 4:
          this.$router.push({name: 'person-page', params: {slide: 'news'}})
          break
      }
    },

    loadImg() {
      this.IS_LOADING(false)
    },

    hideBlock() {
      this.activeAbout = false
      this.activeEducation = false
      this.activePublication = false
      this.activeCase = false
      this.activeNews = false
    },

    changeBlockClick(id) {
      if(!this.activeFullText) {
        this.changeTextFull();
      }
      this.changeBlock(id)
    },

    checkActiveLengthNews(length) {
      this.$emit('checkActiveLengthNews', length)
    },

    checkActiveLengthCase(length) {
      this.$emit('checkActiveLengthCase', length)
    },
  }
}
</script>

<style scoped lang="scss">


.main-card-person-container {

  .empty-background {
    z-index: 1;
    top: 0;
    left: calc(-1* var(--controller-padding));
    width: calc(var(--calc-width) + var(--controller-padding));
    height: var(--calc-height);
    position: absolute;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 10%;
    margin-left: calc( -1 * var(--controller-padding));
    bottom: 0;
    z-index: 899;
    background: linear-gradient(180deg, rgba(34, 34, 59, 0) 0, var(--color-background) 100%);
  }

  .main-title-page {
    position: absolute;
    color: #F2E9E4 !important;
    top: calc(var(--main-padding-top) + var(--breadcrumbs-height));
  }

  .text-info-container {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
    transition: height .6s ease;
    bottom: calc(-1 * var(--main-padding-bottom));

    .person-info-text-container {
      position: relative;
      overflow: hidden;
      bottom: 0;
      box-shadow: 0px -4px 15px 0px rgba(var(--color-box-shadow) 0.25);
      backdrop-filter: blur(2px);
      background-color: rgba(var(--color-card-rgba), 0.8);
      margin-left: calc(-1 * var(--controller-padding));
      width: calc(100% + 2 * var(--controller-padding));

      &:after {
        opacity: 0;
        transition: opacity .6s ease;
        pointer-events: none;
        content: "";
        position: absolute;
        width: 30%;
        height: 100%;
        z-index: 2;
        bottom: 0;
        right: -1%;
        background: linear-gradient(90deg,  rgba(34, 34, 59, 0.00) 0, var(--color-background) 100%);
      }

      &.active-gradient {
        &:after {
          opacity: .8;
        }
      }



      .person-info-text-content {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;

        .case-slider-container {
          overflow: hidden;
          width: 100%;
          padding: 0 var(--controller-padding);
        }

        .news-slider-container {
          width: 100%;
          padding: 0 var(--controller-padding);
        }

        .info-text-container {
          display: flex;
          flex-direction: column;
          padding: 0 var(--controller-padding);

          &.publication {
            .info-container {
              .info-content {
                flex-direction: column;
              }
            }
          }

          &.education {
            .info-container {

              .education-info-container {
                display: flex;
                flex-direction: column;
              }
              .info-content {
                flex-direction: column;

                .education-content {

                  .text-content {
                    line-height: 150%;
                    color: var(--color-person-text);
                  }

                  &.top {
                    .text-content {
                      color: var(--color-text);
                    }
                  }

                  &.bottom {
                    display: flex;
                    flex-direction: row;
                  }
                }
              }
            }

          }

          .info-container {
            .info-content {
              width: 100%;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
            }

            &.paragraph-container {
              .text-content {
                line-height: 140%;
              }
            }
            &.professional-interest {
              .text-content {
                &.main-text {
                  color: var(--color-person-text);
                }
              }
            }

            &.awards {
              .info-content {
                flex-direction: column;
              }

              .text-content {
                &.main-text {
                  color: var(--color-person-text);
                }
              }
            }
          }

          .text-content {
            line-height: 140%;
          }
        }
      }
      .slider-container {
        position: relative;
        height: 100%;
        padding: 0 0 0 var(--controller-padding);

        .slider-content-container {
          position: relative;
        }
        .empty-block {
          min-height: 32px;
          max-height: 32px;
        }
      }
  }
    .button-controller-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .main-button-container {
        opacity: 1;
      }
    }
  }

  .person-image-container {
    position: absolute;
    z-index: 0;
    right: 0;
    width: 100%;
    height: 100%;
    bottom: 0;

    & img {
      width: 100%;
      height: 100%;
    }
  }


}

@media (max-width: 769px) {
  .main-card-person-container {
    .text-info-container {
      row-gap: 16px;
      height: 111px;

      .person-info-text-container {
        height: 100%;
        padding: 16px 0 0;
        .person-info-text-content {

          .info-text-container {
            row-gap: 16px;
            .empty-block {
              max-height: 32px;
              min-height: 32px;
            }


            &.education {
              .info-container {

                .education-info-container {
                  row-gap: 8px;

                  .info-content {
                    row-gap: 3px;
                  }
                }
                .info-content {

                  .education-content {

                    .text-content {
                      font-size: 10px;
                    }

                    &.bottom {
                      column-gap: 14px;
                    }
                  }
                }
              }

            }

            .info-container {
              .info-content {
                row-gap: 8px;
                column-gap: 8px;
              }

              &.paragraph-container {
                .text-content {
                  &.main-text {
                    font-size: 12px;
                  }
                }
              }
              &.professional-interest {
                .text-content {
                  &.main-text {
                    font-size: 10px;
                  }
                }
              }

              &.awards {
                .text-content {
                  &.main-text {
                    font-size: 10px;
                  }
                }
              }
            }

            .text-content {
              &.main-title {
                padding-bottom: 8px;
              }
            }
          }
        }
        .slider-container {
          .text-content {
            &.main-title {
              padding-bottom: 8px;
            }
          }
          .slider-content-container {
            max-height: 243px;
          }
        }
      }

      &.active-height {
        height: 389px;
      }
      .button-controller-container {
        column-gap: 4px;
        row-gap: 4px;
        max-width: 220px;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .main-card-person-container {
    .text-info-container {
      row-gap: 22px;
      height: 135px;

      .person-info-text-container {
        height: 100%;
        padding: 18px 0 0;
        .person-info-text-content {

          .info-text-container {
            row-gap: 16px;
            .empty-block {
              max-height: 32px;
              min-height: 32px;
            }


            &.education {
              .info-container {

                .education-info-container {
                  row-gap: 8px;

                  .info-content {
                    row-gap: 3px;
                  }
                }
                .info-content {

                  .education-content {

                    .text-content {
                      font-size: 10px;
                    }

                    &.bottom {
                      column-gap: 14px;
                    }
                  }
                }
              }

            }

            .info-container {
              .info-content {
                row-gap: 8px;
                column-gap: 8px;
              }

              &.paragraph-container {
                .text-content {
                  &.main-text {
                    font-size: 12px;
                  }
                }
              }
              &.professional-interest {
                .text-content {
                  &.main-text {
                    font-size: 10px;
                  }
                }
              }

              &.awards {
                .text-content {
                  &.main-text {
                    font-size: 10px;
                  }
                }
              }
            }

            .text-content {
              &.main-title {
                padding-bottom: 8px;
              }
            }
          }
        }
        .slider-container {
          .text-content {
            &.main-title {
              padding-bottom: 8px;
            }
          }
          .slider-content-container {
            max-height: 276px;
          }
        }
      }

      &.active-height {
        height: 428px;
      }
      .button-controller-container {
        column-gap: 4px;
        row-gap: 4px;
        max-width: 250px;
      }
    }

  }
}

@media (max-width: 375px){
  .main-card-person-container {
    .text-info-container {
      row-gap: 9px;
      height: 109px;

      .person-info-text-container {
        height: 100%;
        padding: 16px 0 0;
        .person-info-text-content {

          .info-text-container {
            row-gap: 14px;
            .empty-block {
              max-height: 32px;
              min-height: 32px;
            }


            &.education {
              .info-container {

                .education-info-container {
                  row-gap: 8px;

                  .info-content {
                    row-gap: 3px;
                  }
                }
                .info-content {

                  .education-content {

                    .text-content {
                      font-size: 10px;
                    }

                    &.bottom {
                      column-gap: 14px;
                    }
                  }
                }
              }

            }

            .info-container {
              .info-content {
                row-gap: 8px;
                column-gap: 8px;
              }

              &.paragraph-container {
                .text-content {
                  &.main-text {
                    font-size: 11px;
                  }
                }
              }
              &.professional-interest {
                .text-content {
                  &.main-text {
                    font-size: 10px;
                  }
                }
              }

              &.awards {
                .text-content {
                  &.main-text {
                    font-size: 10px;
                  }
                }
              }
            }

            .text-content {
              &.main-title {
                padding-bottom: 8px;
              }
            }
          }
        }
        .slider-container {
          .text-content {
            &.main-title {
              padding-bottom: 8px;
            }
          }
          .slider-content-container {
            max-height: 208px;
          }
        }
      }

      &.active-height {
        height: 328px;
      }
      .button-controller-container {
        column-gap: 4px;
        row-gap: 4px;
        max-width: 200px;
      }
    }

  }
}


@media (max-width: 375px) and (min-height: 630px) {

  .main-card-person-container {
    .text-info-container {
      row-gap: 18px;
      height: 128px;

      .person-info-text-container {
        height: 100%;
        padding: 16px 0 0;
        .person-info-text-content {

          .info-text-container {
            row-gap: 14px;
            .empty-block {
              max-height: 32px;
              min-height: 32px;
            }


            &.education {
              .info-container {

                .education-info-container {
                  row-gap: 8px;

                  .info-content {
                    row-gap: 3px;
                  }
                }
                .info-content {

                  .education-content {

                    .text-content {
                      font-size: 10px;
                    }

                    &.bottom {
                      column-gap: 14px;
                    }
                  }
                }
              }

            }

            .info-container {
              .info-content {
                row-gap: 8px;
                column-gap: 8px;
              }

              &.paragraph-container {
                .text-content {
                  &.main-text {
                    font-size: 12px;
                  }
                }
              }
              &.professional-interest {
                .text-content {
                  &.main-text {
                    font-size: 10px;
                  }
                }
              }

              &.awards {
                .text-content {
                  &.main-text {
                    font-size: 10px;
                  }
                }
              }
            }

            .text-content {
              &.main-title {
                padding-bottom: 8px;
              }
            }
          }
        }
      }

      &.active-height {
        height: 345px;
      }
      .button-controller-container {
        column-gap: 4px;
        row-gap: 4px;
        max-width: 200px;
      }
    }

  }
}


@media (max-width: 320px) {
  .main-card-person-container {
    .text-info-container {
      row-gap: 16px;
      height: 114px;

      .person-info-text-container {
        height: 100%;
        padding: 16px 0 0;
        .person-info-text-content {

          .info-text-container {
            row-gap: 14px;
            .empty-block {
              max-height: 32px;
              min-height: 32px;
            }


            &.education {
              .info-container {

                .education-info-container {
                  row-gap: 8px;

                  .info-content {
                    row-gap: 3px;
                  }
                }
                .info-content {

                  .education-content {

                    .text-content {
                      font-size: 9px;
                    }

                    &.bottom {
                      column-gap: 14px;
                    }
                  }
                }
              }

            }

            .info-container {
              .info-content {
                row-gap: 8px;
                column-gap: 8px;
              }

              &.paragraph-container {
                .text-content {
                  &.main-text {
                    font-size: 10px;
                  }
                }
              }
              &.professional-interest {
                .text-content {
                  &.main-text {
                    font-size: 9px;
                  }
                }
              }

              &.awards {
                .text-content {
                  &.main-text {
                    font-size: 9px;
                  }
                }
              }
            }

            .text-content {
              &.main-title {
                padding-bottom: 8px;
              }
            }
          }
        }
        .slider-container {

          .text-content {
            &.main-title {
              padding-bottom: 8px;
            }
          }
          .slider-content-container {
            max-height: 150px;
          }
        }
      }

      &.active-height {
        height: 249px;
      }
      .button-controller-container {
        column-gap: 4px;
        row-gap: 4px;
        max-width: 185px;
      }
    }

    .person-image-container {
    }
  }
}


</style>