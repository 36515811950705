<template>
  <div class="slider-img-content-container" v-if="!activeMobile">
    <div class="swiper-button swiper-button-left" v-if="lengthInfo > 1">
      <img v-if="activeColor" :src="getUrlIcon(imgNameLeft + '-dark.svg')" alt="icon">
      <img v-else :src="getUrlIcon(imgNameLeft + '-light.svg')" alt="icon">
    </div>
    <transition name="fade-translate-x">
      <swiper @slide-change="onSwiperMain"
              :class="{'active-gradient-color' : !sliderSetting.loop}"
              @swiper="changeSettingSwiper"
              :slides-per-view="sliderSetting.slidesPerView"
              :centered-slides="sliderSetting.centeredSlides"
              :effect="'coverflow'"
              :breakpoints="{
                769: {
                  coverflowEffect: {
                    rotate: 0,
                    depth: 300,
                    stretch: 0,
                    slideShadows: false,
                  },
                  navigation: {
                    nextEl: '.swiper-button-right',
                  prevEl: '.swiper-button-left'
                  }
                }
              }"
              :loop="sliderSetting.loop"
              :modules="modules"
              class="swiper"
              :speed="1000"
              :direction="'horizontal'"
              :allow-touch-move="true">
              <slot/>
      </swiper>
    </transition>
    <div class="swiper-button swiper-button-right" v-if="lengthInfo > 1">
      <img v-if="activeColor" :src="getUrlIcon(imgNameRight + '-dark.svg')" alt="icon">
      <img v-else :src="getUrlIcon(imgNameRight + '-light.svg')" alt="icon">
    </div>
  </div>
  <div class="slider-img-content-container" v-else>
    <div class="swiper-button swiper-button-left" v-if="lengthInfo > 1">
      <img v-if="activeColor" :src="getUrlIcon(imgNameLeft + '-dark.svg')" alt="icon">
      <img v-else :src="getUrlIcon(imgNameLeft + '-light.svg')" alt="icon">
    </div>
    <transition name="fade-translate-x">
      <swiper @slide-change="onSwiperMain"
              :class="{'active-gradient-color' : !sliderSetting.loop}"
              @swiper="changeSettingSwiper"
              :slides-per-view="sliderSetting.slidesPerView"
              :centered-slides="true"
              :navigation="{
                      nextEl: '.swiper-button-right',
              prevEl: '.swiper-button-left'
              }"
              :loop="sliderSetting.loop"
              :modules="modulesMobile"
              class="swiper"
              :speed="1000"
              :direction="'horizontal'"
              :allow-touch-move="true">
        <slot/>
      </swiper>
    </transition>
    <div class="swiper-button swiper-button-right" v-if="lengthInfo > 1">
      <img v-if="activeColor" :src="getUrlIcon(imgNameRight + '-dark.svg')" alt="icon">
      <img v-else :src="getUrlIcon(imgNameRight + '-light.svg')" alt="icon">
    </div>
  </div>
</template>

<script>
import {EffectCoverflow, Navigation} from "swiper";

export default {
  name: "SliderImg",
  props: {
    sliderSetting: {
      type: Object,
      required: true
    },
    lengthInfo: {
      type: Number
    }
  },
  data() {
    return {
      imgSwiper: '',
      activeIndexImg: 0,
      filterActive: false,
      modules: [Navigation, EffectCoverflow],
      modulesMobile: [Navigation],
      imgNameLeft: 'single-arrow-left',
      imgNameRight: 'single-arrow-right'
    }
  },
  methods: {
    changeSettingSwiper(swiper) {
      this.imgSwiper = swiper
    },
    onSwiperMain(swiper) {
      this.activeIndexImg = swiper.realIndex
      this.$emit('changeSlide', this.activeIndexImg)
    },
  }
}
</script>

<style scoped lang="scss">

.slider-img-content-container {
  position: relative;
  max-width: inherit;
  max-height: inherit;
  height: 100%;
  width: 100%;

  .swiper-button {
    z-index: 900;
    position: absolute;
    cursor: pointer;
    top: 40%;
    opacity: .6;
    width: 100%;
    height: 100%;
    transition: opacity .6s ease;

    &.swiper-button-left {
      left: 0;
    }

    &.swiper-button-right {
      right: 0;
    }

    &:hover {
      opacity: 1;
    }

    & img {
      width: 100%;
      height: 100%;
    }
  }

  .swiper {
    height: inherit;

    &.active-gradient-color {
      &:after {
        background: linear-gradient(90deg,  rgba(34, 34, 59, 0.00) 0, var(--color-card) 100%);
      }

      &:before {
        background: linear-gradient(270deg,  rgba(34, 34, 59, 0.00) 0, var(--color-card) 100%);
      }
    }


    &:after {
      content: "";
      position: absolute;
      width: 30%;
      height: 100%;
      z-index: 2;
      bottom: 0;
      right: -2%;
      pointer-events: none;
      background: linear-gradient(90deg,  rgba(34, 34, 59, 0.00) 0, var(--color-background) 100%);
    }

    &:before {
      content: "";
      position: absolute;
      width: 30%;
      height: 100%;
      z-index: 2;
      top: 0;
      left: -1%;
      pointer-events: none;
      background: linear-gradient(270deg,  rgba(34, 34, 59, 0.00) 0, var(--color-background) 100%);
    }
  }
}

@media (max-width: 2560px){
  .slider-img-content-container {

    .swiper-button {
      max-width: 29px;
      max-height: 61px;
    }

    .swiper {
      max-width: 809px;
    }
  }
}



@media (max-width: 1920px) {
  .slider-img-content-container {

    .swiper-button {
      max-width: 18px;
      max-height: 39px;
    }

    .swiper {
      max-width: 620px;
    }
  }
}


@media (max-width: 1600px) {
  .slider-img-content-container {

    .swiper-button {
      max-width: 15px;
      max-height: 30px;
    }

    .swiper {
      max-width: 520px;
    }
  }
}

@media (max-height: 800px) {
  .slider-img-content-container {

    .swiper-button {
      max-width: 15px;
      max-height: 30px;
    }
  }
}
@media (max-width: 1400px) and (min-height: 801px)  {
  .slider-img-content-container {

    .swiper-button {
      max-width: 12px;
      max-height: 26px;
    }
  }
}

@media (max-width: 1133px) {
  .slider-img-content-container {

    .swiper {
      max-width: 396px;
    }

    .swiper-button {
      max-width: 12px;
      max-height: 26px;
    }
  }
}
@media (max-width: 769px) {
  .slider-img-content-container {
    .swiper {
      margin-left: -18px;
      max-width: var(--calc-width);

      &.active-gradient-color {
        &:after {
          background: linear-gradient(90deg,  rgba(34, 34, 59, 0.00) 0, var(--color-background) 100%);
        }

        &:before {
          background: linear-gradient(270deg,  rgba(34, 34, 59, 0.00) 0, var(--color-background) 100%);
        }
      }
    }

    .swiper-button {
      top: 50%;
      padding: 10px;
      max-width: 27px;
      max-height: 35px;
    }
  }
}


@media (max-width: 320px) {
  .slider-img-content-container {
    .swiper {
      margin-left: -16px;
    }

    .swiper-button {
      max-width: 26px;
      max-height: 32px;
    }
  }
}

@media (min-width: 2561px) {
  .slider-img-content-container {

    .swiper-button {
      max-width: 53px;
      max-height: 100px;
    }

    .swiper {
      max-width: 1421px;

      //&.active-gradient-color {
      //  max-width: 1421px;
      //}
    }
  }
}

</style>