<template>
    <div class="main-button-container mobile">
      <div class="main-button-content mobile">
      <p class="text-content" :class="{'mini-button-active' : miniButtonActive}">
        {{ textButton }}
      </p>
      </div>
    </div>
</template>

<script>
export default {
  name: "ButtonMainMobile",
  props: {
    textButton: {
      type: String,
      required: true
    },
    miniButtonActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
    .main-button-container {
      position: relative;
      z-index: 3;
      width: fit-content;
      border: 0 solid var(--color-field);
      color: var(--color-text);
      background: linear-gradient(104deg, var(--color-main-button-mobile) 17.64%, var(--color-background) 100%);
      box-shadow: 2px 4px 30px 0 rgba(var(--color-box-shadow), 0.15);

      &.mini-button-active {
        box-shadow: 2px 4px 12px 0 rgba(var(--color-box-shadow), 0.15);
      }

      &:hover {
        color: var(--color-element);
        border-width: 1px;
      }
      .main-button-content {
        .text-content {
          font-family: 'CormorantUnicase-Regular', sans-serif;
          text-transform: uppercase;
        }

      }
    }

    @media (max-width: 769px) {
      .main-button-container {
        padding: 8px 16px;
        border-radius: 5px;
        .main-button-content {
          .text-content {
            letter-spacing: 2px;
            font-size: 10px;
          }
        }
      }
    }

    @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
      .main-button-container {
        padding: 8px 16px;
        border-radius: 5px;
        .main-button-content {
          .text-content {
            letter-spacing: 2.4px;
            font-size: 12px;
          }
        }
      }
    }


    @media (max-width: 375px){
      .main-button-container {
        padding: 8px 16px;
        border-radius: 5px;
        .main-button-content {
          .text-content {
            letter-spacing: 2px;
            font-size: 10px;

            &.mini-button-active {
              font-size: 8px;
            }
          }
        }
      }
    }


    @media (max-width: 375px) and (min-height: 630px) {
      .main-button-container {
        padding: 8px 16px;
        border-radius: 5px;
        .main-button-content {
          .text-content {
            letter-spacing: 2px;
            font-size: 10px;
          }
        }
      }
    }


    @media (max-width: 320px) {
      .main-button-container {
        padding: 6px 16px;
        border-radius: 5px;
        .main-button-content {
          .text-content {
            letter-spacing: 1.6px;
            font-size: 7px;

            &.mini-button-active {
              font-size: 7px;
            }
          }
        }
      }
    }

</style>