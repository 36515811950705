<template>
  <div>
    <transition name="fade-opacity-lazy">
      <title-router-main-page v-if="(activeMainSlide > 0 && activeMainSlide < 6) && !activeMobile"
                              :active-main-slide="activeMainSlide"/>
    </transition>

    <transition name="fade-opacity-lazy">
      <main-title-page v-if="(activeMainSlide > 0 && activeMainSlide < 6) && activeStateMainTitle && !activeMobile"
                       :active-main-title="mainTitleInfo"/>
    </transition>

    <transition name="fade-opacity-lazy">
      <mobile-controller  @slideTo="slideNext" v-if="activeMobileController"/>
    </transition>

    <transition name="fade-opacity-lazy">
      <main-title-router-mobile v-if="activeMobile && (activeMainSlide > 0 && activeMainSlide < 6)"
                                :active-state-main-title="activeStateMainTitle"
                                :active-main-slide="activeMainSlide"
                                :active-main-title="mainTitleInfo"/>
    </transition>

    <swiper @slide-change="onSwiperMain"
            @swiper="changeSettingSwiper"
            :effect="'fade'"
            :modules="modules"
            class="swiper"
            :navigation = "{
                  nextEl: '.swiper-button-bottom'
              }"
            :allow-slide-next="true"
            :allow-slide-prev="true"
            :mousewheel="true"
            :speed="1000"
            :allow-touch-move="false"
            direction="vertical">




      <swiper-slide>
        <start-screen :active-screen="activeStartScreen"
                      @checkStartSwiper="checkBlockStartSwiper"/>
      </swiper-slide>
      <swiper-slide class="main-slide-container">

        <about-screen :active-screen="activeAboutScreen"
                      :href-about="hrefAbout"
                      @blockPrevSlide="blockPrevSlide"
                      @blockNextSlide="blockNextSlide"/>
      </swiper-slide>
      <swiper-slide class="main-slide-container">
        <transition name="fade-opacity-lazy">
          <team-screen v-if="activeTeamScreen"/>
        </transition>

      </swiper-slide>
      <swiper-slide class="main-slide-container">
        <transition name="fade-opacity-lazy">
          <industry-screen v-if="activeIndustryScreen"/>
        </transition>

      </swiper-slide>
      <swiper-slide class="main-slide-container">
        <transition name="fade-opacity-lazy">
          <case-screen v-if="activeCaseScreen"/>
        </transition>

      </swiper-slide>
      <swiper-slide class="main-slide-container">
        <transition name="fade-opacity-lazy">
          <news-screen v-if="activeNewsScreen"/>
        </transition>

      </swiper-slide>
      <swiper-slide class="main-slide-container">
        <transition name="fade-opacity-lazy">
          <footer-screen v-if="activeFooterScreen"/>
        </transition>

      </swiper-slide>
    </swiper>
  </div>


</template>

<script>

import {EffectFade, HashNavigation, History, Mousewheel, Navigation} from "swiper";
import {mapActions, mapGetters} from "vuex";
import FooterScreen from "@/components/features/FooterScreen.vue";
import NewsScreen from "@/components/features/NewsScreen.vue";
import CaseScreen from "@/components/features/CaseScreen.vue";
import IndustryScreen from "@/components/features/IndustryScreen.vue";
import TeamScreen from "@/components/features/TeamScreen.vue";
import AboutScreen from "@/components/features/AboutScreen.vue";
import StartScreen from "@/components/features/StartScreen.vue";
import MainTitleRouterMobile from "@/components/shared/MainTitleRouterMobile.vue";
import MainTitlePage from "@/components/entities/MainTitlePage.vue";
import TitleRouterMainPage from "@/components/shared/TitleRouterMainPage.vue";
import MobileController from "@/components/entities/MobileController.vue";

export default {
  name: "main-page",
  components: {
    MobileController,
    TitleRouterMainPage,
    MainTitlePage,
    MainTitleRouterMobile,
    StartScreen, AboutScreen, TeamScreen, IndustryScreen, CaseScreen, NewsScreen, FooterScreen},
  data() {
    return {
      innerHeight: 0,
      innerWidth: 0,
      modules: [EffectFade, Mousewheel, HashNavigation, Navigation, History],

      mainSwiper: '',

      blockSwiper: false,

      activeAboutScreen: false,
      activeTeamScreen: false,
      activeIndustryScreen: false,
      activeCaseScreen: false,
      activeNewsScreen: false,
      activeFooterScreen: false,
      activeStartScreen: false,

      blockSwiperNext: false,
      blockSwiperPrev: false,

      activeStateMainTitle: false,
      hrefAbout: ['im', 'mission', 'values', 'iminvest', 'partners']
    }
  },
  mounted() {
    this.checkMount();
    if(this.activeMainSlide === 0) {
      this.activeStartScreen = true
    }
    this.changeActiveUrl(false);
    this.mainSwiper.allowTouchMove = this.allowTouchMoveState;
    this.activeStateMainTitle = true;
  },



  computed: {
    ...mapGetters('partners',['activePartnersLength']),

    activeMobileController() {
      let slide = 0;
      if(this.activePartnersLength) {
        slide = 4
      } else {
        slide = 3
      }
      if(this.activeMobile) {
        if(this.activeIndustrySlide < 1) {
          if(this.activeMainSlide === 1) {
            return this.activeAboutSlide === slide;
          } else {
            return this.activeMainSlide > 0 && this.activeMainSlide < 6
          }
        } else {
          return false
        }
      } else {
        return false
      }
    },

    activeMouseScroll() {
      return this.$store.state.activeMouseScroll;
    },

    activeModalFilter() {
      return this.$store.state.activeModalFilter;
    },

    activeCookieModal() {
      return this.$store.state.activeCookieModal;
    },

    activeMobile() {
      return this.$store.state.activeMobile;
    },

    activeAboutSlide() {
      return this.$store.state.activeAboutSlide;
    },

    activeTeamSlide() {
      return this.$store.state.persons.activeTeamSlide;
    },

    activeIndustrySlide() {
      return this.$store.state.industry.activeIndustrySlide;
    },

    changeActiveSubSlide(){
      return this.$store.state.changeActiveSubSlide;
    },

    activeMainSlide() {
      return this.$store.state.activeMainSlide;
    },

    allowTouchMoveState() {
      return this.$store.state.allowTouchMoveState;
    },


    controllerHref() {
      return this.$tm('mainController.mainControllerInfo').map(item => item.slideName);
    },

    mainTitleInfo() {
      switch (this.activeMainSlide) {
        case 1:
          return this.mainTitlePage.aboutScreen.subTitle[this.activeAboutSlide];
        case 2:
          if(this.changeActiveSubSlide) {
            return this.mainTitlePage.teamScreen.subTitle[this.activeTeamSlide-1];
          } else {
            return this.mainTitlePage.teamScreen.startTitle;
          }
        case 3:
          return this.mainTitlePage.industryScreen[this.activeIndustrySlide];
        case 4:
          return this.mainTitlePage.caseScreen;
        case 5:
          return this.mainTitlePage.newsScreen[0];
      }
    }
  },



  watch: {
    '$route.name'() {
      this.changeActiveUrl(true);
    },
    activeMouseScroll() {
      if(this.activeMouseScroll) {
        this.mainSwiper.enable();
      } else {
        this.mainSwiper.disable();
      }
    },

    activeModalFilter() {
      if(this.activeModalFilter) {
        this.mainSwiper.disable();
      } else {
        this.mainSwiper.enable();
      }
    },

    activeMainSlide() {
      this.changeSlideController();
    },
    mainTitleInfo() {
      this.changeStateActiveMainTitle();
    },
    activeCookieModal() {
      if(this.activeCookieModal) {
        this.mainSwiper.disable();
      } else {
        this.mainSwiper.enable();
      }
    },
    allowTouchMoveState() {
      this.mainSwiper.allowTouchMove = this.allowTouchMoveState;
    }
  },


  methods: {
    ...mapActions(['ACTIVE_MAIN_SLIDE',
      'CHANGE_ACTIVE_SUB_SLIDE',
      'ACTIVE_MOBILE',
      'ALLOW_TOUCH_MOVE_STATE',
      'RETURN_INDEX_ACTIVE',
      'ACTIVE_SUB_PAGE',
      'ACTIVE_MODAL_FILTER']),

    changeActiveUrl(state) {
      this.blockNextSlide(false)
      this.blockPrevSlide(false)
      if(this.$route.name?.includes('industry')) {
        this.mainSwiper.slideTo(3, 0, state)
      }
      if(this.$route.name?.includes('team')) {
        this.mainSwiper.slideTo(2, 0, state)
      }
      if(this.$route.name?.includes('about')) {
        this.mainSwiper.slideTo(1, 0, state)
      }
      if(this.$route.name?.includes('cases')) {
        this.mainSwiper.slideTo(4, 0, state)
      }
      if(this.$route.name?.includes('press')) {
        this.mainSwiper.slideTo(5, 0, state)
      }
      if(this.$route.name?.includes('contacts')) {
        this.mainSwiper.slideTo(6, 0, state)
      }
      if(this.$route.name?.includes('main')) {
          this.mainSwiper.slideTo(0, 0, state)
        this.ACTIVE_MAIN_SLIDE(0)
      }
      if(this.$route.name?.includes('not-found-page')) {
        this.mainSwiper.slideTo(0, 0, state)
      }
    },

    checkMount() {
      this.ACTIVE_SUB_PAGE(false)
    },

    changeStateActiveMainTitle() {
        this.activeStateMainTitle = false;
        setTimeout(() => {
          this.activeStateMainTitle = true;
        }, 500)
    },


    changeSettingSwiper(swiper) {
      this.mainSwiper = swiper
    },
    onSwiperMain(swiper) {
      if(!this.blockSwiper) {
        this.ACTIVE_MAIN_SLIDE(swiper.activeIndex)
        this.checkActiveSlide(this.activeMainSlide);
        setTimeout(() => {
          this.checkBlockSwiper();
        },500)
      }
    },

    slideNext() {
      this.mainSwiper.slideNext();
    },


    checkActiveSlide(id) {
      this.ACTIVE_MODAL_FILTER(false)
      this.RETURN_INDEX_ACTIVE(-1)
      if (id === 1) {
        this.CHANGE_ACTIVE_SUB_SLIDE(false)
        setTimeout(() => {
          this.CHANGE_ACTIVE_SUB_SLIDE(true)
        }, 100)
      } else {
        this.CHANGE_ACTIVE_SUB_SLIDE(false)
      }
      this.activeStartScreen = false
      this.activeAboutScreen = false;
      this.activeTeamScreen = false;
      this.activeIndustryScreen = false;
      this.activeCaseScreen = false;
      this.activeNewsScreen = false;
      this.activeFooterScreen = false;
      switch(id) {
        case 0:
          this.IS_LOADING(false)
          this.activeStartScreen = true
          this.blockSwiperNext = false
          break;
        case 1:
          this.IS_LOADING(false)
          this.activeAboutScreen = true;
          break;
        case 2:
          this.IS_LOADING(false)
          this.blockSwiperPrev = false
            this.activeTeamScreen = true;
          break;
        case 3:
          this.activeIndustryScreen = true;
          break;
        case 4:
          this.activeCaseScreen = true;
          break
        case 5:
          this.activeNewsScreen = true;
          break;
        case 6:
          this.IS_LOADING(false)
          this.activeFooterScreen = true;
          break
      }

      if(this.activeMainSlide === 1) {
        this.$router.push({name: 'about', params: {typeAbout: this.hrefAbout[this.activeAboutSlide]}})
      }
    },
    changeSlideController() {
      this.blockSwiper = true
      this.blockSwiperNext = false;
      this.blockSwiperPrev = false;
      this.checkBlockSwiper();
      this.mainSwiper.slideTo(this.activeMainSlide)
      this.checkActiveSlide(this.activeMainSlide);
      setTimeout(() => {
        this.blockSwiper = false
      })
    },

    blockPrevSlide(state) {
      this.blockSwiperPrev = state;
      this.checkBlockSwiper();
    },

    blockNextSlide(state) {
      this.blockSwiperNext = state;
      this.checkBlockSwiper();
    },

    checkBlockSwiper() {
      this.mainSwiper.allowSlideNext = !this.blockSwiperNext;
      this.mainSwiper.allowSlidePrev = !this.blockSwiperPrev;
    },

    checkBlockStartSwiper(id) {
        if (id < 2) {
          this.mainSwiper.disable();
        } else {
          setTimeout(() => {
            if (id === 2 && !this.activeCookieModal) {
              setTimeout(() => {
                this.mainSwiper.enable();
              }, 500)
            }
          }, 1000)
        }
    },
  }
 }
</script>

<style scoped lang="scss">

.main-title-page {
  position: absolute;
  left: var(--controller-padding)
}

.swiper-slide {
  &.main-slide-container {
    width: 100%;
    height: 100%;
    background-color: var(--color-background);
  }
}



.slider-content-container {
    max-height: 802px;
}
</style>