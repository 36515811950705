<template>
  <div class="controller-team-container">
    <div class="controller-list-container" :class="{'active-light' : !activeColor, 'one': countActiveTypeTeam === 1, 'two': countActiveTypeTeam === 2, 'three': countActiveTypeTeam === 3}" >
        <div class="controller-list-content"
             v-show="getActiveTypeTeam[index]"
             @mouseenter="focusChange(item.id)" @mouseleave="focusChange(0)"
             @click="changeTeamActive(item.id)"
             v-for="(item, index) in teamInfo">
          <transition name="fade-opacity-animation">
          <div class="controller-list-item" v-show="isVisible(item.id)">
            <transition name="fade-scale">
              <pointer-container v-if="!activeMobile && focusParam && this.activeId === item.id"/>
            </transition>
            <pointer-container v-if="activeMobile"/>
            <large-title :active-cormorant="true" v-if="!activeMobile">
              {{item.text}}
            </large-title>
            <large-title :active-cormorant="true" v-else class="active-team">
              {{item.text}}
            </large-title>
          </div>
          </transition>
        </div>
    </div>
    <transition name="fade-opacity-animation">
      <div class="background-img-container" :class="{'active-dark' : activeColor}" v-if="activeBackground && !activeMobile">
        <div class="lottie-wrapper" v-show="activeColor">
          <LottieAnimation :animationData="require('@/assets/lottie/BrashTeamDarkTheme.json')"
                           :flex-growth="'None'"
                           style="height: 100%"
                           :loop="false"
                           :delay="1000"
                           :rendererSettings="{ preserveAspectRatio: 'xMaxYMid meet' }" />
        </div>
        <div class="lottie-wrapper" v-show="!activeColor">
          <LottieAnimation :animationData="require('@/assets/lottie/BrashTeamLightTheme.json')"
                           :flex-growth="'None'"
                           :loop="false"
                           :delay="1000"
                           :rendererSettings="{ preserveAspectRatio: 'xMaxYMid meet' }" />
        </div>
<!--        <img v-if="activeColor" :src="blotDark" alt="blot">-->
<!--        <img v-else :src="blotLight" alt="blot">-->
      </div>
    </transition>
    <transition name="fade-opacity-animation">
      <div class="background-img-container" v-if="activeBackground && activeMobile" :class="{'active-dark' : activeColor}">
        <div class="lottie-wrapper dark" v-show="activeColor">
          <LottieAnimation :animationData="require('@/assets/lottie/BrashTeamDarkThemeMobile.json')"
                           :flex-growth="'None'"
                           style="height: 100%"
                           :loop="false"
                           :delay="1000"
                           :rendererSettings="{ preserveAspectRatio: 'xMaxYMid meet' }" />
        </div>
        <div class="lottie-wrapper" v-show="!activeColor">
          <LottieAnimation :animationData="require('@/assets/lottie/BrashTeamLightThemeMobile.json')"
                           :flex-growth="'None'"
                           style="height: 100%"
                           :loop="false"
                           :delay="1000"
                           :rendererSettings="{ preserveAspectRatio: 'xMaxYMid meet' }" />
        </div>
<!--        <img v-if="activeColor" :src="blotDarkMobile" alt="blot">-->
<!--        <img v-else :src="blotLightMobile" alt="blot">-->
      </div>
    </transition>
  </div>
</template>

<script>
import PointerContainer from "@/components/shared/PointerContainer.vue";
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import LottieContainer from "@/components/entities/LottieContainer.vue";
import {mapGetters} from "vuex";

export default {
  name: "ControllerTeam",
  components: {LottieContainer, LargeTitle, PointerContainer},
  props: {
    activeTeamIndex: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      blotLight: 'blot-light.svg',
      blotDark: 'blot-dark.svg',
      blotLightMobile: 'blot-mobile-light.svg',
      blotDarkMobile: 'blot-mobile-dark.svg',
      activeBackground: false,
      activeItem: -1,
      focusParam: false,
      activeId: -1
    }
  },

  mounted() {
    this.showNextItem();
    this.activeBackground = true
  },

  computed: {
    ...mapGetters('persons', ['getActiveTypeTeam']),
    teamInfo() {
      return this.$tm('teamScreen.teamStartScreen.teamList');
    },

    countActiveTypeTeam() {
      return this.getActiveTypeTeam.filter(item => item === true).length;
    },
  },
  methods: {
    changeTeamActive(id) {
      this.$emit('changeTeamActive',id)
    },

    focusChange(id) {
      this.activeId = id
      this.focusParam = !this.focusParam;
    },

    showNextItem() {
      if (this.activeItem < this.teamInfo.length) {
        setTimeout(() => {
          this.activeItem++
          this.showNextItem();
        }, 250)
      }
    },

    isVisible(index) {
      return index <= this.activeItem
    },
  }
}
</script>

<style scoped lang="scss">
.controller-team-container {
  width: 100%;
  //flex-basis: 43%;
  display: flex;
  justify-content: flex-end;
  align-items: center;    .background-img-container {
  height: 100%;
  width: 100%;

  &.active-dark {
    width: 95%;
  }
}
  height: 100%;
  position: relative;

  .controller-list-container {
    transition: margin-bottom .8s ease;
    margin-right: 20%;
    margin-bottom: 15%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;

    &.active-dark {
      margin-bottom: 0;
    }

    .controller-list-content {
      cursor: pointer;


      &:hover {
        .controller-list-item {
          .text-content {
            opacity: 1;
          }
        }

      }

      .controller-list-item {
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;

        .text-content {
          font-family: 'CormorantUnicase-Bold', sans-serif;
          opacity: .6;
          transition: all .5s ease;
          &.active-team {
            opacity: 1;
          }
        }
      }
    }
  }

  .background-img-container {
    z-index: 0;
    height: 100%;
    width: 100%;
    right: calc(-1 * var(--padding-right));
    position: absolute;
    bottom: 10%;

    & img {
      width: 100%;
      height: 100%;
    }

    &.active-dark {
      width: 100%;
      top:  calc(-1 * (var(--title-padding)));
    }

    .lottie-wrapper {
      justify-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 2560px){
  .controller-team-container {
    .controller-list-container {
      row-gap: 56px;
      max-width: 438px;
      margin-right: 10%;
      margin-bottom: 10%;


      .controller-list-content {
        height: 38px;

        .controller-list-item {

          .pointer-container {
            right: -32px;
          }

          .text-content {
            font-size: 38px;
            letter-spacing: 7.6px;
          }
        }
      }
    }


    .background-img-container {
      max-height: 1050px;
      height: 100%;
      width: 100%;

      &.active-dark {
        max-height: inherit;
        max-width: 1125px;
        width: 100%;
        height: 125%;
      }
    }
  }
}

@media (max-width: 2240px) {
  .controller-team-container {
    .controller-list-container {
      max-width: 386px;
      margin-bottom: 20%;
    }

    .background-img-container {
      height: 100%;
      width: 110%;

      &.active-dark {
        width: 100%;
      }
    }
  }
}


@media (max-width: 1920px) {
  .controller-team-container {
    .controller-list-container {
      row-gap: 40px;
      max-width: 326px;
      margin-bottom: 8%;
      .controller-list-content {
        height: 39px;

        .controller-list-item {

          .pointer-container {
            right: -24px;
          }

          .text-content {
            font-size: 32px;
            letter-spacing: 6.4px;
          }
        }
      }
    }

    .background-img-container {
      max-height: 762px;
      height: 100%;
      width: 100%;

      &.active-dark {
        max-height: inherit;
        max-width: 845px;
        width: 100%;
        height: 125%;
      }
    }
  }
}


@media (max-width: 1600px) {
  .controller-team-container {
    .controller-list-container {
      row-gap: 32px;
      max-width: 248px;
      margin-bottom: 20%;
      margin-right: 15%;
      .controller-list-content {
        height: 27px;

        .controller-list-item {

          .pointer-container {
            right: -18px;
          }

          .text-content {
            font-size: 22px;
            letter-spacing: 4.4px;
          }
        }
      }
    }

    .background-img-container {
      max-height: 650px;
      height: 100%;
      width: 100%;

      &.active-dark {
        max-height: inherit;
        max-width: 845px;
        width: 100%;
        height: 115%;
      }
    }
  }
}
@media (max-height: 800px) {
  .controller-team-container {
    .controller-list-container {
      row-gap: 32px;
      max-width: 248px;
      margin-bottom: 15%;
      margin-right: 12%;
      .controller-list-content {
        height: 27px;

        .controller-list-item {

          .pointer-container {
            right: -18px;
          }

          .text-content {
            letter-spacing: 4.4px;
          }
        }
      }
    }
    .background-img-container {
      max-width: 628px;
      max-height: 515px;
      height: 110%;
      width: 115%;

      &.active-dark {
        max-height: inherit;
        max-width: 643px;
        width: 100%;
        height: 125%;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .controller-team-container {
    .controller-list-container {
      row-gap: 32px;
      max-width: 248px;
      margin-right: 20%;
      margin-bottom: 25%;
      &.active-dark {
        margin-bottom: 10%;
      }

      .controller-list-content {
        height: 27px;

        .controller-list-item {

          .pointer-container {
            right: -18px;
          }

          .text-content {
            font-size: 24px;
            letter-spacing: 4.4px;
          }
        }
      }
    }
    .background-img-container {
      max-height: 680px;
      height: 100%;
      width: 110%;

      &.active-dark {
        max-height: inherit;
        max-width: 761px;
        width: 105%;
        height: 110%;
      }
    }
  }
}

@media (max-width: 1133px) {
  .controller-team-container {
    .controller-list-container {
      margin-bottom: 12%;
      row-gap: 26px;
      max-width: 226px;

      .controller-list-content {
        height: 24px;

        .controller-list-item {

          .pointer-container {
            right: -16px;
          }

          .text-content {
            font-size: 20px;
            letter-spacing: 4px;
          }
        }
      }
    }
    .background-img-container {
      max-width: 540px;
      max-height: 460px;
      height: 110%;
      width: 115%;

      &.active-dark {
        max-height: inherit;
        max-width: 643px;
        width: 100%;
        height: 125%;
      }
    }
  }
}

@media (max-width: 769px) {
  .controller-team-container {
  align-items: flex-start;
  justify-content: center;
  width: 120%;
  flex-basis: 75%;
  .controller-list-container {
    margin-right: -5%;
    margin-bottom: 0;
    row-gap: 40px;
    max-width: 178px;

    &.one {
      margin-top: 130px;
    }

    &.two {
      margin-top: 104px;
    }

    &.three {
      margin-top: 74px;
    }

    .controller-list-content {
      height: 19px;

      .controller-list-item {

        .pointer-container {
          right: -16px;
        }

        .text-content {
          font-size: 16px;
          letter-spacing: 3.2px;
        }
      }
    }
  }

  .background-img-container {
    left: 0;
    right: 0;
    height: 90%;
    width: 100%;
    top: 0;
    bottom: auto;

    &.active-dark {
      height: 100%;
      .lottie-wrapper {
        right: 5%;
        height: 105%;
        width: 105%;
      }
    }

    img {
      object-fit: cover;
    }
  }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .controller-team-container {
    align-items: flex-start;
    justify-content: center;
    width: 120%;
    flex-basis: 75%;


    &.one {
      margin-top: 140px;
    }

    &.two {
      margin-top: 102px;
    }

    &.three {
      margin-top: 74px;
    }

    .controller-list-container {
      margin-right: -5%;
      margin-bottom: 0;
      row-gap: 46px;
      max-width: 178px;


      .controller-list-content {
        height: 19px;

        .controller-list-item {

          .pointer-container {
            right: -16px;
          }

          .text-content {
            font-size: 16px;
            letter-spacing: 3.2px;
          }
        }
      }
    }

    .background-img-container {
      left: 0;
      right: 0;
      height: 90%;
      width: 100%;
      top: 0;
      bottom: auto;

      &.active-dark {
        height: 100%;
        top: -20%;

        .lottie-wrapper {
          right: 5%;
          height: 105%;
          width: 105%;
        }
      }
    }
  }
}

@media (min-height: 600px) and (max-height: 630px){
  .controller-team-container {
    .controller-list-container {
      row-gap: 24px;

      &.one {
        margin-top: 133px;
      }

      &.two {
        margin-top: 113px;
      }

      &.three {
        margin-top: 94px;
      }
    }
    .background-img-container {
      height: 90%;
      width: 100%;

      .lottie-wrapper {
        height: 110%;
      }
      &.active-dark {
        height: 100%;
        top: -20%;

        .lottie-wrapper {
          right: 8%;
          height: 115%;
          width: 115%;
        }
      }
    }
  }
}


@media (max-width: 375px) {
  .controller-team-container {
    flex-basis: 80%;

    .controller-list-container {
      margin-right: -5%;
      margin-top: 75px;
      margin-bottom: 0;
      row-gap: 20px;
      max-width: 148px;

      &.one {
        margin-top: 130px;

        &.active-light {
          margin-top: 113px;
        }
      }

      &.two {
        margin-top: 125px;

        &.active-light {
          margin-top: 100px;
        }
      }

      &.three {
        margin-top: 116px;
        &.active-light {
          margin-top: 82px;
        }
      }


      .controller-list-content {
        height: 16px;

        .controller-list-item {

          .pointer-container {
            right: -10px;
          }

          .text-content {
            font-size: 13px;
            letter-spacing: 2.6px;
          }
        }
      }
    }
    .background-img-container {
      height: 90%;
      width: 100%;

      .lottie-wrapper {
        height: 105%;
      }
      &.active-dark {
        height: 100%;
        top: -15%;

        .lottie-wrapper {
          right: 8%;
          height: 115%;
          width: 115%;
        }
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .controller-team-container {
    flex-basis: 80%;
    .controller-list-container {
      margin-right: -5%;
      margin-top: 74px;
      margin-bottom: 0;
      row-gap: 40px;
      max-width: 178px;

      &.one {
        margin-top: 154px;
      }

      &.two {
        margin-top: 125px;
      }

      &.three {
        margin-top: 98px;
      }


      .controller-list-content {
        height: 19px;

        .controller-list-item {

          .pointer-container {
            right: -10px;
          }

          .text-content {
            letter-spacing: 3.2px;
          }
        }
      }
    }
    .background-img-container {
      height: 90%;
      width: 100%;

      .lottie-wrapper {
        height: 105%;
      }
      &.active-dark {
        height: 100%;
        top: -15%;

        .lottie-wrapper {
          right: 3%;
          height: 110%;
          width: 115%;
        }
      }
    }

}}


@media (max-width: 320px) {
  .controller-team-container {
    flex-basis: 80%;
    .controller-list-container {
      margin-right: -8%;
      margin-top: 54px;
      margin-bottom: 0;
      row-gap: 16px;
      max-width: 136px;


      &.one {
        margin-top: 100px;
        &.active-light {
          margin-top: 90px;
        }
      }

      &.two {
        margin-top: 87px;

        &.active-light {
          margin-top: 87px;
        }
      }

      &.three {
        margin-top: 54px;
      }


      .controller-list-content {
        height: 15px;

        .controller-list-item {

          .pointer-container {
            right: -8px;
          }

          .text-content {
            font-size: 12px;
            letter-spacing: 2.4px;
          }
        }
      }
    }
    .background-img-container {
      height: 105%;
      width: 100%;

      .lottie-wrapper {
        height: 105%;
      }
      &.active-dark {
        height: 100%;
        top: -15%;

        .lottie-wrapper {
          right: 10%;
          height: 115%;
          width: 115%;
        }
      }
    }
  }
}



@media (min-width: 2561px) {
  .controller-team-container {
    .controller-list-container {
      margin-right: 12%;
      row-gap: 112px;
      max-width: 753px;

      .controller-list-content {
        height: 90px;

        .controller-list-item {

          .pointer-container {
            right: -48px;
          }

          .text-content {
            letter-spacing: 14.8px;
          }
        }
      }
    }

    .background-img-container {
      max-height: 1800px;
      height: 100%;
      width: 100%;

      &.active-dark {
        max-height: inherit;
        max-width: 2000px;
        width: 100%;
        height: 125%;
      }
    }
  }
}
</style>