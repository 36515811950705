import {api} from "@/providers/api";

export const serviceModules = {
    namespaced: true,
    state: () => ({
        activeService: {},
    }),
    getters: {
        getActiveService: (state) => {
            return state.activeService
        },
    },
    mutations: {
        SET_ACTIVE_SERVICE: (state, payload) => {
            state.activeService = payload
        },
    },
    actions:{
        async getActivePractice({commit}, { slug, params  } = {slug, params: {}}) {
            let response = await api.main.mainControllerGetServiceSlug(slug,{
                ...params
            }).then(r => r.data);
            commit('SET_ACTIVE_SERVICE', response)
        },
    }
}