<template>
  <div class="title-router-main-page">
    <transition name="fade-opacity-lazy">
      <title-router :active-delete="activeDeleteState"
                    :active-main-slide="activeMainSlide"
                    class="title-router-main-page-content"
                    :active-delete-index="activeDeleteIndex"
                    :active-static="activeStatic"
                    :active-click="activeClick"
                    v-if="stateTitle || changeActiveSubSlide"
                    :active-main-title="activeMainTitle"
                    @returnToSlide="returnToSlide"
                    :active-sub-title="activeSubTitle"/>
    </transition>
  </div>
</template>

<script>
import TitleRouter from "@/components/shared/TitleRouter.vue";
import {mapActions} from "vuex";

export default {
  name: "TitleRouterMainPage",
  components: {TitleRouter},
  props: {
    activeMainSlide: {
      type: Number,
      required: true
    }
  },

  mounted() {
    this.checkStateTitle();
  },

  data() {
    return {
      stateTitle: false,
      activeStatic: false,
      activeClick: false,
      activeDeleteState: false,
      activeDeleteIndex: 0
    }
  },
  computed: {
    activeFullPath() {
      return this.$route.fullPath;
    },

    activeAboutSlide() {
      return this.$store.state.activeAboutSlide;
    },

    activeTeamSlide() {
      return this.$store.state.persons.activeTeamSlide;
    },

    activeIndustrySlide() {
      return this.$store.state.industry.activeIndustrySlide;
    },

    activeIndustryTitle() {
      return this.$store.state.industry.activeIndustryTitle;
    },

    changeActiveSubSlide() {
      return this.$store.state.changeActiveSubSlide;
    },

    activeMainTitle() {
      this.checkStateTitle();
      switch (this.activeMainSlide) {
        case 1:
          return this.mainTitlePage.aboutScreen.startTitle;
        case 2:
            return this.mainTitlePage.teamScreen.startTitle;
        case 3:
          return this.mainTitlePage.industryScreen[0];
      }
    },

    activeSubTitle() {
      if(this.activeMainSlide === 1) {
        this.activeClick = false;
        this.activeStatic = true
        return this.mainTitlePage.aboutScreen.subTitle[this.activeAboutSlide];
      } else if (this.activeMainSlide === 2) {
        this.activeClick = true
        this.activeStatic = true
        return this.mainTitlePage.teamScreen.subTitle[this.activeTeamSlide-1];
      } else if (this.activeMainSlide === 3) {
        this.activeStatic = false
        this.activeClick = true
        switch (this.activeIndustrySlide) {
          case 1:
            if(this.activeIndustryTitle === '') {
              return this.mainTitlePage.industryScreen[1];
            } else {
              return this.activeIndustryTitle;
            }
          case 2:

            if(this.activeIndustryTitle === '') {
              return this.mainTitlePage.industryScreen[2];
            } else {
              return this.activeIndustryTitle;
            }
        }
      }
    },


    returnIndexActive() {
        return this.$store.state.returnIndexActive
    }
  },


  watch: {

    activeMainSlide() {
      this.checkStateTitle();
    },
    returnIndexActive() {
      this.returnToSlide(this.returnIndexActive, true, true)
    }
  },


  methods: {
    ...mapActions(['RETURN_INDEX_ACTIVE', 'CHANGE_ACTIVE_SUB_SLIDE']),
    checkStateTitle() {
      if(!this.activeMobile) {
        if(this.activeMainSlide === 1) {
          this.stateTitle = false
          setTimeout(() => {
            this.stateTitle = true
          }, 500)
        } else {
          this.stateTitle = false
        }
      } else {
          this.stateTitle = false
          setTimeout(() => {
            this.stateTitle = true
          }, 500)
      }
    },

    returnToSlide(index, state, isClick) {
      switch (this.activeMainSlide) {
        case 3:
          switch (index) {
            case 0:
              if(state === undefined) {
                this.RETURN_INDEX_ACTIVE(0);
              }
              break;
            case 1:
              if(isClick) {
                this.activeDeleteIndex = 4;
                this.activeDeleteState = true;
                this.$nextTick(() => {
                  this.activeDeleteState = false;
                })
                if(state === undefined) {
                  this.RETURN_INDEX_ACTIVE(1);
                }
              }
              break;
            case 2:
              if(isClick) {
                this.activeDeleteIndex = 4;
                this.activeDeleteState = true;
                this.$nextTick(() => {
                  this.activeDeleteState = false;
                })
                if (state === undefined) {
                  this.RETURN_INDEX_ACTIVE(1);
                }
              }
              break;
            case 3:
              if(isClick) {
                this.activeDeleteIndex = 4;
                this.activeDeleteState = true;
                this.$nextTick(() => {
                  this.activeDeleteState = false;
                })
                if (state === undefined) {
                  this.RETURN_INDEX_ACTIVE(1);
                }
              }
              break;
          }
        break;
        case 2:
          if(state === undefined) {
            if(index === 0) {
              this.changeMainSlide(2);
            }
          }
          break;
      }

    },
  }
}
</script>

<style scoped lang="scss">
.title-router-main-page {
  position: absolute;
  z-index: 10;
  left: var(--controller-padding);
  top: var(--main-padding-top);
  .title-router-main-page-content {
    position: relative;
  }
}
</style>