<template>
  <div class="cookie-container" v-if="!activeMobile">
    <button-toggle-lang :active-color-scheme="true"/>
    <transition name="fade-opacity-lazy">
      <cookie-start-button v-if="!cookieSetting"
                           @changeModalCookieSetting="changeModalCookieSetting"
                           @closeCookie="closeCookie"/>
      <cookie-modal @changeStateCookie="changeModalCookieSetting"
                    @closeCookie="closeCookie"
                    v-else/>
    </transition>
  </div>
  <div class="cookie-container" v-else>
    <transition name="fade-opacity-lazy">
      <cookie-start-button-mobile v-if="!cookieSetting"
                           @changeModalCookieSetting="changeModalCookieSetting"
                           @closeCookie="closeCookie"/>
      <cookie-modal @changeStateCookie="changeModalCookieSetting"
                    @closeCookie="closeCookie"
                    v-else/>
    </transition>
  </div>
</template>

<script>
import ButtonToggleLang from "@/components/entities/ButtonToggleLang.vue";
import CookieStartButton from "@/components/entities/сookie/CookieStartButton.vue";
import CookieModal from "@/components/entities/сookie/CookieModal.vue";
import CookieStartButtonMobile from "@/components/entities/сookie/CookieStartButtonMobile.vue";

export default {
  name: "CookieScreen",
  components: {CookieStartButtonMobile, CookieModal, CookieStartButton, ButtonToggleLang},
  data() {
    return {
      cookieSetting: false,
    }
  },
  methods: {
    changeModalCookieSetting() {
      this.cookieSetting = !this.cookieSetting;
    },
    closeCookie() {
      localStorage.setItem('cookieActive', 'true')
      this.$emit('closeCookie', false)
    }
  }
}
</script>

<style scoped lang="scss">
.cookie-container {
  position: absolute;
  display: flex;
  justify-content: center;
  top:0;left:0;right:0;bottom: 0;
  width: var(--calc-width);
  height: var(--calc-height);
  z-index: 998;

  .toggle-button-container {
    position: absolute;
  }
}

@media (max-width: 2560px){
  .cookie-container {
    backdrop-filter: blur(20px);
    .toggle-button-container {
      right: 78px;
      top: 78px;
    }

  }
}

@media (max-width: 2240px){
  .cookie-container {
    backdrop-filter: blur(20px);
    .toggle-button-container {
      right: 86px;
      top: 86px;
    }
  }
}

@media (max-width: 1920px) {
  .cookie-container {

    .toggle-button-container {
      right: 64px;
      top: 64px;
    }
  }
}


@media (max-width: 1600px) {
  .cookie-container {
    backdrop-filter: blur(13px);
    .toggle-button-container {
      right: 48px;
      top: 48px;
    }
  }
}

@media (max-width: 1400px) {
  .cookie-container {

    .toggle-button-container {
      right: 48px;
      top: 48px;
    }
  }
}

@media (max-width: 1133px) {
  .cookie-container {
    backdrop-filter: blur(12px);
    .toggle-button-container {
      right: 36px;
      top: 36px;
    }
  }
}

@media (max-width: 769px) {
  .cookie-container {
    align-items: center;
    backdrop-filter: blur(20px);
    padding: 0 18px;
  }
}
@media (max-width: 769px)  and (min-height: 748px) and (max-height: 1000px) {
  .cookie-container {
    align-items: center;
    backdrop-filter: blur(20px);
    padding: 0 18px;
  }
}
@media (max-width: 320px) {
  .cookie-container {
    padding: 0 13px;
  }
}

@media (min-width: 2561px) {
  .cookie-container {
    backdrop-filter: blur(46px);

    .toggle-button-container {
      right: 150px;
      top: 150px;
    }
  }
}
</style>