<template>
  <div class="small-logo-container" v-if="!activeWithout">
    <img v-if="activeColorLogo" :src="getUrlLogo(imgName + '-dark.svg')" alt="icon">
    <img v-else :src="getUrlLogo(imgName + '-light.svg')" alt="icon">
  </div>
  <div class="small-logo-container" v-else>
    <img v-if="activeColorLogo" :src="getUrlLogo(imgNameWithout + '-dark.svg')" alt="icon">
    <img v-else :src="getUrlLogo(imgNameWithout + '-light.svg')" alt="icon">
  </div>
</template>

<script>
export default {
  name: "SmallLogo",
  props: {
    activeColorLogo: {
      type: Boolean,
      required: true
    },
    activeWithout: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imgName: 'logo',
      imgNameWithout: 'logo-without'
    }
  }
}
</script>

<style scoped lang="scss">
  .small-logo-container {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
  @media (max-width: 2560px){
    .small-logo-container {
      padding: 40px 94px;
      max-height: 210px;
      max-width: 268px;
    }
  }

  @media (max-width: 1920px) {
    .small-logo-container {
      padding: 41px 88px;
      max-height: 180px;
      max-width: 236px;
    }
  }

  @media (max-width: 1600px) {
    .small-logo-container {
      padding: 26px 58px;
      max-height: 120px;
      max-width: 158px;
    }
  }
  @media (max-height: 800px) {
    .small-logo-container {
      padding: 26.5px 59px;
      max-height: 120px;
      max-width: 158px;
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .small-logo-container {
      padding: 66px 58px 26px;
      max-height: 160px;
      max-width: 158px;
    }
  }

  @media (max-width: 1133px) {
    .small-logo-container {
      padding: 40px 46px 19.5px;
      max-height: 126px;
      max-width: 135px;
    }
  }

  @media (max-width: 769px) {
    //iPhone 14
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .small-logo-container {
      padding: 0;
      max-height: 36px;
      max-width: 22px;
    }
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    //iPhone 8
  }


  @media (max-width: 375px) and (min-height: 630px) {
    //iPhone X
  }


  @media (max-width: 320px) {
    //iPhone SE
  }



  @media (min-width: 2561px) {
    .small-logo-container {
      padding: 80px 189px;
      max-height: 420px;
      max-width: 535px;
    }
  }

</style>