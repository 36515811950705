import {api} from "@/providers/api";
import axios from "axios";

export const partnersModule = {
    namespaced: true,
    state: () => ({
        partnersInfo: [],
        partnersPhoto: [],
    }),
    getters: {
        partnersAll: (state) => {
            return state.partnersInfo;
        },
        partnersAllPhoto: (state) => {
            return state.partnersPhoto;
        },

        activePartnersLength: (state) => {
            return state.partnersInfo.length > 0;
        },
    },
    mutations: {
        SET_PARTNERS: (state, payload) => {
            state.partnersInfo = payload
        },
        SET_PARTNERS_PHOTO: (state, payload) => {
            state.partnersPhoto = payload
        },
    },

    actions: {
        async getPartners({commit}, {params } = {params: {}}) {
            const response = await api.main.mainControllerGet({
                type: 'DEFAULT'
            }).then(r => r.data);
            commit('SET_PARTNERS', response)
            let promiseArr = [];
             response.forEach(item => {
                 promiseArr.push(axios.get(item?.fileUrl).then(r => r.data))
             })
             const responsePhoto = await Promise.all(promiseArr);
            commit('SET_PARTNERS_PHOTO', responsePhoto)
        },

    }
}