<template>
  <div class="drop-down-container" :ref="'customDropdown' + nameButton">
    <div class="drop-down-button-container" :class="{'drop-down-active' : dropdownActive}">
      <div class="drop-down-button" :class="{'input-active' : activeInputList, 'active-button' : dropdownActive}">
        <transition name="fade-opacity-lazy">
          <p class="text" v-if="!activeInputList" :class="activeText !== nameButton ? 'active' : ''">
            {{ activeText }}
          </p>
          <input v-else v-model="searchText"/>
        </transition>
        <transition name="fade-opacity-lazy">
          <button-icon v-if="dropdownActive"  @click.prevent="openInput" img-name="glass"/>
        </transition>
      </div>
      <transition name="fade-translate-y">
        <div v-if="dropdownActive"  class="drop-down-list-container" id="drop-down-list-container" @wheel="scrollBlock"
             @touchstart="touchStart"
             @touchend="touchEnd"
             @touchmove="scrollBlockMove" ref="blockDropDown">
            <div @click="changeActualName(item)" class="drop-down-text" v-for="item in filterDropdownList">
              <p class="text">
                {{ item?.name || item?.slug || item  }}
              </p>
            </div>
        </div>
      </transition>
    </div>
    <div class="arrow-container" @click="openDropdown" v-if="!activeColor" :class="{'active-button' : dropdownActive}">
      <transition name="fade-translate-y" mode="out-in">
        <button-icon class="arrow" v-if="!dropdownActive" img-name="single-arrow-top-two"/>
        <button-icon class="arrow" v-else img-name="single-arrow-bottom-two"/>
      </transition>
    </div>
    <div class="arrow-container" @click="openDropdown" v-else :class="{'active-button' : dropdownActive}">
      <transition name="fade-translate-y" mode="out-in">
        <button-icon class="arrow" v-if="!dropdownActive" img-name="single-arrow-top"/>
        <button-icon class="arrow" v-else img-name="single-arrow-bottom"/>
      </transition>
    </div>
  </div>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import {mapActions} from "vuex";

export default {
  name: "DropdownBox",
  components: {ButtonIcon},
  props: {
    infoList: {
      required: true
    },
    nameButton: {
      type: String,
      required: true
    },
    resetFilterState: {
      type: Boolean
    },
    activeScrollNews: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dropdownActive: false,
      activeText: '',
      activeId: 0,
      focusParam: true,
      activeInputList: false,
      searchText: '',
      activePlaceholder: false,
      startY: 0,
      scrolling: false
    }
  },
  mounted() {
    this.activeText = this.nameButton
    window.addEventListener('click', this.closeOptions);
    window.addEventListener('wheel', this.closeOptions);
  },
  unmounted() {
    window.removeEventListener('wheel', this.closeOptions);
  },


  computed: {
    filterDropdownList() {
      if(this.searchText === '') {
        return this.infoList;
      } else {
        return this.infoList.filter(item => item?.name?.toLowerCase()?.includes(this.searchText.toLowerCase())
                                            || item?.slug?.toLowerCase()?.includes(this.searchText.toLowerCase()))
      }
    },
  },

  watch: {

    dropdownActive() {
      if(this.dropdownActive) {
        this.ACTIVE_MOUSE_SCROLL(false)
      } else {
        if(!this.activeScrollNews) {
          this.ACTIVE_MOUSE_SCROLL(true)
        }
      }
    },

    nameButton() {
      if(!this.activePlaceholder) {
        this.activeText = this.nameButton;
      }
    },

    resetFilterState() {
      this.checkResetActiveText();
    },

    infoList() {
      if(this.activeText !== this.nameButton) {
        this.activeText = this.infoList.find(item => item.id === this.activeId)?.name
      }
    }
  },
  methods: {

    ...mapActions(['ACTIVE_MOUSE_SCROLL']),

    scrollBlock(e) {
      if(e.deltaY < 0) {
        this.$refs.blockDropDown.scrollBy(0, -10)
      } else {
        this.$refs.blockDropDown.scrollBy(0, 10)
      }
    },

    scrollBlockMove(e) {
      if (!this.scrolling) return;
      let deltaY = e.touches[0].clientY - this.startY;
      document.getElementById('drop-down-list-container').scrollTop -= deltaY;
      this.startY = e.touches[0].clientY;
    },

    touchStart(e) {
      this.startY = e.touches[0].clientY;
      this.scrolling = true;
    },

    touchEnd() {
      this.startY = 0;
      this.scrolling = false;
    },



    checkResetActiveText() {
      if(this.resetFilterState) {
        this.changeActualName(this.activeText)
      }
    },

    closeOptions(event) {
      if (!this.$refs['customDropdown' + this.nameButton]?.contains(event.target)) {
        this.dropdownActive = false;
        if(this.activeInputList) {
          this.openInput();
        }
      }
    },


    openInput() {
      this.searchText = ''
      this.activeInputList = !this.activeInputList
    },

    openDropdown() {
        this.dropdownActive = !this.dropdownActive;
        this.$emit('activeDropDownList', this.dropdownActive)
        if(this.activeInputList) {
          this.openInput();
        }
    },

    changeActualName(activeText) {
      if(this.activeInputList) {
          this.openInput();
        }
          if((activeText?.name || activeText?.slug || activeText) === this.activeText) {
            this.activePlaceholder = false
            this.activeText = this.nameButton
            this.activeId = 0
            this.$emit('changeActualName', '')
            } else {
            this.activePlaceholder = true
            this.activeText = activeText?.name ||  activeText?.slug || activeText
            this.activeId = activeText?.id
            this.$emit('changeActualName',activeText?.id || activeText)
          }
      this.openDropdown();
    }
  }
}
</script>

<style scoped lang="scss">
input {
  width: 100%;
  background-color: rgba(1,1,1,0);
  color: var(--color-text);
}


div::-webkit-scrollbar {
  width: 20px;
  height: 100%;
}

div::-webkit-scrollbar-track {
  background: var(--color-field);
  border-radius: 30%;
}

div::-webkit-scrollbar-thumb {
  opacity: .6;
  background-color: var(--color-text);
}
div::-webkit-scrollbar-thumb:hover{
  opacity: 1;
}

  .drop-down-container {
    display: flex;
    align-items: center;
    flex-direction: row;

    &:hover {
      .drop-down-button-container {
        .drop-down-button {
          opacity: 1;
          color: var(--color-text);
          background-color: var(--color-field);
        }
      }

      .arrow-container {
        opacity: 1;
      }
    }

    .drop-down-button-container {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      &:hover {
        .drop-down-button {
          opacity: .8;
        }
      }

      &.drop-down-active {
        .drop-down-button {
          opacity: 1;
        }
      }

      .drop-down-list-container {
        touch-action: auto;
        pointer-events: auto;
        top: 0;
        max-width: inherit;
        width: inherit;
        display: flex;
        flex-direction: column;
        pointer-events: visible;
        z-index: 998;
        overflow-y: scroll;
        background-color: var(--color-card);
        position: absolute;

        .drop-down-text {
          transition: all .6s ease;
          height: 100%;
          display: inherit;
          flex-direction: column;
          justify-content: center;
          position: relative;
          opacity: .6;
          color: var(--color-text);
          background-color: var(--color-field);

          .text {
            text-overflow: ellipsis;
            width: inherit;
            overflow: hidden;
            white-space: nowrap;
            font-family: 'Raleway', sans-serif;
            font-weight: 400;
          }
          &:last-child:after {
            display: none;
          }

          &:hover {
            opacity: 1;
            cursor: pointer;
          }
        }
      }

      .drop-down-button {
        display: flex;
        align-items: center;
        opacity: .6;
        width: 100%;
        position: relative;
        transition: all .6s ease;
        background: rgba(var(--color-field-rgba), 0.2);

        &.active-button {
          color: var(--color-text);
          background-color: var(--color-field);
        }


        .text {
          text-overflow: ellipsis;
          width: inherit;
          overflow: hidden;
          white-space: nowrap;
          font-family: 'Raleway', sans-serif;
          font-weight: 400;
        }

        .button-icon-container {
          cursor: pointer;
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }
    }

    .arrow-container {
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: .6;
      transition: opacity .6s ease;

      &.active-button {
        opacity: 1;
      }
      .arrow {
        width: 100%;
        height: 100%;
        & img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

@media (max-width: 2560px){
  input {
    font-size: 22px;
  }

  div::-webkit-scrollbar {
    max-width: 6px;
  }

  div::-webkit-scrollbar-track {
    border-radius: 30%;
  }

  div::-webkit-scrollbar-thumb {
    width: 1.5px;
    margin: 1px;
    border-radius: 4px;
  }


  .drop-down-container {
    column-gap: 9px;
    width: 422px;
    .drop-down-button-container {
      max-width: 381px;
      font-size: 22px;
      .drop-down-button {
        column-gap: 9px;
        height: 40px;
        border-radius: 6px;
        padding: 0 14px 7px;

        &.input-active {
          padding: 7px 14px;
        }

        .text {
          font-size: 22px;
        }


        .button-icon-container {
          max-height: 28px;
          max-width: 28px;
          right: 10px;
          top: 7px;
        }
      }

      .drop-down-list-container {
        font-size: 22px;
        margin-top: 46px;
        padding: 3px;
        border-radius: 6px;
        max-height: 111px;
        row-gap: 3px;

        .drop-down-text {
          padding: 0 12px 3px;
          border-radius: 6px;
          min-height: 33px;
          max-height: 33px;
        }
      }
    }

    .arrow-container {
      max-height: 32px;
      max-width: 32px;

      .arrow {
        padding: 0 4px 4px;
      }
    }
  }
}

@media (max-width: 2240px) {
  input {
    font-size: 18px;
  }
  div::-webkit-scrollbar {
    max-width: 4px;
  }

  div::-webkit-scrollbar-track {
    background: var(--color-field);
    width: 1px;
    border-radius: 30%;
  }

  div::-webkit-scrollbar-thumb {
    width: 2px;
    margin: 1px;
    border-radius: 4px;
  }


  .drop-down-container {
    width: 350px;
    column-gap: 9px;
    .drop-down-button-container {
      font-size: 16px;
      max-width: 310px;
      .drop-down-button {
        height: 32px;
        border-radius: 6px;
        padding: 0 8px 4px;

        &.input-active {
          padding: 4px 8px;
        }

        .text {
          font-size: 16px;
        }

        .button-icon-container {
          max-height: 18px;
          max-width: 18px;
          top: 4px;
          right: 8px;
        }
      }

      .drop-down-list-container {
        font-size: 16px;
        margin-top: 36px;
        padding: 2px;
        border-radius: 5px;
        height: 96px;
        row-gap: 2px;

        .drop-down-text {
          padding: 0 10px 3px;
          border-radius: 6px;
          min-height: 28px;
          max-height: 28px;

          .text {
            font-size: 14px;
          }
        }
      }
    }

    .arrow-container {
      max-height: 24px;
      max-width: 24px;

      .arrow {
        padding: 4px 3px;
      }
    }
  }
}

  @media (max-width: 1920px) {
    input {
      font-size: 14px;
    }
    div::-webkit-scrollbar {
      max-width: 4px;
    }

    div::-webkit-scrollbar-track {
      background: var(--color-field);
      width: 1px;
      border-radius: 30%;
    }

    div::-webkit-scrollbar-thumb {
      width: 2px;
      margin: 1px;
      border-radius: 4px;
    }


    .drop-down-container {
      width: 270px;
      column-gap: 8px;
      .drop-down-button-container {
        font-size: 12px;
        max-width: 238px;
        .drop-down-button {
          height: 24px;
          border-radius: 6px;
          padding: 0 8px 4px;

          &.input-active {
            padding: 4px 8px;
          }

          .text {
            font-size: 14px;
          }

          .button-icon-container {
            max-height: 18px;
            max-width: 18px;
            top: 4px;
            right: 8px;
          }
        }

        .drop-down-list-container {
          font-size: 12px;
          margin-top: 28px;
          padding: 2px;
          border-radius: 5px;
          height: 80px;
          row-gap: 2px;

          .drop-down-text {
            padding: 0 8px 3px;
            border-radius: 5px;
            min-height: 24px;
            max-height: 24px;

            .text {
              font-size: 14px;
            }
          }
        }
      }

      .arrow-container {
        max-height: 24px;
        max-width: 24px;

        .arrow {
          padding: 4px 3px;
        }
      }
    }
  }

@media (max-width: 1600px) {
  input {
    font-size: 9px;
  }
  div::-webkit-scrollbar {
    max-width: 2px;
  }

  div::-webkit-scrollbar-track {
    background: var(--color-field);
    width: 1px;
    border-radius: 30%;
  }

  div::-webkit-scrollbar-thumb {
    width: 1px;
    margin: 1px;
    border-radius: 4px;
  }


  .drop-down-container {
    width: 156px;
    column-gap: 5px;
    .drop-down-button-container {
      max-width: 135px;
      font-size: 9px;
      .drop-down-button {
        height: 18px;
        border-radius: 4px;
        padding: 0 5px 3px;

        &.input-active {
          padding: 3px 5px;
        }

        .text {
          font-size: 9px;
        }

        .button-icon-container {
          max-height: 14px;
          max-width: 14px;
          top: 2px;
          right: 5px;
        }
      }

      .drop-down-list-container {
        font-size: 9px;
        margin-top: 20px;
        padding: 1px;
        border-radius: 4px;
        max-height: 60px;
        row-gap: 1px;

        .drop-down-text {
          padding: 0 3px 2px;
          border-radius: 4px;
          max-height: 18px;
          min-height: 18px;

          .text {
            font-size: 9px;
          }
        }
      }
    }

    .arrow-container {
      max-height: 16px;
      max-width: 16px;

      .arrow {
        padding: 3px;
      }
    }
  }
}

@media (max-height: 800px) {
  input {
    font-size: 9px;
  }
  div::-webkit-scrollbar {
    max-width: 2px;
  }

  div::-webkit-scrollbar-track {
    background: var(--color-field);
    width: 1px;
    border-radius: 30%;
  }

  div::-webkit-scrollbar-thumb {
    width: 1px;
    margin: 1px;
    border-radius: 4px;
  }


  .drop-down-container {
    width: 156px;
    column-gap: 5px;
    .drop-down-button-container {
      max-width: 135px;
      font-size: 9px;
      .drop-down-button {
        height: 18px;
        border-radius: 4px;
        padding: 0 5px 3px;

        &.input-active {
          padding: 3px 5px;
        }

        .text {
          font-size: 9px;
        }

        .button-icon-container {
          max-height: 14px;
          max-width: 14px;
          top: 2px;
          right: 5px;
        }
      }

      .drop-down-list-container {
        font-size: 9px;
        margin-top: 20px;
        padding: 1px;
        border-radius: 4px;
        max-height: 60px;
        row-gap: 1px;

        .drop-down-text {
          padding: 0 3px 2px;
          border-radius: 4px;
          max-height: 18px;
          min-height: 18px;

          .text {
            font-size: 9px;
          }
        }
      }
    }

    .arrow-container {
      max-height: 16px;
      max-width: 16px;

      .arrow {
        padding: 3px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  input {
    font-size: 10px;
  }
  div::-webkit-scrollbar {
    max-width: 2px;
  }

  div::-webkit-scrollbar-track {
    background: var(--color-field);
    width: 1px;
    border-radius: 30%;
  }

  div::-webkit-scrollbar-thumb {
    width: 1px;
    margin: 1px;
    border-radius: 4px;
  }


  .drop-down-container {
    width: 199px;
    .drop-down-button-container {
      column-gap: 5px;
      font-size: 10px;
      max-width: 178px;
      .drop-down-button {
        height: 18px;
        border-radius: 4px;
        padding: 0 5px 3px;

        &.input-active {
          padding: 3px 5px;
        }

        .text {
          font-size: 10px;
        }

        .button-icon-container {
          max-height: 14px;
          max-width: 14px;
          top: 2px;
          right: 5px;
        }
      }

      .drop-down-list-container {
        font-size: 10px;
        margin-top: 20px;
        padding: 1px;
        border-radius: 4px;
        max-height: 60px;
        row-gap: 1px;

        .drop-down-text {
          padding: 0 3px 2px;
          border-radius: 4px;
          max-height: 18px;
          min-height: 18px;

          .text {
            font-size: 10px;
          }
        }
      }
    }

    .arrow-container {
      max-height: 16px;
      max-width: 16px;

      .arrow {
        padding: 3px;
      }
    }
  }
}

@media (max-width: 1133px) {
  input {
    font-size: 10px;
  }

  .drop-down-container {
    width: 199px;
    .drop-down-button-container {
      column-gap: 4px;
      font-size: 10px;
      max-width: 181px;
      .drop-down-button {
        height: 18px;
        padding: 0 7px 4px;
        &.input-active {
          padding: 4px 7px;
        }

        .text {
          font-size: 10px;
        }

        .button-icon-container {
          max-height: 12px;
          max-width: 12px;
          top: 3px;
        }
      }


      .drop-down-list-container {
        font-size: 10px;
        margin-top: 20px;
        padding: 2px;
        border-radius: 4px;
        max-height: 53px;
        row-gap: 2px;

        .drop-down-text {
          padding: 0 5px;
          border-radius: 4px;
          max-height: 15px;
          min-height: 15px;
          .text {
            font-size: 10px;
          }
        }
      }
    }

    .arrow-container {
      max-height: 14px;
      max-width: 14px;

      .arrow {
        padding: 2px;
      }
    }
  }
}

@media (max-width: 769px) {
  input {
    font-size: 12px;
  }

  .drop-down-container {
    width: 100%;
    column-gap: 4px;
    .drop-down-button-container {
      max-width: 244px;
      font-size: 12px;
      .drop-down-button {
        height: 26px;
        padding: 0 20px 3px 10px;
        &.input-active {
          padding: 3px 10px;
        }

        .text {
          font-size: 12px;
        }

        .button-icon-container {
          max-height: 16px;
          max-width: 16px;
          top: 5px;
        }
      }

      .drop-down-list-container {
        font-size: 12px;
        margin-top: 28px;
        padding: 2px;
        border-radius: 4px;
        max-height: 76px;
        row-gap: 2px;

        .drop-down-text {
          padding: 0 3px;
          border-radius: 4px;
          max-height: 25px;
          min-height: 25px;
          .text {
            font-size: 12px;
          }
        }
      }
    }

    .arrow-container {
      margin-left: 8px;
      max-height: 20px;
      max-width: 20px;

      .arrow {
        padding: 0;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  input {
    font-size: 14px;
  }

  .drop-down-container {
    width: 100%;
    column-gap: 4px;
    .drop-down-button-container {
      max-width: 294px;
      font-size: 10px;
      .drop-down-button {
        height: 32px;
        padding: 0 20px 5px 10px;
        &.input-active {
          padding: 5px 10px;
        }

        .text {
          font-size: 14px;
        }

        .button-icon-container {
          max-height: 18px;
          max-width: 18px;
          top: 6px;
        }
      }

      .drop-down-list-container {
        font-size: 14px;
        margin-top: 36px;
        padding: 2px;
        border-radius: 4px;
        max-height: 76px;
        row-gap: 2px;

        .drop-down-text {
          padding: 0 3px;
          border-radius: 4px;
          max-height: 25px;
          min-height: 25px;
          .text {
            font-size: 14px;
          }
        }
      }
    }

    .arrow-container {
      margin-left: 8px;
      max-height: 24px;
      max-width: 24px;

      .arrow {
        padding: 0;
      }
    }
  }
}


@media (max-width: 375px){
  input {
    font-size: 12px;
  }

  .drop-down-container {
    width: 100%;
    column-gap: 4px;
    .drop-down-button-container {
      font-size: 12px;
      .drop-down-button {
        height: 26px;
        padding: 0 20px 3px 10px;
        &.input-active {
          padding: 3px 10px;
        }

        .text {
          font-size: 12px;
        }

        .button-icon-container {
          max-height: 16px;
          max-width: 16px;
          top: 5px;
        }
      }

      .drop-down-list-container {
        font-size: 12px;
        margin-top: 28px;
        padding: 2px;
        border-radius: 4px;
        max-height: 76px;
        row-gap: 2px;

        .drop-down-text {
          padding: 0 3px;
          border-radius: 4px;
          max-height: 25px;
          min-height: 25px;
          .text {
            font-size: 12px;
          }
        }
      }
    }

    .arrow-container {
      margin-left: 8px;
      max-height: 20px;
      max-width: 20px;

      .arrow {
        padding: 0;
      }
    }
  }
}


@media (max-width: 320px) {
  input {
    font-size: 10px;
  }

  .drop-down-container {
    width: 100%;
    column-gap: 4px;
    .drop-down-button-container {
      max-width: 226px;
      font-size: 10px;
      .drop-down-button {
        height: 24px;
        padding: 0 20px 3px 10px;
        &.input-active {
          padding: 3px 10px;
        }

        .text {
          font-size: 10px;
        }

        .button-icon-container {
          max-height: 15px;
          max-width: 15px;
          top: 4.5px;
        }
      }

      .drop-down-list-container {
        font-size: 10px;
        margin-top: 26px;
        padding: 2px;
        border-radius: 4px;
        max-height: 56px;
        row-gap: 2px;

        .drop-down-text {
          padding: 0 3px;
          border-radius: 4px;
          max-height: 18px;
          min-height: 18px;
          .text {
            font-size: 10px;
          }
        }
      }
    }

    .arrow-container {
      margin-left: 8px;
      max-height: 20px;
      max-width: 20px;

      .arrow {
        padding: 0;
      }
    }
  }
}


@media (min-width: 2561px) {
  input {
    font-size: 37px;
  }

  div::-webkit-scrollbar {
    max-width: 8px;
  }

  div::-webkit-scrollbar-track {
    border-radius: 30%;
  }

  div::-webkit-scrollbar-thumb {
    width: 2px;
    margin: 1px;
    border-radius: 4px;
  }


  .drop-down-container {
    font-size: 37px;
    width: 730px;
    column-gap: 18px;
    .drop-down-button-container {
      max-width: 656px;

      .drop-down-button {
        height: 67px;
        border-radius: 8px;
        padding: 0 24px 12px;

        &.input-active {
          padding: 12px 24px;
        }

        .button-icon-container {
          max-height: 47px;
          max-width: 47px;
          top: 12px;
          right: 12px;
        }
      }

      .drop-down-list-container {
        margin-top: 79px;
        padding: 4px;
        border-radius: 8px;
        height: 217px;
        row-gap: 4px;
        .drop-down-text {
          padding: 0 24px;
          border-radius: 8px;
          min-height: 67px;
          max-height: 67px;

        }
      }
    }

    .arrow-container {
      max-height: 50px;
      max-width: 56px;

      .arrow {
        padding: 0 9px 5px;
      }
    }
  }
}

</style>