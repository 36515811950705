<template>
<div class="input-container" @click="focusInput" :class="{'textarea' : info.type === 'textarea', 'focus' : focused, 'eng' : activeEng}">
    <div class="letter-counter" v-if="info.type === 'textarea'">
      <p>{{this.countLetter}}/{{lengthTextArea}}</p>
    </div>
    <button-icon v-if="activeGlass" :img-name="'glass'"/>
    <input :type="info.type"
           :name="info.dataIndex"
           @focus="focused = true"
           @blur="focused = false"
        :placeholder="info.placeholder"
        v-if="info.type !== 'textarea'"
        v-model="textInput">

    <textarea class="textarea"
              ref="textareaMessage"
              :name="info.dataIndex"
              @focus="focused = true"
              @blur="focused = false"
              :placeholder="info.placeholder"
              :maxlength="lengthTextArea"
              v-model="textInput" v-else></textarea>
</div>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";

export default {
  name: "InputMain",
  components: {ButtonIcon},
  props: {
      info: {
      type: Object,
      required: true
    },
    lengthTextArea: {
        type: Number
    },
    submitActive: {
        type: Boolean
    },
    nameBlock: {
        type: String
    },
    activeGlass: {
        type: Boolean,
      required: true
    },
    activeTextInput: {
        type: String
    }
  },

  mounted() {
    if(this.activeTextInput !== undefined) {
      this.textInput = this.activeTextInput
    }
  },

  data() {
    return {
      textInput: '',
      countLetter: 0,
      focused: false
    }
  },
  watch: {
    textInput() {
      this.countLetter = this.textInput.length
      this.$emit('changeTextInput', this.textInput, this.info.dataIndex)
    },
    submitActive() {
      if(this.submitActive) {
        this.textInput = ''
      }
    },
  },
  methods: {
    focusInput() {
      let block = document.querySelector("." + this.nameBlock)
      if(this.info.type !== 'textarea') {
        let input = block.querySelectorAll("input")
        input[this.info.id].focus()
      } else {
        let textarea = block.querySelectorAll("textarea")
        textarea[this.info.id].focus()
      }
    },
  }
}
</script>

<style scoped lang="scss">

  .input-container {
    cursor: text;
    display: flex;
    justify-content: center;
    background: var(--color-field);
    font-family: 'Raleway-Regular', sans-serif;
    color: var(--color-text);
    position: relative;
    transition: opacity .5s ease;
    opacity: .6;

    &.eng {
      & input, textarea {
        &::placeholder {
          font-variant: all-small-caps;
        }
      }
    }

    &.textarea {
      padding: 8px;
      border-radius: 8px;
    }

    &:hover {
      opacity: 1;
    }

    &.focus {
      opacity: 1;
    }

    .button-icon-container {
      position: absolute;
    }

    .letter-counter {
      color: var(--color-text);
      font-family: 'CormorantUnicase-Regular', sans-serif;
      position: absolute;
      opacity: .6;
    }


    .important-input {
      position: relative;
    }

    & input, textarea {
      width: 100%;
      height: 100%;
      font-family: 'Raleway-Regular', sans-serif;
      color: var(--color-text);
      background-color: rgba(1,1,1,0);

      &::placeholder {
        color: var(--color-text);
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .textarea {
      resize: none;
    }
  }

  @media (max-width: 2560px){
    .input-container {
      padding: 9px;
      border-radius: 12px;

      &.textarea {
        padding: 9px;
        border-radius: 12px;
      }

      &:hover {
        box-shadow: 0 0 40px 0 rgba(242, 233, 228, 0.10);
      }

      &.focus {
        box-shadow: 0 0 40px 0 rgba(242, 233, 228, 0.10);
      }

      .letter-counter {
        font-size: 16px;
        bottom: 9px;
        right: 9px;
      }

      & input, textarea {
        font-size: 16px;
        color: var(--color-text);
      }
    }
  }

  @media (max-width: 2240px) {
  }


  @media (max-width: 1920px) {
    .input-container {
      padding: 8px;
      border-radius: 8px;

      &.textarea {
        padding: 8px;
        border-radius: 8px;
      }

      &:hover {
        box-shadow: 0 0 30px 0 rgba(242, 233, 228, 0.10);
      }

      &.focus {
        box-shadow: 0 0 30px 0 rgba(242, 233, 228, 0.10);
      }


      .letter-counter {
        font-size: 14px;
        bottom: 8px;
        right: 8px;
      }

      & input, textarea {
        font-size: 14px;
        color: var(--color-text);
      }
    }
  }

  @media (max-width: 1600px) {
    .input-container {
      padding: 5px;
      border-radius: 6px;

      &.textarea {
        padding: 5px;
        border-radius: 6px;
      }

      &:hover {
        box-shadow: 0 0 20px 0 rgba(242, 233, 228, 0.10);
      }

      &.focus {
        box-shadow: 0 0 20px 0 rgba(242, 233, 228, 0.10);
      }


      .letter-counter {
        font-size: 9px;
        bottom: 5px;
        right: 5px;
      }

      & input, textarea {
        font-size: 9px;
        color: var(--color-text);
      }
    }
  }


  @media (max-width: 1133px) {
    .input-container {
      padding: 5px 4px;
      border-radius: 8px;

      &.textarea {
        padding: 5px;
        border-radius: 6px;
      }

      .letter-counter {
        font-size: 8px;
        bottom: 5px;
        right: 4px;
      }

      & input, textarea {
        font-size: 8px;
        color: var(--color-text);
      }
    }
  }

  @media (max-width: 769px) {
    .input-container {
      padding: 8px;
      border-radius: 8px;

      &.textarea {
        padding: 5px;
        border-radius: 6px;
      }

      .letter-counter {
        font-size: 12px;
        bottom: 5px;
        right: 4px;
      }

      & input, textarea {
        font-size:12px;
      }
    }
  }



  @media (max-width: 320px) {
    .input-container {
      border-radius: 6px;

      .letter-counter {
        font-size: 10px;
      }

      & input, textarea {
        font-size:10px;
      }
    }
  }




  @media (min-width: 2561px) {
    .input-container {
      padding: 18px;
      border-radius: 18px;

      &.textarea {
        padding: 18px;
        border-radius: 18px;
      }

      &:hover {
        box-shadow: 0 0 50px 0 rgba(242, 233, 228, 0.10);
      }

      &.focus {
        box-shadow: 0 0 50px 0 rgba(242, 233, 228, 0.10);
      }

      .letter-counter {
        font-size: 32px;
        bottom: 24px;
        right: 18px;
      }

      & input, textarea {
        font-size: 32px;
        color: var(--color-text);
      }
    }
  }

</style>