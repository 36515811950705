<template>
  <div class="title-router-sub-page">
    <title-router :active-delete="activeDelete"
                  :active-static-color="activeStaticColor"
                  :active-delete-index="0"
                  :active-sub-page="true"
                  :active-main-title="activeMainTitle"
                  @returnToSlide="returnToSlide"
                  :active-sub-title="activeSubTitle"/>
    <transition name="fade-opacity-lazy">
      <div class="link-icon-container" v-if="activeLink && !activeMobile"
           @click="copyURL" :class="{'active-modal-filter' : activeModalFilter}">
        <img  :src="getUrlIcon('link.svg')" alt="icon">
      </div>
    </transition>
  </div>
</template>

<script>
import TitleRouter from "@/components/shared/TitleRouter.vue";

export default {
  name: "TitleRouterSubPage",
  components: {TitleRouter},
  props: {
    activeStaticColor: {
      type: Boolean,
      default: false
    },
    activeMainTitle: {
      type: String,
      required: true
    },
    activeSubTitle: {
      type: String,
      required: true
    },
    activeRouterHref: {
      type: String,
      required: true
    }
  },
  computed: {
    activeTeamSlide() {
      return this.$store.state.persons.activeTeamSlide;
    },
    activeModalFilter() {
      return this.$store.state.activeModalFilter;
    },
  },

  watch: {
    activeSubTitle() {
      if(this.activeSubTitle === '') {
        this.$nextTick(( ) => {
          this.activeLink = true
        })
      }
    }
  },

  data() {
    return {
      activeDelete: false,
      activeLink: false
    }
  },
  methods: {
    copyURL() {
      const currentURL = window.location.href;
      const tempInput = document.createElement('input');
      tempInput.value = currentURL;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
    },

    returnToSlide(index) {
      switch (index) {
        case 0:
          this.$router.push(this.activeRouterHref)
          break;
        case 1:
          if(this.$route.fullPath.includes('about/team'))  {
            this.changeSubSlide(this.activeTeamSlide,'team')
          }

      }
    }
  }

}
</script>

<style scoped lang="scss">
 .title-router-sub-page {
   display: flex;
   flex-direction: row;
   align-items: center;
   z-index: 20;
   position: absolute;
   top: var(--main-padding-top);
   left: var(--controller-padding);

   .link-icon-container {
     display: flex;
     align-items: center;
     justify-content: center;
     height: 100%;
     & img {
       width: 100%;
       object-fit: contain;
       height: 100%;
       opacity: .6;
       cursor: pointer;
       transition: opacity .6s ease;

       &:hover {
         opacity: 1;
       }
     }
   }
 }

 @media (max-width: 2560px){
   .title-router-sub-page {
     column-gap: 8px;
     height: 19px;
     .link-icon-container {
       max-width: 24px;
     }
   }
 }


 @media (max-width: 1920px) {
   .title-router-sub-page {
     column-gap: 8px;
     height: 19px;
     .link-icon-container {
       max-width: 16px;
     }
   }
 }

 @media (max-width: 1600px) {
   .title-router-sub-page {
     column-gap: 4px;
     height: 12px;
     .link-icon-container {
       max-width: 12px;
     }
   }
 }
 @media (max-height: 800px) {
   .title-router-sub-page {
     column-gap: 4px;
     height: 12px;
     .link-icon-container {
       max-width: 10px;
     }
   }
 }

 @media (max-width: 1400px) and (min-height: 801px) {
   .title-router-sub-page {
     column-gap: 4px;
     height: 14px;
     .link-icon-container {
       max-width: 10px;
     }
   }
 }

 @media (max-width: 1133px) {
   .title-router-sub-page {
     column-gap: 4px;
     height: 10px;
     .link-icon-container {
       max-width: 10px;
     }
   }
 }


 @media (min-width: 2561px) {
   .title-router-sub-page {
     column-gap: 18px;
     height: 41px;
     .link-icon-container {
       max-width: 40px;
     }
   }
 }

</style>