<template>
    <div class="cookie-menu-container">
      <div class="cookie-title">
        {{ $t('cookieModal.cookieTitle') }}
        <button-icon @click="changeModalCookieSetting" :img-name="'cross'"/>
      </div>
      <div class="check-box-container">
        <div class="check-box-content">
          <p class="check-box-text left">
              {{ $t('cookieModal.checkOne') }}
            </p>
          <span class="check-box-text right">
               {{ $t('cookieModal.checkTwo') }}
            </span>
        </div>
        <div class="check-box-content" v-for="check in $tm('cookieModal.checkActive')" :key="check.id">
            <span class="check-box-text">
              {{ check.textTitle }}
            </span>
          <check-box-main :name-check="check.id"/>
        </div>
      </div>
      <div class="cookie-paragraph">
        {{ $t('cookieModal.cookieParagraph') }}
      </div>
      <div class="cookie-button">
        <div @click="changeModalCookieSetting" class="cookie-save-setting-button">
          <p>
            {{ $t('cookieModal.saveSetting') }}
          </p>
        </div>
        <gradient-button-agreement @click="closeCookie"/>
      </div>
    </div>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import CheckBoxMain from "@/components/shared/CheckBoxMain.vue";
import GradientButtonAgreement from "@/components/shared/GradientButtonAgreement.vue";

export default {
  name: "CookieModal",
  components: {
    GradientButtonAgreement, CheckBoxMain, ButtonIcon},
  methods: {
    changeModalCookieSetting() {
      this.$emit('changeStateCookie')
    },
    closeCookie() {
      this.$emit('closeCookie', false)
    }
  }
}
</script>

<style scoped lang="scss">

  .cookie-menu-container {
    border-color: var(--color-element);
    border-style: solid;
    background-color: var(--color-background);
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;

    .cookie-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--blue);
      font-weight: 400;

      .button-icon-container {
        cursor: pointer;
        opacity: .6;
        transition: all .6s ease;

        &:hover {
          opacity: 1;
        }
      }
    }

    .cookie-paragraph {
      text-align: justify;
      line-height: 120%;
    }

    .cookie-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .cookie-save-setting-button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(var(--color-field-rgba), 0.2);
        border-color: rgba(var(--color-element-rgba), 0.2);
        border-style: solid;
        cursor: pointer;
        transition: all .8s ease;

        &:hover {
          background-color: rgba(var(--color-field-rgba), 0.6);
          border-color: rgba(var(--color-element-rgba), 0.6);
        }

        & p {
          line-height: 100%;
        }
      }
    }

    .check-box-container {
      display: flex;
      flex-direction: column;

      .check-box-content {
        justify-content: space-between;
        align-items: baseline;
        display: flex;
        flex-direction: row;

        .check-box-text {

          &.right {
            color: var(--blue)
          }
        }
      }
    }
  }

    @media (max-width: 2560px) {
      .cookie-menu-container {
        padding: 52px 42px;
        border-radius: 32px;
        max-width: 568px;
        border-width: 4px;
        left: 38%;
        top: 86px;


        .cookie-title {
          font-size: 24px;
          margin-bottom: 32px;
          height: 56px;

          .button-icon-container {
            padding: 2px;
          }
        }

        .cookie-paragraph {
          font-size: 12px;
          line-height: 120%;
          margin-bottom: 32px;
        }

        .cookie-button {
          .cookie-text {
            max-width: 349px;
            width: 100%;
          }

          .cookie-save-setting-button {
            max-width: 416px;
            border-radius: 14px;
            height: 46px;
            padding: 13px 0;
            border-width: 2px;

            & p {
              font-size: 18px;
            }
          }
        }

        .check-box-container {
          font-size: 12px;

          .check-box-content {
            margin-bottom: 22px;
          }
        }
      }
    }

    @media (max-width: 2240px) {
      .cookie-menu-container {
        padding: 46px 42px;
        border-radius: 32px;
        max-width: 568px;
        border-width: 3.5px;
        left: 38%;
        top: 72px;


        .cookie-title {
          font-size: 24px;
          margin-bottom: 28px;
        }

        .cookie-paragraph {
          font-size: 12px;
          line-height: 120%;
          margin-bottom: 28px;
        }

        .cookie-button {
          .cookie-text {
            max-width: 313px;
            width: 100%;
          }

          .cookie-save-setting-button {
            max-width: 416px;
            border-radius: 14px;
            height: 46px;
            padding: 13px 0;
            border-width: 2px;

            & p {
              font-size: 18px;
            }
          }
        }

        .check-box-container {
          font-size: 12px;

          .check-box-content {
            margin-bottom: 16px;

            &:last-child {
              margin-bottom: 32px;
            }
          }
        }
      }
    }

    @media (max-width: 1920px) {
      .cookie-menu-container {
        padding: 24px 32px;
        border-radius: 24px;
        max-width: 426px;
        border-width: 3px;
        left: 38%;
        top: 64px;

        .cookie-title {
          font-size: 18px;
          height: 48px;
          margin-bottom: 24px;
        }

        .cookie-paragraph {
          font-size: 10px;
          line-height: 120%;
          margin-bottom: 24px;
        }

        .cookie-button {
          .cookie-save-setting-button {
            max-width: 308px;
            border-radius: 10px;
            height: 32px;
            padding: 10px 0;
            border-width: 1px;

            & p {
              font-size: 12px;
            }
          }

          .cookie-text {
            max-width: 266px;
            width: 100%;
          }
        }

        .check-box-container {
          font-size: 10px;

          .check-box-content {
            margin-bottom: 16px;

            &:last-child {
              margin-bottom: 24px;
            }
          }
        }
      }
    }


    @media (max-width: 1600px) {
      .cookie-menu-container {
        padding: 18px 24px;
        border-radius: 18px;
        max-width: 320px;
        border-width: 1.5px;
        left: 38%;
        top: 48px;

        .cookie-title {
          font-size: 13.5px;
          margin-bottom: 18px;
          height: 32px;
          .button-icon-container {
            padding: 0;
          }
        }

        .cookie-paragraph {
          font-size: 8px;
          line-height: 120%;
          margin-bottom: 18px;
        }

        .cookie-button {
          .cookie-save-setting-button {
            max-width: 230px;
            border-radius: 8px;
            height: 26px;
            padding: 10px 0;
            border-width: 1px;

            & p {
              font-size: 10px;
            }
          }

          .cookie-text {
            max-width: 266px;
            width: 100%;
          }
        }

        .check-box-container {
          font-size: 7.5px;

          .check-box-content {
            margin-bottom: 12px;

            &:last-child {
              margin-bottom: 18px;
            }
          }
        }
      }
    }

    @media (max-width: 1400px) {
      .cookie-menu-container {
        padding: 18px 24px;
        border-radius: 18px;
        max-width: 320px;
        border-width: 1.5px;
        left: 38%;
        top: 48px;

        .cookie-title {
          font-size: 13.5px;
          margin-bottom: 18px;

          .button-icon-container {
            padding: 1px;
          }
        }

        .cookie-paragraph {
          font-size: 8px;
          line-height: 120%;
          margin-bottom: 18px;
        }

        .cookie-button {
          .cookie-save-setting-button {
            max-width: 230px;
            border-radius: 8px;
            height: 26px;
            padding: 10px 0;
            border-width: 1px;

            & p {
              font-size: 10px;
            }
          }

          .cookie-text {
            max-width: 266px;
            width: 100%;
          }
        }

        .check-box-container {
          font-size: 7.5px;

          .check-box-content {
            margin-bottom: 12px;

            &:last-child {
              margin-bottom: 18px;
            }
          }
        }
      }
    }

    @media (max-width: 1133px) {
      .cookie-menu-container {
        padding: 20px;
        border-radius: 14px;
        max-width: 252px;
        border-width: 1px;
        left: 38%;
        top: 36px;

        .cookie-title {
          font-size: 12px;
          margin-bottom: 14px;
          height: 28px;
          .button-icon-container {
            padding: 2px;
          }
        }

        .cookie-paragraph {
          font-size: 6px;
          line-height: 120%;
          margin-bottom: 14px;
        }

        .cookie-button {
          .cookie-save-setting-button {
            max-width: 168px;
            border-radius: 8px;
            height: 24px;
            padding: 8px 0;
            border-width: 1px;

            & p {
              font-size: 8px;
            }
          }

          .cookie-text {
            max-width: 266px;
            width: 100%;
          }
        }

        .check-box-container {
          font-size: 6px;

          .check-box-content {
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 9px;
            }
          }
        }
      }
    }

    @media (max-width: 769px) {
      .cookie-menu-container {
        height: fit-content;
        position: relative;
        padding: 16px;
        border-radius: 14px;
        max-width: 392px;
        border-width: 1px;
        top: 0;
        left: 0;
        right: 0;

        .cookie-title {
          height: 48px;
          font-size: 18px;
          margin-bottom: 24px;

          .button-icon-container {
            max-width: 16px;
            max-height: 16px;
            margin: 0 4px;
            padding: 0;
          }
        }

        .cookie-paragraph {
          font-size: 10px;
          line-height: 120%;
          margin-bottom: 24px;
        }

        .cookie-button {
          .cookie-save-setting-button {
            max-width: 268px;
            border-radius: 10px;
            height: 38px;
            padding: 12px 0;
            border-width: 1px;

            & p {
              font-size: 12px;
            }
          }

          .cookie-text {
            max-width: 266px;
            width: 100%;
          }
        }

        .check-box-container {
          font-size: 10px;

          .check-box-text {
            &.right {
              font-size: 12px;
            }
          }

          .check-box-content {
            margin-bottom: 24px;

            &:last-child {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
    @media (max-width: 769px) and (min-height: 748px) and (max-height: 1000px) {
      .cookie-menu-container {
        height: fit-content;
        position: relative;
        padding: 24px;
        border-radius: 14px;
        max-width: 392px;
        border-width: 1px;
        top: 0;
        left: 0;
        right: 0;

        .cookie-title {
          font-size: 20px;
          margin-bottom: 24px;

          .button-icon-container {
            max-width: 16px;
            max-height: 16px;
            padding: 0;
          }
        }

        .cookie-paragraph {
          font-size: 12px;
          line-height: 120%;
          margin-bottom: 24px;
        }

        .cookie-button {
          .cookie-save-setting-button {
            max-width: 288px;
            border-radius: 10px;
            height: 40px;
            padding: 12px 0;
            border-width: 1px;

            & p {
              font-size: 14px;
            }
          }

          .cookie-text {
            max-width: 266px;
            width: 100%;
          }
        }

        .check-box-container {
          font-size: 12px;

          .check-box-content {
            margin-bottom: 18px;

            &:last-child {
              margin-bottom: 27px;
            }
          }
        }
      }
    }

    @media (max-width: 375px) {
      .cookie-menu-container {
        padding: 16px;
        border-radius: 14px;
        max-width: 392px;
        border-width: 1px;

        .cookie-title {
          font-size: 18px;
          margin-bottom: 24px;
          height: 48px;
          .button-icon-container {
            max-width: 16px;
            max-height: 16px;
            margin: 0 4px;
          }
        }

        .cookie-paragraph {
          font-size: 10px;
          line-height: 120%;
          margin-bottom: 24px;
        }

        .cookie-button {
          .cookie-save-setting-button {
            max-width: 253px;
            border-radius: 10px;
            height: 38px;
            padding: 12px 0;
            border-width: 1px;

            & p {
              font-size: 12px;
            }
          }
        }

        .check-box-container {
          font-size: 10px;

          .check-box-content {
            margin-bottom: 16px;

            &:last-child {
              margin-bottom: 24px;
            }
          }
        }
      }
    }

    @media (max-width: 320px) {
      .cookie-menu-container {
        padding: 10px 16px;
        border-radius: 16px;
        max-width: 294px;
        border-width: 1px;

        .cookie-title {
          font-size: 16px;
          margin-bottom: 24px;
          height: 32px;
          .button-icon-container {
            max-width: 11px;
            max-height: 11px;
            margin: 0 2px;
          }
        }

        .cookie-paragraph {
          font-size: 9px;
          line-height: 120%;
          margin-bottom: 24px;
        }

        .cookie-button {
          .cookie-save-setting-button {
            max-width: 214px;
            border-radius: 10px;
            height: 32px;
            padding: 12px 0;
            border-width: 1px;

            & p {
              font-size: 12px;
            }
          }
        }

        .check-box-container {
          font-size: 10px;

          .check-box-content {
            margin-bottom: 20px;

            .check-box-text {
              &.right {
                font-size: 10px;
              }
            }

            &:last-child {
              margin-bottom: 24px;
            }
          }
        }
      }
    }

    @media (min-width: 2561px) {
      .cookie-menu-container {
        padding: 56px 74px;
        border-radius: 48px;
        max-width: 994px;
        border-width: 4px;
        left: 38%;
        top: 150px;

        .cookie-title {
          font-size: 42px;
          margin-bottom: 56px;
          height: 112px;
          .button-icon-container {
            padding: 4px;
          }
        }

        .cookie-paragraph {
          font-size: 24px;
          line-height: 120%;
          margin-bottom: 56px;
        }

        .cookie-button {
          .cookie-text {
            max-width: 749px;
            width: 100%;
          }

          .cookie-save-setting-button {
            max-width: 749px;
            border-radius: 24px;
            height: 80px;
            padding: 24px 0;
            border-width: 4px;

            & p {
              font-size: 32px;
            }
          }
        }


        .check-box-container {
          font-size: 24px;

          .check-box-content {
            margin-bottom: 40px;
          }
        }
      }
    }

</style>