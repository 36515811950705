import {api} from "@/providers/api";

export const ratingModule = {
    namespaced: true,
    state: () => ({
        ratingInfo: [],
        ratingPhoto: [],
    }),
    getters: {
        ratingAll: (state) => {
            return state. ratingInfo;
        },
        ratingAllPhoto: (state) => {
            return state. ratingPhoto;
        }
    },
    mutations: {
        SET_RATING: (state, payload) => {
            state.ratingInfo = payload
        },
        SET_RATING_PHOTO: (state, payload) => {
            state.ratingPhoto = payload
        },
    },

    actions: {
        async getRating({commit}, {params } = {params: {}}) {
            const response = await api.main.mainControllerGet({
                type: 'SVG'
            }).then(r => r.data);
            commit('SET_RATING', response)
            // let promiseArr = [];
            // response.forEach(item => {
            //     promiseArr.push(axios.get(item?.fileUrl).then(r => r.data))
            // })
            // const responsePhoto = await Promise.all(promiseArr);
            // commit('SET_RATING_PHOTO', responsePhoto)
        },

    }
}