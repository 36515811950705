<template>
    <div class="industry-screen-container main-container" v-if="!isLoading">
        <modal-consulting v-if="activeModalServices"
                        :main-title="$t('modalContent.modalConsulting.mainTitleServices')"
                        @closeModal="changeActiveModal"/>

      <div class="industry-screen-content">
        <transition name="fade-opacity-lazy">
          <div class="industry-container left" v-if="activeScreen">
            <transition name="fade-opacity-lazy">
              <div class="paragraph-container" v-if="activeStateIndustry || activeStatePractice || activeStateServices">
                <transition name="fade-opacity-lazy" mode="out-in">
                  <main-paragraph :key="activeStartInfo">
                    {{activeStartInfo?.text}}
                  </main-paragraph>
                </transition>
              </div>
            </transition>
            <transition name="fade-opacity-lazy">
              <div class="consulting-button-container" v-if="activeStateServices && !activeMobile">
                <button-large @clickToState="changeActiveModal" :main-title="$t('consultingButton')" :name-img="'user-support'"/>
              </div>
            </transition>
          </div>
        </transition>
        <transition name="fade-opacity-lazy">
        <div class="industry-container right"
             @mousemove="changeActiveScrollTrue"
             @mouseleave="changeActiveScrollFalse" v-if="activeScreen"
             @mouseenter="changeActiveScrollTrue"
             :class="{'industry' : activeStateIndustry,
                            'practice' : activeStatePractice,
                            'services' : activeStateServices}">
          <industry-title-container             :active-main-title="activeIndustryTitle"
                                                :active-practice-screen="activeStatePractice"
                                                :active-services-screen="activeStateServices"
                                                :active-sub-title="activePracticeTitle"/>
          <transition name="fade-opacity-lazy">
            <div class="slider-info-container industry" v-if="activeStateIndustry">
              <slider-industry-screen :active-name-class="'industry'"
                                        :setting-swiper="settingSwiperIndustry"
                                        :active-info="activeIndustryLang"
                                        :name-id="'industryId'"
                                        @changeInfoActive="changeInfoActiveIndustry"
                                        @changeIndexActive="changeIndexActiveIndustry"/>
            </div>
            <div class="slider-info-container practice"  v-else-if="activeStatePractice">
              <slider-industry-screen   v-if="activePracticeLang !== undefined && activePracticeLang?.length > 0"
                                        :active-name-class="'practice'"
                                        :name-id="'practiceId'"
                                        :setting-swiper="settingSwiperPractice"
                                        :active-info="activePracticeLang"
                                        @changeInfoActive="changeInfoActivePractice"
                                        @changeIndexActive="changeIndexActivePractice"/>
              <sub-title v-else :active-cormorant="false">
                ПРАКТИКИ У ЭТОЙ ОТРАСЛИ ПУСТЫ :(
              </sub-title>
            </div>
            <div class="slider-info-container services" v-else-if="activeStateServices">
              <slider-industry-screen   v-if="activeServiceLang !== undefined && activeServiceLang?.length > 0"
                                        :active-name-class="'services'"
                                        :setting-swiper="settingSwiperServices"
                                        @changeInfoActive="changeInfoActiveServices"
                                        :active-info="activeServiceLang"/>
              <sub-title v-else :active-cormorant="false">
                СЕРВИСЫ У ЭТОЙ ПРАКТИКИ ПУСТЫ :(
              </sub-title>

            </div>
          </transition>
          <transition name="fade-opacity-lazy">
          <button-main-mobile :mini-button-active="true"
                              @click="changeActiveModal"
                              v-if="activeMobile & activeStateServices"
                              :text-button="$t('consultingButton')"/>
          </transition>
        </div>
        </transition>
      </div>
    </div>
</template>

<script>
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import SliderIndustryScreen from "@/components/entities/SliderIndustryScreen.vue";
import IndustryTitleContainer from "@/components/entities/IndustryTitleContainer.vue";
import {mapActions, mapGetters} from "vuex";
import ModalConsulting from "@/components/entities/ModalConsulting.vue";
import SubTitle from "@/components/shared/Text/SubTitle.vue";
import ButtonLarge from "@/components/shared/ButtonLarge.vue";
import ButtonMainMobile from "@/components/shared/ButtonMainMobile.vue";


export default {
  name: "IndustryScreen",
  components: {
    ButtonMainMobile,
    ButtonLarge,
    SubTitle,
    ModalConsulting,IndustryTitleContainer, SliderIndustryScreen, MainParagraph, LargeTitle},
  data() {
    return {
      activeScroll: false,

      settingSwiperIndustry: {
        direction: 'vertical',
        slidesPerView: 5,
        loop: true,
        centeredSlides: true,
      },
      settingSwiperPractice: {
        direction: 'vertical',
        slidesPerView: 12,
        loop: true,
        centeredSlides: true,

      },
      settingSwiperServices: {
        direction: 'vertical',
        slidesPerView: 7,
        loop: true,
        centeredSlides: true,
      },

      activeScreen: false,

      activeInfoIndustry: 0,
      activeInfoPractice: 0,
      activeInfoServices: 0,

      activeIndexIndustry: 0,
      activeIndexPractice: 0,

      activeStateIndustry: true,
      activeStatePractice: false,
      activeStateServices: false,

      activeModalServices: false,

      slugIndustry: undefined,
      slugPractice: undefined
    }
  },

  async created() {
      await this.checkInfo();
      this.$watch(
        () => this.$route.params,
        (toParams, previousParams) => {
          this.slugIndustry = toParams?.slugIndustry
          this.slugPractice = toParams?.slugPractice
        }
    )
  },

  mounted() {
    this.IS_LOADING(true)
    this.checkMobile();
    this.checkActiveScroll();
    if(!this.$route.name.includes('industry')) {
      this.$router.push({name: 'industry'})
    }
  },

  computed: {
    ...mapGetters({
      industryAll: 'industry/industryAll',
    }),


    activeIndustryLang() {
       return this.langModification(this.industryAll)
    },

    activePracticeIndustry() {
      return this.industryAll.find(item=> item?.slug === this.slugIndustry)?.practices
    },

    activePracticeLang() {
      if(this.activePracticeIndustry !== undefined) {
        return this.langModification(this.activePracticeIndustry)
      } else {
        return undefined
      }
    },

    activeServiceLang() {
      if(this.activePracticeIndustry !== undefined && this.slugPractice !== undefined) {
        return this.langModification(this.activePracticeIndustry.find(item=> item.slug === this.slugPractice)?.services)
      } else {
        return undefined
      }
    },

    changeActiveSubSlide() {
      return this.$store.state.changeActiveSubSlide;
    },

    returnIndexActive() {
      return this.$store.state.returnIndexActive;
    },

    activeIndustryTitle() {
        return this.activeIndustryLang?.find(item => item?.slug === this.slugIndustry)?.name;
    },

    activePracticeTitle() {
      return this.activePracticeLang?.find(item => item?.slug === this.slugPractice)?.name;
    },

    activeIndustry() {
      return this.activeIndustryLang[this.activeInfoIndustry];
    },
    activePractice() {
      if(this.activePracticeLang !== undefined) {
        return this.activePracticeLang[this.activeInfoPractice];
      } else {
        return undefined;
      }
    },
    activeServices() {
      if(this.activeServiceLang !== undefined) {
        return this.activeServiceLang[this.activeInfoServices];
      } else {
        return undefined;
      }
    },

    activeStartInfo() {
      if(this.activeStateIndustry) {
        return this.activeIndustry
      } else if (this.activeStatePractice){
        return this.activePractice
      } else {
        return this.activeServices
      }
    },
  },

  watch: {

    activeScroll() {
      this.checkActiveScroll();
    },

    returnIndexActive() {
      if(this.returnIndexActive !== -1) {
        this.returnToPage();
      }
    },
    activeMobile() {
      this.checkMobile();
    },

    activeEng() {
      this.returnToStart();
    },

    '$route'() {
      this.checkMobile();
      this.checkActiveIndex();
    },
  },

  methods: {
    ...mapActions('industry', ['ACTIVE_INDUSTRY_SLIDE', 'ACTIVE_INDUSTRY_TITLE', 'getIndustry']),
    ...mapActions(['CHANGE_ACTIVE_SUB_SLIDE',  'RETURN_INDEX_ACTIVE', 'ACTIVE_MOUSE_SCROLL']),

    changeActiveScrollTrue() {
      if(!this.activeMobile) {
        this.activeScroll = true;
      }

    },

    changeActiveScrollFalse() {
      if(!this.activeMobile) {
        this.activeScroll = false;
      }
    },

    checkActiveScroll() {
      if(this.activeScroll) {
        this.ACTIVE_MOUSE_SCROLL(false)
      } else {
        this.ACTIVE_MOUSE_SCROLL(true)
      }
    },

    checkActiveIndex() {
      if(this.$route.name.includes('industry')) {
        this.slugIndustry = this.$route.params.slugIndustry
        this.slugPractice =  this.$route.params.slugPractice
        if(this.slugIndustry !== undefined && this.slugPractice === undefined) {
          if(this.activeStateServices) {
            this.RETURN_INDEX_ACTIVE(1)
          } else if(this.activeStateIndustry) {
            this.mountChangeActivePractice();
          }
        } else if(this.slugIndustry !== undefined && this.slugPractice !== undefined) {
          this.activeStatePractice = false
          this.mountChangeActiveService();
        } else {
          this.RETURN_INDEX_ACTIVE(0)
        }
      }
    },

    mountChangeActivePractice() {
      this.activeStateIndustry = false
      this.ACTIVE_INDUSTRY_SLIDE(1)
      this.CHANGE_ACTIVE_SUB_SLIDE(true)
      this.ACTIVE_INDUSTRY_TITLE('')
      setTimeout(() => {
        this.activeInfoPractice = 0
        this.ACTIVE_INDUSTRY_TITLE(this.activeIndustryTitle)
        this.activeStatePractice = true
      },500)
      if(this.activePracticeLang?.length < 5) {
        this.settingSwiperPractice.slidesPerView = 3
      } else {
        this.checkMobile();
      }
    },

    mountChangeActiveService() {
      this.activeStateIndustry = false
      this.ACTIVE_INDUSTRY_SLIDE(1)
      this.CHANGE_ACTIVE_SUB_SLIDE(true)
      setTimeout(() => {
        this.ACTIVE_INDUSTRY_TITLE(this.activeIndustryTitle)
        setTimeout(() => {
          this.ACTIVE_INDUSTRY_SLIDE(2)
          this.ACTIVE_INDUSTRY_TITLE(this.activePracticeTitle)
          setTimeout(() => {
            this.activeInfoServices = 0
            this.ACTIVE_INDUSTRY_TITLE('')
            this.activeStateServices = true
          }, 300)
        }, 100)
      }, 100)
      if(this.activeServiceLang?.length < 5) {
        this.settingSwiperServices.slidesPerView = 3
      } else {
        this.checkMobile();
      }
    },


    checkActiveSlider() {
      this.slugIndustry = this.$route.params.slugIndustry
      this.slugPractice =  this.$route.params.slugPractice
      if(this.slugIndustry !== undefined && this.slugPractice !== undefined) {
        this.mountChangeActiveService()
      } else if(this.slugPractice === undefined && this.slugIndustry !== undefined) {
        this.mountChangeActivePractice()
      } else {
        if(this.$route.name.includes('industry') && this.slugPractice === undefined && this.slugIndustry === undefined) {
            this.$router.push({name: 'industry'})
        }
      }
    },

    checkMobile() {
      if(this.activeMobile) {
        this.settingSwiperIndustry.slidesPerView = 7
        this.settingSwiperPractice.slidesPerView = 5
        this.settingSwiperServices.slidesPerView = 4.5
      } else {
        this.settingSwiperIndustry.slidesPerView = 5
        this.settingSwiperPractice.slidesPerView = 6
        this.settingSwiperServices.slidesPerView = 6
      }
    },

    returnToPage() {
      if(this.returnIndexActive === 0) {
        this.returnToStart();
        this.$nextTick(() => {
          this.RETURN_INDEX_ACTIVE(-1)
        })
      } else {
        this.returnToPractice();
        this.$nextTick(() => {
          this.RETURN_INDEX_ACTIVE(-1)
        })
      }
    },

    returnToPractice() {
      this.ACTIVE_INDUSTRY_SLIDE(1)
      this.activeStateServices = false;
      this.activeInfoPractice = 0
      this.ACTIVE_INDUSTRY_TITLE('')
      setTimeout(() => {
        this.$router.push({name: 'industry-practice', params: {slugIndustry: this.slugIndustry}})
        this.ACTIVE_INDUSTRY_TITLE(this.activeIndustryTitle)
        this.activeStatePractice = true
      },500)
    },

    returnToStart() {
        this.activeInfoIndustry = 0
        this.activeStateServices = false
        this.activeStatePractice = false
        this.CHANGE_ACTIVE_SUB_SLIDE(false)
        this.ACTIVE_INDUSTRY_SLIDE(0)
        setTimeout(() => {
          this.$router.push({name: 'industry'})
          this.activeStateIndustry = true
        }, 500)
    },

    changeActiveModal() {
      this.activeModalServices = !this.activeModalServices
    },

    changeIndexActiveIndustry(slug) {
        this.$router.push({name: 'industry-practice', params: {slugIndustry: slug}})
        this.ACTIVE_INDUSTRY_SLIDE(1)
        this.CHANGE_ACTIVE_SUB_SLIDE(true)
        this.activeStateIndustry = false
        this.ACTIVE_INDUSTRY_TITLE('')
        if(this.activePracticeLang?.length < 5) {
          this.settingSwiperPractice.slidesPerView = 3
        } else {
          this.checkMobile();
        }
        setTimeout(() => {
          this.activeInfoPractice = 0
          this.ACTIVE_INDUSTRY_TITLE(this.activeIndustryTitle)
          this.activeStatePractice = true
        },500)
    },

    changeIndexActivePractice(slug) {
        this.$router.push({name: 'industry-service', params: {slugPractice: slug}})
        this.ACTIVE_INDUSTRY_SLIDE(2)
        this.activeStatePractice = false
        if(this.activeServiceLang?.length < 5) {
          this.settingSwiperServices.slidesPerView = 3
        } else {
          this.checkMobile();
        }
        setTimeout(() => {
          this.ACTIVE_INDUSTRY_TITLE(this.activePracticeTitle)
        }, 250)

        setTimeout(() => {
          this.activeInfoServices = 0
          this.ACTIVE_INDUSTRY_TITLE('')
          this.activeStateServices = true
        },500)
    },

    changeInfoActiveIndustry(id) {
      this.activeInfoIndustry = id
    },
    changeInfoActivePractice(id) {
      this.activeInfoPractice = id
    },
    changeInfoActiveServices(id) {
      this.activeInfoServices = id
    },


    async checkInfo() {
      try {
        await this.getIndustry();
      } catch (e) {
      } finally {
        setTimeout(() => {
          this.IS_LOADING(false)
          this.checkActiveScroll();
        }, 500)
        setTimeout(() => {
          this.checkActiveSlider();
          this.activeScreen = true
        }, 500)
      }
    }
  }
}
</script>

<style scoped lang="scss">


.paragraph-container {
  .text-content {
    line-height: 150%;
  }
}

.consulting-button-container {
  width: 100%;
  position: relative;
  align-self: flex-start;

  .main-button-container {
    border-color: var(--color-text);
    border-style: solid;
    border-width: 1px;
  }
}

.industry-screen-content {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  .industry-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    height: 100%;
    width: 100%;

    &.right {
      justify-content: space-between;
      .slider-info-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        max-height: 100%;
        flex: 1;
        height: 0;

          &.services {
            height: 80%;
          }
      }
    }

    &.left {
      .paragraph-container {
        flex-basis: 90%;
        display: flex;
        align-items: center;
        position: relative;
      }
    }
 }


    &.right {
      justify-content: space-between;

      .industry-title-container {
        align-self: center;
      }
  }
}

@media (min-width: 770px) {
  .industry-screen-container {
    padding: 0 0 0 var(--controller-padding);
  }

  .industry-screen-content {
    .industry-container {
      &.right {
        background-color: var(--color-card);
        height: var(--calc-height);

        &:after {
          width: 100%;
          height: 45%;
          pointer-events: none;
          background: linear-gradient(180deg,  rgba(34, 34, 59, 0.0) 0, var(--color-background) 79%);
          visibility: visible;
          content: "";
          position: absolute;
          z-index: 2;
          bottom: -1%;
          right: 0;
        }

        &:before {
          width: 100%;
          height: 45%;
          pointer-events: none;
          background: linear-gradient(0deg,  rgba(34, 34, 59, 0.0) 0, var(--color-background) 55%);
          visibility: visible;
          content: "";
          position: absolute;
          z-index: 2;
          top: -1%;
          left: 0;
        }
      }

      &.left {
        padding: calc(var(--title-padding) + var(--main-padding-top)) 0 var(--main-padding-bottom) 0;
      }
    }
  }
}


@media (max-width: 2560px){
  .industry-screen-content {
    .consulting-button-container {
      max-width: 778px;
    }

    .industry-container {
      &.left {
        max-width: 720px;
      }

      &.right {
        padding: 36px 0;
        max-width: 1284px;
      }
    }
  }
}

@media (max-width: 2240px){
  .industry-screen-content {
    .consulting-button-container {
      max-width: 778px;
    }

    .industry-container {
      &.left {
        max-width: 720px;
      }

      &.right {
        padding: 32px 0;
        max-width: 964px;
      }
    }
  }
}


@media (max-width: 1920px) {
  .industry-screen-content {

    .consulting-button-container {
      max-width: 584px;
    }

    .industry-container {
      &.left {
        max-width: 580px;
      }

      &.right {
        padding: 28px 0;
        max-width: 816px;
      }
    }
  }
}


@media (max-width: 1600px) {
  .industry-screen-content {

    .consulting-button-container {
      max-width: 420px;
    }
    .industry-container {
      &.left {
        max-width: 420px;
      }

      &.right {
        padding: 32px 0;
        max-width: 620px;
      }
    }
  }
}
@media (max-height: 800px) {
  .industry-screen-content {

    .consulting-button-container {
      max-width: 420px;
    }
    .industry-container {
      &.left {
        max-width: 400px;
      }

      &.right {
        padding: 24px 0;
        max-width: 526px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .industry-screen-content {
    .consulting-button-container {
      max-width: 352px;
    }

    .industry-container {
      &.left {
        max-width: 420px;
      }

      &.right {
        padding: 24px 0;
        max-width: 612px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .industry-screen-content {

    .consulting-button-container {
      max-width: 355px;
    }
    .industry-container {
      &.left {
        max-width: 350px;
      }

      &.right {
        padding: 24px 0;
        max-width: 512px;
      }
    }
  }
}

@media (max-width: 769px) {
  .industry-screen-content {
    flex-direction: column;
    justify-content: flex-start;
    .industry-container {
      position: relative;
      &.left {
        justify-content: flex-start;
        max-height: 180px;
        height: 100%;
        max-width: 100%;

        .paragraph-container {
          flex-basis: auto;

          .text-content {
            font-size: 12px;
            position: relative;
          }
        }
      }

      &.right {
        padding: 0;
        top: auto;
        position: relative;
        z-index: 1;
        flex: 1;
        height: 0;
        max-height: unset;
        max-width: 100%;
        transition: max-height .6s ease;
        .industry-title-container {
          position: relative;
        }


        .main-button-container {
          margin: 6px auto 0;
          border-radius: 12px;
          padding: 14px 24px;
        }

        .slider-info-container {
          position: relative;
          padding: 0;

          &.practice {
            max-height: 305px;
          }

          &.services {
            display: flex;
            flex-direction: column;
            max-height: 240px;
            height: 100%;
            padding: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .industry-screen-content {
    flex-direction: column;
    justify-content: flex-start;
    .industry-container {
      position: relative;
      &.left {
        justify-content: flex-start;
        max-height: 195px;
        height: 100%;
        max-width: 100%;

        .paragraph-container {
          flex-basis: auto;

          .text-content {
            font-size: 13px;
            position: relative;
          }
        }
      }

      &.right {
        position: relative;
        z-index: 1;
        max-height: unset;
        max-width: 100%;
        transition: max-height .6s ease;

        .industry-title-container {
          position: relative;
        }

        .main-button-container {
          margin: 6px auto 0;
          border-radius: 12px;
          padding: 14px 24px;
        }


        .slider-info-container {
          position: relative;

          &.practice {
            max-height: 334px;
          }

          &.services {
            display: flex;
            flex-direction: column;
            max-height: 280px;
            padding: 0;
            height: 100%;
          }
        }
      }
    }
  }
}


@media (max-width: 375px){
  .industry-screen-content {
    flex-direction: column;
    justify-content: flex-start;
    .industry-container {
      position: relative;
      &.left {
        justify-content: flex-start;
        max-height: 174px;
        height: 100%;
        max-width: 100%;

        .paragraph-container {
          flex-basis: auto;

          .text-content {
            font-size: 11.5px;
            position: relative;
          }
        }
      }

      &.right {
        position: relative;
        z-index: 1;
        max-width: 100%;
        transition: max-height .6s ease;

        .main-button-container {
          margin: 6px auto 0;
          border-radius: 12px;
          padding: 10px 18px;
          font-size: 8px !important;
        }

        .industry-title-container {
          position: relative;
        }

        .slider-info-container {
          position: relative;

          &.practice {
            max-height: 228px;
          }

          &.services {
            display: flex;
            flex-direction: column;
            max-height: 170px;
            height: 100%;
            padding: 0;
          }
        }
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .industry-screen-content {
    flex-direction: column;
    justify-content: flex-start;
    .industry-container {
      position: relative;
      &.left {
        justify-content: flex-start;
        max-height: 174px;
        height: 100%;
        max-width: 100%;

        .paragraph-container {
          flex-basis: auto;

          .text-content {
            font-size: 11.5px;
            position: relative;
          }
        }
      }

      &.right {
        position: relative;
        z-index: 1;
        max-width: 100%;
        transition: max-height .6s ease;
        .industry-title-container {
          position: relative;
        }

        .main-button-container {
          margin: 6px auto 0;
          border-radius: 12px;
          padding: 14px 24px;
        }

        .slider-info-container {
          position: relative;

          &.practice {
            max-height: 286px;
          }

          &.services {
            display: flex;
            flex-direction: column;
            max-height: 230px;
            height: 100%;
            padding: 0;
          }
        }
      }
    }
  }
}


@media (max-width: 320px) {
  .industry-screen-content {
    flex-direction: column;
    justify-content: flex-start;
    .industry-container {
      position: relative;
      &.left {
        justify-content: flex-start;
        max-height: 125px;
        height: 100%;
        max-width: 100%;

        .paragraph-container {
          flex-basis: auto;

          .text-content {
            font-size: 9px;
            position: relative;
          }
        }
      }

      &.right {
        position: relative;
        z-index: 1;
        max-width: 100%;
        transition: max-height .6s ease;

        .industry-title-container {
          position: relative;
        }

        .main-button-container {
          margin: 6px auto 0;
          border-radius: 8px;
          padding: 10px 18px;
        }

        .slider-info-container {
          position: relative;

          &.practice {
            max-height: 190px;
          }

          &.services {
            display: flex;
            flex-direction: column;
            max-height: 105px;
          }
        }
      }
    }
  }
}




@media (min-width: 2561px) {

  .industry-screen-content {

    .consulting-button-container {
      max-width: 1440px;
    }

    .industry-container {
      &.left {
        max-width: 1400px;
      }

      &.right {
        padding: 64px 0;
        max-width: 1955px;
      }
    }
  }
}
</style>