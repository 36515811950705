<template>
  <div class="slider-content-container" :class="{'not-active-gradient' : !activeGradient}">
    <swiper @slide-change="onSwiperMain"
            @swiper="changeSettingSwiper"
            :slides-per-view="settingSwiper.slidesPerView"
            :virtual="true"
            :navigation = "{
                  nextEl: '.swiper-button-right-mobile'
             }"
            :space-between="8"
            :modules="modules"
            class="swiper"
            :speed="1000"
            :direction="'horizontal'"
            :allow-touch-move="true">
      <swiper-slide class="clickable" v-for="(item, index) in infoLengthSlider" v-slot="{ isActive }" :key="index" :virtualIndex="index">
                <div class="content"  v-if="typeSlider === 'news'"
                     :class="{'active-slide' : isActive}">
                  <card-news v-if="activeNewsFull" :active-full="true" :info-news="activeInfo[index]"/>
                  <card-news-case-mobile-mini v-else :type-rout="'press'" :info-post="activeInfo[index]"/>
                </div>
              <div class="content" v-else
                   :class="{'active-slide' : isActive}">
                <card-news-case-mobile-mini :type-rout="'cases'" :info-post="activeInfo[index]"/>
              </div>
      </swiper-slide>
    </swiper>
    <div class="swiper-button swiper-button-right-mobile">
      <transition name="fade-opacity-lazy">
        <div v-if="activeNewsSlide < infoLengthSlider-1">
          <img v-if="activeColor" :src="getUrlIcon(imgNameRight + '-dark.svg')" alt="icon">
          <img v-else :src="getUrlIcon(imgNameRight + '-light.svg')" alt="icon">
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import CardNews from "@/components/entities/CardNews.vue";
import {Navigation, Virtual} from "swiper";

import SliderFrame from "@/components/entities/SliderFrame.vue";
import CardNewsCaseMobileMini from "@/components/entities/CardNewsCaseMobileMini.vue";

export default {
  name: "SliderNewsMobile",
  components: {CardNewsCaseMobileMini, SliderFrame, CardNews},
  props: {
    typeRouter: {
      type: String,
      default: 'press',
      required: true
    },

    activeGradient: {
      type: Boolean,
      default: true
    },

    activeInfo: {
      type: Array,
      required: true
    },
    settingSwiper: {
      type: Object,
      required: true
    },
    typeSlider: {
      type: String,
      default: 'news'
    },
    activeNewsFull: {
      type: Boolean,
      default: true
    },
    activeId: {
      type: Number,
      default: undefined
    }
  },

  data() {
    return {
      modules: [Virtual, Navigation],
      filterActive: false,
      activeNewsSlider: false,
      imgNameRight: 'single-arrow-right',
      activeNewsSlide: 0,
      newsSwiper: ''
    }
  },
  methods: {
    changeSettingSwiper(swiper) {
      this.newsSwiper = swiper
    },
    onSwiperMain(swiper) {
      this.activeNewsSlide = swiper.activeIndex;
    },
  },

  watch: {
    activeInfo() {
      this.filterActive = true
      setTimeout(() => {
        this.filterActive = false
      }, 500)
    },
  },

  computed: {
    infoLengthSlider() {
      return this.activeInfo?.length;
    },
  },
}
</script>

<style scoped lang="scss">
.slider-content-container {
  position: relative;
  display: flex;
  align-items: center;
  max-height: inherit;
  height: inherit;

  &.empty {
    &:after {
      display: none;
    }
    &:before {
      display: none;
    }
  }

  .swiper-button {
    z-index: 900;
    position: absolute;
    width: 100%;
    height: 100%;


    &.swiper-button-left {
      left: 0;
    }


    & div {
      position: relative;
    }

    & img {
      width: 100%;
      height: 100%;
      opacity: .6;
      transition: opacity .6s ease;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  .swiper {
    height: inherit;
    max-height: inherit;
    width: 100%;
  }

  &.empty {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.not-active-gradient {
    &:after {
      display: none;
    }
  }

  &:after {
    overflow: hidden;
    pointer-events: none;
    content: "";
    position: absolute;
    width: 30%;
    height: 110%;
    z-index: 2;
    bottom: -1%;
    right: -1%;
    background: linear-gradient(90deg,  rgba(34, 34, 59, 0.00) 0, var(--color-background) 100%);
  }
}

.swiper-slide {
  justify-content: flex-start;
}

.content {
  width: 100%;
  position: relative;
  height: 100%;
  transition: padding 1.5s ease;
}

@media (max-width: 769px) {
  .slider-content-container {
    position: relative;

    .swiper-button {
      padding: 10px;
      max-width: 26px;
      max-height: 33px;

      &.swiper-button-right-mobile {
        right: 0;
      }
    }
  }

  .content {
    .card-news-container {
      max-width: 310px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 740px) and (max-height: 1000px) {
  .slider-content-container {
    position: relative;

    .swiper-button {
      padding: 10px;
      max-width: 27px;
      max-height: 35px;
    }
  }

  .content {
    .card-news-container {
      max-width: 335px;
    }
  }
}

@media (max-width: 320px) {
  .slider-content-container {
    position: relative;

    .swiper-button {
      padding: 10px;
      max-width: 25px;
      max-height: 30px;
    }
  }

  .content {
    .card-news-container {
      max-width: 270px;
    }
  }
}



</style>