<template>
  <div class="media-screen-container main-container" v-show="!isLoading">
      <modal-consulting v-if="activeModal"
                        :type-submit="'press'"
                        @closeModal="changeStateModal"
                        :main-title="$t('modalContent.modalConsulting.mainTitleNews')"/>
    <transition name="fade-translate-y">
    <drop-list-filter-container v-if="activeDropdown && !activeMobile"
                                :active-scroll-news="true"
                                @changeActivePractice="changeActivePractice"
                                @changeActivePerson="changeActivePerson"/>
    </transition>
    <modal-filter   v-show="activeModalFilter"
                    :active-screen="activeModalFilter"
                    @changeActivePractice="changeActivePractice"
                    @changeActivePerson="changeActivePerson"
                    @closeModal="changeModalFilter"/>

    <transition name="fade-translate-x">
      <button-main-mobile v-if="activeDropdown && activeMobile"
                          @click="changeModalFilter"
                          :text-button="$t('mobileInfo.buttonFilter')"/>
    </transition>

    <div class="media-screen-content"  @touchstart="stopScrollBlock">
      <div class="media-left-content" >
        <transition name="fade-opacity-lazy">
          <main-title v-if="activeMainTitle" :active-cormorant="false">{{$t('newsScreen.mainTitle')}}</main-title>
        </transition>
        <div class="media-button-container">
          <div class="media-button-content"
               :key="index"
               v-for="(item, index) in $tm('newsScreen.buttonName')">
            <transition v-show="isVisible(index)" name="fade-opacity-lazy">
            <button-large v-if="!activeMobile" :nameImg="item.img"
                          :href-download-active="hrefDownload[index]"  :main-title="item.name" @clickToState="clickToState(index)"/>
            </transition>
            <transition v-show="isVisible(index)" name="fade-translate-y-revert">
            <button-large v-if="activeMobile"  :nameImg="item.img"
                          :href-download-active="hrefDownload[index]"  :main-title="item.nameMobile" @clickToState="clickToState(index)"/>
            </transition>
          </div>

        </div>
      </div>
      <transition name="fade-translate-x-revert">
        <div class="media-right-content" v-show="activeSlider"
             :class="{'active-gradient' : !activeTitle}"
             @mousemove="changeActiveScrollTrue"
             @mouseleave="changeActiveScrollFalse"
             @mouseenter="changeActiveScrollTrue">
          <transition name="fade-opacity-lazy">
          <large-title class="title-slider" v-if="!activeMobile" :active-cormorant="false">{{$t('newsScreen.mainTitleNews')}}</large-title>
          </transition>
          <slider-news-all :setting-swiper="settingSwiper"
                           :class="{'active-index': activeNewsSlider}"
                           @changeNewsSliderIndex="changeNewsSliderIndex"
                           @changeNewsSliderState="changeNewsSliderState"
                           :active-person-text-filter="activePersonTextFilter"
                           :active-practice-text-filter="activePracticeTextFilter"/>
        </div>
      </transition>

    </div>
  </div>
</template>

<script>
import DropListFilterContainer from "@/components/entities/DroplistFilterContainer.vue";
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import ButtonLarge from "@/components/shared/ButtonLarge.vue";
import SliderNews from "@/components/entities/SliderNews.vue";
import ModalConsulting from "@/components/entities/ModalConsulting.vue";
import MainTitlePage from "@/components/entities/MainTitlePage.vue";
import PageCaseNewsPage from "@/components/entities/PageCaseNewsPage.vue";
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import SliderNewsAll from "@/components/entities/SliderNewsAll.vue";
import ButtonMainMobile from "@/components/shared/ButtonMainMobile.vue";
import ModalFilter from "@/components/entities/ModalFilter.vue";
import {mapActions} from "vuex";

export default {
  name: "NewsScreen",
  components: {
    ModalFilter,
    ButtonMainMobile,
    SliderNewsAll,
    MainTitle,
    PageCaseNewsPage,
    MainTitlePage, ModalConsulting, SliderNews, ButtonLarge, LargeTitle, DropListFilterContainer},
  data() {
    return {
      activePracticeTextFilter: '',
      activePersonTextFilter: '',
      activeModal: false,
      settingSwiper: {
        direction: 'vertical',
        slidesPerView: 2.1,
        loop: true,
        centeredSlides: false,
        virtual: true
      },

      activeModalFilter: false,

      hrefDownload: [
        {
          id: 0,
          state: true,
          url: '/img/logo-without-dark.d94091d3.svg'
        },
        {
          id: 1,
          state: true,
          url: '/img/logo-without-dark.d94091d3.svg'
        },
        {
          id: 2,
          state: false,
          url: ''
        }
      ],

      activeDropdown: false,
      activeMainTitle: false,
      activeSlider: false,
      activeItem: -1,
      activeNewsSlider: false,
      activeTitle: true,

      activeScroll: false
    }
  },

  created() {
    this.IS_LOADING(true)
  },

  mounted() {
    this.$router.push({name: 'press'})
    this.checkSize();
    addEventListener('resize', this.checkSize);
    if(!this.isLoading) {
      this.checkActiveScroll();
      this.startAnimation();
    }
  },
  unmounted() {
    removeEventListener('resize', this.checkSize)
  },

  watch: {
    isLoading() {
      if(!this.isLoading) {
        this.startAnimation();
      }
    },
    activeScroll() {
      this.checkActiveScroll();
    },
  },

  methods: {
    ...mapActions(['ACTIVE_MOUSE_SCROLL']),

    stopScrollBlock() {
      this.checkActiveScroll();
    },

    checkActiveScroll() {
      if(this.activeScroll) {
        this.ACTIVE_MOUSE_SCROLL(false)
      } else {
        this.ACTIVE_MOUSE_SCROLL(true)
      }
    },


    changeActiveScrollTrue() {
      if(!this.activeMobile) {
        this.activeScroll = true;
      }

    },

    changeActiveScrollFalse() {
      if(!this.activeMobile) {
        this.activeScroll = false;
      }
    },


    changeModalFilter() {
      this.activeModalFilter = !this.activeModalFilter;
    },

    startAnimation() {
        setTimeout(() => {
          this.activeMainTitle = true
          this.showNextItem();
        }, 250)
    },

    changeNewsSliderIndex(id) {
      if(id === 0) {
        setTimeout(() => {
          this.activeTitle = true
        }, 1000)
      } else {
        this.activeTitle = false
      }
    },

    changeNewsSliderState(state) {
      this.activeNewsSlider = state
    },

    checkSize() {
      if(window.innerWidth <= 2560 && window.innerWidth > 2240) {
        this.settingSwiper.slidesPerView = 3.3
      } else if(window.innerWidth <= 2240 && window.innerWidth > 1920)  {
        this.settingSwiper.slidesPerView = 2.3
      } else if(window.innerWidth <= 1920 && window.innerWidth > 1600)  {
        this.settingSwiper.slidesPerView = 3.0
      } else if (window.innerWidth <= 1600 && window.innerWidth > 1400)  {
        this.settingSwiper.slidesPerView = 3.8
      } else if (window.innerWidth < 1400 && window.innerWidth > 1280 && window.innerHeight <= 800) {
        this.settingSwiper.slidesPerView = 2.8
      } else if (window.innerWidth < 1400 && window.innerWidth > 1280) {
        this.settingSwiper.slidesPerView = 3.8
      } else if (window.innerWidth <= 1280 && window.innerWidth > 1133) {
        this.settingSwiper.slidesPerView = 2.5
      } else if  (window.innerWidth <= 1133) {
        this.settingSwiper.slidesPerView = 3.1
      } else {
          this.settingSwiper.slidesPerView = 2.5
        }

      if(window.innerWidth <= 769 && window.innerWidth > 320) {
        this.settingSwiper.slidesPerView = 1.1
        this.settingSwiper.loop = false;
        this.settingSwiper.centeredSlides = false;
        this.settingSwiper.virtual = true;
      } else if(window.innerWidth <= 320) {
        this.settingSwiper.slidesPerView = 1
        this.settingSwiper.loop = false;
        this.settingSwiper.centeredSlides = false;
        this.settingSwiper.virtual = true;
      }
    },

    changeActivePractice(text) {
      this.activePracticeTextFilter = text
    },
    changeActivePerson(text) {
      this.activePersonTextFilter = text
    },

    changeStateModal() {
      this.activeModal = !this.activeModal
    },

    async clickToState(id) {
      switch(id) {
        case 0:
          let fileUrl;
          if(this.$i18n.locale === 'ru') {
            fileUrl = '/rus_compressed.pdf';
          } else {
            fileUrl = '/eng_compressed.pdf';
          }
          const link = document.createElement('a');
          link.href = fileUrl;
          link.target= '_blank'
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          break;
        case 1:
          this.changeStateModal();
          break;
      }
    },


    showNextItem() {
      if (this.activeItem < 3) {
        setTimeout(() => {
          this.activeItem++
          this.showNextItem();
        }, 250)
      } else {
        this.activeSlider = true
        this.checkActiveScroll();
        setTimeout(() => {
          this.activeDropdown = true
        }, 500)
      }
    },

    isVisible(index) {
      return index <= this.activeItem
    },


  }
}
</script>

<style scoped lang="scss">
.main-button-container {
  position: absolute;
  top: calc(var(--main-padding-top));
  right: var(--controller-padding);
}

.media-screen-container {
  justify-content: flex-end;
  .drop-list-filter-container {
    z-index: 901;
    position: absolute;
    top: calc(var(--main-padding-top));
    right: var(--padding-right);
  }

  .media-screen-content {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    .media-left-content {
      z-index: 3;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .text-content {
        line-height: normal;
        font-weight: 200;
      }

      .media-button-container {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    .media-right-content {
      max-width: calc(var(--max-width-right) + var(--extra-right-padding));
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;

      .title-slider {
        top: calc(var(--main-padding-top) + var(--breadcrumbs-height));
        left: var(--extra-right-padding);
        position: absolute;
        z-index: 900;
      }

      .slider-news-content-container {
        padding: var(--padding-top-slider) var(--extra-right-padding) 0;
        transition: padding-top .5s ease;
      }
    }
  }
}

@media (min-width: 770px) {
  .media-screen-container {
    padding: 0 0 0 var(--controller-padding);
    justify-content: flex-start;
    .media-screen-content {

      .media-left-content {
        padding: calc(var(--title-padding) + var(--main-padding-top) + var(--top-left-content)) 0 var(--main-padding-bottom) 0;
      }

      .media-right-content {
        background-color: var(--color-card);

        &:before {
          transition: all .6s ease;
          overflow: hidden;
          pointer-events: none;
          width: 100%;
          height: 20%;
          background: linear-gradient(0deg,  rgba(34, 34, 59, 0.0) 0, var(--color-background) 50%);
          content: "";
          position: absolute;
          z-index: 900;
          top: -1%;
        }
      }
    }
  }
}

@media (max-width: 2560px){
  .media-screen-container {
    --padding-right: 96px;
    .media-screen-content {
      .media-left-content {
        --top-left-content: 64px;
        max-width: 775px;

        .text-content {
          font-size: 44px;
        }

        .media-button-container {
          row-gap: 18px;

          .media-button-content {
            height: 72px;
          }
        }
      }

      .media-right-content {
        --extra-right-padding: 36px;
        --max-width-right: 1279px;

        &.active-gradient {
          &:before {
            height: 20%;
            background: linear-gradient(0deg, rgba(34, 34, 59, 0) 0, var(--color-background) 30%);
          }
        }

        &:before {
          height: 20%;
          background: linear-gradient(0deg,  rgba(34, 34, 59, 0.0) 0, var(--color-background) 50%);
        }


        .slider-news-content-container {
          --padding-top-slider: 235px;
        }
      }
    }
  }
}

@media (max-width: 2240px) {
  .media-screen-container {
    .media-screen-content {
      .media-left-content {
        --top-left-content: 87px;
        max-width: 720px;

        .text-content {
          font-size: 38px;
        }

        .media-button-container {
          .media-button-content {
            height: 66px;
          }
        }
      }

      .media-right-content {
        --extra-right-padding: 32px;
        --max-width-right: 975px;

        &.active-gradient {
          &:before {
            height: 25%;
            background: linear-gradient(0deg, rgba(34, 34, 59, 0) 0, var(--color-background) 30%);
          }
        }

        &:before {
          height: 25%;
          background: linear-gradient(0deg,  rgba(34, 34, 59, 0.0) 0, var(--color-background) 50%);
        }

        .slider-news-content-container {
          --padding-top-slider: 265px;
        }
      }
    }
  }
}


@media (max-width: 1920px) {
  .media-screen-container {
    .media-screen-content {
      .media-left-content {
        --top-left-content: 64px;
        max-width: 500px;

        .text-content {
          font-size: 24px;
          padding-top: 64px;
        }

        .media-button-container {
          row-gap: 16px;

          .media-button-content {
            height: 56px;
          }
        }
      }

      .media-right-content {
        --extra-right-padding: 32px;
        --max-width-right: 930px;

        &.active-gradient {
          &:before {
            height: 20%;
            background: linear-gradient(0deg, rgba(34, 34, 59, 0) 0, var(--color-background) 30%);
          }
        }

        &:before {
          height: 20%;
          background: linear-gradient(0deg,  rgba(34, 34, 59, 0.0) 0, var(--color-background) 50%);
        }

        .text-content {
          margin-bottom: 32px;
        }

        .slider-news-content-container {
          --padding-top-slider: 170px;
        }
      }
    }
  }
}


@media (max-width: 1600px) {
  .media-screen-container {
    --padding-right: 64px;
    .media-screen-content {
      .media-left-content {
        --top-left-content: 42px;
        max-width: 420px;

        .text-content {
          font-size: 22px;
          padding-top: 0;
        }

        .media-button-container {
          row-gap: 8px;

          .media-button-content {
            height: 40px;
          }
        }
      }

      .media-right-content {
        --extra-right-padding: 32px;
        --max-width-right: 680px;

        .slider-news-content-container {
          --padding-top-slider: 140px;
        }
      }
    }
  }
}
@media (max-height: 800px) {
  .media-screen-container {
    .media-screen-content {
      .media-left-content {
        --top-left-content: 42px;
        max-width: 379px;

        .text-content {
          font-size: 22px;
        }

        .media-button-container {
          row-gap: 8px;

          .media-button-content {
            height: 40px;
          }
        }
      }

      .media-right-content {
        --extra-right-padding: 24px;
        --max-width-right: 530px;

        .slider-news-content-container {
          --padding-top-slider: 130px;
        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .media-screen-container {
    .media-screen-content {
      .media-left-content {
        --top-left-content: 42px;
        max-width: 420px;

        .text-content {
          font-size: 23px;
        }

        .media-button-container {
          row-gap: 8px;

          .media-button-content {
            height: 40px;
          }
        }
      }

      .media-right-content {
        --extra-right-padding: 24px;
        --max-width-right: 612px;

        .slider-news-content-container {
          --padding-top-slider: 157px;
        }
      }
    }
  }
}

@media (max-width: 1133px) {
  .media-screen-container {
    --padding-right: 55px;
    .media-screen-content {
      .media-left-content {
        --top-left-content: 32px;
        max-width: 355px;

        .text-content {
          font-size: 19px;
        }

        .media-button-container {
          row-gap: 9.5px;

          .media-button-content {
            height: 34px;
          }
        }
      }

      .media-right-content {
        --max-width-right: 480px;
        --extra-right-padding: 24px;

        .text-content {
          margin-bottom: 16px;
        }


        .slider-news-content-container {
          --padding-top-slider: 135px;
        }
      }
    }
  }
}

@media (max-width: 769px) {
  .media-screen-container {
    .media-screen-content {
      flex-direction: column-reverse;
      row-gap: 26px;
      padding-bottom: 34px;
      max-height: 100%;
      .media-left-content {
        justify-content: flex-start;
        row-gap: 26px;
        max-width: 100%;
        max-height: 115px;
        .text-content {
          font-size: 12px;
        }

        .media-button-container {
          flex-direction: row;
          justify-content: flex-start;
          column-gap: 16px;

          .media-button-content {
            width: 100%;

            .large-button-container {
              max-width: 100%;
            }
          }
        }
      }

      .media-right-content {

        max-width: 100%;
        width: 100%;
        max-height: 350px;
        height: 100%;

        .slider-content-container {
          padding: 0;
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .media-screen-container {
    .media-screen-content {
      flex-direction: column-reverse;
      row-gap: 24px;
      padding-bottom: 16px;
      max-height: 100%;
      .media-left-content {
        justify-content: flex-start;
        row-gap: 24px;
        max-width: 100%;
        max-height: 122px;
        .text-content {
          line-height: 140%;
          font-size: 12px;
        }

        .media-button-container {
          flex-direction: row;
          justify-content: flex-start;
          column-gap: 16px;

          .media-button-content {;
            width: 100%;
          }
        }
      }

      .media-right-content {

        max-width: 100%;
        width: 100%;
        max-height: 491px;
        height: 100%;

        .text-content {
          margin-bottom: 16px;
        }


        .slider-content-container {
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 375px){
  .media-screen-container {
    .media-screen-content {
      row-gap: 16px;
      padding-bottom: 18px;
      .media-left-content {
        row-gap: 16px;
        max-height: 90px;
        .text-content {
          line-height: 140%;
          font-size: 10px;
        }

        .media-button-container {
          flex-direction: row;
          justify-content: flex-start;
          column-gap: 16px;

          .media-button-content {
            width: 100%;
          }
        }
      }

      .media-right-content {

        max-width: 100%;
        width: 100%;
        max-height: 316px;
        height: 100%;

        .slider-content-container {
          max-width: 100%;
        }
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .media-screen-container {
    .media-screen-content {
      row-gap: 16px;
      padding-bottom: 32px;
      .media-left-content {
        row-gap: 16px;
        max-height: 116px;
        .text-content {
          line-height: 140%;
          font-size: 12px;
        }

        .media-button-container {
          flex-direction: row;
          justify-content: flex-start;
          column-gap: 16px;

          .media-button-content {
            width: 100%;
          }
        }
      }

      .media-right-content {

        max-width: 100%;
        width: 100%;
        max-height: 350px;
        height: 100%;

        .slider-content-container {
          max-width: 100%;
        }
      }
    }
  }
}


@media (max-width: 320px) {
  .media-screen-container {
    .media-screen-content {
      row-gap: 19px;
      padding-bottom: 20px;
      .media-left-content {
        row-gap: 19px;
        max-height: 99px;
        .text-content {
          line-height: 140%;
          font-size: 10px;
        }

        .media-button-container {
          flex-direction: row;
          justify-content: flex-start;
          column-gap: 16px;

          .media-button-content {
            width: 100%;
          }
        }
      }

      .media-right-content {

        max-width: 100%;
        width: 100%;
        max-height: 230px;
        height: 100%;

        .slider-content-container {
          max-width: 100%;
        }
      }
    }
  }
}


@media (min-width: 2561px) {
  .media-screen-container {
    --padding-right: 192px;
    .media-screen-content {
      .media-left-content {
        --top-left-content: 128px;
        max-width: 1350px;

        .text-content {
          font-size: 78px;
        }

        .media-button-container {
          row-gap: 32px;

          .media-button-content {
            height: 126px;
          }
        }
      }

      .media-right-content {
        --extra-right-padding: 64px;
        --max-width-right: 1962px;


        .slider-news-content-container {
          --padding-top-slider: 357px;
        }
      }
    }
  }
}

</style>