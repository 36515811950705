<template>
  <div class="card-news-case-mini-container" @click="routToNewsPage(infoPost?.slug)">
    <div class="main-title-container">
      <large-title class="main-title-content"
                   :active-cormorant="false"
                  >{{ translationsList?.translations?.name }}</large-title>
    </div>
    <main-paragraph class="description-content">{{ translationsList?.translations?.shortName }}</main-paragraph>

    <main-paragraph class="date-content" :class="{'active-en' : activeEng}">{{ datePublicationFormat }}</main-paragraph>
  </div>
</template>

<script>
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import {mapActions} from "vuex";

export default {
  name: "CardNewsCaseMobileMini",
  components: {LargeTitle, MainParagraph},
  props: {
    infoPost: {
      type: Object,
      required: true
    },
    typeRout: {
      type: String,
      required: true
    }
  },
  computed: {
    datePublicationFormat() {
      return this.datePublicationFormatChange(this.infoPost?.publishDate)
    },
    translationsList() {
      return {
        translations: this.checkLangCode(this.infoPost, this.$i18n.locale, 'translations', 'lang'),
        practices: this.infoPost?.practices.map(item => {
          return this.checkLangCode(item, this.$i18n.locale, 'modifications', 'langCode')
        }),
        users: this.infoPost?.users.map(item => {
          return this.checkLangCode(item, this.$i18n.locale, 'profiles', 'lang')
        }),
      };
    },
  },
  methods: {
    ...mapActions(['ACTIVE_SUB_PAGE']),
    routToNewsPage(slug) {
      this.ACTIVE_SUB_PAGE(true)
      console.log(this.typeRout)
      this.$router.push('/' + this.typeRout + '/' + slug)
    }
  }
}
</script>

<style scoped lang="scss">
.card-news-case-mini-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom-style: solid;
  border-bottom-color: var(--color-element);
  width: 100%;
  background-color: var(--color-card);
  border-radius: 3px;
  .main-title-container {
    .date-content {
      &.active-en {
        font-variant: all-small-caps !important;
      }
    }
    .text-content {
      &.large {
        cursor: pointer;
      }
    }
  }

  .sub-title-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: baseline;
    justify-content: space-between;
  }
}

@media (max-width: 769px) {
  .card-news-case-mini-container {
    max-width: 306px;
    height: 100%;
    padding: 16px;
    border-bottom-width: 0;

    &.not-active-full {
      .description-content {
        font-size: 12px;
      }

      .main-title-container {

        .date-content {
          font-weight: 200;
          font-size: 12px;
          margin-bottom: 8px;
        }

        .main-title-content {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .description-content {
      font-size: 12px;
    }

    .main-title-container {
      .main-title-content {
        font-size: 16px;
        font-weight: 600;
      }

      .date-content {
        font-weight: 200;
        font-size: 12px;
        margin-bottom: 8px;
      }
    }


    .sub-title-container {
      flex-direction: column;
      row-gap: 16px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .card-news-case-mini-container {
    max-width: 335px;
  }
}

@media (max-width: 375px){
  .card-news-case-mini-container {
    max-width: 282px;
    height: 100%;
    padding: 16px;
    border-bottom-width: 0;

    &.not-active-full {
      .description-content {
        font-size: 12px;
      }

      .main-title-container {

        .date-content {
          font-weight: 200;
          font-size: 10px;
          margin-bottom: 8px;
        }

        .main-title-content {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    .description-content {
      font-size: 12px;
    }

    .main-title-container {
      .main-title-content {
        font-size: 14px;
        font-weight: 600;
      }

      .date-content {
        font-weight: 200;
        font-size: 10px;
        margin-bottom: 8px;
      }
    }


    .sub-title-container {
      flex-direction: column;
      row-gap: 16px;
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .card-news-case-mini-container {
    height: 100%;
    padding: 16px;
    max-width: 306px;
    border-bottom-width: 0;

    &.not-active-full {
      .description-content {
        font-size: 12px;
      }

      .main-title-container {

        .date-content {
          font-weight: 200;
          font-size: 12px;
          margin-bottom: 8px;
        }

        .main-title-content {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .description-content {
      font-size: 12px;
    }

    .main-title-container {
      .main-title-content {
        font-size: 16px;
        font-weight: 600;
      }

      .date-content {
        font-weight: 200;
        font-size: 12px;
        margin-bottom: 8px;
      }
    }


    .sub-title-container {
      flex-direction: column;
      row-gap: 16px;
    }
  }
}


@media (max-width: 320px) {
  .card-news-case-mini-container {
    height: 100%;
    max-width: 228px;
    padding: 12px;
    border-bottom-width: 0;

    &.not-active-full {
      .description-content {
        font-size: 10px;
      }

      .main-title-container {

        .date-content {
          font-weight: 200;
          font-size: 10px;
          margin-bottom: 8px;
        }

        .main-title-content {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }

    .description-content {
      font-size: 10px;
    }

    .main-title-container {
      .main-title-content {
        font-size: 12px;
        font-weight: 600;
      }

      .date-content {
        font-weight: 200;
        font-size: 10px;
        margin-bottom: 8px;
      }
    }


    .sub-title-container {
      flex-direction: column;
      row-gap: 8px;
    }
  }
}

</style>