<template>
    <div class="values-dark-card-container" ref="valuesDark" 
         @click="clickMobile" 
         @mouseenter="focusTrue" 
         @mouseleave="focusFalse" 
         :class="{'right-content' : direction, 'focus-active' : focusParam}">
      <div class="values-dark-card-content">
        <main-title :active-cormorant="true">{{cardInfo.mainTitle}}</main-title>
        <transition name="fade-translate-x">
          <main-paragraph v-if="focusParam">{{cardInfo.description}}</main-paragraph>
        </transition>
      </div>
      <div class="background-image" :class="`${cardInfo.styleNum}`" >
          <div class="lottie-wrapper">
            <LottieAnimation :animationData="require('@/assets/lottie/ValuesDarkTheme_' + (cardInfo.id + 1) + '.json')"
                             class="lottie"
                             @onLoopComplete="completeAnimation"
                             :autoPlay="false"
                             :ref="'lottieValues' + cardInfo.styleNum"
                             :flex-growth="'None'"
                             :delay="1000"
                             :rendererSettings="{ preserveAspectRatio: 'xMaxYMid meet' }" />
          </div>
      </div>
    </div>
</template>

<script>
import MainTitle from "@/components/shared/Text/MainTitle.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";

export default {
  components: {MainParagraph, MainTitle},
  props: {
    cardInfo: {
      type: Object,
      required: true
    },
    direction: {
      type: Boolean,
      required: true
    }
  },
  name: "CardValuesDark",
  data() {
    return {
      focusParam: false,
      lottieDOM: undefined
    }
  },

  mounted() {
    this.lottieDOM = this.$refs['lottieValues' + this.cardInfo.styleNum]
    if(this.activeMobile) {
      window.addEventListener('click', this.closeOptions);
    }
  },
  unmounted() {
    window.removeEventListener('click', this.closeOptions);
  },


  methods: {
    completeAnimation() {
      if(!this.focusParam) {
        this.lottieDOM.stop();
      }
    },

    closeOptions(event) {
      if (!this.$refs['valuesDark'].contains(event.target)) {
        this.focusParam = false;
      }
    },

    clickMobile() {
      if(this.activeMobile) {
        this.focusParam = !this.focusParam
      }
    },

    checkAnimation() {
      if(this.focusParam) {
        this.lottieDOM.play();
      }
    }
  },

  watch: {
    focusParam() {
      this.checkAnimation();
    }
  }
}
</script>

<style scoped lang="scss">
  .values-dark-card-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    position: relative;
    background-color: var(--color-card);

    &.right-content {
      justify-content: right;
      .values-dark-card-content {
        width: 100%;
        .text-content {
          &.main-title {
            text-align: right;
          }
          &.main-text {
            text-align: left;
          }
        }
      }
    }

  .values-dark-card-content {
    transition: height .8s ease;
    .text-content {

      &.main-text {
        position: relative;
        line-height: 150%;
      }
    }

  }


    .background-image {
      position: absolute;
      height: inherit;
      width: 100%;

      .lottie-wrapper {
        width: 100%;
        height: 100%;
      }

      & img {
        width: 100%;
        height: 100%;
      }

      &.one {
        right: 0;
        bottom: 0;
      }
      &.two {
        right: 0;
        top: 0;
      }
      &.three {
        left: 0;
        bottom: 0;
      }
      &.four {
        left: 0;
        top: 0;
      }
    }
  }

  @media (max-width: 2560px){
    .values-dark-card-container {
      max-width: 850px;
      padding: 64px 42px;
      max-height: 368px;

      &.focus-active {
        .values-dark-card-content {
          height: 242px;
        }
      }

      .values-dark-card-content {
        height: 65px;
        max-width: 680px;
        .text-content {
          &.main-title {
            letter-spacing: 10.8px;
            font-size: 54px;
            margin-bottom: 32px;
          }
        }
      }
    }
  }

  @media (max-width: 2240px) {
    .values-dark-card-container {
      max-width: 744px;
      padding: 56px 36px;
      max-height: 322px;

      &.focus-active {
        .values-dark-card-content {
          height: 213px;
        }
      }

      .values-dark-card-content {
        height: 57px;
        max-width: 595px;
        .text-content {
          &.main-title {
            letter-spacing: 9.4px;
            font-size: 47px;
            margin-bottom: 28px;
          }
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .values-dark-card-container {
      max-width: 638px;
      padding: 32px;
      height: 276px;

      &.focus-active {
        .values-dark-card-content {
          height: 180px;
        }
      }

      .values-dark-card-content {
        height: 48px;
        max-width: 510px;
        .text-content {
          &.main-title {
            letter-spacing: 8px;
            font-size: 40px;
            margin-bottom: 24px;
          }
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .values-dark-card-container {
      max-width: 425px;
      padding: 32px 22px;
      height: 184px;

      &.focus-active {
        .values-dark-card-content {
          height: 119px;
        }
      }

      .values-dark-card-content {
        height: 31px;
        max-width: 340px;
        .text-content {
          &.main-title {
            letter-spacing: 5.2px;
            font-size: 26px;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
  @media (max-height: 800px) {
    .values-dark-card-container {
      max-width: 425px;
      padding: 32px 22px;
      height: 184px;

      &.focus-active {
        .values-dark-card-content {
          height: 119px;
        }
      }

      .values-dark-card-content {
        height: 31px;
        max-width: 340px;
        .text-content {
          &.main-title {
            letter-spacing: 5.2px;
            font-size: 26px;
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .values-dark-card-container {
      max-width: 503px;
      padding: 37px 25px;
      height: 218px;

      &.focus-active {
        .values-dark-card-content {
          height: 142px;
        }
      }

      .values-dark-card-content {
        height: 39px;
        max-width: 402px;
        .text-content {
          &.main-title {
            letter-spacing: 6.4px;
            font-size: 32px;
            margin-bottom: 19px;
          }
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .values-dark-card-container {
      max-width: 374px;
      padding: 28px 19px;
      height: 162px;

      &.focus-active {
        .values-dark-card-content {
          height: 106px;
        }
      }

      .values-dark-card-content {
        height: 28px;
        max-width: 300px;
        .text-content {
          &.main-title {
            letter-spacing: 4.69px;
            font-size: 24px;
            margin-bottom: 14px;
          }
        }
      }
    }
  }

  @media (max-width: 769px) {
    .values-dark-card-container {
      overflow: hidden;
      max-width: 100%;
      padding: 14px;
      height: 112px;

      &.right-content {
        .values-dark-card-content {
          .text-content {
            &.main-text {
              text-align: right;
            }
          }
        }
      }

      &.focus-active {
        .values-dark-card-content {
          height: 84px;
        }
      }

      .values-dark-card-content {
        height: 19px;
        max-width: 283px;
        .text-content {
          &.main-title {
            letter-spacing: 3.2px;
            font-size: 16px;
            margin-bottom: 4px;
          }

          &.main-text {
            line-height: 150%;
            font-size: 10px;
          }
        }
      }
      .background-image {
        &.one {
          top: -35%;
          bottom: auto;
        }
        &.two {
          width: 93%;
        }

        &.three {
          left: -1%;
          width: 125%;
          bottom: 20%;
        }

        &.four {
          width: 85%;
          left: -3%;
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .values-dark-card-container {
      max-width: 100%;
      padding: 16px;
      height: 131px;

      &.right-content {
        .values-dark-card-content {
          .text-content {
            &.main-text {
              text-align: right;
            }
          }
        }
      }

      &.focus-active {
        .values-dark-card-content {
          height: 105px;
        }
      }

      .values-dark-card-content {
        height: 22px;
        max-width: 268px;
        .text-content {
          &.main-title {
            letter-spacing: 3.6px;
            font-size: 18px;
            margin-bottom: 8px;
          }

          &.main-text {
            line-height: 150%;
            font-size: 10px;
          }
        }
      }

      .background-image {
        &.one {
          top: -25%;
          bottom: auto;
        }
        &.two {
          width: 93%;
        }

        &.three {
          left: -1%;
          width: 125%;
          bottom: 20%;
        }

        &.four {
          width: 85%;
          left: -3%;
        }
      }
    }
  }



  @media (max-width: 375px){
    .values-dark-card-container {
    padding: 14px;
    height: 100px;

    &.focus-active {
      .values-dark-card-content {
        height: 69px;
      }
    }

    .values-dark-card-content {
      height: 17px;
      max-width: 283px;
      .text-content {
        &.main-title {
          letter-spacing: 2.8px;
          font-size: 14px;
          margin-bottom: 4px;
        }

        &.main-text {
          font-size: 10px;
        }
      }
    }
      .background-image {
        &.one {
          top: -35%;
        }
        &.two {
          width: 90%;
        }

        &.three {
          bottom: 36%;
        }
      }
  }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .values-dark-card-container {
      padding: 14px;
      height: 112px;

      &.focus-active {
        .values-dark-card-content {
          height: 83px;
        }
      }

      .values-dark-card-content {
        height: 19px;
        max-width: 283px;
        .text-content {
          &.main-title {
            letter-spacing: 3.2px;
            font-size: 16px;
            margin-bottom: 4px;
          }

          &.main-text {
            font-size: 10px;
          }
        }
      }
      .background-image {
        &.one {
          top: -30%;
        }
      }
    }
  }


  @media (max-width: 320px) {
    .values-dark-card-container {
      padding: 12px;
      height: 75px;

      &.focus-active {
        .values-dark-card-content {
          height: 57px;
        }
      }

      .values-dark-card-content {
        height: 15px;
        max-width: 239px;
        .text-content {
          &.main-title {
            letter-spacing: 2.4px;
            font-size: 12px;
            margin-bottom: 2px;
          }

          &.main-text {
            font-size: 8px;
          }
        }
      }
      .background-image {
        &.one {
          top: -65%;
        }
        &.two {
          width: 92%;
        }

        &.three {
          left: -11%;
          bottom: 75%;
          width: 150%;
        }

        &.four {
          left: -11%;
        }
      }
    }
  }


  @media (min-width: 2561px) {
    .values-dark-card-container {
      max-width: 1488px;
      padding: 112px 72px;
      max-height: 644px;

      &.focus-active  {
        .values-dark-card-content {
          height: 422px;
        }
      }

      .values-dark-card-content {
        height: 114px;
        max-width: 1190px;
        .text-content {
          &.main-title {
            letter-spacing: 18.8px;
            font-size: 94px;
            margin-bottom: 56px;
          }
        }
      }
    }
  }

</style>