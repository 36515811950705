<template>
  <slider-news @changeNewsSliderState="changeNewsSliderState"
               v-if="!activeMobile && activeLangFilter.length !== 0"
               @changeNewsSliderIndex="changeNewsSliderIndex"
               :controller-setting="controllerSetting"
               :active-news-full="activeNewsFull"
               :setting-swiper="settingSwiper"
               :active-info="activeLangFilter"/>
  <slider-news-mobile v-else-if="activeMobile && activeLangFilter.length !== 0"
                      :active-gradient="false"
                      :active-news-full="activeNewsFull"
                      :setting-swiper="settingSwiper"
                      :active-info="activeLangFilter"/>
</template>

<script>
import SliderNews from "@/components/entities/SliderNews.vue";
import {mapGetters} from "vuex";
import SliderNewsMobile from "@/components/entities/SliderNewsMobile.vue";

export default {
  name: "SliderNewsUser",
  components: {SliderNewsMobile, SliderNews},
  props: {

    activeGradient: {
      type: Boolean,
      default: true
    },

    controllerSetting: {
      type: Object,
      default: {
        top: true,
        bottom: true
      }
    },
    settingSwiper: {
      type: Object,
      required: true
    },
    activeNewsFull: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('persons',['activeUserPressAll']),
    ...mapGetters('news',['allPress']),
    activeLangFilter() {
      if(this.activeUserPressAll?.length !== 0) {
        return this.activeUserPressAll.filter(item => {
          return item?.translations.find(item => item.lang === this.$i18n.locale)
        })
      } else {
        return []
      }
    },
  },
  mounted() {
    this.$emit('activeLength', this.activeLangFilter.length)
  },
  methods: {
    changeNewsSliderState(state) {
      this.$emit('changeNewsSliderState', state)
    },

    changeNewsSliderIndex(index) {
      this.$emit('changeNewsSliderIndex', index)
    }
  }
}
</script>

<style scoped>

</style>