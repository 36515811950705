<template>
  <div class="partners-screen-container" v-if="!isLoading">
    <div class="left-content">
      <transition name="fade-opacity-animation">
        <large-title v-if="activeTitle" :active-cormorant="true">
          {{$t('aboutScreen.partnersScreen')}}
        </large-title>
      </transition>

    </div>
    <transition name="fade-opacity-animation">
    <div class="right-content" v-if="activeSlider">
      <slider-img @changeSlide="changeSlide" :slider-setting="sliderSetting" :length-info="partnersAll?.length">
        <swiper-slide v-for="(item, index) in partnersAll"
                      class="clickable"
                      v-slot="{ isActive }"
                      style="transition: transform .8s ease"
                      :key="index">
        <div class="img-container" :class="{'active-slide' : isActive}">
          <div class="img-content">
            <div v-html="partnersAllPhoto[index]" class="brand-logo-container">
            </div>
          </div>
        </div>
        </swiper-slide>
      </slider-img>
      <transition name="fade-opacity-lazy" mode="out-in">
        <p class="brand-text-content" :key="this.activeIndexSlide">
          {{ partnersAll[this.activeIndexSlide]?.[this.activeLanguage] }}
        </p>
      </transition>
    </div>
    </transition>
  </div>
</template>

<script>
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import SliderImg from "@/components/entities/SliderImg.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PartnersScreen",
  components: {SliderImg, LargeTitle},

  data() {
    return {
      activeTitle: false,
      activeSlider: false,
      sliderSetting: {
        loop: true,
        slidesPerView: 3.5,
        centeredSlides: true,
        grabCursor: true
      },
      activeIndexSlide: 0,
      result: ''
    }
  },
  computed: {
    ...mapGetters('partners',['partnersAll', 'partnersAllPhoto']),


    activeLanguage() {
      if(this.$i18n.locale === 'en') {
        return 'eng'
      } else {
        return 'ru'
      }
    }
  },
  mounted() {
    if(this.activeMobile) {
      this.checkSize();
      addEventListener('resize', this.checkSize)
    }
    if(!this.isLoading) {
      setTimeout(() => {
        this.startAnimation();
      }, 250)
    }
  },
  unmounted() {
    removeEventListener('resize', this.checkSize)
  },
  watch: {
    isLoading() {
      if(!this.isLoading)
      {
        this.startAnimation()
      }
    },
    activeColor() {
      this.checkColorLogo();
    }
  },
  methods: {
    ...mapActions('partners', ['getPartners']),

    checkColorLogo() {
      let parentBlock = document.querySelector(".right-content")
      let childBlock = parentBlock.querySelectorAll('path')
      childBlock.forEach((item, index) => {
        switch (this.activeColor) {
          case true:
            if(item.getAttribute('stroke') !== null) {
              item.setAttribute('stroke', 'rgb(255,253,251)')
            }
            if(item.getAttribute('fill')  !== null) {
              item.setAttribute('fill', 'rgb(255,253,251)')
            }
            break
          case false:
            if(item.getAttribute('stroke')  !== null) {
              item.setAttribute('stroke', 'rgb(13,27,42)')
            }
            if(item.getAttribute('fill')  !== null) {
              item.setAttribute('fill', 'rgb(13,27,42)')
            }
            break
        }
      })
    },

    changeSlide(id) {
      this.activeIndexSlide = id
    },
    checkSize() {
      if(window.innerWidth < 769) {
        this.sliderSetting.slidesPerView = 2
      } else {
        this.sliderSetting.slidesPerView = 3.5
      }
    },

    startAnimation() {
        this.activeTitle = true
        this.activeSlider = true
        setTimeout(() => {
          this.checkColorLogo();
        }, 125)
    },
  }
}
</script>

<style scoped lang="scss">

svg {
  width: 100%;
  height: 100%;
  fill: var(--color-logo-partners);
}

.brand-text-content {
  white-space: pre-wrap;
  position: relative;
  font-family: 'CormorantUnicase-Regular', sans-serif;
  line-height: 170%;
  margin: 0 auto;
  width: fit-content;
}

.img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;


  .img-content {
    height: 100%;
    box-shadow: 0 4px 20px 0 rgba(var(--color-background-rgba), 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-card-img);

    .brand-logo-container {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}
  .partners-screen-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    .left-content {
      width: 100%;
      .text-content {
        line-height: 170%;
      }
    }

    .right-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;
    }
  }

@media (max-width: 2560px){


  .img-container {
    row-gap: 44px;
    max-width: 554px;
    min-width: 554px;
    max-height: 582px;


    .img-content {
      border-radius: 3px;
      width: 342px;
      max-height: 408px;

      .brand-logo-container {
        max-width: 222px;
        max-height: 222px;
      }
    }
  }

  .partners-screen-container {
    .left-content {
      max-width: 841px;
      .text-content {
        letter-spacing: 7.6px;
        font-size: 38px;
      }
    }

    .right-content {
      max-width: 956px;
      max-height: 582px;

      .slider-img-content-container {
        max-height: 408px;
      }

      .brand-text-content {
        font-size: 38px;
        letter-spacing: 7.6px;
      }
    }
  }
}

@media (max-width: 2240px) {

  .img-container {
    row-gap: 19px;
    max-width: 809px;
    min-width: 809px;
    max-height: 534px;



    .img-content {
      width: 342px;
      max-height: 408px;

      .brand-logo-container {
        max-width: 222px;
        max-height: 222px;
      }
    }
  }

  .partners-screen-container {
    .left-content {
      max-width: 716px;
      .text-content {
        font-size: 32px;
        letter-spacing: 6.4px;
      }
    }

    .right-content {
      max-width: 911px;
      max-height: 536px;

      .slider-img-content-container {
        max-height: 408px;
      }

      .brand-text-content {
        font-size: 32px;
        letter-spacing: 6.4px;
      }
    }
  }
}


@media (max-width: 1920px) {


  .img-container {
    row-gap: 24px;
    max-width: 298px;
    min-width: 298px;
    max-height: 368px;


    .img-content {
      width: 256px;
      max-height: 306px;

      .brand-logo-container {
        max-width: 168px;
        max-height: 168px;
      }
    }
  }

  .partners-screen-container {
    .left-content {
      max-width: 870px;
      .text-content {
        letter-spacing: 6.4px;
        font-size: 28px;
      }
    }

    .right-content {
      max-width: 701px;
      max-height: 367px;

      .slider-img-content-container {
        max-height: 306px;
      }

      .brand-text-content {
        font-size: 22px;
        letter-spacing: 4.4px;
      }
    }
  }
}


@media (max-width: 1600px) {
  .img-container {
    row-gap: 24px;
    max-width: 370px;
    min-width: 370px;
    max-height: 361px;

    .img-content {
      width: 220px;
      max-height: 262px;
      box-shadow: 0 4px 4px 0 rgba(var(--color-background-rgba), 0.25);
      .brand-logo-container {
        max-width: 142px;
        max-height: 142px;
      }
    }
  }

  .partners-screen-container {;
    .left-content {
      max-width: 528px;
      .text-content {
        font-size: 22px;
        letter-spacing: 4.4px;
      }
    }

    .right-content {
      max-width: 578px;
      max-height: 361px;

      .slider-img-content-container {
        max-height: 262px;
      }

      .brand-text-content {
        font-size: 22px;
        letter-spacing: 4.4px;
      }
    }
  }
}
@media (max-height: 800px) {

  .img-container {
    row-gap: 24px;
    max-width: 370px;
    min-width: 370px;
    max-height: 361px;



    .img-content {
      width: 220px;
      max-height: 262px;

      .brand-logo-container {
        max-width: 142px;
        max-height: 142px;
      }
    }
  }

  .partners-screen-container {
    max-height: 420px;
    .left-content {
      max-width: 452px;
      .text-content {
        font-size: 20px;
        letter-spacing: 4.4px;
      }
    }

    .right-content {
      max-width: 572px;
      max-height: 361px;

      .slider-img-content-container {
        max-height: 262px;
      }

      .brand-text-content {
        font-size: 22px;
        letter-spacing: 4.4px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {

  .img-container {
    row-gap: 24px;
    max-width: 370px;
    min-width: 370px;
    max-height: 361px;



    .img-content {
      width: 220px;
      max-height: 262px;

      .brand-logo-container {
        max-width: 142px;
        max-height: 142px;
      }
    }
  }

  .partners-screen-container {
    .left-content {
      max-width: 452px;
      .text-content {
        font-size: 20px;
        letter-spacing: 4.4px;
      }
    }

    .right-content {
      max-width: 576px;
      max-height: 361px;

      .slider-img-content-container {
        max-height: 262px;
      }

      .brand-text-content {
        font-size: 22px;
        letter-spacing: 4.4px;
      }
    }
  }
}

@media (max-width: 1133px) {

  .img-container {
    row-gap: 18px;
    max-width: 300px;
    min-width: 300px;
    max-height: 288px;



    .img-content {
      width: 182px;
      max-height: 218px;

      .brand-logo-container {
        max-width: 118px;
        max-height: 118px;
      }
    }
  }

  .partners-screen-container {
    .left-content {
      max-width: 422px;
      .text-content {
        font-size: 19px;
        letter-spacing: 3.8px;
      }
    }

    .right-content {
      max-width: 478px;
      max-height: 354px;

      .slider-img-content-container {
        max-height: 218px;
      }

      .brand-text-content {
        font-size: 19px;
        letter-spacing: 3.8px;
      }
    }
  }
}
@media (max-width: 769px) {

  .img-container {
    transition: transform .6s ease;
    transform: scale(0.9);
    max-width: 186px;
    min-width: 186px;
    max-height: 220px;

    &.active-slide {
      transform: scale(1);
    }

    .img-content {
      width: 186px;
      max-height: 220px;
      box-shadow: none;

      .brand-logo-container {
        max-width: 144px;
        max-height: 144px;
      }
    }
  }

  .partners-screen-container {
    justify-content: flex-start;
    row-gap: 24px;
    flex-direction: column-reverse;
    .left-content {
      max-width: 291px;
      .text-content {
        text-align: center;
        text-transform: none;
        line-height: 140%;
        font-weight: 100;
        font-family: 'Raleway', sans-serif;
        font-size: 12px;
        letter-spacing: normal;
      }
    }

    .right-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 100%;
      max-height: 316px;
      row-gap: 73px;

      .slider-img-content-container {
        max-height: inherit;
      }
      .brand-text-content {
        font-size: 16px;
        letter-spacing: normal;
        font-weight: 700;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .img-container {
    max-width: 208px;
    min-width: 208px;
    max-height: 245px;
    .img-content {
      width: 208px;
      max-height: 245px;

      .brand-logo-container {
        max-width: 160px;
        max-height: 160px;
      }
    }
  }

  .partners-screen-container {
    justify-content: flex-start;
    row-gap: 24px;
    flex-direction: column-reverse;
    .left-content {
      max-width: 291px;
      .text-content {
        text-align: center;
        text-transform: none;
        line-height: 140%;
        font-weight: 100;
        font-family: 'Raleway', sans-serif;
        font-size: 12px;
        letter-spacing: normal;
      }
    }

    .right-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 100%;
      max-height: 329px;
      row-gap: 61px;
      .brand-text-content {
        font-size: 16px;
        letter-spacing: normal;
        font-weight: 700;
      }
    }
  }
}


@media (max-width: 375px){
  .img-container {
    max-width: 186px;
    min-width: 186px;
    max-height: 220px;
    .img-content {
      width: 186px;
      max-height: 220px;
      .brand-logo-container {
        max-width: 144px;
        max-height: 144px;
      }
    }
  }

  .partners-screen-container {
    row-gap: 24px;
    .left-content {
      max-width: 291px;
      .text-content {
        font-size: 12px;
      }
    }

    .right-content {
      max-height: 267px;
      row-gap: 24px;
      .brand-text-content {
        font-size: 16px;
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .img-container {
    max-width: 186px;
    min-width: 186px;
    max-height: 220px;
    .img-content {
      width: 186px;
      max-height: 220px;
      .brand-logo-container {
        max-width: 144px;
        max-height: 144px;
      }
    }
  }

  .partners-screen-container {
    row-gap: 24px;
    .left-content {
      max-width: 291px;
      .text-content {
        font-size: 12px;
      }
    }

    .right-content {
      max-height: 309px;
      row-gap: 66px;
      .brand-text-content {
        font-size: 16px;
      }
    }
  }
}


@media (max-width: 320px) {
  .img-container {
    max-width: 153px;
    min-width: 153px;
    max-height: 180px;
    .img-content {
      border-radius: 4px;
      width: 153px;
      max-height: 180px;
      .brand-logo-container {
        max-width: 116px;
        max-height: 116px;
      }
    }
  }

  .partners-screen-container {
    row-gap: 16px;
    .left-content {
      max-width: 291px;
      .text-content {
        font-size: 10px;
      }
    }

    .right-content {
      max-height: 220px;
      row-gap: 16px;
      .brand-text-content {
        font-size: 14px;
      }
    }
  }
}

@media (min-width: 2561px) {


  .img-container {
    row-gap: 38px;
    max-width: 1000px;
    min-width: 1000px;
    max-height: 970px;



    .img-content {
      border-radius: 3px;
      width: 597px;
      max-height: 714px;

      .brand-logo-container {
        max-width: 390px;
        max-height: 390px;
      }
    }
  }

  .partners-screen-container {
    .left-content {
      max-width: 1480px;
      .text-content {
        letter-spacing: 12.8px;
        font-size: 64px;
      }
    }

    .right-content {
      max-width: 1630px;
      max-height: 970px;

      .slider-img-content-container {
        max-height: 714px;
      }

      .brand-text-content {
      font-size: 64px;
      letter-spacing: 12.8px;
    }
    }
  }
}

</style>