<template>
    <slider-news @changeNewsSliderState="changeNewsSliderState"
                 v-if="!activeMobile"
                 :active-gradient="activeGradient"
                 @changeNewsSliderIndex="changeNewsSliderIndex"
                 :controller-setting="controllerSetting"
                 :setting-swiper="settingSwiper"
                 :active-info="filterInfo"/>
    <slider-news-mobile v-else
                        :type-router="'news'"
                        :active-news-full="activeNewsFull"
               :setting-swiper="settingSwiper"
               :active-info="filterInfo"/>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SliderNews from "@/components/entities/SliderNews.vue";
import SliderNewsMobile from "@/components/entities/SliderNewsMobile.vue";

export default {
  name: "SliderNewsAll",
  components: {SliderNewsMobile, SliderNews},
  props: {
    activeGradient: {
      type: Boolean,
      default: true
    },


    activePracticeTextFilter: {
      type: String,
    },
    activePersonTextFilter: {
      type: String
    },
    controllerSetting: {
      type: Object,
      default: {
        top: true,
        bottom: true
      }
    },
    settingSwiper: {
      type: Object,
      required: true
    },
    activeNewsFull: {
      type: Boolean,
      default: true
    },
    activeId: {
      type: Number,
      default: undefined
    }
  },
  async created() {
    await this.checkInfo();
  },

  data() {
    return {
      activeIdState: false
    }
  },

  mounted() {
    this.activeIdState = this.activeId !== undefined;
  },

  computed: {
    ...mapGetters({
      newsAll: 'news/allPress',
      sortedNewsAll: 'news/getAllSortedNews'
    }),

    activeLangFilter() {
      return this.sortedNewsAll?.filter(item => {
        return item?.translations.find(item => item.lang === this.$i18n.locale)
      })
    },

    filterInfo() {
      if(!this.activeIdState) {
        if (this.activePracticeTextFilter !== '' && this.activePersonTextFilter !== '') {
          return this.sortedNewsAll?.filter(item => this.changeActivePractice(item) && this.changeActivePersons(item));
        } else if (this.activePracticeTextFilter !== '') {
          return this.activeLangFilter?.filter(item => this.changeActivePractice(item));
        } else if (this.activePersonTextFilter !== '') {
          return this.sortedNewsAll?.filter(item => this.changeActivePersons(item))
        } else {
          return this.activeLangFilter
        }
      }  else {
        return this.activeLangFilter?.filter(item => item?.id !== this.activeId)
      }
    }
  },

  watch: {
    filterInfo() {
      if(this.filterInfo.length === 0 && this.activeId !== undefined) {
        this.activeIdState = false
      } else {
        this.activeIdState = this.activeId !== undefined;
      }
    },
    activeId() {
      this.activeIdState = this.activeId !== undefined;
    }
  },

  methods: {
    ...mapActions({
      getNews: 'news/getPress',
    }),

    async checkInfo() {

      if(this.newsAll === undefined) {
        try {
          await this.getNews();
        } catch (e) {
          console.log(e)
        } finally {
          this.IS_LOADING(false)
        }
      } else {
        this.IS_LOADING(false)
      }
    },

    changeActivePractice(item) {
      return item?.practices.find(item => item.id === this.activePracticeTextFilter)
    },
    changeActivePersons(item) {
      return item?.users.find(item => item.id === this.activePersonTextFilter)
    },

    changeNewsSliderState(state) {
      this.$emit('changeNewsSliderState', state)
    },

    changeNewsSliderIndex(index) {
      this.$emit('changeNewsSliderIndex', index)
    }

  }
}
</script>

<style scoped>

</style>