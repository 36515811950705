<template>
  <div :class="{'eng' : activeEng}">
    <div class="title-rout-container" v-if="activeStatic" :class="{
      'active-modal-filter' : activeModalFilter,
    'active-click' : activeClick,
    'active-static-color' : activeStaticColor}">
        <span class="title-rout-content" @click="returnToPage(0)" :class="{'active-mobile-first-slide' : activeMobile && activeSubTitle === undefined}">
          <span class="active-hover">
            {{activeMainTitle}}
          </span>
        </span>
      <span class="title-rout-content" v-if="!activeMobile || (activeSubTitle !== undefined)">
             \
        </span>
      <transition name="fade-opacity-lazy" mode="out-in">
        <span class="title-rout-content static" :key="activeSubTitle">
            {{activeSubTitle}}
        </span>
      </transition>
    </div>

    <div class="title-rout-container array" v-else :class="{'active-modal-filter' : activeModalFilter, 'active-click' : true,
    'active-static-color' : activeStaticColor}">
      <transition-group name="fade-opacity-lazy">
        <div class="title-rout-content static" v-for="(item,index) in arrayRouterSubTitle"
             :key="index"
             :class="{'active-mobile-first-slide' :
                        activeMobile
                        && arrayRouterSubTitle[1] === undefined
                        && arrayRouterSubTitle.length < 3}">
            <span class="slash-symbol"
                  v-if="(index > 0 && !activeMobile) || (index > 0 && arrayRouterSubTitle[1] !== undefined) || (index > 1)">
              \
            </span>
            <span :key="index" @click="returnToPage(index)"
                  :class="{'active-hover' : index === 0 || index === 2 ||
            (index === 1 && arrayRouterSubTitle.length > 3) ||
            (index === 1 && activeSubPage),
            }">
            {{item}}
            </span>
        </div>
      </transition-group>
    </div>
  </div>


</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "TitleRouter",
  props: {
    activeStaticColor: {
      type: Boolean,
      default: false
    },
    activeMainTitle: {
      type: String,
      required: true
    },
    activeMainSlide: {
      type: Number,
    },
    activeSubTitle: {
      required: true
    },
    activeDelete: {
      type: Boolean,
      required: true
    },

    activeSubPage: {
      type: Boolean,
      default: false
    },

    activeDeleteIndex: {
      type: Number,
      required: true
    },

    activeClick: {
      type: Boolean,
      default: true
    },

    activeStatic: {
      type: Boolean,
      default: false,
    }
  },



  data() {
    return {
      activeRouter: true,
      arrayRouterSubTitle: []
    }
  },

  computed: {
    activeModalFilter() {
      return this.$store.state.activeModalFilter;
    },
  },

  mounted() {
    this.addNewTitle(this.activeMainTitle);
  },

  watch: {
    activeDelete() {
      if(this.activeDelete) {
        this.deleteLast(this.activeDeleteIndex);
      }
    },
    activeSubTitle: {
      handler() {
        if(this.activeSubTitle !== '') {
          this.addNewTitle();
        }
      },
      immediate: true
    },
  },

  methods: {
    ...mapActions(['RETURN_INDEX_ACTIVE']),

    addNewTitle() {

      if(this.arrayRouterSubTitle.length >= 1) {
        if(this.activeSubTitle !== undefined) {
          this.arrayRouterSubTitle.push(this.activeSubTitle);
        }
      } else {
        this.arrayRouterSubTitle.push(this.activeMainTitle);
      }
    },

    deleteLast(index) {
      this.arrayRouterSubTitle.splice(-index)
    },

    returnToPage(index) {
      let isClick = index === 0 || index === 2 ||
          (index === 1 && this.arrayRouterSubTitle.length > 3) ||
          (index === 1 && this.activeSubPage);
      if(index === this.arrayRouterSubTitle.length - 1) {
        isClick = false
      }
      console.log(index)
      if(this.activeMobile && index === 2) {
        this.$emit('returnToSlide', 1, undefined, isClick)
      } else {
        this.$emit('returnToSlide', index, undefined, isClick)
      }
    },
  },
}
</script>

<style scoped lang="scss">

.eng {
  font-variant: all-small-caps !important;
  text-transform: none !important;
}

.title-rout-content {
  &.active-mobile-first-slide {
    span {
      color: var(--color-text) !important;
    }
  }
}

  .title-rout-container {
    height: var(--breadcrumbs-height);
    font-family: 'Raleway',sans-serif;
    font-weight: 400;
    display: flex;
    align-items: center;
    line-height: 120%;
    transition: filter .5s ease;


    &.active-click {

      .title-rout-content {

        .slash-symbol {
          color:  #9A8C98 !important;
        }

        &.static {
          position: relative;
        }


        span {
          position: relative;
          transition: color .8s ease;
          color: #9A8C98;

          &.active-hover {
            &:hover {
              cursor: pointer;
              color: var(--color-text);
            }
          }
        }

        &:last-child {
          span {
            color: var(--color-text);

            &.active-hover {
              &:hover {
                cursor: default;
              }
            }
          }
        }
      }
    }

    &.active-static-color {

      .title-rout-content {
        &:last-child {
          color: #F2E9E4;
          span {
            color: #F2E9E4;
          }
        }
      }
    }

    .title-rout-content {
      margin: 0 2px;
      color: #9A8C98;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.static {
        position: relative;
      }

      &:last-child {
        color: var(--color-text);
      }
    }

  }

  @media (max-width: 2560px){
    .title-rout-container {
      font-size: 18px;
      &.active-click {
        .title-rout-content {
          max-width: 250px;
        }
      }
    }
  }

  @media (max-width: 2240px) {
    .title-rout-container {
      font-size: 16px;
    }
  }

  @media (max-width: 1920px) {
    .title-rout-container {
      font-size: 14px;

      &.active-click {
        .title-rout-content {
          max-width: 234px;
        }
      }
    }
  }

  @media (max-width: 1600px) {
    .title-rout-container {
      font-size: 10px;
      .title-rout-content {
        margin: 0 1px;
      }

      &.active-click {
        .title-rout-content {
          max-width: 180px;
        }
      }
    }
  }

  @media (max-height: 800px) {
    .title-rout-container {
      font-size: 10px;

      &.active-click {
        .title-rout-content {
          max-width: 150px;
        }
      }
    }
  }


  @media (max-width: 1400px) and (min-height: 801px) {
    .title-rout-container {
      font-size: 12px;
      &.active-click {
        .title-rout-content {
          max-width: 150px;
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .title-rout-container {
      font-size: 8px;
      &.active-click {
        .title-rout-content {
          max-width: 110px;
        }
      }
    }
  }

  @media (max-width: 769px) {
    .title-rout-container {
      font-size: 10px;
      &.active-click {

        .title-rout-content {
          max-width: 95px;
        }

        span {
          &.active-hover {
            &:hover {
              color: #9A8C98;;
            }
          }
        }
      }
    }
  }

@media (max-width: 769px) and (min-height: 720px) and (max-height: 1000px) {
  .title-rout-container {
    font-size: 10px;
    &.active-click {
      .title-rout-content {
        max-width: 110px;
      }
    }
  }
}

@media (max-width: 375px){
  .title-rout-container {
    &.active-click {
      .title-rout-content {
        max-width: 80px;
      }
    }
  }
}



  @media (max-width: 320px) {
    .title-rout-container {
      font-size: 8px;
      &.active-click {
        .title-rout-content {
          max-width: 70px;
        }
      }
    }
  }


  @media (min-width: 2561px) {
    .title-rout-container {
      font-size: 34px;

      &.active-click {
        .title-rout-content {
          max-width: 550px;
        }
      }

      .title-rout-content {
        margin: 0 3px;
      }
    }
  }


</style>