<template>
  <div class="frame-case-news-page-container" v-if="!activeMobile" :class="{'block-scroll' : !activeOverflow}">
    <transition name="fade-opacity-lazy">
      <title-router-sub-page :active-router-href="hrefTitle"
                             v-if="stateActiveTitle"
                             :active-main-title="activeMainTitle"
                             :active-sub-title="activeTitle"/>
    </transition>
    <transition name="fade-opacity-lazy" mode="out-in">
      <card-news-case class="main-container"
                      v-show="!isLoadingImg"
                      :publish-date="infoContent?.publishDate"
                      @changeActiveOverflow="changeActiveOverflow"
                      :active-main-title="activeMainTitle"
                      :active-id="infoContent?.id"
                      :info-content="translationsList"
                      :active-slider="activeSlider"/>
    </transition>
  </div>
  <div class="frame-case-news-page-container" v-else :class="{'block-scroll' : !activeOverflow}">
    <transition name="fade-opacity-lazy" mode="out-in">
      <card-news-case-mobile
                      v-show="!isLoadingImg"
                      :active-router-href="hrefTitle"
                      :publish-date="infoContent?.publishDate"
                      @changeActiveOverflow="changeActiveOverflow"
                      :active-main-title="activeMainTitle"
                      :active-id="infoContent?.id"
                      :info-content="translationsList"
                      :active-slider="activeSlider"/>
    </transition>
  </div>
</template>

<script>
import CardNewsCase from "@/components/entities/news-case-page/CardNewsCase.vue";
import MainFooter from "@/components/widgets/MainFooter.vue";
import TitleRouterSubPage from "@/components/shared/TitleRouterSubPage.vue";
import {mapActions} from "vuex";
import CardNewsCaseMobile from "@/components/entities/news-case-page/CardNewsCaseMobile.vue";

export default {
  name: "PageCaseNewsPage",
  components: {CardNewsCaseMobile, TitleRouterSubPage, MainFooter, CardNewsCase},
  props: {
    activeSlider: {
      type: Boolean,
      required: true
    },
    infoContent: {
      type: Object,
      required: true
    },
    activeMainSlide: {
      type: Number,
      required: true
    },
    activeId: {
      type: String,
      required: true
    },
    pathIncludes: {
      type: String,
      required: true
    },
    activeMainTitle: {
      type: String,
      required: true
    },
    hrefTitle: {
      type: String,
      required: true
    }
  },
  computed: {
    translationsList() {
      try {
        return {
          translations: this.checkLangCode(this.infoContent, this.$i18n.locale.toString(), 'translations', 'lang'),
          practices: this.infoContent?.practices.map(item => {
            return this.checkLangCode(item, this.$i18n.locale.toString(), 'modifications', 'langCode')
          }),
          users: this.infoContent?.users,
          photo: {
            preview: this.infoContent?.preview,
            photo: this.infoContent?.photos
          },
          views: this.infoContent?.views
        };
      } catch (e) {
        return undefined
      }
    }
  },
  mounted() {
    this.startAnimation();
  },
  data() {
    return {
      activeTitle: '',
      stateActiveTitle: false,
      activeOverflow: false
    }
  },

  methods: {
    ...mapActions(['ACTIVE_MAIN_SLIDE']),
    startAnimation() {
      this.changeActiveSubTitle();
      this.ACTIVE_MAIN_SLIDE(this.activeMainSlide)
    },

    changeActiveOverflow(state) {
      this.activeOverflow = state;
    },

    changeActiveSubTitle() {
      this.stateActiveTitle = true
      setTimeout(() => {
        this.$nextTick(() => {
          this.activeTitle = this.translationsList?.translations?.name;
          this.$nextTick(() => {
            this.activeTitle = '';
          })
        })
      }, 500)
    },

    changeActive() {
      this.changeActiveInfo = true;
      this.stateActiveTitle = false;
      this.$nextTick( () => {
        this.changeActiveSubTitle();
        setTimeout(() => {
          this.changeActiveOverflow(false)
          this.changeActiveInfo = false;
        }, 500)
      })
    }
  },
  watch: {
    activeId() {
      if(this.$route.fullPath.includes(this.pathIncludes)) {
        this.changeActive();
      }
    },
    activeEng() {
      this.changeActive();
    }
  },
}
</script>

<style scoped lang="scss">
.frame-case-news-page-container {
  position: relative;
  height: 100%;
  overflow: visible;
  &.block-scroll {
    height: var(--calc-height);
    overflow: hidden;
  }
}

.main-container {
  --title-padding: 0px;
  position: relative;
}


@media (max-width: 1920px) and (min-width: 1601px) {
  .frame-case-news-page-container {
    --controller-padding: 305px;
  }

}

@media (min-width: 2561px) {
  .frame-case-news-page-container {
    --controller-padding: 773px;
  }
}

@media (max-width: 2560px){
  .main-container {
    --main-padding-bottom: 40px;
  }
}

@media (max-width: 2240px) {
  .main-container {
    --main-padding-bottom: 40px;
  }
}


@media (max-width: 1920px) {
  .main-container {
    --main-padding-bottom: 72px;
  }
}


@media (max-width: 1600px) {
  .main-container {
    --main-padding-bottom: 22px;
  }
}
@media (max-height: 800px) {
  .main-container {
    --main-padding-bottom: 22px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .main-container {
    --main-padding-bottom: 60px;
  }
}

@media (max-width: 1133px) {
  .main-container {
    --main-padding-bottom: 32px;
  }
}
@media (max-width: 769px) {
  //iPhone 14
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 2561px) {
  .main-container {
    --main-padding-bottom: 72px;
  }
}

</style>