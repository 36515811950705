<template>
  <slider-frame :modules="modules"
                :active-grab-cursor="true"
                :swiper-setting="settingSwiper"
                :length-info="lengthTeamInfo"
                v-show="!isLoading"
                :active-controller="activeNavigation"
                :active-left-gradient="false"
                v-if="teamInfo.length > 1"
                @changeActiveIndex="changeActiveIndex">
    <swiper-slide v-for="(item, index) in lengthTeamInfoAdd"
                  :key="index"
                  class="clickable"
                  v-slot="{ isActive }"
                  :virtualIndex="index">
      <div class="content" v-if="index < lengthTeamInfo" :class="{'active-slide' : isActive}">
        <card-team     :active-index="activeIndex === index"
                       :info-person="teamInfo[index]"/>
      </div>
        <div class="card-news-container empty" v-else-if="index >= lengthTeamInfo && !activeMobile"></div>
    </swiper-slide>
  </slider-frame>
  <slider-frame :modules="modules"
                :class="{'active-left-swiper-button' : activeLeftSwiper}"
                :swiper-setting="settingSwiper"
                :length-info="lengthTeamInfo"
                v-show="!isLoading"
                :active-controller="activeNavigation"
                :active-left-gradient="false"
                v-else
                @changeActiveIndex="changeActiveIndex">
    <swiper-slide class="clickable" style="justify-content: flex-start">
    <card-team :active-index="true"
               :info-person="teamInfo[0]"/>
    </swiper-slide>
  </slider-frame>
</template>

<script>
import SliderFrame from "@/components/entities/SliderFrame.vue";
import CardTeam from "@/components/entities/CardTeam.vue";
import {Navigation, Virtual} from "swiper";

export default {
  name: "SliderTeam",
  components: {CardTeam, SliderFrame},
  props: {
    teamInfo: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      activeIndex: 0,
      settingSwiper: {
        direction: 'horizontal',
        slidesPerView: 3,
        loop: false,
        centeredSlides: false,
        virtual: true,
        allowTouch: false,
        spaceBetween: 0
      },
      activeNavigation: {
        left: false,
        right: true
      },

      countImg: 0,

      modules: [Virtual, Navigation],
      activeLeftSwiper: false,
    }
  },
  mounted() {
    this.IS_LOADING(false)
    this.checkSize();
    addEventListener('resize', this.checkSize);
  },
  unmounted() {
    removeEventListener('resize', this.checkSize)
  },
  computed: {
    lengthTeamInfo() {
      return this.teamInfo.length;
    },
    lengthTeamInfoAdd() {
      if(this.activeMobile) {
        return this.lengthTeamInfo;
      } else {
        return this.lengthTeamInfo + 2;
      }
    }
  },
  watch: {
    // isLoading() {
    //   console.log(this.isLoading)
    // },
    //
    // countImg() {
    //   if(this.lengthTeamInfo > 2) {
    //     if(!this.activeMobile) {
    //       if(this.countImg === Math.round(this.settingSwiper.slidesPerView)) {
    //         this.IS_LOADING(false)
    //       }
    //     } else {
    //       if(this.countImg === this.lengthTeamInfo - 1) {
    //         this.IS_LOADING(false)
    //       }
    //     }
    //   } else {
    //     if(this.countImg === this.lengthTeamInfo) {
    //       this.IS_LOADING(false)
    //     }
    //   }
    // }
  },
  methods: {
    //
    // loadImg() {
    //   this.countImg = this.countImg + 1;
    // },

    changeActiveIndex(id) {
      this.activeIndex = id
      this.activeNavigation.right = id < this.lengthTeamInfo - 1;
      this.activeNavigation.left = id > 0 && !this.activeMobile;
      if(this.activeNavigation.left) {
        setTimeout(() => {
          this.activeLeftSwiper = true;
        }, 1000)
      } else {
        setTimeout(() => {
          this.activeLeftSwiper = false;
        }, 1000)
      }
    },
    checkSize() {
      if(window.innerWidth > 2560) {
        this.settingSwiper.slidesPerView = 3.1
      } else if((window.innerWidth <= 2560 && window.innerWidth > 2240))  {
        this.settingSwiper.slidesPerView = 3.05
      } else if((window.innerWidth <= 2240 && window.innerWidth > 1920))  {
        this.settingSwiper.slidesPerView = 2.88
      } else if(window.innerWidth < 1600 && window.innerWidth > 1400) {
        this.settingSwiper.slidesPerView = 3.4
      } else if (window.innerWidth < 1400 && window.innerWidth > 1280 && window.innerHeight <= 800) {
        this.settingSwiper.slidesPerView = 3.0
      } else if (window.innerWidth < 1400 && window.innerWidth > 1280) {
        this.settingSwiper.slidesPerView = 2.5
      } else if(window.innerWidth <= 1133) {
        this.settingSwiper.slidesPerView = 2.9
      } else {
        this.settingSwiper.slidesPerView = 2.8
      }

      if(window.innerWidth <= 769) {
        this.settingSwiper.spaceBetween = 12
        this.settingSwiper.slidesPerView = 1
      } else {
        this.settingSwiper.spaceBetween = 0
      }
    }
  }
}
</script>

<style scoped lang="scss">
.slider-content-container {
  height: 100%;
  width: 100%;
  position: relative;
}
.content {
  transition: padding  1.5s ease;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 40px 24px;

  &.active-slide {
    padding: 0 24px 0 0;
  }
  .team-card-container {
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 2560px){
  .slider-content-container {
    padding-left: 48px;
  }
  .content {
    padding: 47px 21px;

    &.active-slide {
      padding: 0 21px 0 0;
    }
  }
}

@media (max-width: 2240px){

  .content {
    padding: 47px 15.5px;

    &.active-slide {
      padding: 0 15.5px 0 0;
    }
  }
}

@media (max-width: 1920px) {
  .slider-content-container {
    padding-left: 24px;
  }
  .content {
    padding: 40px 24px;

    &.active-slide {
      padding: 0 24px 0 0;
    }
  }
}


@media (max-width: 1600px) {
  .slider-content-container {
    padding-left: 24px;
  }
  .content {
    padding: 27px 16px;

    &.active-slide {
      padding: 0 16px 0 0;
    }
  }
}
@media (max-height: 800px) {
  .content {
    padding: 27px 12px;

    &.active-slide {
      padding: 0 12px 0 0;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .slider-content-container {
    padding-left: 56px;
  }
  .content {
    padding: 27px 12px;

    &.active-slide {
      padding: 0 12px 0 0;
    }
  }
}

@media (max-width: 1133px) {
  .content {
    padding: 33px 12px;

    &.active-slide {
      padding: 0 12px 0 0;
    }
  }
}

@media (max-width: 769px) {
  .slider-content-container {
    padding-left: 0;
    &.active-left-swiper-button {
      padding-left: 0;
    }
  }
  .content {
    padding: 0;

    &.active-slide {
      padding: 0;
    }
  }
}


@media (min-width: 2561px) {
  .slider-content-container {
    padding-left: 72px;
  }
  .content {
    padding: 98px 48px;

    &.active-slide {
      padding: 0 48px 0 0;
    }
  }
}
</style>