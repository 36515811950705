<template>
  <p class="text-content large" :class="{'cormoranti-unicase-active': activeCormorant, 'epic-large' : epicLarge, 'eng' : activeEng }">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "LargeTitle",
  props: {
    activeCormorant: {
      type: Boolean,
      required: true
    },
    epicLarge: {
      type: Boolean
    }
  }
}
</script>

<style scoped lang="scss">
.text-content {
  line-height: 1.2;
}

  @media (max-width: 2560px){
    .text-content {
      &.large {
        font-size: 44px;
      }

      &.epic-large {
        font-size: 92px;
      }
    }
  }

  @media (max-width: 2240px) {
    .text-content {
      &.large {
        font-size: 38px;
      }

      &.epic-large {
        font-size: 92px;
      }
    }
  }


  @media (max-width: 1920px) {
    .text-content {
      &.large {
        font-size: 32px;
      }

      &.epic-large {
        font-size: 80px;
      }
    }
  }


  @media (max-width: 1600px) {
    .text-content {
      &.large {
        font-size: 22px;
      }

      &.epic-large {
        font-size: 54px;
      }
    }
  }
  @media (max-height: 800px) {
    .text-content {
      &.large {
        font-size: 22px;
      }

      &.epic-large {
        font-size: 54px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .text-content {
      &.large {
        font-size: 23px;
      }

      &.epic-large {
        font-size: 56px;
      }
    }
  }

  @media (max-width: 1133px) {
    .text-content {
      &.large {
        font-size: 19px;
      }

      &.epic-large {
        font-size: 47px;
      }
    }
  }

@media (max-width: 769px) {
  .text-content {
    &.large {
      font-size: 16px;
    }

    &.epic-large {
      font-size: 47px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .text-content {
    &.large {
      font-size: 16px;
    }

    &.epic-large {
      font-size: 47px;
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  .text-content {
    &.large {
      font-size: 13px;
    }

    &.epic-large {
      font-size: 47px;
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .text-content {
    &.large {
      font-size: 16px;
    }

    &.epic-large {
      font-size: 47px;
    }
  }
}


@media (max-width: 320px) {
  .text-content {
    &.large {
      font-size: 12px;
    }

    &.epic-large {
      font-size: 47px;
    }
  }
}


  @media (min-width: 2561px) {
    .text-content {
      &.large {
        font-size: 74px;
      }

      &.epic-large {
        font-size: 188px;
      }
    }
  }


</style>