<template>
  <p class="text-menu-content" :class="{'sub-item-active' : subItemActive, 'eng' : activeEng}">
    {{textMenuItem}}
  </p>
</template>

<script>
export default {
  name: "TextMenu",
  props: {
    subItemActive: {
      type: Boolean,
      required: true
    },
    textMenuItem: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
 .text-menu-content {
   font-size: 14px;
   letter-spacing: 2.8px;
   font-family: 'CormorantUnicase-Bold', sans-serif;
   text-transform: lowercase;
   color: var(--color-text);


   &.sub-item-active {
     font-size: 12px;
   }
 }

 @media (max-width: 2560px){
   .text-menu-content {
     font-size: 16px;
     letter-spacing: 3.2px;

     &.sub-item-active {
       font-size: 14px;
     }
   }
 }

 @media (max-width: 2240px) {
   .text-menu-content {
     font-size: 16px;
     letter-spacing: 3.2px;

     &.sub-item-active {
       font-size: 14px;
     }
   }
 }


 @media (max-width: 1920px) {
   .text-menu-content {
     font-size: 14px;
     letter-spacing: 2.8px;
     &.sub-item-active {
       font-size: 12px;
     }
   }
 }


 @media (max-width: 1600px) {
   .text-menu-content {
     font-size: 12px;
     letter-spacing: 2.4px;

     &.sub-item-active {
       letter-spacing: 2px;
       font-size: 10px;
     }
   }
 }

 @media (max-width: 1133px) {
   .text-menu-content {
     font-size: 10px;
     letter-spacing: 2px;
     &.sub-item-active {
       letter-spacing: 1.6px;
       font-size: 8px;
     }
   }
 }


 @media (min-width: 2561px) {
   .text-menu-content {
     font-size: 32px;
     letter-spacing: 5.6px;

     &.sub-item-active {
       font-size: 28px;
     }
   }
 }
</style>