<template>
  <page-case-news-page :active-id="slug"
                       v-if="!isLoading || !isLoadingData"
                       :href-title="'/cases'"
                       :active-main-title="activeMainTitle"
                       :path-includes="'/cases'"
                       :active-main-slide="4"
                       :active-slider="false"
                       :info-content="activeCase"/>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CardNewsCase from "@/components/entities/news-case-page/CardNewsCase.vue";
import MainFooter from "@/components/widgets/MainFooter.vue";
import TitleRouterSubPage from "@/components/shared/TitleRouterSubPage.vue";
import PageCaseNewsPage from "@/components/entities/PageCaseNewsPage.vue";

export default {
  name: "case-page",
  components: {PageCaseNewsPage, TitleRouterSubPage, MainFooter, CardNewsCase},
  props: ['slug'],
  async created() {
    await this.checkInfo();
  },

  data() {
    return {
      isLoadingData: true
    }
  },

  computed: {
    ...mapGetters({
      activeCase: "case/getActiveCaseInfo",
      caseAll: 'case/allCase',
    }),

    activeMainTitle() {
      return this.mainTitlePage.caseScreen;
    },

  },

  watch: {
    slug() {
      this.checkInfo();
    },
    activeEng() {
      this.checkLang();
    },
    activeCase() {
      if(this.activeCase === ''){
        this.IS_LOADING_IMG(false)
        this.changeMainSlide(4)
      }
    }
  },

  methods: {
    ...mapActions({
      getActiveCase: 'case/getActiveCase'
    }),

    ...mapActions(['IS_LOADING_IMG', 'IS_LOADING']),


    async checkLang() {
      try {
        if(this.$i18n.locale === 'en') {
          if (this.activeCase?.translations.find(item => item.lang === 'en') === undefined) {
            this.$router.push('/cases')
          }
        }
      } catch (e) {
          console.log(e)
        }
    },

    async checkInfo() {
      this.isLoadingData = true
      await this.IS_LOADING_IMG(true)
      await this.IS_LOADING(true)
        try {
          await this.getActiveCase({slug: this.slug})
        } catch (e) {
          console.log(e)
        } finally {
          if(this.caseAll !== undefined) {
            this.IS_LOADING(false)
          } else {
            this.isLoadingData = false
          }
        }
    },
  }
}
</script>

<style scoped lang="scss">


</style>