<template>
  <div class="case-card-container" :class="{'not-active-full': !activeCaseFull}" @click="routToCasePage(caseInfo?.slug)">
    <div class="main-info-content">
      <div class="main-title-container">
        <large-title :active-cormorant="false" >
          {{ translationsList?.translations?.name }}
        </large-title>
      </div>

      <div class="main-description-container">
        <main-paragraph>
          {{ translationsList?.translations?.shortName }}
        </main-paragraph>
      </div>
    </div>
    <div class="sub-info-content">

      <div class="practice-info-container" v-if="activeCaseFull && translationsList?.practices.length !== 0">
          <sub-paragraph class="sub-title-content" :active-cormorant="true" v-if="!activeMobile">
            {{ $t('caseInfo.subTitlePractice') }}
          </sub-paragraph>
        <div class="practice-info-text-container">
          <sub-paragraph v-for="item in translationsList?.practices" :active-cormorant="false">
            #{{ item?.name.toLowerCase().split(' ').join('_') }}
          </sub-paragraph>
        </div>
      </div>

      <div class="persons-info-container" v-if="activeCaseFull && translationsList?.users.length !== 0">

        <sub-paragraph class="sub-title-content" :active-cormorant="true">
          {{ $t('caseInfo.subTitlePersons') }}
        </sub-paragraph>
        <div class="persons-info-text-container">
          <card-person-mini v-for="item in caseInfo?.users"
                            :active-super-mini="true"
                            :key="item.id"
                            :person-info="item"/>
<!--          <sub-paragraph class="persons-info-text-content"-->
<!--             v-for="item in translationsList?.users"-->
<!--             :key="item.id" :active-cormorant="false">-->
<!--            {{ item?.fullName }}-->
<!--          </sub-paragraph>-->
        </div>
      </div>

      <main-paragraph class="time-publication-content" :class="{'active-en' : activeEng}">
        {{ datePublicationFormat }}
      </main-paragraph>

    </div>
  </div>
</template>

<script>
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";
import MainParagraph from "@/components/shared/Text/MainParagraph.vue";
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import {mapActions} from "vuex";
import CardPersonMini from "@/components/entities/CardPersonMini.vue";

export default {
  name: "CardCase",
  components: {CardPersonMini, LargeTitle, MainParagraph, SubParagraph},
  props: {
    caseInfo: {
      type: Object,
      required: true
    },
    activeCaseFull: {
      type: Boolean
    }
  },
  computed: {


    // toLowerCasePractice() {
    //   return this.caseInfo.practice.filter(item => item.toLowerCase());
    // },
    datePublicationFormat() {
      return this.datePublicationFormatChange(this.caseInfo?.publishDate)
    },
    translationsList() {
      return {
        translations: this.checkLangCode(this.caseInfo, this.$i18n.locale, 'translations', 'lang'),
        practices: this.caseInfo?.practices.map(item => {
          return this.checkLangCode(item, this.$i18n.locale, 'modifications', 'langCode')
        }),
        users: this.caseInfo?.users.map(item => {
          return this.checkLangCode(item, this.$i18n.locale, 'profiles', 'lang')
        }),
      };
    },
  },
  methods: {
    ...mapActions(['ACTIVE_SUB_PAGE']),
    routToCasePage(slug) {
      this.ACTIVE_SUB_PAGE(true)
      this.$router.push('/cases/' + slug)
    }
  }
}
</script>

<style scoped lang="scss">
  .case-card-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    background-color: var(--color-card);
    transition: box-shadow .6s ease;
    cursor: pointer;
    overflow: hidden;
    &:hover {
      box-shadow: 0px 4px 35px 0px rgba(var(--color-box-shadow-box));
    }

    .main-info-content {
      .main-title-container {
        .text-content {
          font-weight: 600;
        }
      }

      .main-description-container {
        .text-content {
          font-weight: 400;
        }
      }
    }

    .sub-info-content {
      display: flex;
      flex-direction: column;
      .practice-info-container {
        .practice-info-text-container {
          //overflow: hidden;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            width: 5%;
            height: 100%;
            background: linear-gradient(90deg, rgba(34, 34, 59, 0) 0%, var(--color-card) 90%);
          }

          //.text-content {
          //     white-space: nowrap;
          //   }

          .practice-info-text-content {
            font-weight: 500;


            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .persons-info-container {
        .persons-info-text-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .persons-info-text-content {
            font-weight: 500;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .time-publication-content {

        &.active-en {
          font-variant: all-small-caps !important;
        }

        .text-content {
          font-weight: 500;
        }
      }
    }
  }

  @media (max-width: 2560px){
    .case-card-container {
      max-width: 630px;
      padding: 36px 18px;

      &.not-active-full {
        padding: 26px 18px;

        .sub-title-content {
          letter-spacing: 3.267px;
          margin-bottom: 5px;
          font-size: 16.3px;
        }

        .main-info-content {
          .main-title-container {
            margin-bottom: 37px;
            .text-content {
              font-size: 37.3px;
            }
          }
        }
      }

      .sub-title-content {
        font-size: 16px;
        letter-spacing: 3.2px;
        margin-bottom: 4px;
      }

      .main-info-content {
        .main-title-container {
          max-width: 550px;
          margin-bottom: 36px;
          .text-content {
            font-size: 37px;
          }
        }

        .main-description-container {
          .text-content {
            font-size: 21px;
          }
        }
      }

      .sub-info-content {
        row-gap: 36px;
        .practice-info-container {
          .practice-info-text-container {
            column-gap: 8px;
            .practice-info-text-content {
              margin-right: 4px;
              font-size: 16px;
            }
          }
        }

        .persons-info-container {

          .text-content {
            margin-bottom: 10px;
          }

          .persons-info-text-container {
            column-gap: 32px;
            row-gap: 16px;
            max-width: 375px;
          }
        }
        .time-publication-content {
          .text-content {
            font-size: 21px;
          }
        }
      }
    }
  }

  @media (max-width: 2240px){
    .case-card-container {
      max-width: 630px;
      padding: 36px;
      border-radius: 6px;

      &.not-active-full {
        padding: 26px 18px;

        .sub-title-content {
          letter-spacing: 3.267px;
          margin-bottom: 5px;
          font-size: 16.3px;
        }

        .main-info-content {
          .main-title-container {
            margin-bottom: 33px;
            .text-content {
              font-size: 33.6px;
            }
          }
        }
      }

      .sub-title-content {
        font-size: 16px;
        letter-spacing: 3.2px;
        margin-bottom: 4px;
      }

      .main-info-content {
        .main-title-container {
          max-width: 550px;
          margin-bottom: 36px;
          .text-content {
            font-size: 37px;
          }
        }

        .main-description-container {
          .text-content {
            font-size: 21px;
          }
        }
      }

      .sub-info-content {
        row-gap: 36px;
        .practice-info-container {
          .practice-info-text-container {
            .practice-info-text-content {
              margin-right: 4px;
              font-size: 16px;
            }
          }
        }

        .persons-info-container {
          .text-content {
            margin-bottom: 10px;
          }

        }
        .time-publication-content {
          .text-content {
            font-size: 21px;
          }
        }
      }
    }
  }

  @media (max-width: 1920px) {
    .case-card-container {
      max-width: 540px;
      padding: 32px;

      &.not-active-full {
        padding: 24px 16px;

        .sub-title-content {
          letter-spacing: 2.8px;
          margin-bottom: 4px;
          font-size: 14px;
        }

        .main-info-content {
          .main-title-container {
            margin-bottom: 32px;
            .text-content {
              font-size: 32px;
            }
          }

          .main-description-container {
            .text-content {
              font-size: 18px;
            }
          }
        }
      }

      .sub-title-content {
        font-size: 14px;
        letter-spacing: 2.8px;
        margin-bottom: 4px;
      }

      .main-info-content {
        .main-title-container {
          margin-bottom: 32px;
        }

        .main-description-container {
          .text-content {
            font-size: 18px;
          }
        }
      }

      .sub-info-content {
        row-gap: 32px;
        .practice-info-container {
          .practice-info-text-container {
            column-gap: 4px;
            .practice-info-text-content {
              margin-right: 4px;
            }
          }
        }

        .persons-info-container {
          .text-content {
            margin-bottom: 10px;
          }
          .persons-info-text-container {
            column-gap: 20px;
            row-gap: 10px;
            max-width: 360px;
          }
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .case-card-container {
      max-width: 360px;
      padding: 22px;
      border-radius: 4px;

      &.not-active-full {
        padding: 17px 11px;

        .sub-title-content {
          letter-spacing: 1.68px;
          margin-bottom: 2px;
          font-size: 8.4px;
        }

        .main-info-content {
          .main-title-container {
            margin-bottom: 19.2px;
            .text-content {
              font-size: 19.2px;
            }
          }

          .main-description-container {
            .text-content {
              font-size: 10.8px;
            }
          }
        }
      }

      .sub-title-content {
        font-size: 10px;
        letter-spacing: 2px;
        margin-bottom: 3px;
      }

      .main-info-content {
        .main-title-container {
          margin-bottom: 22px;

          .text-content {
            font-size: 22px;
          }
        }
        .main-description-container {
          .text-content {
            font-size: 12px;
          }
        }
      }

      .sub-info-content {
        row-gap: 22px;
        .practice-info-container {
          .practice-info-text-container {
            .practice-info-text-content {
              margin-right: 3px;
              font-size: 9px;
            }
          }
        }

        .persons-info-container {
          .text-content {
            margin-bottom: 6px;
          }
          .persons-info-text-container {
            column-gap: 16px;
            row-gap: 8px;
            max-width: 212px;
          }
        }
        .time-publication-content {
          .text-content {
            font-size: 12px;
          }
        }
      }
    }
  }
  @media (max-height: 800px) {
    .case-card-container {
      padding: 22px;
      max-width: 360px;


      &.not-active-full {
        padding: 17px 11px;

        .sub-title-content {
          letter-spacing: 1.86px;
          margin-bottom: 2px;
          font-size: 9.3px;
        }

        .main-info-content {
          .main-title-container {
            margin-bottom: 21.3px;
            .text-content {
              font-size: 21.3px;
            }
          }

          .main-description-container {
            .text-content {
              font-size: 12px;
            }
          }
        }
      }

      .sub-title-content {
        font-size: 10px;
        letter-spacing: 2px;
        margin-bottom: 3px;
      }

      .main-info-content {
        .main-title-container {
          margin-bottom: 22px;
        }
        .main-description-container {
          .text-content {
            font-size: 12px;
          }
        }
      }

      .sub-info-content {
        row-gap: 22px;
        .practice-info-container {
          .practice-info-text-container {
            .practice-info-text-content {
              margin-right: 3px;
              font-size: 9px;
            }
          }
        }

        .persons-info-container {
          .text-content {
            margin-bottom: 6px;
          }
          .persons-info-text-container {
            column-gap: 16px;
            row-gap: 8px;
            max-width: 212px;
          }
        }
        .time-publication-content {
          .text-content {
            font-size: 12px;
          }
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .case-card-container {
      padding: 28px;
      max-width: 466px;

      &.not-active-full {
        padding: 16px 11px;
        .sub-title-content {
          letter-spacing: 1.86px;
          margin-bottom: 2px;
          font-size: 9.3px;
        }

        .main-info-content {
          .main-title-container {
            margin-bottom: 21.3px;
            .text-content {
              font-size: 21.3px;
            }
          }

          .main-description-container {
            .text-content {
              font-size: 12px;
            }
          }
        }
      }

      .sub-title-content {
        font-size: 12px;
        letter-spacing: 2.4px;
        margin-bottom: 4px;
      }

      .main-info-content {
        .main-title-container {
          margin-bottom: 23px;
          .text-content {
            font-size: 28px;
          }
        }
        .main-description-container {
          .text-content {
            font-size: 14px;
          }
        }
      }

      .sub-info-content {
        row-gap: 28px;
        .practice-info-container {
          .practice-info-text-container {
            .practice-info-text-content {
              margin-right: 3px;
              font-size: 12px;
            }
          }
        }

        .persons-info-container {

          .text-content {
            margin-bottom: 8px;
          }
          .persons-info-text-container {
            column-gap: 20px;
            row-gap: 10px;
            max-width: 320px;
          }
        }
        .time-publication-content {
          .text-content {
            font-size: 16px;
          }
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .case-card-container {
      padding: 22px;
      max-width: 332px;

      &.not-active-full {
        max-width: 286px;
        padding: 17px 8px;
        .sub-title-content {
          letter-spacing: 1.48px;
          margin-bottom: 2px;
          font-size: 7.4px;
        }

        .main-info-content {
          .main-title-container {
            margin-bottom: 16.9px;
            .text-content {
              font-size: 16.9px;
            }
          }

          .main-description-container {
            .text-content {
              line-height: normal;
              font-size: 9.56px;
            }
          }
        }
      }

      .sub-title-content {
        font-size: 9px;
        letter-spacing: 1.8px;
        margin-bottom: 4px;
      }

      .main-info-content {
        .main-title-container {
          margin-bottom: 20px;
            .text-content {
            font-size: 20px;
          }
        }
        .main-description-container {
          .text-content {
            font-size: 11px;
          }
        }
      }

      .sub-info-content {
        row-gap: 18px;
        .practice-info-container {
          .practice-info-text-container {
            column-gap: 2px;
            .practice-info-text-content {
              margin-right: 2.7px;
              font-size: 8px;
            }
          }
        }

        .persons-info-container {

          .text-content {
            margin-bottom: 6px;
          }

          .persons-info-text-container {
            column-gap: 16px;
            row-gap: 8px;
            max-width: 200px;
          }
        }
        .time-publication-content {
          .text-content {
            font-size: 11px;
          }
        }
      }
    }
  }

  @media (max-width: 769px) {
    .case-card-container {
      padding: 16px;
      max-width: 296px;

      &:hover {
        box-shadow: 0 0 0 0 rgba(0,0,0,0);
      }

      &.not-active-full {
        max-width: 306px;
        padding: 16px;

        .main-info-content {
          .main-title-container {
            margin-bottom: 20px;
            .text-content {
              font-size: 16px;
            }
          }

          .main-description-container {
            .text-content {
              line-height: normal;
              font-size: 12px;
            }
          }
        }
      }

      .sub-title-content {
        font-size: 11px;
        letter-spacing: 2.2px;
        margin-bottom: 4px;
      }

      .main-info-content {
        .main-title-container {
          margin-bottom: 16px;
          .text-content {
            font-size: 16px;
          }
        }
        .main-description-container {
          .text-content {
            line-height: 140%;
            font-size: 12px;
          }
        }
      }

      .sub-info-content {
        row-gap: 24px;
        overflow: hidden;
        .practice-info-container {
          .practice-info-text-container {
            flex-direction: column;
            .text-content {
              color: var(--color-stroke);
              font-weight: 500;
              overflow: hidden;
              margin-right: 6px;
              font-size: 12px;
              white-space: nowrap;
            }
          }
        }

        .persons-info-container {
          .persons-info-text-container {
            justify-content: space-between;
            column-gap: 16px;
            row-gap: 8px;
            max-width: 100%;
            .text-content {
              margin-bottom: 8px;
              font-size: 12px;
            }
          }
        }
        .time-publication-content {
          .text-content {
            font-size: 12px;
          }
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .case-card-container {
      padding: 16px;
      max-width: 335px;

      &.not-active-full {
        max-width: 335px;
        padding: 16px;

        .main-info-content {
          .main-title-container {
            margin-bottom: 20px;
            .text-content {
              font-size: 18px;
            }
          }

          .main-description-container {
            .text-content {
              line-height: normal;
              font-size: 14px;
            }
          }
        }
      }

      .sub-title-content {
        font-size: 12px;
        letter-spacing: 2.4px;
        margin-bottom: 4px;
      }

      .main-info-content {
        .main-title-container {
          margin-bottom: 16px;
          .text-content {
            font-size: 18px;
          }
        }
        .main-description-container {
          .text-content {
            line-height: 140%;
            font-size: 14px;
          }
        }
      }

      .sub-info-content {
        row-gap: 32px;
        .practice-info-container {
          .practice-info-text-container {
            .text-content {
              font-size: 14px;
            }
          }
        }

        .persons-info-container {
          .persons-info-text-container {
            .text-content {
              margin-bottom: 8px;
              font-size: 14px;
            }
          }
        }
        .time-publication-content {
          .text-content {
            font-size: 14px;
          }
        }
      }
    }
  }




  @media (max-width: 375px){
    .case-card-container {
      padding: 16px;
      max-width: 254px;

      &.not-active-full {
        max-width: 254px;
        padding: 16px;

        .main-info-content {
          .main-title-container {
            margin-bottom: 20px;
            .text-content {
              font-size: 14px;
            }
          }

          .main-description-container {
            .text-content {
              line-height: normal;
              font-size: 12px;
            }
          }
        }
      }

      .sub-title-content {
        font-size: 9px;
        letter-spacing: 1.8px;
        margin-bottom: 4px;
      }

      .main-info-content {
        .main-title-container {
          margin-bottom: 16px;
          .text-content {
            font-size: 14px;
          }
        }
        .main-description-container {
          max-height: 68px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          .text-content {

            font-size: 12px;
          }
        }
      }

      .sub-info-content {
        row-gap: 20px;
        .practice-info-container {
          .practice-info-text-container {
            .text-content {
              font-size: 9px;
            }
          }
        }

        .persons-info-container {
          .persons-info-text-container {
            .text-content {
              margin-bottom: 6px;
              font-size: 9px;
            }
          }
        }
        .time-publication-content {
          .text-content {
            font-size: 10px;
          }
        }
      }
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .case-card-container {
      padding: 16px;
      max-width: 296px;

      &.not-active-full {
        max-width: 306px;
        padding: 16px;

        .main-info-content {
          .main-title-container {
            margin-bottom: 20px;
            .text-content {
              font-size: 16px;
            }
          }

          .main-description-container {
            .text-content {
              line-height: normal;
              font-size: 12px;
            }
          }
        }
      }

      .sub-title-content {
        font-size: 11px;
        letter-spacing: 2.2px;
        margin-bottom: 4px;
      }

      .main-info-content {
        .main-title-container {
          margin-bottom: 16px;
          .text-content {
            font-size: 16px;
          }
        }
        .main-description-container {
          .text-content {
            font-size: 12px;
          }
        }
      }

      .sub-info-content {
        row-gap: 24px;
        .practice-info-container {
          .practice-info-text-container {
            .text-content {
              font-size: 12px;
            }
          }
        }

        .persons-info-container {
          .persons-info-text-container {
            .text-content {
              margin-bottom: 8px;
              font-size: 12px;
            }
          }
        }
        .time-publication-content {
          .text-content {
            font-size: 12px;
          }
        }
      }
    }
  }

  @media (max-width: 320px) {
    .case-card-container {
      padding: 16px;
      max-width: 254px;

      &.not-active-full {
        max-width: 254px;
        padding: 16px;

        .main-info-content {
          .main-title-container {
            margin-bottom: 20px;
            .text-content {
              font-size: 12px;
            }
          }

          .main-description-container {
            .text-content {
              line-height: normal;
              font-size: 10px;
            }
          }
        }
      }

      .sub-title-content {
        font-size: 9px;
        letter-spacing: 1.8px;
        margin-bottom: 4px;
      }

      .main-info-content {
        .main-title-container {
          margin-bottom: 8px;
          .text-content {
            font-size: 12px;
          }
        }
        .main-description-container {
          .text-content {
            font-size: 10px;
          }
        }
      }

      .sub-info-content {
        row-gap: 16px;
        .practice-info-container {
          .practice-info-text-container {
            .text-content {
              font-size: 9px;
            }
          }
        }

        .persons-info-container {
          .persons-info-text-container {
            .text-content {
              margin-bottom: 6px;
              font-size: 9px;
            }
          }
        }
        .time-publication-content {
          .text-content {
            font-size: 9px;
          }
        }
      }
    }
  }



  @media (min-width: 2561px) {
    .case-card-container {
      border-radius: 8px;
      max-width: 1260px;
      padding: 72px;

      &.not-active-full {
        padding: 56px 36px;

        .sub-title-content {
          letter-spacing: 6.533px;
          margin-bottom: 9px;
          font-size: 32.6px;
        }

        .main-info-content {
          .main-title-container {
            margin-bottom: 74px;
            .text-content {
              font-size: 74.67px;
            }
          }
        }
      }


      .sub-title-content {
        letter-spacing: 6.4px;
        margin-bottom: 8px;
        font-size: 32px;
      }

      .main-info-content {
        .main-title-container {
          max-width: 948px;
          margin-bottom: 72px;
          .text-content {
            font-size: 74px;
          }
        }
        .main-description-container {
          .text-content {
            font-size: 42px;
          }
        }

      }

      .sub-info-content {
        row-gap: 72px;
        .practice-info-container {
          .practice-info-text-container {
            column-gap: 12px;
            .practice-info-text-content {
              margin-right: 12px;
            }
          }
        }

        .persons-info-container {

          .text-content {
            margin-bottom: 14px;
          }

          .persons-info-text-container {
            column-gap: 32px;
            row-gap: 16px;
            max-width: 652px;
          }
        }
      }
    }
  }

</style>