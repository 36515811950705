<template>
  <div class="main-button-container"
       :class="{'disabled' : disabledButton, 'active' : activeButton, 'active-dark' : activeColor}">
    <sub-paragraph :active-cormorant="false" :class="{'eng' : activeEng }">
      <slot/>
    </sub-paragraph>
  </div>
</template>

<script>
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";

export default {
  name: "ButtonMain",
  components: {SubParagraph},
  props: {
    disabledButton: {
      type: Boolean,
      required: true
    },
    activeButton: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">

  .main-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .5s ease;
    max-width: fit-content;
    opacity: .6;
    cursor: pointer;
    background: var(--color-card);

    &:hover {
      opacity: 1;
    }

    &.disabled {
      opacity: .2;
      cursor: default;
    }

    &.active-dark {
        &.active {
        .text-content {
          color: var(--color-background);
        }
      }
    }

    &.active {
      background: var(--color-light-text);
      border: none;
      opacity: 1;

      //.text-content {
      //  color: var(--color-background);
      //}
    }

    .text-content {
      font-weight: 500;

      &.eng {
        font-variant: all-small-caps;
      }
    }
  }

  @media (max-width: 2560px){
    .main-button-container {
      height: 39px;
      border-radius: 9px;
      padding: 0 24px 4px;
    }
  }

  @media (max-width: 1920px) {
    .main-button-container {
      height: 33px;
      border-radius: 8px;
      padding: 0 16px 4px;
    }
  }

  @media (max-width: 1600px) {
    .main-button-container {
      height: 23px;
      border-radius: 6px;
      padding: 0 14px;
    }
  }
  @media (max-height: 800px) {
    .main-button-container {
      height: 23px;
      border-radius: 6px;
      padding: 0 14px;
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .main-button-container {
      height: 23px;
      border-radius: 6px;
      padding: 0 14px;
    }
  }

  @media (max-width: 1133px) {
    .main-button-container {
      height: 20px;
      border-radius: 5px;
      padding: 0 12px;
    }
  }

  @media (max-width: 769px) {
    .main-button-container {
      height: 25px;
      border-radius: 8px;
      padding: 0 10px 4px;

      .text-content {
        font-size: 10px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .main-button-container {
      height: 28px;
      border-radius: 8px;
      padding: 0 12px 4px;

      .text-content {
        font-size: 10px;
      }
    }
  }

  @media (max-width: 375px){
    .main-button-container {
      height: 25px;
      border-radius: 8px;
      padding: 0 10px 4px;

      .text-content {
        font-size: 9px;
      }
    }
  }


  @media (max-width: 320px) {
    .main-button-container {
      height: 23px;
      border-radius: 8px;
      padding: 0 8px 4px;

      .text-content {
        font-size: 9px;
      }
    }
  }




  @media (min-width: 2561px) {
    .main-button-container {
      height: 78px;
      border-radius: 18px;
      padding: 0 48px 8px;
    }
  }

</style>