<template>
  <slider-case :active-info="activeLangFilter"
               :activePersonPage="activePersonPage"
               :active-sub-page="activeSubPage"
               v-if="!activeMobile && activeLangFilter.length !== 0"
               :active-case-full="activeCaseFull"
               :setting-swiper="settingSwiper"/>
  <slider-news-mobile v-else-if="activeMobile && activeLangFilter.length !== 0"
                      :active-gradient="false"
                      :active-news-full="activeCaseFull"
                      :type-slider="'case'"
                      :type-router="'case'"
                      :setting-swiper="settingSwiper"
                      :active-info="activeLangFilter"/>
</template>

<script>
import {mapGetters} from "vuex";
import SliderCase from "@/components/entities/SliderCase.vue";
import SliderNewsMobile from "@/components/entities/SliderNewsMobile.vue";

export default {
  name: "SliderCaseUser",
  components: {SliderNewsMobile, SliderCase},
  props: {
    settingSwiper: {
      type: Object,
      required: true
    },
    activeCaseFull: {
      type: Boolean,
      default: true
    },
    activeSubPage: {
      type: Boolean,
      default: false
    },
    activePersonPage: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters('persons',['activeUserCaseAll']),
    ...mapGetters('case',['allCase']),
    activeLangFilter() {
      if(this.activeUserCaseAll?.length !== 0) {
        return this.activeUserCaseAll.filter(item => {
          return item?.translations.find(item => item.lang === this.$i18n.locale)
        })
      } else {
       return []
      }

    },
  },
  mounted() {
    this.$emit('activeLength', this.activeLangFilter.length)
  },
}
</script>

<style scoped>

</style>