<template>
  <label :for="'fileInput ' + activeInput" class="file-input-content" @mouseenter="focusFalse" @mouseleave="focusTrue">
    <input accept=".doc, .docx, .pdf" name="file" type="file" :id="'fileInput ' + activeInput"  @change="handleFileChange">
      <label :for="'fileInput ' + activeInput" class="icon-and-text-container">
        <div class="icon-and-text-content">
          <sub-paragraph :active-cormorant="false">
            <slot/>
          </sub-paragraph>
          <button-icon :img-name="'download'"/>
        </div>
      </label>
  </label>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import LargeTitle from "@/components/shared/Text/LargeTitle.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";

export default {
  name: "InputFile",
  components: {SubParagraph, LargeTitle, ButtonIcon},
  props: {
    activeInput: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      fileSrc: null,
    }
  },
  methods: {

    handleFileChange(event) {
      this.fileSrc = event.target.files[0];
      if(this.fileSrc) {
        let fileSize = this.fileSrc.size / 1000000.0;
        if(fileSize <= 5) {
          this.$emit('changeActiveFile', this.fileSrc)
        } else {
          this.fileSrc = false;
          this.$emit('changeActiveFile', undefined)
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">


.file-input-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  border-style: dashed;
  border-color: var(--color-stroke);;
  background-color: var(--color-field);
  transition: opacity .5s ease;
  opacity: .6;

  .icon-and-text-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    .icon-and-text-content {
      transition: opacity .5s ease;
      opacity: .4;
      display: flex;
      justify-content: center;
      .text-content {
        white-space: nowrap;
      }
    }
  }

  &:hover {
    opacity: 1;

    .icon-and-text-container {
      .icon-and-text-content {
        opacity: 1;
      }
    }
  }
}


  #fileInput {
    position: absolute;
    z-index: 999;
  }

.file-input-content input[type="file"] {
  display: none;
}

@media (max-width: 2560px){
  .file-input-content {
    border-radius: 9px;
    border-width: 2.5px;

    .icon-and-text-container {
      .icon-and-text-content {

        .text-content  {
          font-size: 16px;
        }
        .button-icon-container {
          margin-left: 28px;
          width: 28px;
          height: 28px;
        }
      }
    }
  }
}

@media (max-width: 1920px) {
  .file-input-content {
    border-radius: 8px;
    border-width: 2px;

    .icon-and-text-container {
      .icon-and-text-content {
        .text-content {
          font-size: 14px;
        }

        .button-icon-container {
          margin-left: 24px;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .file-input-content {
    border-radius: 5px;
    border-width: 1px;

    .icon-and-text-container {
      .icon-and-text-content {
        .text-content  {
          font-size: 10px;
        }
        .button-icon-container {
          margin-left: 16px;
        }
      }
    }
  }
}


@media (max-width: 1133px) {
  .file-input-content {
    border-radius: 4px;
    border-width: 1px;

    .icon-and-text-container {
      .icon-and-text-content {
        .text-content  {
          font-size: 9px;
        }
        .button-icon-container {
          margin-left: 14px;
        }
      }
    }
  }
}

@media (max-width: 769px) {
  .file-input-content {
    border-radius: 4px;
    border-width: 1px;

    .icon-and-text-container {
      .icon-and-text-content {
        .text-content  {
          font-size: 10px;
        }
        .button-icon-container {
          margin-left: 8px;
        }
      }
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}




@media (min-width: 2561px) {
  .file-input-content {
    border-radius: 18px;
    border-width: 4.5px;

    .icon-and-text-container {
      max-width: 389px;
      .icon-and-text-content {

        .button-icon-container {
          margin-left: 56px;
          width: 56px;
          height: 56px;
        }
      }
    }
  }
}


</style>