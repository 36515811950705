<template>
  <div class="start-screen-container">
    <transition name="fade-opacity-animation">
    <div  class="main-slide-container first-title" v-if="activeStartTitle">
      <div class="first-screen-container first-title" v-if="!activeMobile">
          <p class="title-content first-sub" >
            {{ $t('startScreen.firstTitleUp') }}
          </p>
            <p class="title-content first-sub">
              {{ $t('startScreen.firstTitleDown') }}
            </p>
      </div>
      <div class="first-screen-container first-title" v-else>
        <p class="title-content first-sub" >
          {{ $t('startScreen.mainTitleMobile') }}
        </p>
      </div>
    </div>
    </transition>
    <transition name="fade-opacity-animation">
    <div class="main-slide-container logo" v-if="activeLogo">
      <div class="first-screen-container">
        <div class="main-logo-container"
             :class="{'active-dark' : activeColor}">
          <div class="lottie-wrapper">
            <LottieAnimation :animationData="require('@/assets/lottie/LogoMain.json')"
                             style="height: inherit"
                             :flex-growth="'None'"
                             :loop="false"
                             :delay="1000"
                             :rendererSettings="{ preserveAspectRatio: 'xMidYMid meet' }" />
          </div>
        </div>
      </div>
    </div>
    </transition>
    <transition name="fade-opacity-lazy">
        <div class="main-slide-container main-title" v-if="activeEndSlide">
          <div class="first-screen-container ">
            <div class="main-title-container">
              <transition name="fade-opacity-animation">
                <p class="title-content main" v-if="activeMainTitle">
                  {{logoText}}
                </p>
              </transition>
              <div class="title-container">
                <transition name="fade-opacity-animation">
                  <p class="title-content second-sub" v-if="activeSubTitle">
                    {{$t('startScreen.secondTitle')}}
                  </p>
                </transition>
              </div>
            </div>
          </div>
          <transition name="fade-opacity-move-y">
            <sub-controller v-if="activeStartController && !activeMobile"/>
          </transition>
        </div>
    </transition>
  </div>
</template>

<script>
import SubController from "@/components/widgets/SubController.vue";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import {mapActions} from "vuex";

export default {
  name: "StartScreen",
  components: {ButtonIcon, SubController},
  props: {
    activeScreen:{
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      activeStartSlide: 0,
      logoText: 'IM LEGAL',
      imgLogo: 'main-logo.svg',

      activeStartController: false,
      activeStartTitle: false,
      activeMainTitle: false,
      activeSubTitle: false,
      activeLogo: false,
      activeEndSlide: false
    }
  },
  mounted() {
    if(this.activeScreen) {
      this.$router.push({name: 'main'})
    }
    this.startAnimation();
  },

  computed: {
    activeCookieModal() {
      return this.$store.state.activeCookieModal;
    }
  },

  watch: {
    activeScreen() {
      if(this.activeScreen) {
        this.$router.push({name: 'main'})
      }
      if(!this.activeLogo) {
        this.startAnimation();
      }
    }
  },

  methods: {
    ...mapActions(['ACTIVE_MOBILE_MENU', 'ACTIVE_START_SLIDE']),
    startAnimation() {
      if(this.activeScreen) {
        if(!Boolean(localStorage.getItem('cookieActive'))) {
          this.$emit('checkStartSwiper', 0)
          this.ACTIVE_START_SLIDE(0)
        } else {
          this.$emit('checkStartSwiper', 2)
          this.ACTIVE_START_SLIDE(2)
        }
        this.IS_LOADING(false)
        setTimeout(() => {
          this.activeStartTitle = true;
            setTimeout(() => {
              this.activeLogo = true
              setTimeout(() => {
                this.activeStartTitle = false;
                setTimeout(() => {
                  this.activeEndSlide = true
                  setTimeout(() => {
                    this.activeMainTitle = true
                    this.activeSubTitle = true
                    setTimeout(() => {
                      this.activeStartController = true
                      if(!Boolean(localStorage.getItem('cookieActive'))) {
                        this.$emit('checkStartSwiper', 2)
                        this.ACTIVE_START_SLIDE(2)
                      }
                    }, 500)
                    this.ACTIVE_MOBILE_MENU(true)
                  }, 250)
                }, 1000)
              }, 2500)
            }, 750)
        }, 300)
      }
    },
  }
}
</script>

<style scoped lang="scss">

.main-slide-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;

  &.first-title {
    z-index: 1;
  }

  &.logo {
    z-index: 0;
  }
}

.start-screen-container {
  width: 100%;
  height: 100%;
}

.first-screen-container {
  display: flex;
  width: 100%;
  height: var(--calc-height);
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;

  &.main-title {
    position: absolute;
  }

  &.first-title {
    padding: 0;
  }

  .main-logo-container {
    position: relative;
    height: 100%;
    width: 100%;

    .lottie-wrapper {
      right: 0;
      top: 0;
      justify-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
    }
    & img {
      width: 100%;
      height: 100%;
    }

    &.active-dark {
      opacity: .4;
    }
  }


  .title-content {
    font-family: 'CormorantUnicase-Regular',sans-serif;
    text-transform: uppercase;
    text-align: center;
    white-space: pre-wrap;

    &.main {
      font-family: 'CormorantUnicase-Bold',sans-serif;
      line-height: 120%;
    }

    &.first-sub {
      line-height: 200%;
    }

  }

  .main-title-container {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 2560px){
  .first-screen-container {
    padding: 230px 0 328px;

    .title-container {
      height: 46px;
    }

    .main-logo-container {
      max-width: 100%;
    }

    .title-content {
      font-size: 38px;

      &.main {
        font-size: 186px;
      }

      &.first-sub {
        letter-spacing: 13.3px;
      }

      &.second-sub {
        letter-spacing: 9.5px;
      }
    }
  }
}

@media (max-width: 2240px) {
  .first-screen-container {
    padding: 140px 0 238px;
  }
}


@media (max-width: 1920px) {

  .first-screen-container {
    padding: 92px 0 185px;


    .title-container {
      height: 39px;
    }

    .title-content {
      font-size: 32px;

      &.main {
        font-size: 160px;
      }

      &.first-sub {
        letter-spacing: 11.2px;
      }

      &.second-sub {
        letter-spacing: 8px;
      }
    }
  }
}

@media (max-width: 1600px) {
  .first-screen-container {
    padding: 152px 0 244px;

    .title-container {
      height: 46px;
    }


    .title-content {
      font-size: 22px;

      &.main {
        font-size: 106px;
      }

      &.first-sub {
        letter-spacing: 7.7px;
      }

      &.second-sub {
        letter-spacing: 5.5px;
      }
    }
  }
}
@media (max-height: 800px) {
  .first-screen-container {
    padding: 62px 0 154px;

    .title-container {
      height: 27px;
    }

    .title-content {
      font-size: 22px;

      &.main {
        font-size: 106px;
      }

      &.first-sub {
        letter-spacing: 7.7px;
      }

      &.second-sub {
        letter-spacing: 5.5px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .first-screen-container {
    padding: 195px 0 291px;

    .title-container {
      height: 27px;
    }

    .title-content {
      font-size: 24px;

      &.main {
        font-size: 114px;
      }

      &.first-sub {
        font-size: 24px;
        letter-spacing: 8.4px;
      }

      &.second-sub {
        font-size: 22px;
        letter-spacing: 5.5px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .first-screen-container {
    padding: 95px 0 203px;

    .title-container {
      height: 27px;
    }


    .title-content {
      font-size: 20px;

      &.main {
        font-size: 96px;
      }

      &.first-sub {
        font-size: 20px;
        letter-spacing: 7px;
      }

      &.second-sub {
        font-size: 20px;
        letter-spacing: 4.75px;
      }
    }
  }
}

@media (max-width: 769px) {
  .first-screen-container {
    overflow: hidden;
    padding: 140px 0;

    .title-container {
      height: 27px;
    }


    .title-content {
      font-size: 14px;

      &.main {
        font-size: 69px;
      }

      &.first-sub {
        font-size: inherit;
        padding: 0 39px;
        letter-spacing: 4.9px;
      }

      &.second-sub {
        font-size: inherit;
        letter-spacing: 3.5px;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .first-screen-container {
    padding: 154px 0;

    .title-container {
      height: 27px;
    }


    .title-content {
      font-size: 16px;

      &.main {
        font-size: 76px;
      }

      &.first-sub {
        font-size: inherit;
        padding: 0 43px;
        letter-spacing: 5.6px;
      }

      &.second-sub {
        font-size: inherit;
        letter-spacing: 3.789px;
      }
    }
  }
}


@media (max-width: 375px){
  .first-screen-container {
    padding: 109px 0;

    .title-container {
      height: 27px;
    }


    .title-content {
      font-size: 14px;

      &.main {
        font-size: 68px;
      }

      &.first-sub {
        padding: 0 34px;
        letter-spacing: 4.9px;
      }

      &.second-sub {
        letter-spacing: 3.5px;
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .first-screen-container {
    padding: 137px 0;

    .title-container {
      height: 27px;
    }



    .title-content {
      font-size: 14px;

      &.main {
        font-size: 68px;
      }

      &.first-sub {
        padding: 0 34px;
        letter-spacing: 4.9px;
      }

      &.second-sub {
        letter-spacing: 3.5px;
      }
    }
  }
}


@media (max-width: 320px) {
  .first-screen-container {
    padding: 93px 0;

    .title-container {
      height: 27px;
    }



    .title-content {
      font-size: 12px;

      &.main {
        font-size: 58px;
      }

      &.first-sub {
        padding: 0 28px;
        letter-spacing: 4.2px;
      }

      &.second-sub {
        letter-spacing: 3px;
      }
    }
  }
}



@media (min-width: 2561px) {
  .first-screen-container {
    padding: 214px 0 547px;

    .title-container {
      height: 90px;
    }


    .title-content {
      font-size: 74px;

      &.main {
        font-size: 372px;
      }

      &.first-sub {
        letter-spacing: 25.9px;
      }

      &.second-sub {
        letter-spacing: 18.5px;
      }
    }
  }
}

</style>