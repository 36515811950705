<template>
  <p class="small-title-container" :class="{'active-color-scheme': activeColorScheme}">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "FooterTitle",
  props: {
    activeColorScheme: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
 .small-title-container {
   line-height: 120%;
   color: #22223B;
   font-family: 'ProximaNova-Regular', sans-serif;
   &.active-color-scheme {
      color: var(--color-text);
   }
 }

 @media (max-width: 2560px){
   .small-title-container {
     margin-bottom: 28px;
     font-size: 24px;
   }
 }


 @media (max-width: 1920px) {
   .small-title-container {
     margin-bottom: 24px;
     font-size: 20px;
   }
 }

 @media (max-width: 1600px) {
   .small-title-container {
     margin-bottom: 16px;
     font-size: 14px;
   }
 }
 @media (max-height: 800px) {
   .small-title-container {
     margin-bottom: 16px;
     font-size: 14px;
   }
 }

 @media (max-width: 1400px) and (min-height: 801px) {
   .small-title-container {
     margin-bottom: 18px;
     font-size: 18px;
   }
 }

 @media (max-width: 1133px) {
   .small-title-container {
     margin-bottom: 16px;
     font-size: 12px;
   }
 }
 @media (max-width: 769px) {
   .small-title-container {
     margin-bottom: 14px;
     font-size: 19px;
   }
 }

 @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
   .small-title-container {
     margin-bottom: 18px;
     font-size: 20px;
   }
 }


 @media (max-width: 375px){
   .small-title-container {
     margin-bottom: 14px;
     font-size: 16px;
   }
 }


 @media (max-width: 375px) and (min-height: 630px) {
   .small-title-container {
     margin-bottom: 14px;
     font-size: 19px;
   }
 }


 @media (max-width: 320px) {
   .small-title-container {
     margin-bottom: 8px;
     font-size: 16px;
   }
 }




 @media (min-width: 2561px) {
   .small-title-container {
     margin-bottom: 56px;
     font-size: 46px;
   }
 }


</style>