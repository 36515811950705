<template>
  <transition name="fade-opacity-lazy" mode="out-in">
    <div class="input-file-container" v-if="!activeChangeFile">
      <input-file :active-input="activeInput" @changeActiveFile="changeActiveFile">
        <slot name="start"></slot>
      </input-file>
    </div>
    <div class="input-file-container" v-else>
      <sub-paragraph :active-cormorant="false">
        <slot name="left"></slot>
      </sub-paragraph>
      <input-file :active-input="activeInput" @changeActiveFile="changeActiveFile">
        <slot name="right"></slot>
      </input-file>
    </div>
  </transition>
</template>

<script>
import InputFile from "@/components/shared/InputFile.vue";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import SubParagraph from "@/components/shared/Text/SubParagraph.vue";

export default {
  name: "InputFileBlock",
  components: {SubParagraph, ButtonIcon, InputFile},
  props: {
    activeInput: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeChangeFile: false,
      indexActiveUpload: 0,
      activeFile: false
    }
  },
  methods: {
    changeActiveFile(file) {
      this.$emit('changeActiveFile', this.activeFile, false)
      if(file) {
        this.activeFile = file
        this.indexActiveUpload = 1;
        this.activeChangeFile = true;
        this.$emit('changeActiveFile', this.activeFile, false)
      } else {
        this.activeFile = false;
        this.indexActiveUpload = 0;
        this.activeChangeFile = false;
        this.$emit('changeActiveFile', this.activeFile, true)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .input-file-container {
    display: flex;
    flex-direction: row;
    position: relative;

    .text-content {
      width: 100%;
      justify-content: center;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 769px) {
    .input-file-container {
      .text-content {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 320px) {
    .input-file-container {
      .text-content {
        font-size: 10px;
      }
    }
  }


</style>